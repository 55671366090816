import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { AuthService } from '@scandium-oy/ngx-scandium';
import { endOfDay, startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Timesheet } from '../models/timesheet.model';
import { firestoreInLimit } from '../utility/constants';
import { Roles } from '../utility/role';
import { ContractorsService } from './contractors.service';
import { FirestoreService } from './firestore.service';
import { UsersService } from './users.service';

const itemCollection = 'timesheets';

@Injectable({
  providedIn: 'root',
})
export class TimesheetsService {

  constructor(
    private authService: AuthService,
    private contractorsService: ContractorsService,
    private firestore: FirestoreService,
    private usersService: UsersService,
  ) { }

  private setDates(ts: Timesheet): Timesheet {
    if (ts) {
      ts.dateIn = ts.dateIn ? ts.dateIn.toDate() : null;
      ts.dateOut = ts.dateOut
        ? ts.dateOut.toDate()
        : null;
      if (ts.userDates) {
        ts.userDates.in = ts.userDates.in ? ts.userDates.in.toDate() : null;
        ts.userDates.out = ts.userDates.out
          ? ts.userDates.out.toDate()
          : null;
      }
    }
    return ts;
  }

  private returnList(results: Timesheet[]): Timesheet[] {
    return results.filter((ts) => ts.deleted == null).map((ts) => this.setDates(ts));
  }

  private construct(options?: {
    site?: string;
    sites?: string[];
    startDate?: Date;
    endDate?: Date;
    checked?: boolean;
    user?: string;
    date?: Date;
    project?: string;
    contractor?: string;
  }) {
    const queryConstraints = [];
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    } else if ([Roles.admin, Roles.worker, Roles.storage].includes(this.usersService.currentUserS().role)) {
      queryConstraints.push(where('contractor', '==', this.contractorsService.contractorS()?.guid));
    }
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.sites) {
      if (options.sites.length > firestoreInLimit) {
        console.error(`Timesheets service, sites > ${firestoreInLimit}`, options.sites);
      }
      queryConstraints.push(where('site', 'in', options.sites));
    }
    if (options?.startDate) {
      const startDate = startOfDay(options.startDate);
      queryConstraints.push(where('dateIn', '>=', startDate));
    }
    if (options?.endDate) {
      const endDate = endOfDay(options.endDate);
      queryConstraints.push(where('dateIn', '<=', endDate));
    }
    if (options?.checked !== undefined) {
      queryConstraints.push(where('checked', '==', options.checked));
    }
    if (options?.user) {
      queryConstraints.push(where('user', '==', options.user));
    }
    if (options?.date) {
      const dateString = options.date.toLocaleDateString('fi');
      queryConstraints.push(where('date', '==', dateString));
    }
    if (options?.project) {
      queryConstraints.push(where('project', '==', options.project));
    }
    return queryConstraints;
  }

  async save(item: Timesheet, isAdmin = false) {
    if (!isAdmin) {
      const user = this.authService.getCurrentUser();
      const username = this.usersService.currentUserS()?.displayName;
      item.user = user.uid;
      item.username = username ?? user.displayName;
    }
    return this.firestore.save<Timesheet>(itemCollection, item);
  }

  async update(item: Timesheet) {
    const user = this.authService.getCurrentUser();
    item.updater = user?.uid ?? null;
    return this.firestore.update(itemCollection, item);
  }

  async remove(item: Timesheet) {
    const user = this.authService.getCurrentUser();
    item.updater = user?.uid ?? null;
    return this.firestore.softDelete(itemCollection, item);
  }

  get(site: string, date: string): Observable<Timesheet[]> {
    const user = this.authService.getCurrentUser();
    const contractor = this.contractorsService.contractorS()?.guid;
    const queryConstraints = [
      where('date', '==', date),
      where('user', '==', user.uid),
      where('contractor', '==', contractor),
    ];
    if (site != null) {
      queryConstraints.push(where('site', '==', site));
    }
    return this.firestore.getList<Timesheet>(
      itemCollection,
      undefined,
      queryConstraints,
    ).pipe(
      map((results) => this.returnList(results)),
    );
  }

  getOnce(site: string, date: string): Observable<Timesheet[]> {
    const user = this.authService.getCurrentUser();
    const contractor = this.contractorsService.contractorS()?.guid;
    return this.firestore.getListOnce<Timesheet>(
      itemCollection,
      undefined,
      [
        where('site', '==', site),
        where('date', '==', date),
        where('user', '==', user.uid),
        where('contractor', '==', contractor),
      ],
    ).pipe(
      map((results) => this.returnList(results)),
    );
  }

  getList(
    options?: {
      site?: string;
      sites?: string[];
      startDate?: Date;
      endDate?: Date;
      checked?: boolean;
      user?: string;
      date?: Date;
      project?: string;
      contractor?: string;
    },
    sort?: boolean,
  ): Observable<Timesheet[]> {
    const queryConstraints = this.construct(options);
    let sortBy = undefined;
    if (sort) {
      sortBy = { value: 'dateIn', sort: 'asc' };
    }

    return this.firestore
      .getList<Timesheet>(itemCollection, sortBy, queryConstraints)
      .pipe(
        map((results) => this.returnList(results)),
      );
  }

  getListOnce(
    options?: {
      site?: string;
      sites?: string[];
      startDate?: Date;
      endDate?: Date;
      checked?: boolean;
      user?: string;
      date?: Date;
      project?: string;
      contractor?: string;
    },
    sort?: boolean,
  ): Observable<Timesheet[]> {
    const queryConstraints = this.construct(options);
    let sortBy = undefined;
    if (sort) {
      sortBy = { value: 'dateIn', sort: 'asc' };
    }

    return this.firestore
      .getListOnce<Timesheet>(itemCollection, sortBy, queryConstraints)
      .pipe(
        map((results) => this.returnList(results)),
      );
  }
}
