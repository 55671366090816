import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { CameraButtonModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectAreaDialogComponent } from 'src/app/components/select-area/select-area.dialog';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { Room } from 'src/app/models/room.model';
import { deepClone } from 'src/app/utility/object';

export interface SiteReportNote {
  images: string[];
  name: string;
  area: string;
  text: string;
}

@Component({
  standalone: true,
  selector: 'app-new-report-note-dialog',
  templateUrl: './new-note.dialog.html',
  styleUrls: ['./new-note.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
    CameraButtonModule,
    TakeVideoComponent,
  ],
})
export class NewReportNoteDialogComponent {
  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);

  readonly formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    text: [''],
    images: [Array.from<string>([])],
    area: [null],
  });

  onUrl(url: string) {
    const images = this.formGroup.get('images').value ?? [];
    images.push(url);
    this.formGroup.get('images').setValue(images);
  }

  selectArea() {
    const site = this.navParams.get('site');
    this.modalCtrl.create({ component: SelectAreaDialogComponent, componentProps: { site } }).then((m) => {
      m.present();

      m.onDidDismiss<Room>().then((data) => {
        if (data.data) {
          const room = deepClone(data.data);
          const roomName = room.siteArea ? `${room.siteArea.name} / ${room.id}` : room.id;
          this.formGroup.get('area').setValue(roomName);
        }
      });
    });
  }

  onSave() {
    const item = this.formGroup.value;
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
