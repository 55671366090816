import { FirestoreItem } from '../models/firestore-item.model';
import { ListItem } from '../models/list-item.model';

export function compareWithGuid(a: FirestoreItem, b: FirestoreItem) {
  return a?.guid === b?.guid;
}

export function compareWithName(a: ListItem, b: ListItem) {
  return a?.name === b?.name;
}

export function deepClone<T>(o: T): T {
  if (!window.structuredClone) {
    return JSON.parse(JSON.stringify(o));
  } else {
    return structuredClone(o);
  }
}
