import { Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2PDF from 'jspdf-html2canvas';

async function convertBlobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

@Injectable({
  providedIn: 'root',
})
export class PrintService {

  private async openFile(filename: string, data: Blob) {
    const base64 = await convertBlobToBase64(data) as string;
    const writeResult = await Filesystem.writeFile({
      path: `urakka/${filename}`,
      data: base64,
      directory: Directory.Cache,
      recursive: true,
    });
    const filePath = writeResult.uri;
    FileOpener.open({ filePath, contentType: 'application/pdf' });
  }

  printTable(name: string, docId: string = 'printable') {
    const doc = new jsPDF({
      orientation: 'landscape',
    });
    autoTable(doc, {
      html: `#${docId}`,
      margin: { right: 1, left: 1 },
      bodyStyles: { fontSize: 7, cellPadding: 1 },
      headStyles: { fontSize: 7, cellPadding: 1 },
    });
    const now = new Date();
    return doc.save(`${name}-${now.toISOString()}.pdf`);
  }

  async printDoc(name: string, htmlElement: HTMLElement, css?: string, options?: { orientation?: 'landscape' | 'portrait'; scale?: number }) {
    let style: HTMLStyleElement;

    if (css != null) {
      style = document.createElement('style');
      style.prepend(css);
      htmlElement.prepend(style);
    }

    return html2PDF(htmlElement, {
      jsPDF: {
        format: 'a4',
        orientation: options?.orientation ?? 'landscape',
      },
      margin: {
        top: 16,
        right: 16,
        bottom: 16,
        left: 16,
      },
      html2canvas: {
        scale: options?.scale ?? 1,
        useCORS: true,
      },
      success: () => { /** empty */ },
    }).then((pdf) => {
      style?.remove();
      const blob = pdf.output('blob');
      if (Capacitor.isNativePlatform()) {
        this.openFile(`${name}.pdf`, blob);
      } else {
        pdf.save(`${name}.pdf`);
      }
      return blob;
    }, (error) => console.error(error));
  }
}
