<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>

  </div>
  <form [formGroup]="formGroup">
    @if (categories$ | async; as categories) {
      <div class="ion-margin-bottom">
        <ion-button shape="round"
          color="secondary"
          (click)="selectCategory(categories)">
          @if (formGroup.get('category').value; as category) {
            <span>
              {{ category.name }}
            </span>
          } @else {
            {{ 'help.category' | translate }}
          }
        </ion-button>*
      </div>
    }
    <ion-label>{{ 'item.name' | translate }}*</ion-label>
    <form formGroupName="name">
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="fi"></ion-input>
      </ion-item>
    </form>
    @if (formGroup.get('name.fi').value) {
      <ion-label>{{ 'help.model' | translate }}*</ion-label>
      <div class="ion-margin-bottom">
        @if (!formGroup.get('model').value) {
          <app-take-video [name]="formGroup.get('name.fi').value"
            [color]="'secondary'"
            [expand]="''"
            [fill]="'solid'"
            [shape]="'round'"
            (imageUrl)="onVideoUrl($event)"></app-take-video>
        }
        @if (formGroup.get('model').value) {
          <video controls>
            <source [src]="formGroup.get('model').value"
              type="video/mp4">
          </video>
        }
      </div>
    }
    <form formGroupName="execute">
      <ion-label>{{ 'help.execute' | translate }}*</ion-label>
      <ion-item lines="none">
        <ion-textarea autocapitalize="on"
          formControlName="fi"
          rows="10"></ion-textarea>
      </ion-item>
    </form>
    <ion-label>{{ 'help.checklist' | translate }}</ion-label>
    <div class="ion-margin-bottom">
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          #taskInput
          type="string"></ion-input>
        <ion-button slot="end"
          fill="clear"
          (click)="onTask(taskInput)">
          <ion-icon slot="start"
            name="add-circle-outline"></ion-icon>
          {{ 'options.addItem' | translate }}
        </ion-button>
      </ion-item>
      @for (listitem of formGroup.get('checklist').value; track listitem) {
        <ion-item lines="none"
          color="secondary">
          {{ listitem.fi }}
          <ion-icon slot="end"
            class="link"
            name="remove-circle-outline"
            (click)="removeTask(listitem.fi)"></ion-icon>
        </ion-item>
      }
    </div>
    <form formGroupName="safety">
      <ion-label>{{ 'help.safety' | translate }}</ion-label>
      <ion-item lines="none">
        <ion-textarea autocapitalize="on"
          formControlName="fi"
          rows="10"></ion-textarea>
      </ion-item>
    </form>
    @if (formGroup.get('name').value) {
      <ion-label>{{ 'help.quality' | translate }}</ion-label>
      @if (formGroup.get('quality').value.length === 0) {
        <div>
          <app-camera-button class="only-camera"
            [color]="'secondary'"
            [expand]="''"
            [fill]="'solid'"
            [name]="formGroup.get('name').value"
            [shape]="'round'"
            (imageUrl)="onImageUrl($event)"></app-camera-button>
        </div>
      } @else {
        <app-images [images]="formGroup.get('quality').value"
          [showCamera]="formGroup.get('name').value"
          (openCamera)="onImageUrl($event)"></app-images>
      }
    }
    @if (kanbanList$ | async; as kanbanList) {
      <div class="ion-margin-bottom ion-margin-top">
        <div>
          <ion-label>{{ 'help.ticketsLink' | translate }}</ion-label>
        </div>
        <ion-button shape="round"
          color="secondary"
          (click)="selectTickets(kanbanList)">
          @if (formGroup.get('tickets').value.length > 0) {
            @for (ticket of formGroup.get('tickets').value; track ticket) {
              <span class="item">{{ ticket }}</span>
            }
          } @else {
            {{ 'general.select' | translate }}
          }
        </ion-button>
      </div>
    }
    <div class="ion-margin-bottom ion-margin-top">
      <div>
        <ion-label>{{ 'help.roles' | translate }}</ion-label>
      </div>
      <ion-button shape="round"
        color="secondary"
        (click)="selectRoles()">
        @if (formGroup.get('roles').value.length > 0) {
          @for (role of formGroup.get('roles').value; track role) {
            <span class="item">{{ 'users.roles.' + role | translate }}</span>
          }
        } @else {
          {{ 'general.select' | translate }}
        }
      </ion-button>
    </div>
    <div>
      <ion-button color="light"
        shape="round"
        [disabled]="formGroup.invalid"
        (click)="onSave()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</app-main-dialog>