import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@scandium-oy/ngx-scandium';
import { Checklist } from 'src/app/models/checklist.model';
import { storageKeys } from 'src/app/utility/constants';
import { defaultTask, defaultTaskOnSave } from 'src/app/utility/ticket';

const separator = '#';

@Pipe({
  standalone: true,
  name: 'translateTicket',
})
export class TranslateTicketPipe implements PipeTransform {

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService,
  ) { }

  async transform(value: string): Promise<string> {
    const currentLang = this.translateService.currentLang;
    const name = value.split(separator)[0].trim();
    let suffix = '';
    if (value.includes(separator)) {
      suffix = ` ${separator}${value.split(separator)[1]}`;
    }
    if (defaultTaskOnSave === name) {
      return `${this.translateService.instant('tickets.task') as string}${suffix}`;
    } else if (defaultTask === name) {
      return `${this.translateService.instant('tickets.noChoice') as string}${suffix} `;
    }
    const checklists = await this.storageService.get<Checklist[]>(storageKeys.checklists);
    const list = checklists.find((it) => it.fields?.includes(name)) ?? null;
    if (list != null) {
      const index = list.fields.indexOf(name);
      const translations = list[`fields_${currentLang}`] as string[];
      if (translations) {
        return `${translations[index] ?? name}${suffix}`;
      }
    }
    return value;
  }
}
