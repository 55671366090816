import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const products: MenuSubItem[] = [
  {
    icon: 'cube',
    route: '/materials/order',
    name: 'nav.materials.order',
  },
  {
    icon: 'cube',
    route: '/materials/process',
    name: 'nav.materials.process',
  },
  {
    icon: 'cube',
    route: '/materials/products',
    name: 'nav.materials.products',
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'cube',
    name: 'nav.materials.title',
    children: products,
  },
];

export const storageMenu = {
  items,
};
