import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { SelectDialogComponent } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { User } from 'src/app/models/user.model';

export interface SelectedUser extends User {
  name: string;
}

@Component({
  standalone: true,
  selector: 'app-select-user-button',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: `
    <ion-button shape="round" [color]="color" (click)="selectUser()">
      @if (text) {
        {{ text | translate }}
      } @else {
      @if (selectedUser) {
      <span>
        {{ selectedUser.name }}
      </span>
      } @else {
      {{ 'report.selectUser' | translate }}
      }
      }
    </ion-button>
  `,
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class SelectUserButtonComponent {
  private modalCtrl = inject(ModalController);

  @Input()
  color = 'light';

  @Input()
  selectedUser: SelectedUser;

  @Input()
  items: User[];

  @Input()
  text: string;

  @Output()
  selected = new EventEmitter<SelectedUser>();

  selectUser() {
    const clearButton = this.selectedUser != null;
    this.modalCtrl.create({
      component: SelectDialogComponent<User>,
      componentProps: { items: this.items.map((u) => ({ guid: u.guid, name: u.displayName, worksites: u.worksites })), clearButton },
    })
      .then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.selected.emit(null);
            } else {
              this.selected.emit(data.data);
            }
          }
        });
      });
  }
}
