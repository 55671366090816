/* eslint-disable @typescript-eslint/require-await */
import { Injectable } from '@angular/core';
import { serverTimestamp, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationLog } from '../models/location-log.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';
import { UsersService } from './users.service';

const itemCollection = 'location-log';

@Injectable({
  providedIn: 'root',
})
export class LocationLogService implements IService<LocationLog> {
  constructor(
    private firestore: FirestoreService,
    private usersService: UsersService,
  ) { }

  async update(_item: LocationLog): Promise<void> {
    throw new Error('Method not implemented.');
  }

  get(_guid: string): Observable<LocationLog> {
    throw new Error('Method not implemented.');
  }

  getList(options?: { user: string }): Observable<LocationLog[]> {
    const queryConstraints = [];
    if (options?.user) {
      queryConstraints.push(where('user', '==', options.user));
    }
    queryConstraints.push(where('contractor', '==', this.usersService.currentUserS().contractors[0]));

    return this.firestore
      .getList<LocationLog>(itemCollection, undefined, queryConstraints).pipe(
        map((results) => results.map((it) => setDates(it, 'timestamp')) ?? []),
      );
  }

  async delete?(_item: LocationLog): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async save(item: LocationLog) {
    const user = this.usersService.currentUserS();
    item.user = user.guid;
    item.contractor = user.contractors?.length > 0 ? user.contractors[0] : null;
    item.timestamp = serverTimestamp();
    return this.firestore.save(itemCollection, item);
  }

}
