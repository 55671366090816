import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModule, fieldSorter } from '@scandium-oy/ngx-scandium';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { ThankYouDialogComponent } from 'src/app/messages/thank-you/thank-you.dialog';
import { Feedback } from 'src/app/models/feedback.model';
import { Message } from 'src/app/models/message.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { FeedbacksService } from 'src/app/services/feedback.service';
import { MessagesService } from 'src/app/services/messages.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';

@Component({
  standalone: true,
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    LoadingModule,
  ],
})
export class NewMessageComponent {
  private feedbackItem = { guid: 'feedback', name: this.translate.instant('feedback.text') };
  private helpItem = { guid: 'help', name: this.translate.instant('messages.help') };

  @Input()
  set message(value: Message) {
    this.selectedTo.set(value.sender);
    const title = typeof value.title === 'string' ? value.title : value.title['fi'];
    this.formGroup.get('title').setValue(`RE: ${title}`);
  }

  formGroup: FormGroup;

  users$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => user.role === Roles.worker ? this.usersService.getMyList('admins') : this.usersService.getMyList().pipe(
      map((users) => users.filter((u) => u.role === Roles.worker)),
    )),
    map((users) => users.sort(fieldSorter(['displayName']))),
    map((users) => [
      this.feedbackItem,
      this.helpItem,
    ].concat(users?.map((u) => ({ guid: u.guid, name: u.displayName })))),
    shareReplay(1),
  );

  selectedTo = signal<SubItem>(this.feedbackItem);

  constructor(
    private feedbacksService: FeedbacksService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private messagesService: MessagesService,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private usersService: UsersService,
  ) {
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      feedback: ['', Validators.required],
      help: [false],
    });
  }

  private afterMessage(header = 'messages.title', message = 'messages.text') {
    this.formGroup.reset();
    this.modalCtrl.create({ component: ThankYouDialogComponent, componentProps: { header, message } }).then(async (m) => m.present());
  }

  selectUser(items: SubItem[]) {
    const clearButton = this.selectedTo() != null;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedTo.set(null);
          } else {
            this.selectedTo.set(data.data);
          }
        }
      });
    });
  }

  save() {
    const to = this.selectedTo();
    const user = this.usersService.currentUserS();
    const { feedback, title } = this.formGroup.value;
    if ([this.feedbackItem.guid, this.helpItem.guid].includes(to.guid)) {
      const item: Feedback = {
        title,
        feedback,
        user: user.guid,
        username: user.displayName,
        date: new Date(),
        checked: false,
        contractor: user.contractors[0],
        help: to.guid === this.helpItem.guid,
      };
      this.feedbacksService.save(item).then(() => this.afterMessage());
    } else {
      const message: Message = {
        title,
        body: feedback,
        user: to.guid,
        read: false,
        sender: {
          name: user.displayName,
          guid: user.guid,
        },
        contractor: user.contractors.length > 0 ? user.contractors[0] : null,
      };
      this.messagesService.save(message).then(() => {
        this.formGroup.reset();
        this.selectedTo.set(this.feedbackItem);
        this.toastCtrl.create({ message: this.translate.instant('messages.messageSent'), duration: 3000 }).then(async (t) => t.present());
      });
    }
  }
}
