<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ titleS() }}
  </div>
  @if (blueprintS()) {
    <div class="image-container">
      <swiper-container zoom="true">
        <swiper-slide>
          <div class="swiper-zoom-container">
            <img id="blueprint"
              [src]="blueprintS()" />
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  }
</app-main-dialog>