import { Injectable } from '@angular/core';
import { serverTimestamp, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from '../models/message.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'messages';

@Injectable({
  providedIn: 'root',
})
export class MessagesService implements IService<Message> {

  constructor(private firestore: FirestoreService) { }

  async save(item: Message) {
    item.created = serverTimestamp();
    return this.firestore.save(itemCollection, item);
  }

  async update(item: Message) {
    return this.firestore.update(itemCollection, item);
  }

  async markAsRead(item: Message) {
    return this.firestore.updateOnly(itemCollection, item.guid, { read: true });
  }

  async archive(item: Message) {
    return this.firestore.updateOnly(itemCollection, item.guid, { deleted: true });
  }

  async delete(item: Message) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string): Observable<Message> {
    return this.firestore.get<Message>(itemCollection, guid);
  }

  getList(options?: { user?: string; sender?: string }): Observable<Message[]> {
    const queryConstraints = [];
    if (options?.user) {
      queryConstraints.push(where('user', '==', options.user));
    }
    if (options?.sender) {
      queryConstraints.push(where('sender.guid', '==', options.sender));
    }

    return this.firestore
      .getList<Message>(itemCollection, undefined, queryConstraints)
      .pipe(map((results) => results.map((it) => setDates(it, 'created')) ?? []));
  }
}
