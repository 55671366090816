<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>

  </div>
  <div class="ion-padding">
    @if (isFreeLicence$ | async) {
      <h1>
        {{ 'free.title' | translate }}
      </h1>
      <p [innerHTML]="'free.info' | translate"></p>
      <div class="info-card ion-margin-top">
        <h4 class="flex-justify-between">
          {{ 'free.subtitle' | translate }}
          <ion-icon name="color-wand-outline"></ion-icon>
        </h4>
        <p [innerHTML]="'free.subInfo' | translate"></p>
      </div>
    } @else {
      <h1>{{ 'subcontractor.restrictedTitle' | translate }}</h1>
      <p [innerHTML]="'subcontractor.restrictedInfo' | translate"></p>
    }
    <h3>{{ 'free.availableModules' | translate }}</h3>
    @for (item of availableModules; track item) {
      <ion-item lines="none">
        {{ 'modules.' + item.name | translate }}
        <ion-button color="light"
          slot="end">
          {{ item.price | currency:'EUR' }}
        </ion-button>
      </ion-item>
    }
    <h3>{{ 'subcontractor.freeHearMore' | translate }}</h3>
    <p>{{ 'subcontractor.freeHearInfo' | translate }}</p>
    <form [formGroup]="formGroup">
      <h4>{{ 'item.name' | translate }}</h4>
      <ion-item>
        <ion-input autocapitalize="on"
          formControlName="name"></ion-input>
      </ion-item>
      <h4>{{ 'item.email' | translate }}</h4>
      <ion-item>
        <ion-input formControlName="email"
          type="email"></ion-input>
      </ion-item>
      <h4>{{ 'item.phone' | translate }}</h4>
      <ion-item>
        <ion-input autocapitalize="on"
          formControlName="phone"
          type="tel"></ion-input>
      </ion-item>
      <ion-button color="light"
        shape="round"
        [disabled]="formGroup.invalid"
        (click)="sendInfo()">
        {{ 'subcontractor.sendInfo' | translate }}
      </ion-button>
    </form>
  </div>
</app-main-dialog>