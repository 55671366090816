import { ILocation } from '../models/location.model';

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

/**
 *
 * @param start
 * @param end
 * @returns distance in kms
 */
export function calcDistance(start: ILocation, end: ILocation): number {
  if (start == null || end == null) {
    return 0;
  }
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(end.latitude - start.latitude);  // deg2rad below
  const dLon = deg2rad(end.longitude - start.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(start.latitude)) * Math.cos(deg2rad(end.latitude)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}
