<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>

  </div>
  <form [formGroup]="formGroup">
    <ion-label>{{ 'item.name' | translate }}</ion-label>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    @if (categories$ | async; as categories) {
      <div class="ion-margin-bottom">
        <ion-button shape="round"
          color="secondary"
          (click)="selectCategory(categories)">
          @if (formGroup.get('parent').value; as category) {
            <span>
              {{ category.name }}
            </span>
          }@else {
            {{ 'help.parent' | translate }}
          }
        </ion-button>
      </div>
    }
    <div>
      <ion-button color="light"
        shape="round"
        [disabled]="formGroup.invalid"
        (click)="onSave()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</app-main-dialog>