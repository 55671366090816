import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Subscription } from '@invertase/firestore-stripe-payments';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { FirestoreItem } from 'src/app/models/firestore-item.model';
import { User } from 'src/app/models/user.model';
import { StripeService } from 'src/app/services/stripe.service';
import { UsersService } from 'src/app/services/users.service';
import { TextDialogComponent } from 'src/app/text-dialog/text-dialog.dialog';
import { Roles } from 'src/app/utility/role';

@Component({
  standalone: true,
  selector: 'app-free-select-worker',
  templateUrl: './select-worker.dialog.html',
  styleUrls: ['./select-worker.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    FormsModule,
    MainDialogComponent,
  ],
})
export class FreeSelectWorkerDialogComponent {
  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);
  private stripeService = inject(StripeService);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  private originalSubs: (Subscription & FirestoreItem)[];
  private ticket = this.navParams.get<{ parent: string; roomTicket: string }>('ticket');
  site = this.navParams.get<string>('site');
  worker = signal<User>(null);

  subscriptions$ = this.stripeService.getSubscriptions().pipe(
    shareReplay(1),
  );

  step = signal(1);
  inviteFormGroup: FormGroup;
  error = signal<string>(null);
  loading = signal(false);

  constructor() {
    this.inviteFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  private sendInvite(originalSubs: (Subscription & FirestoreItem)[]) {
    this.loading.set(true);
    const currentUser = this.usersService.currentUserS();
    const { email, name } = this.inviteFormGroup.value;
    const sub = originalSubs?.find((s) => s.metadata.email === email);
    if (sub != null) {
      this.selectWorker(sub.metadata);
      return;
    }
    const user: User = {
      displayName: name,
      email,
      role: Roles.worker,
      contractors: currentUser.contractors,
      worksites: this.site ? [this.site] : [],
      disabled: true,
    };
    this.usersService.invite(user).pipe(
      take(1),
    ).subscribe((res) => {
      if (res.result) {
        this.loading.set(false);
        user.guid = res.user;
        this.worker.set(user);
        this.step.update((val) => ++val);
      } else {
        this.loading.set(false);
      }
    });
  }

  invite(originalSubs: (Subscription & FirestoreItem)[]) {
    this.originalSubs = originalSubs;
    this.step.update((val) => ++val);
  }

  showTerms() {
    this.modalCtrl.create({
      component: TextDialogComponent,
      componentProps: { title: this.translate.instant('terms.licenseTitle'), text: this.translate.instant('terms.license') },
      cssClass: ['stack-modal'],
    }).then(async (m) => m.present());
  }

  accept() {
    this.sendInvite(this.originalSubs);
  }

  async subscribe(originalSubs: (Subscription & FirestoreItem)[]) {
    this.loading.set(true);
    const products = await this.stripeService.getProducts();
    const product = await products[0];
    const taxRate = await this.stripeService.getTaxRate();
    this.stripeService.createSubscription(product.currentPrice, taxRate.id, this.worker(), this.ticket).then((session) => {
      const sub = session.subscribe((val) => {
        if (val?.url || val?.error) {
          this.loading.set(false);
          this.inviteFormGroup.reset();
          sub.unsubscribe();
          if (val.error) {
            this.error.set(val.error.message);
          }
          if (val.url) {
            if (Capacitor.isNativePlatform()) {
              Browser.addListener('browserFinished', () => {
                const original = originalSubs?.length ?? 0;
                this.stripeService.getSubscriptions().pipe(
                  take(1),
                ).subscribe((subs) => {
                  if (subs?.length > original) {
                    // Has new
                    const newSub = subs[subs.length - 1];
                    this.selectWorker({ name: newSub.metadata.name, guid: newSub.metadata.guid });
                  } else {
                    this.dismiss();
                  }
                });
              });
              Browser.open({ url: val.url });
            } else {
              window.location.assign(val.url);
            }
          }
        }
      });
    }).catch((e) => {
      console.error(e);
    });
  }

  selectWorker(sub: { name?: string; guid?: string }) {
    this._modal.dismiss(sub);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
