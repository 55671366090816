<ion-button [id]="id()"
  [disabled]="disabled()"
  [expand]="expand()"
  [color]="color()"
  [size]="size()"
  shape="round">
  @if (dateValue()) {
    {{ dateValue() | date:format() }}
  } @else {
    {{ name() | translate }}
  }
</ion-button>
<ion-modal [trigger]="id()"
  class="transparent-modal"
  [keepContentsMounted]="true">
  <ng-template>
    <ion-content class="datetime-container">
      <div class="center">
        <ion-datetime #datetime
          class="select-date"
          locale="fi-FI"
          [presentation]="presentation()"
          preferWheel="false"
          [min]="minDate()"
          [max]="maxDate()"
          [firstDayOfWeek]="1"
          [highlightedDates]="highlightedDatesS()"
          size="cover"
          [value]="dateValue()"
          (ionChange)="onChange($event)">
          <ion-buttons slot="buttons">
            <ion-button color="danger"
              (click)="datetime.reset()">{{ 'general.clear' | translate }}</ion-button>
            <ion-button color="primary"
              (click)="datetime.cancel(true)">{{ 'general.cancel' | translate }}</ion-button>
            <ion-button color="primary"
              (click)="datetime.confirm(true)">{{ 'general.done' | translate }}</ion-button>
          </ion-buttons>
        </ion-datetime>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>