import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ITabItem } from '../components/tabs/tabs.component';
import { Feedback } from '../models/feedback.model';
import { ContractorsService } from '../services/contractors.service';
import { FeedbacksService } from '../services/feedback.service';
import { NotesService } from '../services/notes.service';
import { SitesService } from '../services/sites.service';
import { UserNotesService } from '../services/user-notes.service';
import { UsersService } from '../services/users.service';
import { TextDialogComponent } from '../text-dialog/text-dialog.dialog';
import { fieldSorter } from '../utility/sort';
import { distanceTooLongTitle, lateToSite, leaveEarly } from '../utility/timesheet';

interface ISiteNote {
  site: string;
  date: Date;
  username: string;
  text: string;
  room: string;
}

interface IUserNote {
  date: Date;
  username: string;
  creator: string;
  text: string;
  site: string;
  warning: boolean;
  title: string;
}

export const feedbacksTabKey = 'feedbacks';

@Component({
  selector: 'app-logs-page',
  templateUrl: './logs.page.html',
  styleUrls: ['./logs.page.scss'],
})
export class LogsPage {

  private filter$ = new BehaviorSubject<string>('');

  nav$ = this.route.queryParams.pipe(
    map((params) => params.tabs),
    shareReplay(1),
  );

  tabItems: ITabItem[] = [
    {
      key: 'sites',
      name: this.translate.instant('logs.sites'),
    },
    {
      key: 'users',
      name: this.translate.instant('logs.users'),
    },
    {
      key: feedbacksTabKey,
      name: this.translate.instant('logs.feedbacks'),
    },
  ];

  siteNotes$: Observable<ISiteNote[]> = combineLatest([this.sitesService.getAdminList(), this.contractorsService.getCurrentContractor()]).pipe(
    filter(([_, contractor]) => contractor != null),
    switchMap(([sites, contractor]) => combineLatest([of(sites), this.notesService.getList({ contractor: contractor.guid }), this.filter$.asObservable()])),
    map(([sites, notes, filterValue]) => notes.map((note) => {
      const { username, text, date, room } = note;
      const site = sites.find((s) => s.guid === note.site);
      note.sitename = note.sitename ?? site?.name;
      return { site: note.sitename, date, username, text, room };
    }).filter((note) => note.site.toLowerCase().includes(filterValue.toLowerCase()))),
    map((notes) => notes.sort(fieldSorter(['date'])).reverse()),
    shareReplay(1),
  );

  feedbackNav$ = new BehaviorSubject<string>('unchecked');

  feedbacks$ = this.feedbackNav$.asObservable().pipe(
    switchMap((key) => combineLatest([of(key), this.contractorsService.getCurrentContractor()])),
    filter(([_, contractor]) => contractor != null),
    switchMap(([key, contractor]) => combineLatest([of(key), this.feedbacksService.getList({ contractor: contractor.guid }), this.filter$.asObservable()])),
    map(([key, feedbacks, filterValue]) => feedbacks
      .filter((f) => key === 'unchecked' ? !f.checked : f.checked)
      .filter((note) => note.feedback.toLowerCase().includes(filterValue.toLowerCase()) || note.title.toLowerCase().includes(filterValue.toLowerCase()))),
    map((feedbacks) => feedbacks.sort(fieldSorter(['date'])).reverse()),
    shareReplay(1),
  );

  feedbackTabItems: ITabItem[] = [
    {
      key: 'unchecked',
      name: this.translate.instant('feedback.unchecked'),
    },
    {
      key: 'checked',
      name: this.translate.instant('feedback.checked'),
    },
  ];

  userNotes$: Observable<IUserNote[]> = combineLatest([this.usersService.getList(), this.contractorsService.getCurrentContractor()]).pipe(
    filter(([_, contractor]) => contractor != null),
    switchMap(([users, contractor]) => combineLatest([of(users), this.userNotesService.getList({ contractor: contractor.guid }), this.filter$.asObservable()])),
    map(([users, userNotes, filterValue]) => userNotes.map((note) => {
      const { creator, date, site } = note;
      let notes = note.notes;
      const user = users.find((u) => u.guid === note.user);
      let warning = false;
      let title = note.title ?? null;
      if ([lateToSite, leaveEarly].includes(note.notes)) {
        title = note.notes;
        notes = `${note.site.client}: ${note.site.name}`;
        warning = true;
      } else if (note.type === 'efficiency') {
        title = this.translate.instant('notes.efficiency');
      } else if (note.title == null) {
        title = this.translate.instant('notes.text');
      }
      if ([lateToSite, leaveEarly, distanceTooLongTitle].includes(title)) {
        warning = true;
      }
      return { creator, date, text: notes, username: user?.displayName, site: site?.name, warning, title };
    }).filter((note) => note.username.toLowerCase().includes(filterValue.toLowerCase()) || note.site?.toLowerCase().includes(filterValue.toLowerCase()))),
    map((notes) => notes.sort(fieldSorter(['date'])).reverse()),
    shareReplay(1),
  );

  constructor(
    private route: ActivatedRoute,
    private contractorsService: ContractorsService,
    private feedbacksService: FeedbacksService,
    private modalCtrl: ModalController,
    private notesService: NotesService,
    private sitesService: SitesService,
    private translate: TranslateService,
    private userNotesService: UserNotesService,
    private usersService: UsersService,
  ) { }

  onFeedbackTabSelected(key: string) {
    this.feedbackNav$.next(key);
  }

  onCheck(feedback: Feedback) {
    feedback.checked = true;
    this.feedbacksService.update(feedback);
  }

  onChange(event: CustomEvent) {
    this.filter$.next(event.detail.value);
  }

  onLog(text: string, title: string) {
    this.modalCtrl.create({ component: TextDialogComponent, componentProps: { text, title } }).then(async (m) => m.present());
  }
}
