<app-main-page>
  <div desktopTitle>
    {{ 'nav.workingCalendar' | translate }}
  </div>
  @if (users$ | async; as users) {
    @if (role() === 'admin') {
      <div class="ion-margin-bottom">
        <app-select-user-button [items]="users"
          [selectedUser]="selectedUser()"
          (selected)="selectUser($event)"></app-select-user-button>
      </div>
    }
    @if (selectedUser()) {
      <app-week-calendar [items]="items$ | async"
        [calendarId]="subId()"></app-week-calendar>
    }
  }
</app-main-page>