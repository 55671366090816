import { Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { InstructionDialogComponent } from 'src/app/instructions/item/dialog/instruction.dialog';
import { RoomTicket } from 'src/app/models/ticket.model';
import { InstructionsService } from 'src/app/services/instructions.service';

@Component({
  standalone: true,
  selector: 'app-ticket-help-button',
  template: `@if (ticket.instruction) {
  <ion-button
    color="secondary"
    shape="round"
    size="small"
    (click)="onHelp()">
    {{ 'help.execute' | translate }}
  </ion-button>
}`,
  imports: [
    AppCommonModule,
    IonicModule,
  ],
})
export class TicketHelpButtonComponent {

  @Input() ticket: RoomTicket;

  constructor(
    private instructionsService: InstructionsService,
    private modalCtrl: ModalController,
  ) { }

  onHelp() {
    this.instructionsService.get(this.ticket.instruction).pipe(
      take(1),
    ).subscribe((item) => {
      this.modalCtrl.create({ component: InstructionDialogComponent, componentProps: { item } }).then(async (m) => m.present());
    });
  }
}
