<div>
  @for (item of attachmentsS(); track item) {
  <ion-item button="true"
    color="primary"
    lines="none"
    (click)="openUrl(item.url)">
    <ion-icon slot="start"
      name="document-outline"></ion-icon>
    <ion-label> {{ item.name }}</ion-label>
    <div slot="end"
      class="timestamp">
      {{ item.timestamp | date:'dd.MM.yyyy HH:mm' }}
    </div>
    @if (!isReadOnly) {
    <ion-button slot="end"
      color="secondary"
      shape="round">
      <ion-icon color="danger"
        name="remove-circle-outline"
        (click)="remove($event, item.url)"></ion-icon>
    </ion-button>
    }
  </ion-item>
  } @empty {
  <app-no-results></app-no-results>
  }
</div>
@if (!isReadOnly) {
<div class="ion-margin-top">
  <ion-button color="light"
    shape="round">
    <form method="post"
      enctype="multipart/form-data"
      class="input-form">
      <label class="input-label"
        for="file">
        <input id="file"
          type="file"
          name="file"
          (change)="selectFiles($event)" />
        {{ 'attachment.addTitle' | translate }}
      </label>
    </form>
  </ion-button>
  @if (!autoSave) {
  <ion-button color="light"
    shape="round"
    (click)="onSave()">
    {{ 'general.save' | translate }}
  </ion-button>
  }
</div>
}