/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Allow any because dates can be either Date or Timestamp, can be refactored later
import { Observable } from 'rxjs';
import { FirestoreItem } from './firestore-item.model';
import { Room } from './room.model';
import { Site } from './site.model';
import { SubItem } from './sub-item.model';

export interface Ticket extends FirestoreItem {
  tickets: RoomTicket[];
  contractor: string;
  fake?: boolean;
  type: 'room' | 'common' | 'area';
  site?: string;
  roomId?: string;
  area?: string;
  areaName?: string;
  // extras
  room?: Room;
  siteObj?: Site;
  hours$?: Observable<Record<string, number>>;
  roomSummary?: number;
  roomSummaryInDays?: number;
}

export interface RoomTicket {
  guid: string;
  name: string;
  plainName?: string;
  phase?: number;
  hours: number;
  status: TicketStatus;
  user?: string;
  users?: string[];
  history: TicketHistory[];
  notes?: TicketNote[];
  images?: string[];
  videos?: string[];
  attachments?: string[];
  username?: string;
  usernames?: string[];
  requests?: SubItem[];
  date?: any;
  deadline?: any;
  efficiency?: Record<string, number>;
  littera?: { code: string; name: string };
  area?: string;
  notifyAfter?: string[];
  room?: Room;
  site?: Site;
  start?: TicketHistory;
  end?: TicketHistory;
  progress?: TicketHistory[];
  parent?: Ticket;
  checkedInfo?: string;
  userImage?: string;
  userImages?: string[];
  description?: string;
  descriptionText?: Record<string, string>;
  additionalWork?: boolean;
  additionalWorkInfo?: { admin?: string; adminTimestamp?: any; client?: string; clientTimestamp?: any };
  instruction?: string;
  startDate?: Date;
  contractor?: string;
  subcontractor?: SubItem;
  thumbs?: { img: string; thumb: string }[];
  creator?: string;
  workMachine?: SubItem;
  blockedBy?: SubItem;
  usedTimeInH?: number;
  schedule?: { start: any; end: any };
  location?: string;
  projectPeriod?: string;
  planningObservation?: boolean;
  securityObservation?: boolean;
  repeatGuid?: string;
  repeatTime?: boolean;
  // extra
  contractorName?: string;
  daysOpen?: number;
  preview$?: Observable<string>;
}

export interface TicketHistory {
  timestamp: any;
  status: TicketStatus | 'assigned' | 'joined' | 'removedBlocked';
  username?: string;
  userGuid?: string;
  comment?: string;
  commentText?: Record<string, string>;
  images?: string[];
  videos?: string[];
  backFromBlocked?: boolean;
}

export interface TicketNote {
  timestamp: any;
  username: string;
  userGuid: string;
  text: string;
  textText?: Record<string, string>;
  isAdHoc: boolean;
  hours?: number;
}

export enum TicketStatus {
  created = 'created',
  inProgress = 'inProgress',
  done = 'done',
  checked = 'checked',
  blocked = 'blocked',
  deleted = 'deleted',
  paused = 'paused',
}
