import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { DocumentReference, documentId, where } from '@angular/fire/firestore';
import { AuthService } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, Observable, combineLatest, of, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { SiteArea } from '../models/site-area.model';
import { SiteImage } from '../models/site-image.model';
import { Site } from '../models/site.model';
import { CleaningList } from '../utility/cleaningList';
import { firestoreInLimit } from '../utility/constants';
import { defaultRoom } from '../utility/room';
import { sortIgnoreCase } from '../utility/sort';
import { getDuration } from '../utility/time';
import { ClientsService } from './clients.service';
import { ContractorsService } from './contractors.service';
import { FirestoreService } from './firestore.service';
import { SiteAreasService } from './site-areas.service';
import { UploadService } from './upload.service';

const itemCollection = 'sites';

const converter = {
  toFirestore: (item: Site) => {
    delete item.clientName$;
    delete item.client$;
    delete item.clientName;
    delete item.currentWorkers$;
    delete item.workers$;
    delete item.schedule;
    delete item.hours$;
    delete item.percentage$;
    delete item.timesheets;
    item.rooms?.map((room) => {
      delete room.ticket$;
    });
    item.defaultTicketTemplate = item.defaultTicketTemplate ?? { guid: CleaningList.kanban, name: 'Oletustiketit' };
    return item;
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options) as Site;

    return data;
  },
};

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  private currentSite$ = new BehaviorSubject<Site>(null);
  private sitesS = signal<Site[]>([]);

  private sites$ = toObservable(this.sitesS).pipe(
    shareReplay(1),
  );

  private activeSites$ = combineLatest([
    this.sites$,
    this.contractorsService.getCurrentContractor().pipe(startWith(null)),
  ]).pipe(
    map(([sites, contractor]) => sites
      .filter((site) => !contractor?.archivedSites?.includes(site.guid))
      .filter((site) => !site.archived && !site.offer),
    ),
  );

  private archivedSites$ = combineLatest([
    this.sites$,
    this.contractorsService.getCurrentContractor().pipe(startWith(null)),
  ]).pipe(
    map(([sites, contractor]) => sites
      .filter((site) => contractor?.archivedSites?.includes(site.guid) || (site.archived && !site.offer))),
  );

  isSiteLead$ = combineLatest([this.sites$, this.authService.getUser()]).pipe(
    filter(([sites, user]) => sites != null && user != null),
    map(([sites, user]) => sites.some((s) => s.sitelead?.some((it) => it.guid === user.uid))),
  );

  constructor(
    private authService: AuthService,
    private clientService: ClientsService,
    private contractorsService: ContractorsService,
    private firestore: FirestoreService,
    private siteAreasService: SiteAreasService,
    private uploadService: UploadService,
  ) { }

  private setName(site: Site): Site {
    if (site) {
      site.name = site.project ? site.project : site.streetAddress;
      site.clientName$ = site.client ? this.clientService.get(site.client).pipe(map((c) => c?.name ?? c?.guid ?? '')) : of('');
      site.client$ = site.client ? this.clientService.get(site.client).pipe(
        filter((c) => c != null),
        map((c) => {
          c.name = c?.name ?? c?.guid ?? '';
          return c;
        }),
      ) : of(null);
    }
    return site;
  }

  async save(item: Site): Promise<DocumentReference<unknown>> {
    item.archived = false;
    return this.firestore.save(itemCollection, item, converter).then((doc) => {
      const defaultArea: SiteArea = {
        name: defaultRoom,
        site: doc.id,
        id: '0',
        rooms: [{
          id: defaultRoom,
          type: 'common',
        }],
        children: [],
      };
      this.siteAreasService.save(defaultArea);
      return doc;
    });
  }

  async update(item: Site) {
    return this.firestore.update(itemCollection, item, converter);
  }

  async updateOnly(siteGuid: string, item: object) {
    return this.firestore.updateOnly(itemCollection, siteGuid, item);
  }

  async updateAreas(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { areas: item.areas });
  }

  async updateAreaImage(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { areaImage: item.areaImage });
  }

  async updateAdditionalInfo(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { additionalInfo: item.additionalInfo });
  }

  async updateMainContractors(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { mainContractors: item.mainContractors });
  }

  async updateLogisticsContractors(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { logisticsContractors: item.logisticsContractors });
  }

  async updateContacts(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { contacts: item.contacts });
  }

  async updateBlueprints(item: Site) {
    return this.firestore.updateOnly(itemCollection, item.guid, { blueprints: item.blueprints });
  }

  async archive(item: Site, archived: boolean) {
    return this.firestore.updateOnly(itemCollection, item.guid, { archived });
  }

  getByGuids(guids: string[]): Observable<Site[]> {
    return this.sites$.pipe(
      filter((sites) => sites != null),
      map((sites) => sites.filter((site) => guids.includes(site.guid))),
    );
  }

  getByClient(client: string): Observable<Site[]> {
    return this.activeSites$.pipe(
      map((sites) => sites.filter((site) => site.client === client)),
    );
  }

  getOffers(): Observable<Site[]> {
    return this.sites$.pipe(
      filter((sites) => sites.length > 0),
      map((sites) => sites.filter((site) => site.offer && !site.archived)),
    );
  }

  getActiveList(): Observable<Site[]> {
    return this.activeSites$.pipe(
      map((sites) => sites?.sort((a, b) => sortIgnoreCase(a, b, 'name'))),
    );
  }

  getArchived(client?: string): Observable<Site[]> {
    return this.archivedSites$.pipe(
      map((sites) => sites.filter((site) => client != null ? site.client === client : true)),
    );
  }

  getAdminList(): Observable<Site[]> {
    return this.sites$.pipe(
      filter((sites) => sites.length > 0),
      map((sites) => sites.filter((site) => !site.offer)),
    );
  }

  get(guid: string): Observable<Site> {
    return this.sites$.pipe(
      filter((sites) => sites.length > 0),
      map((sites) => sites.find((site) => site.guid === guid)),
    );
  }

  getSync(guid: string): Site {
    const sites = this.sitesS();
    return sites?.find((s) => s.guid === guid);
  }

  getListSync() {
    return this.sitesS();
  }

  /** Db stuff starts */
  getAdminListFromDb(guids?: string[]): Observable<Site[]> {
    if (guids != null) {
      let ids = Array.from(guids).sort();
      if (ids?.length === 0) {
        return of([]);
      }
      const batches: Observable<Site[]>[] = [];

      while (ids.length) {
        const batch = ids.splice(0, firestoreInLimit);
        batches.push(this.firestore.getList<Site>(itemCollection, undefined, [where(documentId(), 'in', batch)]));
      }

      return combineLatest(batches).pipe(
        map((results) => results.flat()),
        distinctUntilChanged(),
        map((results) => results.map((result) => this.setName(result))),
      );
    } else {
      return this.firestore.getList<Site>(itemCollection).pipe(
        map((results) => results.map((result) => this.setName(result))),
      );
    }
  }

  async saveImage(image: SiteImage) {
    return this.uploadService.saveImage(image);
  }

  getImages(guid: string): Observable<SiteImage[]> {
    return this.uploadService.getImages(guid);
  }

  getSiteName(siteGuid: string) {
    const sites = this.sitesS();
    return sites.find((s) => s.guid === siteGuid)?.name ?? '';
  }

  /**
   * Sets local cache sites
   * @param sites
   */
  setSites(sites: Site[]) {
    console.info(getDuration(), 'Setting sites', sites?.length);
    this.sitesS.set(sites);
  }

  setCurrentSite(site: Site) {
    this.currentSite$.next(site);
  }

  getCurrentSite() {
    return this.currentSite$.getValue();
  }

  getCurrentSite$() {
    return this.currentSite$.asObservable();
  }

  getSiteleadSites() {
    return this.getListSync().filter((s) => s.siteleadGuids?.includes(this.authService.getCurrentUser().uid));
  }
}
