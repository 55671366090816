import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { LoadingModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { LocationService } from '../location.service';

@Component({
  standalone: true,
  selector: 'app-location-dialog',
  templateUrl: './location.dialog.html',
  styleUrls: ['./location.dialog.scss'],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonModule,
    MainDialogComponent,
    LoadingModule,
  ],
})
export class LocationDialogComponent {

  loading = signal<boolean>(false);

  constructor(
    private locationService: LocationService,
    private _modal: ModalController,
  ) { }

  askPermission(): void {
    const callback = (_) => {
      this.loading.set(false);
      this.dismiss();
    };

    this.loading.set(true);
    this.locationService.ask(callback);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
