import { Component } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { FreeLicenseDialogComponent } from 'src/app/contractors/free-license/free-license.dialog';
import { News } from 'src/app/models/news.model';
import { ContractorsService } from 'src/app/services/contractors.service';
import { LicenseService } from 'src/app/services/licence.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';

@Component({
  selector: 'app-new-news-dialog',
  templateUrl: './new-news.dialog.html',
  styleUrls: ['./new-news.dialog.scss'],
})
export class NewNewsDialogComponent {
  formGroup: FormGroup;

  roles$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    map((user) => user.role === Roles.superAdmin ? [Roles.partner, Roles.admin, Roles.manager] : [Roles.worker, Roles.admin]),
    shareReplay(1),
  );

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private licenseService: LicenseService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private usersService: UsersService,
  ) {
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      text: ['', Validators.required],
      role: [''],
      contractor: [this.contractorsService.contractorS()?.guid ?? null],
      sendEmail: [false],
    });
  }

  async save(isFree: boolean) {
    if (isFree) {
      return this.modalCtrl.create({ component: FreeLicenseDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
    }
    this.usersService.getCurrentUser().pipe(take(1)).subscribe((user) => {
      const { title, text, role, contractor, sendEmail } = this.formGroup.value;
      const item: News = {
        created: serverTimestamp(),
        user: user.guid,
        message: {
          fi: text,
        },
        title: {
          fi: title,
        },
        role,
        contractor,
        sendEmail,
      };

      this.dismiss(item);
    });
  }

  dismiss(item?: News) {
    this._modal.dismiss(item);
  }
}
