import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Room } from 'src/app/models/room.model';
import { SiteArea } from 'src/app/models/site-area.model';
import { SiteAreasService } from 'src/app/services/site-areas.service';
import { RoomModule } from 'src/app/sites/rooms/room.module';
import { EditAreaDialogComponent } from '../edit-area/edit-area.dialog';

@Component({
  standalone: true,
  selector: 'app-add-area-dialog',
  templateUrl: './add-area.dialog.html',
  styleUrls: ['./add-area.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    RoomModule,
    MainDialogComponent,
  ],
})
export class AddAreaDialogComponent {
  private root: SiteArea;

  parentName: string;
  itemFormGroup: FormGroup;
  formGroup: FormGroup;
  roomFormGroup: FormGroup;

  rooms: Room[];

  item = signal<SiteArea>(null);
  isRoot = signal<boolean>(null);
  hasEdit = signal(false);
  type = signal<'area' | 'room' | 'common'>(null);

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private siteAreasService: SiteAreasService,
    private translate: TranslateService,
    navParams: NavParams,
  ) {
    this.item.set(navParams.get<SiteArea>('parent'));
    this.root = navParams.get<SiteArea>('root');
    this.hasEdit.set(this.item() != null && this.root != null);
    this.parentName = this.item()?.name;
    this.isRoot.set(this.item()?.guid != null);

    this.formGroup = this.formBuilder.group({
      name: [this.item()?.name],
      areas: this.formBuilder.array(this.item()?.children.map((it) => this.formBuilder.control(it.name)) ?? []),
    });

    this.roomFormGroup = this.formBuilder.group({
      rooms: this.formBuilder.array(this.item()?.rooms.map((it) => this.formBuilder.control(it.id)) ?? []),
    });
    this.rooms = this.item()?.rooms?.length > 0 ? this.item().rooms : null;

    if (this.item() == null) {
      this.type.set('area');
      this.addItem();
    } else if (this.item().children?.length > 0) {
      this.type.set('area');
    }
  }

  selectAddArea() {
    this.type.set('area');
    this.addItem();
  }

  getFields() {
    return this.formGroup.get('areas') as FormArray;
  }

  getRooms() {
    return this.roomFormGroup.get('rooms') as FormArray;
  }

  addItem() {
    this.getFields().push(this.formBuilder.control(''));
  }

  deleteItem(index: number) {
    this.getFields().removeAt(index);
  }

  onDeleteRoom(index: number) {
    this.getRooms().removeAt(index);
    this.rooms.splice(index, 1);
  }

  onAreaRoom(rooms: Room[]) {
    if (this.rooms == null) {
      this.rooms = [];
    }
    rooms.forEach((room) => {
      this.getRooms().push(this.formBuilder.control(room.id));
      this.rooms.push(room);
    });
    this.type.set(null);
  }

  deleteSelf() {
    Dialog.confirm({
      message: this.translate.instant('general.delete') + '?',
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        this._modal.dismiss({ delete: true });
      }
    });
  }

  onEdit() {
    this.modalCtrl.create({
      component: EditAreaDialogComponent,
      componentProps: { item: this.item() },
      cssClass: ['stack-modal'],
    }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.item.update((val) => Object.assign(val, data.data));
          this.siteAreasService.update(this.root);
        }
      });
    });
  }

  save() {
    const item = this.formGroup.value;
    this._modal.dismiss({ areas: item.areas.filter((it: string) => it.length > 0), name: item.name });
  }

  saveRooms() {
    const items = this.roomFormGroup.value;
    const rooms = this.rooms.map((room, i) => {
      room.id = items.rooms[i];
      return room;
    });
    this._modal.dismiss({ rooms });
  }

  onCancel() {
    this.type.set(null);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
