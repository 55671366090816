<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'workMachines.newItem' | translate }}
  </div>
  <form [formGroup]="formGroup">
    <h4>
      {{ 'item.name' | translate }}
    </h4>
    <ion-item>
      <ion-input autocapitalize="on"
        formControlName="name"
        type="string"></ion-input>
    </ion-item>
    <h4>
      {{ 'schedule.add.cost' | translate }}
    </h4>
    <ion-item>
      <ion-input autocapitalize="on"
        formControlName="cost"
        type="number"></ion-input>
    </ion-item>
    <h4>
      {{ 'workMachines.period' | translate }}
    </h4>
    <ion-radio-group formControlName="period">
      <ion-radio color="light"
        value="day"
        labelPlacement="end">{{ 'workMachines.day' | translate }}</ion-radio>
      <ion-radio color="light"
        value="week"
        labelPlacement="end">{{ 'workMachines.week' | translate }}</ion-radio>
      <ion-radio color="light"
        value="month"
        labelPlacement="end">{{ 'workMachines.month' | translate }}</ion-radio>
    </ion-radio-group>
    @if ({
    isFree: isFreeLicence$ | async
    }; as vm) {
      <div class="ion-margin-top">
        <ion-button slot="end"
          shape="round"
          color="light"
          [disabled]="formGroup.invalid"
          (click)="save(vm.isFree)">{{ 'general.save' | translate }}</ion-button>
      </div>
    }
  </form>
</app-main-dialog>