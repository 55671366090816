<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'notification.title' | translate }}
  </div>
  <div class="img">
    <ion-icon name="notifications-outline"></ion-icon>
  </div>
  <div class="info">
    <p>{{ 'notification.info' | translate }}</p>
    @if (loading) {
    <app-loading></app-loading>
    } @else {
    <ion-button color="light"
      shape="round"
      class="ion-margin-top"
      (click)="askPermission()">{{ 'location.accept' | translate }}</ion-button>
    }
  </div>
</app-main-dialog>