import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { DateNavigationComponent } from './date-navigation/date-navigation.component';
import { SelectedDayComponent } from './selected-day/selected-day.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
  ],
  declarations: [
    SelectedDayComponent,
    DateNavigationComponent,
  ],
  exports: [
    SelectedDayComponent,
    DateNavigationComponent,
  ],
})
export class DayModule { }
