import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { LoadingModule } from '@scandium-oy/ngx-scandium';
import { filter, shareReplay, switchMap } from 'rxjs';
import { AppCommonModule } from 'src/app/common.module';
import { ChevronComponent } from 'src/app/components/chevron/chevron.component';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { NoResultsModule } from 'src/app/components/no-results/no-results.module';
import { PartnerNote } from 'src/app/models/partner-note.model';
import { PartnerNotesService } from 'src/app/services/partner-notes.service';
import { PlusService } from 'src/app/services/plus.service';
import { UsersService } from 'src/app/services/users.service';
import { PartnerNoteDialogComponent } from './dialog/partner-note.dialog';

@Component({
  standalone: true,
  selector: 'app-partner-notes-page',
  templateUrl: './partner-notes.page.html',
  styleUrls: ['./partner-notes.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    ChevronComponent,
    NoResultsModule,
    LoadingModule,
    MainPageComponent,
  ],
})
export class PartnerNotesPage {

  items$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => this.service.getList({ user: user.guid })),
    shareReplay(1),
  );

  constructor(
    private modalCtrl: ModalController,
    private plusService: PlusService,
    private service: PartnerNotesService,
    private usersService: UsersService,
  ) { }

  onNewNote() {
    this.plusService.onNewPartnerNote();
  }

  onItem(item: PartnerNote) {
    this.modalCtrl.create({ component: PartnerNoteDialogComponent, componentProps: { item } }).then((m) => {
      m.present();
      m.onDidDismiss<PartnerNote>().then((data) => {
        if (data.data) {
          const updated = Object.assign({}, item, data.data);
          this.service.update(updated);
        }
      });
    });
  }
}
