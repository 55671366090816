@if ({
  scroll: scrollToTop$ | async
  }; as vm) {}
<ion-header class="ion-no-border"
  [class.mobile]="onlyMobile()">
  <ion-toolbar mode="ios">
    @if (canBack()) {
      <ion-buttons slot="start">
        <ion-button fill="clear"
          (click)="onDismiss()">
          <ion-icon slot="icon-only"
            name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    }
    <ion-title mode="md">
      <ng-content select="[desktopTitle]"></ng-content>
    </ion-title>
    <ng-content select="[buttonsEnd]"></ng-content>
  </ion-toolbar>
</ion-header>
<ion-content [class.not-native]="!isNative()">
  <ng-content select="[segmentContainer]"></ng-content>
  <ng-scrollbar orientation="vertical"
    visibility="hover">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </ng-scrollbar>
</ion-content>