import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CameraButtonModule, ImageDialogComponent } from '@scandium-oy/ngx-scandium';
import { TakeVideoComponent } from '../take-video/take-video.component';
import { ImagesComponent } from './images.component';

@NgModule({
  declarations: [ImagesComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    CameraButtonModule,
    TakeVideoComponent,
    ImageDialogComponent,
  ],
  exports: [ImagesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesModule { }
