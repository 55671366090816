<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'partnerNote.title' | translate }}
  </div>
  <form [formGroup]="formGroup"
    (ngSubmit)="save()">
    <ion-label>{{ 'partnerNote.target' | translate }}</ion-label>
    <ion-item>
      <ion-input autocapitalize="on"
        formControlName="target"></ion-input>
    </ion-item>
    <ion-label>{{ 'partnerNote.contact' | translate }}</ion-label>
    <ion-item>
      <ion-input autocapitalize="on"
        formControlName="contact"></ion-input>
    </ion-item>
    <ion-label>{{ 'partnerNote.contactType' | translate }}</ion-label>
    <div class="ion-margin-top ion-margin-bottom">
      <ion-radio-group formControlName="contactType">
        @for (contactType of contactTypes; track contactType) {
          <ion-radio [value]="contactType"
            labelPlacement="end">
            {{ 'partnerNote.contactTypes.' + contactType | translate }}
          </ion-radio>
        }
      </ion-radio-group>
    </div>
    <ion-label>{{ 'partnerNote.duration' | translate }}</ion-label>
    <ion-item>
      <ion-input autocapitalize="on"
        formControlName="duration"
        type="number"></ion-input>
    </ion-item>
    <ion-label>{{ 'partnerNote.interested' | translate }}</ion-label>
    <ion-item>
      <ion-textarea autocapitalize="on"
        formControlName="interested"
        rows="2"></ion-textarea>
    </ion-item>
    <ion-label>{{ 'partnerNote.future' | translate }}</ion-label>
    <ion-item>
      <ion-textarea autocapitalize="on"
        formControlName="future"
        rows="2"></ion-textarea>
    </ion-item>
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        expand="full"
        type="submit"
        [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>