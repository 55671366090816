/* eslint-disable @typescript-eslint/naming-convention */
export enum Roles {
  registered = 'registered',
  worker = 'worker',
  admin = 'admin',
  manager = 'manager',
  storage = 'storage',
  lastplanner = 'lastplanner',
  superAdmin = 'super-admin',
  partner = 'partner',
  supervisor = 'supervisor',
}

export const roles = [Roles.worker, Roles.admin, Roles.storage];
