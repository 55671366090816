@if ({
site: site$ | async,
areas: filteredAreas$ | async
}; as vm) {
  <app-main-dialog (dismiss)="dismiss()">
    <div desktopTitle>
      {{ vm.site?.name }}
    </div>
    <div class="search">
      <ion-searchbar #filter
        mode="ios"
        [placeholder]="'general.filter' | translate"
        (ionInput)="onFilter(filter)"></ion-searchbar>
      @if (filter.value?.length > 0) {
        <div class="flex-end">
          <ion-button color="secondary"
            shape="round"
            (click)="selectFiltered(vm.areas)">
            {{ 'general.selectAll' | translate }}
          </ion-button>
        </div>
      }
    </div>
    <div *ngFor="let area of vm.areas"
      class="area-container">
      <ng-container [ngTemplateOutlet]="areaItem"
        [ngTemplateOutletContext]="{ area, root: area, level: 0 }"></ng-container>
    </div>
    @if (clearButtonS()) {
      <ion-button color="light"
        shape="round"
        (click)="clear()">
        <ion-icon slot="start"
          name="close-outline"></ion-icon>
        {{ 'general.clear' | translate }}
      </ion-button>
    }
    <ng-template #areaItem
      let-area="area"
      let-root="root"
      let-level="level">
      <div class="flex flex-justify-between full-width"
        [ngStyle]="{ 'margin-left':
        'calc(' + (area.children?.length === 0 && area.rooms?.length === 0 ? '0' : '4') + ' * ' + level + 'px)' }"
        [class.child]="area.children?.length === 0 && area.rooms?.length === 0">
        <div class="flex">
          <ion-item [color]="area.selected ? 'success' : 'light'"
            button="true"
            detail="false"
            (click)="onSelect(area, root)">
            <div class="full-width flex-center">
              <ion-label class="ellipsis full-width">
                {{ area.name }}
                @if (area.size) {
                  ({{ area.size }}m<sup>2</sup>)
              }
              </ion-label>
            </div>
          </ion-item>
        </div>
        @if ((isMultipleRooms() && area.rooms?.length > 0)
          || (isMultiple() && area.children?.length > 0)
          && filter.value?.length === 0) {
          <ion-button color="light"
            fill="clear"
            shape="round"
            (click)="selectAllRooms(area, root)">
            <ion-icon class="mobile"
              name="checkbox-outline"></ion-icon>
            <span class="desktop">
              {{ 'general.selectAll' | translate }}
            </span>
          </ion-button>
        }
      </div>
      @if (area.children?.length > 0) {
        <div class="children-container"
          [ngStyle]="{ 'margin-left': 'calc(15 * ' + level + 'px)' }">
          @for (child of area.children; track $index) {
            <ng-container [ngTemplateOutlet]="areaItem"
              [ngTemplateOutletContext]="{ area: child, root, level: level + 1 }"></ng-container>
          }
        </div>
        <div class="last-child full-width"></div>
      }
      @if (area.rooms?.length > 0 && (isMultipleRooms() || !isMultiple())) {
        <div class="children-container"
          [ngStyle]="{ 'margin-left': 'calc(15 * ' + level + 'px)' }">
          @for (room of area.rooms; track $index) {
            <ion-item button="true"
              detail="false"
              (click)="onSelect(area, root, room)">
              <div class="full-width flex-center">
                @if (room.selected) {
                  <ion-icon name="checkmark-outline"></ion-icon>
                } @else {
                  <ion-icon name="business-outline"></ion-icon>
                }
                <ion-label class="ellipsis full-width">{{ room.id }}</ion-label>
              </div>
            </ion-item>
          }
        </div>
      }
    </ng-template>
  </app-main-dialog>
  @if (isMultiple() || isMultipleRooms()) {
    <ion-fab slot="fixed"
      horizontal="end"
      vertical="bottom">
      <div class="card">
        {{ 'tickets.selecting' | translate: { num: selected().length } }}
        <ion-button [disabled]="selected().length === 0"
          shape="round"
          (click)="onSelectMultiple()">
          {{ 'general.accept' | translate }}
        </ion-button>
        @if (selected().length > 0) {
          <div class="flex-center">
            <span class="link"
              (click)="selectNone()">{{ 'item.removeSelected' | translate }}</span>
          </div>
        }
      </div>
    </ion-fab>
  }
  }