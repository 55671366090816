import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { CameraButtonModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Client } from 'src/app/models/client.model';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';

@Component({
  standalone: true,
  selector: 'app-new-client-dialog',
  templateUrl: './new-client.dialog.html',
  styleUrls: ['./new-client.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    CameraButtonModule,
    MainDialogComponent,
  ],
})
export class NewClientDialogComponent {
  client: Client;

  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _modal: ModalController,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    const currentUser = this.usersService.currentUserS();
    const role = this.usersService.role();
    this.client = navParams.get('client');

    this.formGroup = this.formBuilder.group({
      name: [this.client?.name ?? '', Validators.required],
      logo: [this.client?.logo ?? null],
      contractor: [this.client ? this.client.contractor : role === Roles.superAdmin ? null : currentUser.contractors[0]],
    });
  }

  removeLogo() {
    this.formGroup.get('logo').setValue(null);
  }

  onImageUrl(imageUrl: string) {
    this.formGroup.get('logo').setValue(imageUrl);
  }

  save() {
    const { name, logo, contractor } = this.formGroup.value;
    const item: Client = {
      guid: this.client?.guid ?? name.toLowerCase(),
      name,
      logo,
      contractor,
    };
    this.dismiss(item);
  }

  dismiss(item?: Client) {
    this._modal.dismiss(item);
  }
}
