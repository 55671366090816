<ion-content [fullscreen]="true"
  color="primary">
  @switch (step()) {
  @case (1) {
  <div class="background first">
    <div class="image desktop"></div>
    <div class="container">
      <div class="title">
        {{ 'login.title' | translate }}
        <h3>{{ 'firstLogin.first.title' | translate }}</h3>
      </div>
      <div class="text">{{ 'firstLogin.first.text' | translate }}</div>
    </div>
    <div class="button-container">
      <ion-button color="secondary"
        (click)="skip()">
        {{ 'general.skip' | translate }}
      </ion-button>
      <ion-button color="light"
        (click)="nextStep()">
        {{ 'firstLogin.first.next' | translate }}
      </ion-button>
    </div>
  </div>
  } @case (2) {
  <div class="background second">
    <div class="image desktop"></div>
    <div class="container">
      <div class="title">
        {{ 'firstLogin.second.subtitle' | translate }}
        <h3>{{ 'firstLogin.second.title' | translate }}</h3>
      </div>
      <div class="text">{{ 'firstLogin.second.text' | translate }}</div>
    </div>
    <div class="button-container">
      <ion-button color="secondary"
        (click)="skip()">
        {{ 'general.skip' | translate }}
      </ion-button>
      <ion-button color="light"
        (click)="nextStep()">
        {{ 'firstLogin.next' | translate }}
      </ion-button>
    </div>
  </div>
  } @case (3) {
  <div class="background third">
    <div class="image desktop"></div>
    <div class="container">
      <div class="title">
        {{ 'firstLogin.third.subtitle' | translate }}
        <h3>{{ 'firstLogin.third.title' | translate }}</h3>
      </div>
      <div class="text">{{ 'firstLogin.third.text' | translate }}</div>
    </div>
    <div class="button-container">
      <ion-button color="secondary"
        (click)="skip()">
        {{ 'general.skip' | translate }}
      </ion-button>
      <ion-button color="light"
        (click)="nextStep()">
        {{ 'firstLogin.next' | translate }}
      </ion-button>
    </div>
  </div>
  } @case (4) {
  <div class="background fourth">
    <div class="image desktop"></div>
    <div class="container">
      <div class="title">
        {{ 'firstLogin.fourth.subtitle' | translate }}
        <h3>{{ 'firstLogin.fourth.title' | translate }}</h3>
      </div>
      <div class="text">{{ 'firstLogin.fourth.text' | translate }}</div>
    </div>
    <div class="button-container">
      <ion-button color="secondary"
        (click)="skip()">
        {{ 'general.skip' | translate }}
      </ion-button>
      <ion-button color="light"
        (click)="nextStep()">
        {{ 'firstLogin.next' | translate }}
      </ion-button>
    </div>
  </div>
  } @case (5) {
  <div class="background fifth">
    <div class="image desktop"></div>
    <div class="container">
      <div class="title">
        {{ 'firstLogin.fifth.subtitle' | translate }}
        <h3>{{ 'firstLogin.fifth.title' | translate }}</h3>
      </div>
      <div class="text">{{ 'firstLogin.fifth.text' | translate }}</div>
    </div>
    <div class="button-container">
      <ion-button color="secondary"
        (click)="skip()">
        {{ 'general.skip' | translate }}
      </ion-button>
      <ion-button color="light"
        (click)="nextStep()">
        {{ 'firstLogin.next' | translate }}
      </ion-button>
    </div>
  </div>
  } @case (6) {
  <div class="background last">
    <div class="title">
      {{ 'firstLogin.last.subtitle' | translate }}
      <h3>{{ 'firstLogin.last.title' | translate }}</h3>
    </div>
    <div class="container">
      <h4>{{ 'firstLogin.last.text' | translate }}</h4>
      <div [innerHTML]="'terms.termsOfUse' | translate"></div>
    </div>
    <div class="button-container">
      <ion-button color="light"
        (click)="dismiss()">
        {{ 'firstLogin.last.next' | translate }}
      </ion-button>
    </div>
  </div>
  }
  }
</ion-content>