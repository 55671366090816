import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Contractor } from '../models/contractor.model';
import { ContractorsService } from './contractors.service';

export const appModules = {
  essentials: 'essentials',
  location: 'location',
  reporting: 'reporting',
  users: 'users',
  products: 'products',
  workMachines: 'workMachines',
  timesheets: 'timesheets',
  invoices: 'invoices',
};

export const modulePrices = [
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
];

export const packages = {
  basic: {
    name: 'basic',
    modules: [appModules.essentials, appModules.location, appModules.reporting],
  },
  pro: {
    name: 'pro',
    modules: [appModules.essentials, appModules.location, appModules.reporting, appModules.timesheets, appModules.invoices],
  },
  boost: {
    name: 'boost',
    modules: [
      appModules.essentials, appModules.location, appModules.reporting, appModules.workMachines, appModules.products, appModules.timesheets, appModules.invoices, appModules.users,
    ],
  },
};

export const packageList = ['free', packages.basic.name, packages.pro.name, packages.boost.name];

@Injectable({
  providedIn: 'root',
})
export class LicenseService {

  constructor(
    private contractorsService: ContractorsService,
  ) { }

  getContractorsModules(contractor: Contractor) {
    if (contractor == null) {
      return {};
    }
    if (contractor.license == null || contractor.license === 'free') {
      return { [appModules.essentials]: false };
    } else {
      let contractorModules = contractor.modules?.map((m) => m) ?? [];
      switch (contractor.license) {
        case 'boost':
          contractorModules.push(...packages.boost.modules);
          break;
        case 'pro':
          contractorModules.push(...packages.pro.modules);
          break;
        case 'basic':
          contractorModules.push(...packages.basic.modules);
          break;
        default:
          contractorModules.push(appModules.essentials);
          break;
      }
      const ret = {};
      const modules = Array.from(new Set(contractorModules));
      modules.map((module) => {
        ret[module] = true;
      });
      return ret;
    }
  }

  getModules() {
    return this.contractorsService.getCurrentContractor().pipe(
      filter((contractor) => contractor != null),
      map((contractor) => this.getContractorsModules(contractor)),
    );
  }

  getModulesS() {
    const contractor = this.contractorsService.contractorS();
    return this.getContractorsModules(contractor);
  }

  isFreeLicence() {
    return this.getModules().pipe(
      map((modules) => {
        return !modules[appModules.essentials];
      }),
    );
  }

  isLocationModule() {
    return this.getModules().pipe(
      map((modules) => {
        return !!modules[appModules.location];
      }),
    );
  }

  isReportingModule() {
    return this.getModules().pipe(
      map((modules) => {
        return !!modules[appModules.reporting];
      }),
    );
  }
}
