import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IService } from '../models/service.interface';
import { UserNote } from '../models/user-note.model';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'user-notes';

@Injectable({
  providedIn: 'root',
})
export class UserNotesService implements IService<UserNote> {

  constructor(private firestore: FirestoreService) { }

  async save(item: UserNote) {
    return this.firestore.save<UserNote>(itemCollection, item);
  }

  async update(item: UserNote): Promise<void> {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<UserNote> {
    return this.firestore.get<UserNote>(itemCollection, guid);
  }

  getList(options?: { user?: string; contractor?: string }): Observable<UserNote[]> {
    const queryConstraints = [];
    if (options?.user) {
      queryConstraints.push(where('user', '==', options.user));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }

    return this.firestore.getList<UserNote>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results.map((res) => setDates(res, 'date'))),
    );
  }
}
