import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { LogoModule } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';
import { NoResultsModule } from '../components/no-results/no-results.module';
import { Client } from '../models/client.model';
import { Site } from '../models/site.model';
import { User } from '../models/user.model';
import { ClientsService } from '../services/clients.service';
import { NavigationService } from '../services/navigation.service';
import { SitesService } from '../services/sites.service';
import { UsersService } from '../services/users.service';
import { Roles } from '../utility/role';

@Component({
  standalone: true,
  selector: 'app-search-dialog',
  templateUrl: './search.dialog.html',
  styleUrls: ['./search.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NoResultsModule,
    LogoModule,
    MainDialogComponent,
  ],
})
export class SearchDialogComponent {

  private clientsService = inject(ClientsService);
  private _modal = inject(ModalController);
  private navigationService = inject(NavigationService);
  private sitesService = inject(SitesService);
  private usersService = inject(UsersService);

  private searchText$ = new BehaviorSubject('');
  private searched$ = this.searchText$.asObservable().pipe(debounceTime(300));

  filteredSites$ = combineLatest([
    this.searched$,
    this.sitesService.getActiveList().pipe(
      filter((sites) => sites.length > 0),
    ),
  ]).pipe(
    map(([text, sites]) => {
      if (text.length < 2) {
        return [];
      }
      return sites.filter((site) =>
        site.name.toLowerCase().includes(text.toLowerCase())
        || site.client?.toLowerCase().includes(text.toLowerCase()),
      );
    }),
    map((sites) => sites.map((site) => {
      site.client$ = site.client ? this.clientsService.get(site.client) : of(null);
      return site;
    })),
    shareReplay(1),
  );

  filteredUsers$ = combineLatest([
    this.searched$,
    this.usersService.getList(),
  ]).pipe(
    map(([text, users]) => {
      if (text.length < 2) {
        return [];
      }
      return users.filter((user) =>
        user.displayName.toLowerCase().includes(text.toLowerCase()),
      );
    }),
    shareReplay(1),
  );

  filteredClients$ = combineLatest([
    this.searched$,
    this.clientsService.getList(),
  ]).pipe(
    map(([text, clients]) => {
      if (text.length < 2) {
        return [];
      }
      return clients.filter((client) =>
        client.name.toLowerCase().includes(text.toLowerCase()) || client.guid.toLowerCase().includes(text.toLowerCase()),
      );
    }),
    shareReplay(1),
  );

  onChange(event: CustomEvent) {
    const { value } = event.detail;
    this.searchText$.next(value);
  }

  onClient(client: Client) {
    this.navigationService.navigate(['/clients', client.guid]).then(() => {
      this.dismiss();
    });
  }

  onSite(site: Site) {
    this.navigationService.navigateToSite(site.guid).then(() => {
      this.dismiss();
    });
  }

  async onUser(user: User) {
    if ([Roles.worker, Roles.storage].includes(this.usersService.currentUserS().role)) {
      return this.navigationService.navigate(['/profile']).then(() => {
        this.dismiss();
      });
    }
    this.navigationService.navigateToUser(user.guid).then(() => {
      this.dismiss();
    });
  }

  dismiss() {
    this.searchText$.next('');
    this._modal.dismiss();
  }
}
