import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';

@Component({
  standalone: true,
  selector: 'app-chevron',
  templateUrl: './chevron.component.html',
  styleUrls: ['./chevron.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class ChevronComponent {

  @Input()
  text: string;
}
