import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { startOfToday } from 'date-fns';
import { map, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectAreaDialogComponent } from 'src/app/components/select-area/select-area.dialog';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { Contractor } from 'src/app/models/contractor.model';
import { Room } from 'src/app/models/room.model';
import { SiteProjectTemplate } from 'src/app/models/site-project-template.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { SiteProjectTemplatesService } from 'src/app/services/site-project-templates.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { UsersService } from 'src/app/services/users.service';
import { defaultProjectName } from 'src/app/utility/kanban';
import { Roles } from 'src/app/utility/role';
import { ChainProjectsDialogComponent } from '../chain-projects/chain-projects.dialog';

@Component({
  standalone: true,
  selector: 'app-site-project-dialog',
  templateUrl: './site-project.dialog.html',
  styleUrls: ['./site-project.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    SelectDayComponent,
    MainDialogComponent,
  ],
})
export class SiteProjectDialogComponent {
  private modalCtrl = inject(ModalController);
  private siteProjectsService = inject(SiteProjectsService);
  private siteProjectTemplatesService = inject(SiteProjectTemplatesService);
  private usersService = inject(UsersService);

  itemS = signal<SiteProject>(null);
  formGroup: FormGroup;
  readonly defaultProjectName = defaultProjectName;

  selectedContractor = signal<Contractor>(null);

  doneBefore = signal<SiteProject>(null);
  doneAfter = signal<SiteProject>(null);

  selectedTemplate = signal<SiteProjectTemplate>(null);

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private navParams: NavParams,
  ) {
    const item = this.navParams.get<SiteProject>('item');
    this.itemS.set(item);
    const areas = this.navParams.get<unknown[]>('areas');

    this.formGroup = this.formBuilder.group({
      guid: [item?.guid ?? null],
      site: [item?.site ?? null],
      client: [item?.client ?? null],
      periods: [item?.periods ?? []],
      name: [item?.name ?? '', Validators.required],
      start: [
        item?.start ? new Date(item?.start).toISOString() : startOfToday().toISOString(),
        Validators.required,
      ],
      end: [item?.end ? new Date(item?.end).toISOString() : startOfToday().toISOString(), Validators.required],
      areas: [item?.areas ?? (areas ?? [])],
      contractor: [item?.contractor ?? null],
      form: this.formBuilder.group({
        totalSum: [item?.form?.totalSum ?? 0, Validators.required],
        squareMeters: [item?.form?.squareMeters ?? 0, Validators.required],
        vendorPremium: [item?.form?.vendorPremium ?? 0, Validators.required],
        supplies: [item?.form?.supplies ?? 0, Validators.required],
        yieldRequirement: [item?.form?.yieldRequirement ?? 0, Validators.required],
      }),
      projectsDone: [item?.projectsDone ?? {}],
      private: [item?.private ?? null],
    });
  }

  selectTemplate() {
    this.siteProjectTemplatesService.getList().pipe(
      take(1),
    ).subscribe((templates) => {
      const clearButton = this.selectedTemplate() != null;
      this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items: templates, clearButton } }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.selectedTemplate.set(null);
            } else {
              this.selectedTemplate.set(data.data);
              this.formGroup.get('name').setValue(data.data.name);
            }
          }
        });
      });
    });
  }

  onChain() {
    const site = this.itemS()?.site ?? this.navParams.get('site')?.guid;
    this.siteProjectsService.getList({ site }).pipe(
      map((projects) => projects.filter((it) => it.name !== defaultProjectName)),
      take(1),
    ).subscribe((projects) => {
      this.modalCtrl.create({ component: ChainProjectsDialogComponent, componentProps: { projects } }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.before) {
              this.doneBefore.set(data.data.before);
            }
            if (data.data.after) {
              this.doneAfter.set(data.data.after);
            }
          }
        });
      });
    });
  }

  onDateChange(value: string, field: string) {
    this.formGroup.get(field).setValue(value);
  }

  selectRoom() {
    const areas = this.formGroup.get('areas').value ?? [];
    const site = this.itemS()?.site ?? this.navParams.get('site')?.guid;
    this.modalCtrl.create({
      component: SelectAreaDialogComponent,
      componentProps: { site, multiple: true, areas },
    }).then((m) => {
      m.present();

      m.onDidDismiss<Room[]>().then((data) => {
        if (data.data) {
          const value = data.data.map((it) => ({ guid: it.siteArea.root, name: it.siteArea.name, id: it.siteArea.id }));
          this.formGroup.get('areas').setValue(value);
        }
      });
    });
  }

  save() {
    const item = this.formGroup.value;
    item.start = new Date(item.start);
    item.end = new Date(item.end);
    if (this.selectedContractor()) {
      item.contractor = this.selectedContractor().guid;
    }
    if (this.doneAfter() != null) {
      item.projectsDone.after = [{ guid: this.doneAfter().guid, name: this.doneAfter().name }];
    }
    if (this.doneBefore() != null) {
      item.projectsDone.before = [{ guid: this.doneBefore().guid, name: this.doneBefore().name }];
    }
    const user = this.usersService.currentUserS();
    if ((user.role !== Roles.manager || (!user.rights?.calendar && user.rights?.officer)) && item.private == null) {
      item.private = { showTo: [{ guid: user.guid, name: user.displayName }] };
      if (user.contractors?.length > 0) {
        item.contractor = user.contractors[0];
      }
    }
    if (this.selectedTemplate() != null) {
      item.template = this.selectedTemplate().guid;
    }
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
