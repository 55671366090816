import { inject, Injectable } from '@angular/core';
import { arrayUnion, where } from '@angular/fire/firestore';
import { startOfToday } from 'date-fns';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IService } from '../models/service.interface';
import { SiteProject } from '../models/site-project.model';
import { Site } from '../models/site.model';
import { SubItem } from '../models/sub-item.model';
import { defaultProjectName } from '../utility/kanban';
import { FirestoreService } from './firestore.service';

const converter = {
  toFirestore: (item: SiteProject) => {
    return item;
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options) as SiteProject;
    data.start = data.start.toDate();
    data.end = data.end?.toDate();

    data.periods = data.periods?.map((period) => {
      period.start = period.start.toDate();
      period.end = period.end.toDate();
      return period;
    }) ?? [];

    return data;
  },
};

const itemCollection = 'site-projects';

@Injectable({
  providedIn: 'root',
})
export class SiteProjectsService implements IService<SiteProject> {
  private firestore = inject(FirestoreService);

  async save(item: SiteProject) {
    return this.firestore.save<SiteProject>(itemCollection, item);
  }

  async update(item: SiteProject) {
    return this.firestore.update(itemCollection, item);
  }

  async updateAfter(guid: string, after: SubItem) {
    return this.firestore.updateOnly(itemCollection, guid, { 'projectsDone.after': arrayUnion(after) });
  }

  async updateBefore(guid: string, before: SubItem) {
    return this.firestore.updateOnly(itemCollection, guid, { 'projectsDone.before': arrayUnion(before) });
  }

  get(guid: string): Observable<SiteProject> {
    return this.firestore.get<SiteProject>(itemCollection, guid, converter);
  }

  getList(options?: { site?: string; sites?: string[]; contractor?: string; client?: string }): Observable<SiteProject[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.sites) {
      queryConstraints.push(where('site', 'in', options.sites));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }
    if (options?.client) {
      queryConstraints.push(where('client', '==', options.client));
    }

    return this.firestore
      .getList<SiteProject>(itemCollection, undefined, queryConstraints, converter)
      .pipe(map((results) => results?.filter((it) => it.archived == null) ?? []));
  }

  createDefaultProject(site: Site, contractor: string) {
    return this.getList({ site: site.guid, contractor }).pipe(
      take(1),
    ).subscribe(async (projects) => {
      if (projects.find((p) => p.name === defaultProjectName) == null) {
        const project: SiteProject = {
          name: defaultProjectName,
          start: startOfToday(),
          end: site.projectEnd ?? null,
          periods: [],
          site: site.guid,
          contractor,
          client: site.client,
        };
        return this.save(project);
      }
    });
  }
}
