import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from '../common.module';

@Component({
  standalone: true,
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class PrivacyComponent {

}
