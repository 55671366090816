@if (onlyThumbs()) {
  <div class="images"
    [ngStyle]="{ 'flex-wrap': wrap()}">
    @for (image of images(); track image) {
      <div class="smalls">
        <img class="link"
          (click)="openImage(image)"
          [src]="image" />
      </div>
    }
    @for (video of videos(); track video) {
      <div class="smalls"
        [ngStyle]="{ 'flex-wrap': wrap()}">
        <video class="link"
          preload="metadata">
          <source [src]="video + '#t=0.5'"
            type="video/mp4">
        </video>
      </div>
    }
  </div>
} @else {
  <div class="container">
    <swiper-container [modules]="swiperModules"
      pagination="true">
      @for (image of images(); track image) {
        <swiper-slide>
          <img class="img link"
            (click)="openImage(image)"
            [src]="image" />
          @if (canDelete()) {
            <div class="delete link">
              <ion-icon name="remove-circle-outline"
                color="danger"
                (click)="delete(image)"></ion-icon>
            </div>
          }
          <div>
            @if (showDetails()) {
              <ion-note>
                {{ getDetails(image) }}
              </ion-note>
            }
          </div>
        </swiper-slide>
      }
      @for (video of videos(); track video) {
        <swiper-slide>
          <video controls
            playsinline
            preload="metadata">
            <source [src]="video + '#t=0.5'"
              type="video/mp4">
          </video>
        </swiper-slide>
      }
    </swiper-container>
  </div>
  @if (showCamera() || images()?.length > 1 || videos()?.length > 1) {
    <div class="images">
      @if (showCamera() != null) {
        <div class="smalls">
          <app-camera-button [name]="showCamera()"
            [hideText]="true"
            [fill]="'solid'"
            [multiline]="true"
            (imageUrl)="onImageUrl($event)"></app-camera-button>
        </div>
      }
      @if (showVideo()) {
        <div class="smalls">
          <app-take-video color="primary"
            [name]="showCamera()"
            [hideText]="true"
            [fill]="'solid'"
            [multiline]="true"
            (imageUrl)="onVideoUrl($event)">
          </app-take-video>
        </div>
      }
    </div>
  }
}