<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'report.new.note' | translate }}
  </div>
  <form [formGroup]="formGroup">
    <ion-label>{{ 'item.name' | translate }}</ion-label>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <div class="flex">
      <app-camera-button [name]="'reportNote'"
        color="light"
        fill="clear"
        (imageUrl)="onUrl($event)"></app-camera-button>
      <app-take-video [name]="'reportNote'"
        color="light"
        fill="clear"
        (imageUrl)="onUrl($event)"></app-take-video>
    </div>
    <ion-item class="description"
      lines="none">
      <ion-textarea autocapitalize="on"
        formControlName="text"
        rows="5"
        [placeholder]="'kanban.problemTitle' | translate">
      </ion-textarea>
    </ion-item>
    <ion-button expand="block"
      shape="round"
      color="secondary"
      (click)="selectArea()">
      @if (formGroup.get('area').value; as room) {
        {{ room }}
      } @else {
        {{ 'calendar.site.area' | translate }}
      }
    </ion-button>
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="onSave()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</app-main-dialog>