import { inject, Injectable } from '@angular/core';
import { arrayUnion, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { IService } from '../models/service.interface';
import { SiteProjectPeriod } from '../models/site-project-period.model';
import { SubItem } from '../models/sub-item.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'site-project-periods';

@Injectable({
  providedIn: 'root',
})
export class SiteProjectPeriodsService implements IService<SiteProjectPeriod> {
  private firestore = inject(FirestoreService);

  async save(item: SiteProjectPeriod) {
    return this.firestore.save<SiteProjectPeriod>(itemCollection, item);
  }

  async update(item: SiteProjectPeriod) {
    return this.firestore.update(itemCollection, item);
  }

  async updateOrder(guid: string, order: number) {
    return this.firestore.updateOnly(itemCollection, guid, { order });
  }

  async addTickets(guid: string, ticket: SubItem) {
    return this.firestore.updateOnly(itemCollection, guid, { tickets: arrayUnion(ticket) });
  }

  get(guid: string): Observable<SiteProjectPeriod> {
    return this.firestore.get<SiteProjectPeriod>(itemCollection, guid);
  }

  getList(options?: { project?: string; doneIn?: string }): Observable<SiteProjectPeriod[]> {
    const queryConstraints = [];
    if (options?.project) {
      queryConstraints.push(where('project', '==', options.project));
    }
    if (options?.doneIn) {
      queryConstraints.push(where('doneIn', '==', options.doneIn));
    }

    return this.firestore
      .getList<SiteProjectPeriod>(itemCollection, undefined, queryConstraints);
  }
}
