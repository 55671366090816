/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp } from '@angular/fire/firestore';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInMilliseconds, format, getWeek } from 'date-fns';
import { fi } from 'date-fns/locale';

export const isTimestamp = (object: any): object is Timestamp => {
  if (object == null) {
    return false;
  }
  return 'toDate' in object;
};

export const millisToMinutesAndSeconds = (millis: number): string => {
  const totalMinutes = Math.floor(millis / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours + 'h ' + (+minutes < 10 ? '0' : '') + minutes.toFixed(0) + 'm';
};

export const millisToHours = (
  millis: number,
): { hours: number; minutes: number } => {
  const totalMinutes = Math.floor(millis / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const reportMillisToMinutesAndSeconds = (millis: number): number => {
  const totalMinutes = Math.floor(millis / 60000);
  let hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const duration = hours + '.' + (minutes < 10 ? `0${minutes}` : minutes);
  return +duration;
};

export function millisToOnlyHours(ms: number): number {
  const hours = +(ms / (1000 * 60 * 60)).toFixed(1);
  return hours;
}

export const removeLunchDate = new Date('2022-02-01');
export const sixHours = 6 * 60 * 60 * 1000;
/** Half an hour */
export const lunchBreak = 0.5 * 60 * 60 * 1000;
/** Over 8.5 hours */
export const overworkBoundaryMs = 8.5 * 3600 * 1000;
export const overwork100BoundaryMs = 10.5 * 3600 * 1000;
export const twoHoursMs = 2 * 3600 * 1000;

export const numberOfWeek = (date: Date): number => {
  return getWeek(date, { locale: fi });
};

/**
 * Returns duration with lunch break removed
 * @param start
 * @param end
 * @returns Duration in ms
 */
export function calcDuration(start: Date, end: Date): number {
  let duration = end?.getTime() - start?.getTime();
  if (start > removeLunchDate) {
    if (duration >= sixHours) {
      duration -= lunchBreak;
    }
  }
  return duration ?? 0;
}

export function convertTimestamp(item: any): Date {
  const ret = isTimestamp(item) ? item.toDate() : item;
  return ret;
}

export function setTimestamp<T>(item: any, key: string): T {
  if (item == null) {
    return null;
  }
  if (item[key]) {
    if (typeof item[key] === 'string') {
      item[key] = new Date(item[key]);
    } else {
      item[key] = item[key] ? item[key].toDate() : undefined;
    }
  }
  return item;
}

export function setDates<T>(item: any, key: string): T {
  if (item == null) {
    return null;
  }
  item[key] = item[key] ? item[key].toDate() : undefined;
  return item;
}

export function getDateWithTimezone(date: Date): string {
  if (date == null) {
    return '';
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
}

export function getDateWithoutTimezone(date: Date): string {
  if (date == null) {
    return '';
  }
  const dateWithTimezone = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return format(dateWithTimezone, 'yyyy-MM-dd');
}

export const selectedDayFormat = 'dd.MM.yyyy';

export const timeArray = [
  {
    value: 0.25,
    text: '15min',
  },
  {
    value: 0.5,
    text: '30min',
  },
  {
    value: 0.75,
    text: '45min',
  },
  {
    value: 1,
    text: '1h',
  },
  {
    value: 1.5,
    text: '1h 30min',
  },
  {
    value: 2,
    text: '2h',
  },
  {
    value: 2.5,
    text: '2h 30min',
  },
  {
    value: 3,
    text: '3h',
  },
  {
    value: 3.5,
    text: '3h 30min',
  },
  {
    value: 4,
    text: '4h',
  },
  {
    value: 4.5,
    text: '4h 30min',
  },
  {
    value: 5,
    text: '5h',
  },
  {
    value: 5.5,
    text: '5h 30min',
  },
  {
    value: 6,
    text: '6h',
  },
  {
    value: 6.5,
    text: '6h 30min',
  },
  {
    value: 7,
    text: '7h',
  },
  {
    value: 7.5,
    text: '7h 30min',
  },
  {
    value: 8,
    text: '8h',
  },
  {
    value: null,
    text: 'Muu',
  },
];


export const hoursArray = [
  {
    value: 8,
    text: '8h',
  },
  {
    value: 7.3,
    text: '7h 30min',
  },
  {
    value: 7,
    text: '7h',
  },
  {
    value: 6.3,
    text: '6h 30min',
  },
  {
    value: 6,
    text: '6h',
  },
  {
    value: 5.3,
    text: '5h 30min',
  },
  {
    value: 5,
    text: '5h',
  },
  {
    value: 4.3,
    text: '4h 30min',
  },
  {
    value: 4,
    text: '4h',
  },
  {
    value: null,
    text: 'Muu',
  },
];

export const defaultPaymentPeriod = { start: 1, end: 31 };

export const validateDates: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const start = control.parent?.get('start').value;
  const end = control.parent?.get('end').value;
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (startDate > endDate) {
    return { before: 'Start day should be after end date.' };
  }
  return null;
};

let startTime: number = 0;

export function setStartTime() {
  startTime = Date.now();
}

export function getDuration() {
  const end = Date.now();
  return differenceInMilliseconds(end, startTime) / 1000;
  // return intervalToDuration({ start: startTime, end: now }).seconds;
}
