import { Injectable, inject } from '@angular/core';
import { where } from '@firebase/firestore';
import { IService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { SiteReport } from '../models/site-report.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'site-reports';

@Injectable({
  providedIn: 'root',
})
export class SiteReportsService implements IService<SiteReport> {
  private firestore = inject(FirestoreService);

  async save(item: SiteReport) {
    return this.firestore.save<SiteReport>(itemCollection, item);
  }

  async update(item: SiteReport) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<SiteReport> {
    return this.firestore.get<SiteReport>(itemCollection, guid);
  }

  getList(options?: { site?: string; contractor: string }): Observable<SiteReport[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor.guid', '==', options.contractor));
    }

    return this.firestore
      .getList<SiteReport>(itemCollection, undefined, queryConstraints);
  }
}
