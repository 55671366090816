<ion-content>
  <ion-item button="true"
    lines="none"
    (click)="newCategory()">
    {{ 'help.category' | translate }}
  </ion-item>
  <ion-item button="true"
    lines="none"
    (click)="newItem()">
    {{ 'help.item' | translate }}
  </ion-item>
</ion-content>