import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Room } from 'src/app/models/room.model';

const DELIMITER = '-';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent {

  @Input()
  set roomType(value: 'area' | 'room' | 'common') {
    this.onRoomType({ detail: { value } });
    this.hideChoice = true;
  }

  @Output() room = new EventEmitter<Room[]>();

  @Output() cancel = new EventEmitter<void>();

  hideChoice = false;

  roomFormGroup: UntypedFormGroup;
  commonFormGroup: UntypedFormGroup;
  item: Room;
  multiple = signal(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  private getFormGroup(type: string): UntypedFormGroup {
    if (type === 'room') {
      return this.formBuilder.group({
        block: [''],
        numbers: ['', Validators.required],
      });
    } else if (type === 'common') {
      return this.formBuilder.group({
        name: [''],
        numbers: [''],
      });
    }
    throw Error(`Invalid room type: ${type}`);
  }

  private setFormGroup(): void {
    if (this.item.type === 'room') {
      this.roomFormGroup = this.getFormGroup(this.item.type);
      this.commonFormGroup = undefined;
    } else {
      this.commonFormGroup = this.getFormGroup(this.item.type);
      this.roomFormGroup = undefined;
    }
  }

  onRoomType(event: { detail: { value: 'area' | 'room' | 'common' } }): void {
    this.item = {
      id: '',
      type: event.detail.value,
    };
    this.setFormGroup();
  }

  toggleMultiple() {
    this.multiple.update((val) => !val);
  }

  save() {
    if (this.multiple()) {
      const numbers = this.commonFormGroup.get('numbers').value as string;
      if (numbers.includes(DELIMITER)) {
        const start = numbers.split(DELIMITER)[0];
        const end = numbers.split(DELIMITER)[1];
        const rooms: Room[] = [];
        for (let i = +start; i <= +end; i++) {
          const id = `${i}`;
          const room = Object.assign({}, this.item, { id });
          rooms.push(room);
        }
        this.room.emit(rooms);
      }
    } else {
      if (this.item.type === 'room') {
        const numbers = this.roomFormGroup.get('numbers').value as string;
        const block: string = this.roomFormGroup.get('block')?.value ?? '';
        if (numbers.includes(DELIMITER)) {
          const start = numbers.split(DELIMITER)[0];
          const end = numbers.split(DELIMITER)[1];
          const rooms: Room[] = [];
          for (let i = +start; i <= +end; i++) {
            const id = `${block}${i}`;
            const room = Object.assign({}, this.item, { id });
            rooms.push(room);
          }
          this.room.emit(rooms);
        } else {
          this.item.id = `${block}${numbers}`;
          this.room.emit([this.item]);
        }
      } else if (this.item.type === 'common') {
        const name = this.commonFormGroup.get('name').value;
        this.item.id = name;
        this.room.emit([this.item]);
      }
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
