<form [formGroup]="formGroup"
  (ngSubmit)="save()">
  @if (users$ | async; as users) {
    <ion-item lines="none"
      button="true"
      (click)="selectUser(users)">
      {{ selectedTo().name }}
    </ion-item>
  } @else {
    <app-loading></app-loading>
  }
  <h3 class="flex">
    {{ 'feedback.title' | translate }}
  </h3>
  <ion-item lines="none">
    <ion-input autocapitalize="on"
      formControlName="title"
      autocapitalize="true"></ion-input>
  </ion-item>
  <h3>{{ 'feedback.text' | translate }}</h3>
  <ion-item lines="none">
    <ion-textarea autocapitalize="on"
      formControlName="feedback"
      rows="14"
      autocapitalize="true"></ion-textarea>
  </ion-item>
  <div class="ion-margin-top">
    <ion-button color="light"
      shape="round"
      type="submit"
      [disabled]="!formGroup.valid">{{ 'general.send' | translate }}</ion-button>
  </div>
</form>