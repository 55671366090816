import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Dialog } from '@capacitor/dialog';
import { TranslateService } from '@ngx-translate/core';
import { addDays, parse, startOfDay } from 'date-fns';
import { Observable, of } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { Schedule } from '../models/schedule.model';
import { IService } from '../models/service.interface';
import { Site } from '../models/site.model';
import { SubItem } from '../models/sub-item.model';
import { RoomTicket } from '../models/ticket.model';
import { User } from '../models/user.model';
import { firestoreInLimit } from '../utility/constants';
import { selectedDayFormat, setDates } from '../utility/time';
import { ContractorsService } from './contractors.service';
import { FirestoreService } from './firestore.service';

const itemCollection = 'schedules';

export interface AddScheduleItem {
  days: number;
  text: string;
  machine: string;
  project: string;
  start: Date;
  end: Date;
  contractor: string;
  startDate: Date;
  includeWeekend: boolean;
  roomTickets?: RoomTicket[];
}

@Injectable({
  providedIn: 'root',
})
export class SchedulesService implements IService<Schedule> {
  constructor(
    private contractorsService: ContractorsService,
    private firestore: FirestoreService,
    private translate: TranslateService,
  ) { }

  private merge(items: Schedule[]): Schedule {
    const schedule: Schedule = {
      guid: items[0].guid,
      contractor: items[0].contractor,
      date: items[0].date,
      dateObj: items[0].dateObj,
      site: items[0].site,
      users: [],
      locked: [],
      information: items[0].information ?? [],
      machines: [],
      machinesGuids: [],
      projects: [],
      times: [],
    };
    const flatten = items.map((item) => item.users).flat();
    schedule.users = [...new Set(flatten)];

    const flattenLocked = items.map((item) => item.locked ?? []).flat();
    schedule.locked = [...new Set(flattenLocked)];

    const flattenMachines = items.map((item) => item.machines ?? []).flat();
    schedule.machines = [...new Set(flattenMachines)];
    schedule.machinesGuids = schedule.machines.map((m) => m.machine);

    const flattenProjects = items.map((item) => item.projects ?? []).flat();
    schedule.projects = [...new Set(flattenProjects)];

    const flattenTimes = items.map((item) => item.times ?? []).flat();
    schedule.times = [...new Set(flattenTimes)];

    if (items.length > 1) {
      this.update(schedule);
      for (let i = 1; i < items.length; i++) {
        this.firestore.delete(itemCollection, items[i]);
      }
    }
    return schedule;
  }

  async save(item: Schedule) {
    return this.firestore.save<Schedule>(itemCollection, item);
  }

  async update(item: Schedule) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<Schedule> {
    return this.firestore.get<Schedule>(itemCollection, guid);
  }

  getList(
    options?: { date?: string; site?: string; sites?: string[]; user?: string; contractor?: string; dateBetween?: { start: Date; end: Date } },
  ): Observable<Schedule[]> {
    const queryConstraints = [];
    if (options?.contractor || this.contractorsService.contractorS()?.guid) {
      queryConstraints.push(where('contractor', '==', options?.contractor ?? this.contractorsService.contractorS()?.guid));
    }
    if (options?.date) {
      queryConstraints.push(where('date', '==', options.date));
    }
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.sites) {
      if (options.sites.length > 30) {
        console.error('Schedules service, sites > 30', options.sites);
      }
      queryConstraints.push(where('site', 'in', options.sites));
    }
    if (options?.user) {
      queryConstraints.push(where('users', 'array-contains', options.user));
    }
    if (options?.dateBetween) {
      const startBetween = startOfDay(options.dateBetween.start);
      const endBetween = startOfDay(options.dateBetween.end);
      queryConstraints.push(where('dateObj', '>=', startBetween));
      queryConstraints.push(where('dateObj', '<=', endBetween));
    }
    return this.firestore
      .getList<Schedule>(itemCollection, undefined, queryConstraints);
  }

  getListOnce(
    options?: { date?: string; site?: string; sites?: string[]; user?: string; contractor?: string; dateBetween?: { start: Date; end: Date } },
  ): Observable<Schedule[]> {
    const queryConstraints = [];
    if (options?.contractor || this.contractorsService.contractorS()?.guid) {
      queryConstraints.push(where('contractor', '==', options?.contractor ?? this.contractorsService.contractorS()?.guid));
    }
    if (options?.date) {
      queryConstraints.push(where('date', '==', options.date));
    }
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.sites) {
      if (options.sites.length > firestoreInLimit) {
        console.error(`Schedules service, sites > ${firestoreInLimit}`, options.sites);
      }
      queryConstraints.push(where('site', 'in', options.sites));
    }
    if (options?.user) {
      queryConstraints.push(where('users', 'array-contains', options.user));
    }
    if (options?.dateBetween) {
      const startBetween = startOfDay(options.dateBetween.start);
      const endBetween = startOfDay(options.dateBetween.end);
      queryConstraints.push(where('dateObj', '>=', startBetween));
      queryConstraints.push(where('dateObj', '<=', endBetween));
    }
    return this.firestore.getListOnce<Schedule>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results.map((it) => setDates<Schedule>(it, 'dateObj'))),
    );
  }

  getSingle(options?: { site?: string; date: string; user?: string }): Observable<Schedule> {
    const queryConstraints = [];
    if (this.contractorsService.contractorS()?.guid) {
      queryConstraints.push(where('contractor', '==', this.contractorsService.contractorS()?.guid));
    }
    if (options?.date) {
      queryConstraints.push(where('date', '==', options.date));
    }
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.user) {
      queryConstraints.push(where('users', 'array-contains', options.user));
    }
    return this.firestore.getList<Schedule>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results?.length > 0 ? this.merge(results) : null),
    );
  }

  getSingleOnce(options?: { site?: string; date: string; user?: string }): Observable<Schedule> {
    const queryConstraints = [];
    if (this.contractorsService.contractorS()?.guid) {
      queryConstraints.push(where('contractor', '==', this.contractorsService.contractorS()?.guid));
    }
    if (options?.date) {
      queryConstraints.push(where('date', '==', options.date));
    }
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.user) {
      queryConstraints.push(where('users', 'array-contains', options.user));
    }
    return this.firestore.getListOnce<Schedule>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results?.length > 0 ? this.merge(results) : null),
    );
  }

  checkIfHas(
    user: string,
    site: string,
    startDay: Date,
    days: number,
    contractor?: string,
  ): Observable<boolean> {
    const dates = Array.from({ length: days }, (_, i) => i).map((i) => {
      return addDays(startDay, i).toLocaleDateString('fi');
    });
    const queryConstraints = [
      where('users', 'array-contains', user),
      where('site', '!=', site),
    ];
    if (this.contractorsService.contractorS()?.guid) {
      queryConstraints.push(where('contractor', '==', contractor ?? this.contractorsService.contractorS()?.guid));
    }
    return this.firestore.getList<Schedule>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results.flat()),
      map((results) => results.filter((it) => dates.includes(it.date))),
      map((results) => results.length > 0),
    );
  }

  //
  addSchedules(
    user: User, site: Site, schedule: Schedule, item: AddScheduleItem, loading: HTMLIonLoadingElement, initialSelectedDate: Date, includeWeekend: boolean,
  ) {
    const siteGuid = site.guid;

    let selectedDate = initialSelectedDate;

    this.getSchedules(siteGuid, schedule, selectedDate, item.days).pipe(
      debounceTime(200),
      take(1),
    ).subscribe(async (schedules) => {
      const hasMachines = schedules.filter((it) => item.machine && it.machinesGuids.includes(item.machine))?.length ?? 0;
      if (hasMachines > 0) {
        return Dialog.alert({
          message: this.translate.instant('schedule.existingMachine'),
        });
      }
      for (let i = 0; i < item.days; i++) {
        selectedDate = item.days === 1 ? initialSelectedDate : addDays(selectedDate, Math.min(i, 1));
        if (item.days > 1) {
          if (!includeWeekend) {
            if (selectedDate.getDay() === 0) {
              // Sunday, add day to next monday
              selectedDate = addDays(selectedDate, 1);
            } else if (selectedDate.getDay() === 6) {
              // Saturday, add 2 days to next monday
              selectedDate = addDays(selectedDate, 2);
            }
          }
        }
        const date = selectedDate.toLocaleDateString('fi');
        const s = schedules.find((sc) => sc.date === date);
        this.saveSchedule(s, user, site, date, item);
      }
      loading.dismiss();
    });
  }

  saveSchedule(schedule: Schedule, userObj: User, site: Site, date: string, item: AddScheduleItem): void {
    const user = userObj?.guid ?? null;
    const dateObj = parse(date, selectedDayFormat, new Date());
    const times = { user, start: item.start, end: item.end, machine: item.machine ?? null };

    if (schedule != null) {
      if (item.text) {
        if (!schedule.information) {
          schedule.information = [];
        }
        schedule.information.push({ user, text: item.text });
      }
      if (item.machine) {
        schedule.machines.push({ user, machine: item.machine });
        schedule.machinesGuids.push(item.machine);
      }
      schedule.times.push(times);
      if (user) {
        schedule.projects.push({ user, project: item.project });
        schedule.users.push(user);
      }
      this.update(schedule);
    } else {
      schedule = {
        contractor: item.contractor,
        date,
        dateObj,
        site: site.guid,
        users: user != null ? [user] : [],
        information: item.text ? [{ user, text: item.text }] : [],
        machines: item.machine ? [{ user, machine: item.machine }] : [],
        machinesGuids: item.machine ? [item.machine] : [],
        projects: user != null ? [{ user, project: item.project }] : [],
        times: [times],
      };
      this.save(schedule);
    }
  }

  allocateUser(users: User[], site: string, contractor: string, ticket: RoomTicket) {
    const workDayHours = 8;
    if (ticket.hours > workDayHours) {
      const dates = [startOfDay(ticket.deadline)];
      let currDate = startOfDay(ticket.deadline);
      let currHour = ticket.hours - workDayHours;
      while (currHour > 0) {
        currDate = addDays(currDate, -1);
        dates.push(currDate);
        currHour -= workDayHours;
      }
      dates.map((scheduleDate) => {
        this.allocateToDate(scheduleDate, users, site, contractor, ticket.workMachine);
      });
    } else {
      this.allocateToDate(ticket.deadline, users, site, contractor, ticket.workMachine);
    }
  }

  getSchedules(siteGuid: string, schedule: Schedule, startDay: Date, days: number): Observable<Schedule[]> {
    if (days === 1) {
      return schedule ? of([schedule]) : of([]);
    } else {
      return this.getList({ site: siteGuid }).pipe(
        map((schedules) => {
          const dates = Array.from({ length: days }, (_, i) => i).map((i) => {
            return addDays(startDay, i).toLocaleDateString('fi');
          });

          return schedules.filter((it) => dates.includes(it.date));
        }),
      );
    }
  }

  private allocateToDate(dateObj: Date, users: User[], site: string, contractor: string, resource?: SubItem) {
    const date = dateObj.toLocaleDateString('fi');
    this.getSingleOnce({ site, date }).pipe(
      take(1),
    ).subscribe((schedule) => {
      if (schedule) {
        schedule.users.push(...users.map((u) => u.guid));
        schedule.users = Array.from(new Set(schedule.users));
        if (resource) {
          if (!schedule.machines) {
            schedule.machines = [];
          }
          if (!schedule.machinesGuids) {
            schedule.machinesGuids = [];
          }
          schedule.machines.push({ user: users[0].guid, machine: resource.guid });
          schedule.machinesGuids.push(resource.guid);
        }
        this.update(schedule);
      } else {
        const newSchedule: Schedule = {
          contractor,
          date,
          dateObj,
          site,
          users: users.map((u) => u.guid),
          machines: resource ? [{ user: users[0].guid, machine: resource.guid }] : [],
          machinesGuids: resource ? [resource.guid] : [],
          times: [],
          projects: [],
        };
        this.save(newSchedule);
      }
    });
  }
}
