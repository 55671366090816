import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { PrivacyComponent } from './privacy.component';

@Component({
  standalone: true,
  selector: 'app-privacy-page',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainPageComponent,
    PrivacyComponent,
  ],
})
export class PrivacyPage {

}
