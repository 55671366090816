import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { SelectDialogComponent } from '@scandium-oy/ngx-scandium';
import { isBefore, startOfDay, startOfToday } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectAreaDialogComponent } from 'src/app/components/select-area/select-area.dialog';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { Contractor } from 'src/app/models/contractor.model';
import { Room } from 'src/app/models/room.model';
import { SiteProjectPeriod } from 'src/app/models/site-project-period.model';
import { SiteProjectPeriodTemplate } from 'src/app/models/site-project-template.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { AreasService } from 'src/app/services/areas.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlusService } from 'src/app/services/plus.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { SiteProjectPeriodsService } from 'src/app/services/site-project-period.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { Roles } from 'src/app/utility/role';
import { convertTimestamp, getDateWithTimezone, isTimestamp } from 'src/app/utility/time';

@Component({
  standalone: true,
  selector: 'app-project-job-dialog',
  templateUrl: './job.dialog.html',
  styleUrls: ['./job.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    SelectDayComponent,
    MainDialogComponent,
  ],
})
export class ProjectJobDialogComponent {
  private areasService = inject(AreasService);
  private contractorsService = inject(ContractorsService);
  private projectsService = inject(ProjectsService);
  private siteProjectPeriodsService = inject(SiteProjectPeriodsService);
  private usersService = inject(UsersService);
  private workerTicketsService = inject(WorkerTicketsService);

  private site$ = new BehaviorSubject<Site>(null);
  private templateS = signal<SiteProjectPeriodTemplate>(null);

  itemS = signal<SiteProjectPeriod>(null);
  projectS = signal<SiteProject>(null);
  selectedContractor = signal<Contractor>(null);
  hasSelectContractor = computed(() => [Roles.manager, Roles.superAdmin].includes(this.usersService.currentUserS().role) && this.itemS() == null);

  title = signal<string>(null);
  formGroup: FormGroup;

  minDate: string;
  maxDate: string;

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private plusService: PlusService,
    private navParams: NavParams,
  ) {
    const site = this.navParams.get('site');
    this.site$.next(site);
    this.templateS.set(this.navParams.get<SiteProjectPeriodTemplate>('template'));

    const item = this.navParams.get<SiteProjectPeriod>('item');
    this.itemS.set(item);
    if (item) {
      this.selectedContractor.set(item.contractor as Contractor);
    } else if (this.contractorsService.contractorS() != null) {
      this.selectedContractor.set(this.contractorsService.contractorS());
    }
    this.title.set(item?.name ?? null);

    this.projectS.set(this.navParams.get('project'));
    const projectStart = convertTimestamp(this.projectS().start);
    const projectEnd = convertTimestamp(this.projectS().end);
    this.minDate = getDateWithTimezone(projectStart);
    this.maxDate = getDateWithTimezone(projectEnd);

    const start = isBefore(startOfToday(), projectStart) ? projectStart.toISOString() : startOfToday().toISOString();

    this.formGroup = this.formBuilder.group({
      guid: [item?.guid ?? null],
      name: [item?.name ?? (this.templateS()?.name ?? ''), Validators.required],
      start: [
        item?.start ? this.getDate(item.start) : start,
        Validators.required,
      ],
      end: [item?.end ? this.getDate(item.end) : start, Validators.required],
      tickets: [item?.tickets ?? []],
      areas: [item?.areas ?? (this.projectS()?.areas ?? [])],
      project: [item?.project ?? this.projectS().guid],
    });
  }

  private getDate(date: Date | Timestamp) {
    if (isTimestamp(date)) {
      return date.toDate().toISOString();
    }
    return date.toISOString();
  }

  private getRooms() {
    const areas = this.formGroup.get('areas').value as {
      guid: string;
      name: string;
      id: string;
    }[];
    const templateTickets = this.templateS()?.tickets.map((t) => t.name) ?? [];
    this.workerTicketsService.createTicketsFromTemplate(areas, templateTickets, this.selectedContractor().guid, this.site$.getValue()).then((tickets) => {
      const allTickets = [...this.formGroup.get('tickets').value, ...tickets];
      this.formGroup.get('tickets').setValue(allTickets);
    });
  }

  selectContractor() {
    const items = this.navParams.get('contractors') ?? [];
    const clearButton = this.selectedContractor() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Contractor>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedContractor.set(null);
          } else {
            if (data.data.guid === null) {
              const site = this.navParams.get<Site>('site');
              this.projectsService.inviteNewContractor(site).then((contractor) => {
                this.selectedContractor.set(contractor);
              });
            } else {
              this.selectedContractor.set(data.data);
            }
          }
        }
      });
    });
  }

  onDateChange(value: string, field: string) {
    this.formGroup.get(field).setValue(value);
  }

  selectRoom() {
    const areas = this.formGroup.get('areas').value ?? [];
    const site = this.site$.getValue();
    this.modalCtrl.create({
      component: SelectAreaDialogComponent,
      componentProps: { site: site.guid, multiple: false, areas },
    }).then((m) => {
      m.present();

      m.onDidDismiss<Room[] | Room>().then((data) => {
        if (data.data) {
          if (Array.isArray(data.data)) {
            const value = data.data.map((it) => ({ guid: it.siteArea.root, name: it.siteArea.name, id: it.siteArea.id }));
            this.formGroup.get('areas').setValue(value);
          } else {
            const value = { guid: data.data.siteArea.root, name: data.data.siteArea.name, id: data.data.siteArea.id };
            this.formGroup.get('areas').setValue([value]);
          }
          this.getRooms();
        }
      });
    });
  }

  addNewTicket() {
    const item = this.formGroup.getRawValue();
    item.contractor = this.selectedContractor();
    this.plusService.onAdHoc([this.site$.getValue()], this.projectS(), item, false, true).then((tickets) => {
      if (tickets?.length > 0) {
        const allTickets = [...this.formGroup.get('tickets').value, ...tickets];
        this.formGroup.get('tickets').setValue(allTickets);
        if (item.guid) {
          tickets.map(async (t) => this.siteProjectPeriodsService.addTickets(item.guid, t));
        }
      }
    });
  }

  openTicket(item: SubItem) {
    this.areasService.openRoomTicket(item.parent, item.guid);
  }

  ticketTracking() {
    const site = this.site$.getValue();
    this.navigationService.navigateToSiteTickets(site.guid, this.projectS().guid, this.title());
    this.dismiss();
  }

  save() {
    const item = this.formGroup.value;
    item.start = startOfDay(new Date(item.start));
    item.end = startOfDay(new Date(item.end));
    item.contractor = { guid: this.selectedContractor().guid, name: this.selectedContractor().name };
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
