import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'translateField',
})
export class TranslateFieldPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) { }

  transform(value: Record<string, string>) {
    const currentLang = this.translateService.currentLang;
    if (value) {
      return value[currentLang];
    }
    return '';
  }
}
