import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Timestamp } from '@angular/fire/firestore';
import { IonicModule } from '@ionic/angular';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { format } from 'date-fns-tz';
import { millisecondsToMinutes } from 'date-fns/millisecondsToMinutes';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { CalendarItem, WeekCalendarComponent } from '../components/calendar/calendar.component';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { SelectUserButtonComponent, SelectedUser } from '../components/select-user/select-user.component';
import { Schedule, ScheduleTime } from '../models/schedule.model';
import { SchedulesService } from '../services/schedules.service';
import { SitesService } from '../services/sites.service';
import { UsersService } from '../services/users.service';
import { Roles } from '../utility/role';
import { isTimestamp } from '../utility/time';

function getDate(date: Timestamp | Date, time: ScheduleTime, field: string) {
  const itemDate = isTimestamp(date) ? date.toDate() : date;
  const itemTime = isTimestamp(time[field]) ? time[field].toDate() : time[field];
  itemDate.setHours(itemTime.getHours());
  itemDate.setMinutes(itemTime.getMinutes());
  return itemDate;
}

@Component({
  standalone: true,
  selector: 'app-user-week-calendar-page',
  templateUrl: './user-week-calendar.page.html',
  styleUrls: ['./user-week-calendar.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainPageComponent,
    SelectUserButtonComponent,
    WeekCalendarComponent,
  ],
})
export class UserWeekCalendarPageComponent {
  private schedulesService = inject(SchedulesService);
  private sitesService = inject(SitesService);
  private usersService = inject(UsersService);

  mobileView = signal(false);

  selectedUser = signal<SelectedUser>(null);
  subId = computed(() => this.selectedUser()?.guid);

  items$ = toObservable(this.selectedUser).pipe(
    filter((user) => user != null),
    switchMap((user) => combineLatest([this.schedulesService.getList({ user: user.guid }), this.sitesService.getActiveList()])),
    map(([items, sites]) => items?.map((it) => {
      const time = it.times.find((itt) => itt.user === this.selectedUser().guid);
      const startDate = getDate(it.dateObj, time, 'start');
      const endDate = getDate(it.dateObj, time, 'end');
      const duration = millisecondsToMinutes(endDate.getTime() - startDate.getTime());
      const name = sites.find((s) => s.guid === it.site)?.name;
      const information = it.information?.find((itt) => itt.user === this.selectedUser().guid)?.text;
      const subtitle = `${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')}`;

      const item: CalendarItem<Schedule> = {
        guid: it.guid,
        name,
        duration,
        startDate,
        endDate,
        information,
        subtitle,
        item: Object.assign({}, it),
      };

      return item;
    })),
    shareReplay(1),
  );

  users$ = this.usersService.getList(undefined, { role: Roles.worker }).pipe(
    map((users) => users.sort(fieldSorter(['displayName']))),
    tap(() => {
      const user = this.usersService.currentUserS();
      if ([Roles.worker].includes(user.role)) {
        this.selectedUser.set({ guid: user.guid, name: user.displayName, role: user.role, displayName: user.displayName });
      }
    }),
    shareReplay(1),
  );

  role = computed(() => this.usersService.currentUserS()?.role);

  selectUser(selectedUser: SelectedUser) {
    this.selectedUser.set(selectedUser);
  }
}
