<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>

  </div>
  @if (loaded()) {
    @if (!innerBackS()) {
      <app-segment-list [sub]="true"
        [segmentValue]="segment()"
        [segmentButtons]="segmentButtons()"
        (segmentChanged)="segment.set($event)">
      </app-segment-list>
    }
    @if (segment() === 'ticket') {
      <app-new-adhoc [ticket]="ticketS()"
        [site]="siteS()"
        [sites]="sitesS()"
        [additionalWork]="additionalWorkS()"
        [room]="roomS()"
        [project]="projectS()"
        [job]="jobS()"
        [setWorker]="setWorkerS()"
        (saved)="dismiss($event)">
      </app-new-adhoc>
    } @else if (segment() === 'ai') {
      <app-ai-tasks #aiTasks
        [site]="siteS()"
        [sites]="sitesS()"
        (saved)="saved($event)"
        (closed)="dismiss()"
        (hasSelectedTicket)="setHasInner($event)"></app-ai-tasks>
    }
  }
</app-main-dialog>