import { CommonModule } from '@angular/common';
import { Component, forwardRef, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Share } from '@capacitor/share';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from '@scandium-oy/ngx-scandium';
import { QueueItem } from '@scandium-oy/ngx-scandium/lib/services/queue.service';
import { shareReplay, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Room } from 'src/app/models/room.model';
import { RoomTicket, Ticket } from 'src/app/models/ticket.model';
import { LicenseService } from 'src/app/services/licence.service';
import { SitesService } from 'src/app/services/sites.service';
import { StripeService } from 'src/app/services/stripe.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { getChildAreaName, TicketEdit, WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { getAreaTicketGuid } from 'src/app/utility/kanban';
import { environment } from 'src/environments/environment';
import { AddphotosDialogComponent } from '../../ticket/dialog/add-photos.dialog';
import { TicketComponent } from '../../ticket/ticket.component';

@Component({
  standalone: true,
  selector: 'app-kanban-item-view-dialog',
  templateUrl: './item-view.dialog.html',
  styleUrls: ['./item-view.dialog.scss'],
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => TicketComponent),
  ],
})
export class KanbanItemViewDialogComponent {

  private parent: Ticket;
  canEditS = signal<boolean>(false);
  isSiteLeadS = signal<boolean>(false);
  siteGuidS = signal<string>(null);
  roomS = signal<Room>(null);
  roomTicket = signal<RoomTicket>(null);
  parentGuid = signal<string>(null);
  segmentValue = signal<string>(null);

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  showShare = signal(true);

  constructor(
    private licenseService: LicenseService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private stripeService: StripeService,
    private ticketsService: TicketsService,
    private translate: TranslateService,
    private usersService: UsersService,
    private workerTicketsService: WorkerTicketsService,
    navParams: NavParams,
  ) {
    const siteGuid = navParams.get('siteGuid');
    const site = this.sitesService.getSync(siteGuid);
    this.isSiteLeadS.set(this.usersService.isSitelead(site));
    this.canEditS.set(navParams.get('canEdit'));
    this.siteGuidS.set(siteGuid);

    this.parent = navParams.get('parent');
    this.parentGuid.set(this.parent.guid);
    const room: Room = Object.assign({}, navParams.get('room'));
    if (room != null && (room.id == null || room.id === '')) {
      room.id = getChildAreaName(this.parent.areaName);
      if (room.siteArea?.name == null) {
        room.siteArea = { id: '', name: this.parent.areaName, root: this.parent.area };
      }
    }
    this.roomS.set(room);
    this.roomTicket.set(navParams.get('ticket'));

    if (navParams.get<boolean>('setWorker')) {
      this.stripeService.getSubscriptions().pipe(
        takeUntilDestroyed(),
      ).subscribe((subs) => {
        if (subs.length > 0) {
          const last = subs[subs.length - 1];
          const worker = last.metadata;
          const ticket = this.roomTicket();
          ticket.users.push(worker.guid);
          ticket.usernames.push(worker.name);
          this.onTicketSave(ticket);
        }
      });
    }

    Share.canShare().then((value) => this.showShare.set(value.value));
  }

  private updateTicket(roomTicket: RoomTicket) {
    this.ticketsService.getOnce(this.parent.guid).pipe(
      take(1),
    ).subscribe((ticket) => {
      const existing = ticket.tickets.find((t) => (t.guid != null && t.guid === roomTicket.guid) || t.name === roomTicket.name);
      this.roomTicket.set(existing);
    });
  }

  private updateImages(images: string[], videos: string[]) {
    const ticket = this.roomTicket();
    if (images?.length > 0) {
      if (ticket.images == null) {
        ticket.images = [];
      }
      ticket.images.push(...images);
    }
    if (videos?.length > 0) {
      if (ticket.videos == null) {
        ticket.videos = [];
      }
      ticket.videos.push(...videos);
    }
    this.onTicketSave(ticket, { other: true });
  }

  async onPhoto() {
    const cb = (imageUrl: string) => {
      this.updateImages([imageUrl], []);
    };
    cb.bind(this);
    return this.modalCtrl.create({
      component: AddphotosDialogComponent,
      componentProps: { required: false, cb, mediaItems: (this.roomTicket().images?.length ?? 0) + (this.roomTicket().videos?.length ?? 0) },
      cssClass: ['modal-fullscreen', 'transparent-modal'],
    }).then(async (m) => {
      m.present();

      return m.onDidDismiss<{ images: string[]; videos: string[]; items: QueueItem<FileUpload>[] }>().then((data) => {
        if (data.data?.items.length > 0) {
          return { images: [], videos: [] };
        } else if (data.data) {
          this.updateImages(data.data.images, data.data.videos);
        }
        return data.data;
      });
    });
  }

  async onShare() {
    const guid = this.parent.guid;
    const ticket = this.roomTicket();
    const host: string = environment.host;
    const url = new URL(`${host}/ticket`);
    url.searchParams.set('guid', guid);
    url.searchParams.set('ticket', ticket.guid);

    await Share.share({
      title: ticket.name,
      text: ticket.name,
      url: url.href,
      dialogTitle: this.translate.instant('tickets.share'),
    });
  }

  onRoomChange(room: Room) {
    this.roomS.set(room);
    const parentGuid = getAreaTicketGuid(this.siteGuidS(), room.siteArea.root, room.siteArea.id, room.id, this.parent.contractor);
    this.ticketsService.getOnce(parentGuid).pipe(
      take(1),
    ).subscribe((newParent) => this.parent = newParent);
  }

  onTicketSave(roomTicket: RoomTicket, edited: TicketEdit = null) {
    this.workerTicketsService.onTicketSave(this.parent, roomTicket, edited, this.updateTicket.bind(this));
  }

  onDelete(roomTicket: RoomTicket) {
    this.ticketsService.getOnce(this.parent.guid).pipe(
      take(1),
    ).subscribe((ticket) => {
      ticket.tickets = ticket.tickets.filter((t) => !(t.guid != null && t.guid === roomTicket.guid) && t.name !== roomTicket.name);
      this.ticketsService.updateOnly(ticket).then(() => {
        this._modal.dismiss();
      }, (error) => {
        console.error(error);
      });
    });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
