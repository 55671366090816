import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SiteArea } from 'src/app/models/site-area.model';

@Component({
  standalone: true,
  selector: 'app-edit-area-dialog',
  templateUrl: './edit-area.dialog.html',
  styleUrls: ['./edit-area.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class EditAreaDialogComponent {
  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);

  formGroup: FormGroup;

  constructor() {
    const item = this.navParams.get<SiteArea>('item');

    this.formGroup = this.formBuilder.group({
      name: [item.name, Validators.required],
      size: [item.size],
      volume: [item.volume],
      height: [item.height],
    });
  }

  save() {
    const item = this.formGroup.value;
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
