import { inject, Injectable } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Contractor } from '../models/contractor.model';
import { Site } from '../models/site.model';
import { InviteContractor, SubcontractorDialogComponent } from '../subcontractors/dialog/subcontractor/subcontractor.dialog';
import { Roles } from '../utility/role';
import { ContractorsService } from './contractors.service';
import { SiteProjectsService } from './site-projects.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private contractorsService = inject(ContractorsService);
  private modalCtrl = inject(ModalController);
  private siteProjectsService = inject(SiteProjectsService);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  getContractors(site: Site) {
    return this.contractorsService.getList({ client: site.client }).pipe(
      map((contractors) => [
        ...contractors.map((it) => ({ guid: it.guid, name: it.name })),
        { name: this.translate.instant('contractor.addNew'), guid: null },
      ]),
      shareReplay(1),
    );
  }

  async inviteNewContractor(site: Site): Promise<Contractor> {
    return this.modalCtrl.create({ component: SubcontractorDialogComponent }).then(async (m) => {
      m.present();

      return m.onDidDismiss<InviteContractor>().then(async (data) => {
        if (data.data) {
          const admin = data.data.admin ? {
            displayName: data.data.admin,
            email: data.data.adminEmail,
            role: Roles.admin,
          } : null;
          const workers = data.data.workers ?? [];
          const contractor: Contractor = {
            name: data.data.name,
            admin,
            workers,
            license: 'free',
            price: 0,
            created: new Date(),
            licenseStart: new Date(),
            email: data.data.adminEmail ?? null,
            businessId: null,
            sites: [site.guid],
            clients: site.client ? [site.client] : [],
            disabled: false,
          };
          const found = await lastValueFrom(this.contractorsService.hasContractor(contractor.name));
          if (found) {
            Dialog.alert({
              message: this.translate.instant('signup.found'),
            });
            return null;
          } else {
            const doc = await this.contractorsService.save(contractor);
            contractor.guid = doc.id;
            const currentUser = this.usersService.currentUserS();
            if (currentUser.role === Roles.manager) {
              currentUser.contractors.push(doc.id);
              this.usersService.updateContractors(currentUser.guid, currentUser.contractors);
            }
            // Add default project to site
            this.siteProjectsService.createDefaultProject(site, contractor.guid);
            return contractor;
          }
        } else {
          return null;
        }
      });
    });
  }
}
