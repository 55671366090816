<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'calendar.site.schedule' | translate }}
  </div>
  <form [formGroup]="formGroup">
    <ion-item color="primary"
      lines="none">
      <ion-label>{{ 'calendar.site.start' | translate }}</ion-label>
      <ion-datetime-button datetime="scheduleStart"></ion-datetime-button>
      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime id="scheduleStart"
            locale="fi-FI"
            [showDefaultButtons]="true"
            [doneText]="'general.done' | translate"
            [cancelText]="'general.cancel' | translate"
            formControlName="start"
            (ionChange)="onTimeChange(true)"></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <ion-item color="primary"
      lines="none">
      <ion-label>{{ 'calendar.site.end' | translate }}</ion-label>
      <ion-datetime-button datetime="scheduleEnd"></ion-datetime-button>
      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime id="scheduleEnd"
            locale="fi-FI"
            [showDefaultButtons]="true"
            [doneText]="'general.done' | translate"
            [cancelText]="'general.cancel' | translate"
            formControlName="end"
            (ionChange)="onTimeChange(false)"></ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        [disabled]="formGroup.invalid"
        (click)="save()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</app-main-dialog>