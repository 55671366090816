<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'projects.chain' | translate }}
  </div>
  <p>{{ 'projects.chainInfo' | translate }}</p>
  <h4>
    {{ 'projects.doneBefore' | translate }}
  </h4>
  <ion-button color="secondary"
    (click)="selectProject(true)">
    @if (doneBefore()) {
      {{ doneBefore().name }}
    } @else {
      {{ 'general.select' | translate }}
    }
  </ion-button>
  <h4>
    {{ 'projects.doneAfter' | translate }}
  </h4>
  <ion-button color="secondary"
    (click)="selectProject(false)">
    @if (doneAfter()) {
      {{ doneAfter().name }}
    } @else {
      {{ 'general.select' | translate }}
    }
  </ion-button>
  <div class="ion-margin-top">
    <ion-button color="light"
      (click)="save()">
      {{ 'general.save' | translate }}
    </ion-button>
  </div>
</app-main-dialog>