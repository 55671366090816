<p class="font_8 wixui-rich-text__text">Tervetuloa urakka.app-sivustolle. Yksityisyytesi on meille
  t&auml;rke&auml;&auml;, ja olemme sitoutuneet suojelemaan henkil&ouml;tietojasi. T&auml;m&auml;
  yksityisyydensuojaseloste kertoo, miten ker&auml;&auml;mme, k&auml;yt&auml;mme ja suojaamme henkil&ouml;tietojasi,
  kun vierailet verkkosivustollamme tai k&auml;yt&auml;t palveluitamme. K&auml;ytt&auml;m&auml;ll&auml; sivustoamme
  hyv&auml;ksyt henkil&ouml;tietojesi k&auml;sittelyn t&auml;m&auml;n yksityisyydensuojaselosteen mukaisesti.</p>

<p class="font_8 wixui-rich-text__text">​</p>

<p class="font_8 wixui-rich-text__text">Tietojen ker&auml;&auml;minen</p>

<p class="font_8 wixui-rich-text__text">​</p>

<p class="font_8 wixui-rich-text__text">Voimme ker&auml;t&auml; henkil&ouml;tietojasi seuraavilla tavoilla:</p>

<ol class="font_8 wixui-rich-text__text">
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Suoraan sinulta: Ker&auml;&auml;mme tietoja, kun rekister&ouml;idyt
      palveluumme, tilaat uutiskirjeemme, t&auml;yt&auml;t lomakkeita tai otat meihin yhteytt&auml;.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Automaattisesti: Ker&auml;&auml;mme tietoja, kuten ev&auml;steiden ja
      muiden seurantatekniikoiden avulla, kun vierailet verkkosivustollamme.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Kolmansilta osapuolilta: Voimme saada tietoja sinusta kolmansilta
      osapuolilta, kuten sosiaalisen median palveluista, mainoskumppaneilta ja analytiikkapalveluilta.</p>
  </li>
</ol>

<p class="font_8 wixui-rich-text__text">&nbsp;</p>

<p class="font_8 wixui-rich-text__text">Ker&auml;&auml;m&auml;mme tiedot voivat sis&auml;lt&auml;&auml; nimesi,
  s&auml;hk&ouml;postiosoitteesi, puhelinnumerosi, IP-osoitteesi, k&auml;ytt&auml;j&auml;tunnuksesi ja salasanasi,
  maksutiedot, sek&auml; muut tarvittavat tiedot palveluidemme tarjoamiseen.</p>

<p class="font_8 wixui-rich-text__text">​</p>

<p class="font_8 wixui-rich-text__text">Tietojen k&auml;ytt&ouml;</p>

<p class="font_8 wixui-rich-text__text">​</p>

<p class="font_8 wixui-rich-text__text">K&auml;yt&auml;mme henkil&ouml;tietojasi seuraaviin tarkoituksiin:</p>

<ol class="font_8 wixui-rich-text__text">
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Palvelujemme tarjoaminen ja yll&auml;pito: K&auml;yt&auml;mme tietojasi
      palvelujemme tarjoamiseen, yll&auml;pitoon ja parantamiseen.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Asiakaspalvelu: K&auml;yt&auml;mme tietojasi vastataksemme kysymyksiisi,
      ratkaistaksemme ongelmia ja tarjotaksemme sinulle parempaa asiakaspalvelua.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Markkinointi ja viestint&auml;: Voimme k&auml;ytt&auml;&auml; tietojasi
      l&auml;hett&auml;&auml;ksemme sinulle tietoa tuotteistamme, palveluistamme ja tarjouksista, sek&auml; tiedottaa
      sinulle yrityksemme uutisista ja tapahtumista.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Personointi: K&auml;yt&auml;mme tietojasi tarjotaksemme sinulle
      personoituja palveluja ja sis&auml;lt&ouml;&auml;, kuten suosittelemalla sinulle kiinnostavia tuotteita ja
      palveluja.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Analytiikka ja tutkimus: Voimme k&auml;ytt&auml;&auml; tietojasi
      tutkiaksemme k&auml;ytt&auml;jien k&auml;ytt&auml;ytymist&auml; ja&nbsp;parantaaksemme verkkosivustoamme ja
      palveluitamme.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Lain noudattaminen ja oikeuksien suojeleminen: Voimme
      k&auml;ytt&auml;&auml; tietojasi noudattaaksemme lakeja ja s&auml;&auml;d&ouml;ksi&auml;, suojellaksemme
      oikeuksiamme tai muiden oikeuksia, sek&auml; est&auml;&auml;ksemme petoksia ja
      v&auml;&auml;rink&auml;yt&ouml;ksi&auml;.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Tietojen jakaminen</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Emme myy, vuokraa tai muuten jaa henkil&ouml;tietojasi kolmansille
      osapuolille ilman suostumustasi, paitsi seuraavissa tilanteissa:</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Palveluntarjoajat: Voimme jakaa tietojasi palveluntarjoajille, jotka
      suorittavat toimintoja puolestamme, kuten maksujen k&auml;sittely, tietojen analysointi, markkinointi ja
      asiakaspalvelu.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Laki ja oikeus: Voimme jakaa tietojasi, jos se on tarpeen lain
      noudattamiseksi, oikeusprosessin yhteydess&auml; tai viranomaisten pyynn&ouml;st&auml;.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Yritysj&auml;rjestelyt: Jos yrityksemme on mukana fuusiossa,
      yrityskaupassa tai muussa yritysj&auml;rjestelyss&auml;, voimme siirt&auml;&auml; henkil&ouml;tietojasi uudelle
      omistajalle.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Tietojen suojaaminen</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Olemme sitoutuneet suojaamaan henkil&ouml;tietojasi ja
      k&auml;ytt&auml;m&auml;&auml;n asianmukaisia teknisi&auml; ja organisatorisia toimenpiteit&auml;
      est&auml;&auml;ksemme tietojen katoamisen, vahingoittumisen, luvattoman k&auml;yt&ouml;n ja
      v&auml;&auml;rink&auml;yt&ouml;ksen. Huomioithan kuitenkin, ett&auml; mik&auml;&auml;n tietojen siirto
      internetiss&auml; ei ole t&auml;ysin turvallista, joten emme voi taata tietojesi t&auml;ydellist&auml;
      turvallisuutta.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Ev&auml;steet</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">K&auml;yt&auml;mme ev&auml;steit&auml; ja muita seurantatekniikoita
      parantaaksemme k&auml;ytt&auml;j&auml;kokemustasi, tarjotaksemme personoituja sis&auml;lt&ouml;j&auml; ja
      mainoksia, sek&auml; analysoidaksemme verkkosivustomme liikennett&auml;. Voit hallita ev&auml;steasetuksiasi
      selaimen asetuksista.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Oikeutesi</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Sinulla on oikeus tarkastaa, korjata, poistaa ja rajoittaa
      henkil&ouml;tietojesi k&auml;sittely&auml;. Voit my&ouml;s vastustaa tietojesi k&auml;sittely&auml; ja
      pyyt&auml;&auml; tietojen siirtoa. Jos haluat k&auml;ytt&auml;&auml; n&auml;it&auml; oikeuksia, ota meihin
      yhteytt&auml; k&auml;ytt&auml;en alla olevia yhteystietoja.</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Muutokset yksityisyydensuojaselosteeseen</p>
  </li>
  <li class="wixui-rich-text__text">
    <p class="font_8 wixui-rich-text__text">Pid&auml;t&auml;mme oikeuden muuttaa t&auml;t&auml;
      yksityisyydensuojaselostetta milloin tahansa. Muutokset astuvat voimaan heti, kun p&auml;ivitetty</p>
  </li>
</ol>