@if (selectedTicket()) {
  <div class="edit-container">
    <app-new-adhoc [aiTask]="true"
      [room]="selectedTicket().room"
      [site]="siteS()"
      [sites]="sites()"
      [ticket]="selectedTicket()"
      (saved)="onTicketSave($event)"></app-new-adhoc>
  </div>
} @else {
  @if (limit$ | async; as limit) {
    @if (limit.isFree) {
      <div class="flex flex-justify-between free-container">
        <span>{{ 'tickets.ai.tryFree' | translate }}</span>
        {{ limit.count }} / {{ limit.maxPrompts }}
      </div>
    }
  }
  @switch (step()) {
    @case(1) {
      <div class="container">
        <div class="flex">
          <div class="icon-container">
            <ion-icon name="color-wand-outline"></ion-icon>
          </div>
          <div>
            <h2>
              {{ 'tickets.ai.welcome' | translate }}
            </h2>
            <p [innerHTML]="'tickets.ai.info' | translate">
            </p>
            <h2>
              {{ 'tickets.ai.test' | translate }}
            </h2>
            <p>
              {{ 'tickets.ai.testInfo' | translate }}
            </p>
            @if (freeLimit()) {

            } @else {
              <ion-button shape="round"
                (click)="start()">
                {{ 'subcontractor.start' | translate }}
              </ion-button>
            }
          </div>
        </div>
      </div>
    } @case(2) {
      <div>
        <div class="ion-margin-bottom">
          <app-select-site-button [expand]="'block'"
            [selectedSite]="siteS()"
            [items]="sites()"
            [hasNew]="true"
            (selected)="onSite($event)"></app-select-site-button>
        </div>
        @if (['manager', 'super-admin'].includes(role())) {
          <div class="ion-margin-bottom">
            @if (contractors$ | async; as contractors) {
              <ion-button expand="block"
                shape="round"
                color="secondary"
                (click)="selectContractor(contractors)">
                @if (selectedContractor(); as subcontractor) {
                  <span>
                    {{ subcontractor.name }}
                  </span>
                } @else {
                  {{ 'subcontractor.select' | translate }}
                }
              </ion-button>
            }
          </div>
        }
        <div class="flex flex-center">
          <app-speech-to-text [title]="'tickets.ai.speak'"
            (text)="onMic($event)"></app-speech-to-text>
          <app-take-video [name]="'aiVideo'"
            color="light"
            fill="clear"
            [title]="'item.recordVideo'"
            [multiline]="true"
            [returnRef]="true"
            (reference)="onReference($event)"></app-take-video>
        </div>
        <ion-textarea class="description"
          [counter]="true"
          maxlength="500"
          [(ngModel)]="description"
          autocapitalize="on"
          rows="5"
          [placeholder]="'tickets.ai.description' | translate">
        </ion-textarea>
      </div>
      <div class="ion-margin-top">
        @if ({
          areas: areas$ | async,
          canSelectSubcontractor: canSelectSubcontractor$ | async,
          contractors: contractors$ | async,
          users: users$ | async,
        }; as vm) {
          <ion-button [disabled]="description.length === 0 || siteS() == null || loading()"
            color="light"
            shape="round"
            (click)="doTasks(description, vm.areas, vm.users, vm.contractors, vm.canSelectSubcontractor)">
            @if (loading()) {
              <ion-spinner slot="start"
                color="primary"></ion-spinner>
            }
            {{ 'tickets.ai.doTasks' | translate }}
          </ion-button>
        }
      </div>
    } @case (3) {
      <div>
        <h3>
          {{ 'tickets.ai.foundTitle' | translate }}
        </h3>
        <div class="ion-margin-bottom">
          {{ 'tickets.ai.found' | translate: { num: found().length} }}
        </div>
        @if ({
          contractors: contractors$ | async
        }; as vm) {
          <ion-list>
            @for (item of found(); track $index; let index = $index) {
              <ion-item-sliding>
                <ion-item lines="none">
                  <app-carousel-item drag-scroll-item
                    [item]="item"
                    (klick)="onItem($event, vm.contractors)"></app-carousel-item>
                </ion-item>
                <ion-item-options>
                  <ion-item-option color="danger"
                    (click)="deleteItem(index)">
                    <ion-icon name="trash-outline"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            }
          </ion-list>
        }
        <ion-button color="light"
          [disabled]="invalidTickets()"
          shape="round"
          (click)="createTasks()">
          {{ 'tickets.ai.createTickets' | translate }}
        </ion-button>
      </div>
    } @case (4) {
      <div class="flex-center">
        <ion-icon name="happy-outline"></ion-icon>
      </div>
      <div class="container">
        <h2>
          {{ 'tickets.ai.done' | translate }}
        </h2>
        <p [innerHTML]="'tickets.ai.doneInfo' | translate:{ num: found().length}"></p>
      </div>
      <div class="ion-margin flex flex-justify-between">
        <ion-button color="light"
          shape="round"
          (click)="more()">
          {{ 'tickets.ai.more' | translate }}
        </ion-button>
        <ion-button color="secondary"
          shape="round"
          (click)="end()">
          {{ 'tickets.ai.end' | translate }}
        </ion-button>
      </div>
    }
  }
}