import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstructionsLink } from '../models/instructions-link.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'instructions-link';

@Injectable({
  providedIn: 'root',
})
export class InstructionsLinkService {
  constructor(
    private firestore: FirestoreService,
  ) { }

  async save(item: InstructionsLink) {
    return this.firestore.save(itemCollection, item);
  }

  async update(item: InstructionsLink) {
    return this.firestore.update(itemCollection, item);
  }

  async delete(item: InstructionsLink) {
    return this.firestore.delete(itemCollection, item);
  }

  get(options?: { instruction?: string; ticket?: string }): Observable<InstructionsLink> {
    const queryConstraints = [];
    if (options?.instruction) {
      queryConstraints.push(where('instruction', '==', options.instruction));
    }
    if (options?.ticket) {
      queryConstraints.push(where('tickets', 'array-contains', options.ticket));
    }
    return this.firestore.getList<InstructionsLink>(itemCollection, undefined, queryConstraints).pipe(
      map((items) => items?.length > 0 ? items[0] : null),
    );
  }
}
