import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatetimeCustomEvent } from '@ionic/angular';
import { endOfDay, parse, startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { selectedDayFormat } from 'src/app/utility/time';

@Component({
  selector: 'app-selected-day',
  templateUrl: './selected-day.component.html',
  styleUrls: ['./selected-day.component.scss'],
})
export class SelectedDayComponent {
  currentDayISO: string;

  @Input()
  id: string;

  @Input()
  set currentDay(value: string) {
    if (value) {
      const date = parse(value, selectedDayFormat, new Date());
      this.currentDayISO = endOfDay(date).toISOString();
    }
  }

  @Output() changed = new EventEmitter<Date>();

  locale$: Observable<string> = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    map((user) => user.lang ?? 'fi'),
    startWith('fi'),
    shareReplay(1),
  );

  constructor(
    private usersService: UsersService,
  ) { }

  onDayChange(event: DatetimeCustomEvent) {
    const value = event.detail.value as string;
    if (value !== this.currentDayISO) {
      const date = new Date(value);
      this.changed.emit(startOfDay(date));
    }
  }

}
