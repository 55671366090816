/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { CommonModule } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AppCommonModule } from 'src/app/common.module';

export interface ImageItem {
  image: string;
  preview$: Observable<string>;
}

@Component({
  standalone: true,
  selector: 'app-image-item',
  template: `
    @if (item().preview$ | async; as preview) {
        @if (!previewLoaded()) {
          <ion-spinner></ion-spinner>
        }
        <img [hidden]="!previewLoaded()"
          [src]="preview"
          (load)="previewLoaded.set(true)"
          (click)="onClick()" />
      } @else if (item().image) {
        @if (item().preview$ == null) {
          <img [src]="item().image" (click)="onClick()" />
        }
      } @else {
        <ion-icon name="image-outline"></ion-icon>
      }`,
  styles: [`
    img {
      height: var(--app-image-height, 100%);
      object-fit: cover;
      border-radius: var(--app-card-radius);
      width: -webkit-fill-available;
      cursor: pointer;
    }
    ion-icon {
      color: var(--ion-color-medium);
      font-size: 5em;
      &.valid {
        position: absolute;
        font-size: 2em;
        right: 0.5em;
        top: 0.5em;
      }
      &.status {
        position: absolute;
        font-size: 1em;
        right: 0.5em;
        top: 0.5em;
      }
    }
    `],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class ImageComponent {
  item = input.required<ImageItem>();
  previewLoaded = signal(false);

  klick = output<string>();

  onClick() {
    this.klick.emit(this.item().image);
  }
}
