<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (item()) {
      {{ item().name }}
    } @else {
      {{ 'room.addingAreas' | translate }}
    }
  </div>
  @if (item()) {
    <div class="ion-margin-bottom">
      @if (item().size) {
        <ion-note>{{ 'room.size' | translate }} {{ item().size }} m<sup>2</sup></ion-note><br />
      }
      @if (item().volume) {
        <ion-note>{{ 'room.volume' | translate }} {{ item().volume }} m<sup>3</sup></ion-note><br />
      }
      @if (item().height) {
        <ion-note>{{ 'room.height' | translate }} {{ item().height }} m</ion-note><br />
      }
    </div>
  }
  @if (type() == null) {
    @if (rooms == null) {
      <div class="flex">
        <ion-button color="light"
          shape="round"
          size="small"
          (click)="selectAddArea()">
          <ion-icon slot="start"
            name="add-outline"></ion-icon>
          {{ 'room.addArea' | translate }}
        </ion-button>
      </div>
      <!-- <ion-button color="light"
          shape="round"
          size="small"
          (click)="type.set('common')">
          <ion-icon slot="start"
            name="add-outline"></ion-icon>
          {{ 'room.addCommon' | translate }}
        </ion-button>
      </div>
      <ion-note>{{ 'room.addCommonInfo' | translate }}</ion-note>-->
      <div class="ion-margin-top">
        @if (isRoot()) {
          <ion-button color="danger"
            shape="round"
            (click)="deleteSelf()">{{ 'general.delete' | translate }}</ion-button>
        }
      </div>
    } @else {
      <div class="full-width">
        <form class="ion-padding-bottom"
          [formGroup]="roomFormGroup"
          (ngSubmit)="saveRooms()">
          <div class="flex">
            <ion-button color="light"
              shape="round"
              size="small"
              (click)="type.set('common')">
              <ion-icon slot="start"
                name="add-outline"></ion-icon>
              {{ 'room.addCommon' | translate }}
            </ion-button>
            <ion-button color="light"
              shape="round"
              type="submit"
              [disabled]="!roomFormGroup.valid">{{ 'general.save' | translate }}</ion-button>
            @if (hasEdit()) {
              <ion-button color="light"
                shape="round"
                (click)="onEdit()">{{ 'general.edit' | translate }}</ion-button>
            }
          </div>
          <div>
            <ion-note>{{ 'room.addCommonInfo' | translate }}</ion-note>
          </div>
          <form formArrayName="rooms">
            @for (item of getRooms().controls; track item; let i = $index) {
              <ion-item lines="none">
                <ion-input autocapitalize="on"
                  [formControlName]="i"></ion-input>
                <ion-icon slot="end"
                  color="danger"
                  name="remove-circle-outline"
                  (click)="onDeleteRoom(i)"></ion-icon>
              </ion-item>
            }
          </form>
          <div class="ion-margin-top">
            <ion-button color="light"
              shape="round"
              type="submit"
              [disabled]="!roomFormGroup.valid">{{ 'general.save' | translate }}</ion-button>
          </div>
        </form>
      </div>
    }
  }
  @switch (type()) {
    @case ('area') {
      <div>
        @if (parentName) {
          <p>
            {{ 'room.addingTo' | translate:{name: parentName} }}
          </p>
        } @else {
          <p>
            {{ 'room.addingAreasInfo' | translate }}
          </p>
        }
        <form class="ion-padding-bottom"
          [formGroup]="formGroup"
          (ngSubmit)="save()">
          @if (isRoot()) {
            <h4>{{ 'room.name' | translate }}</h4>
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                formControlName="name"></ion-input>
            </ion-item>
          }
          <form formArrayName="areas">
            <h4>{{ 'room.areaName' | translate }}</h4>
            @for (item of getFields().controls; track item; let i = $index) {
              <ion-item lines="none">
                <ion-input autocapitalize="on"
                  [formControlName]="i"
                  [placeholder]="'room.example' | translate:{index: (i + 1)}"></ion-input>
                <ion-icon slot="end"
                  color="danger"
                  name="remove-circle-outline"
                  (click)="deleteItem(i)"></ion-icon>
              </ion-item>
            }
          </form>
          <div class="ion-margin-top">
            <ion-button color="secondary"
              shape="round"
              (click)="addItem()">
              <ion-icon slot="start"
                name="add-outline"></ion-icon>
              {{ 'item.addRow' | translate }}
            </ion-button>
          </div>
          <div class="ion-margin-top">
            <ion-button color="light"
              shape="round"
              type="submit"
              [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
            @if (isRoot()) {
              <ion-button color="danger"
                shape="round"
                (click)="deleteSelf()">{{ 'general.delete' | translate }}</ion-button>
            }
          </div>
        </form>
      </div>
    } @case ('room') {
      <app-room [roomType]="type()"
        (room)="onAreaRoom($event)"></app-room>
    } @case ('common') {
      <app-room [roomType]="type()"
        (room)="onAreaRoom($event)"
        (cancel)="onCancel()"></app-room>
    }
  }
</app-main-dialog>