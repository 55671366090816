import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, signal } from '@angular/core';
import { IonicModule, IonicSlides } from '@ionic/angular';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { AppCommonModule } from 'src/app/common.module';
import { mobileWidth } from 'src/app/utility/constants';
import { CarouselImage } from './carousel-image.model';

@Component({
  standalone: true,
  selector: 'app-carousel-images',
  templateUrl: './carousel-images.component.html',
  styleUrls: ['./carousel-images.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    DragScrollComponent,
    DragScrollItemDirective,
  ],
})
export class CarouselImagesComponent {

  @Input()
  items: CarouselImage[];

  @Output()
  klick = new EventEmitter<CarouselImage>();

  swiperModules = [IonicSlides];

  mobileView = signal(true);

  constructor() {
    this.onResize();
  }

  onItem(item: CarouselImage) {
    this.klick.emit(item);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView.set(window.innerWidth < mobileWidth);
  }
}
