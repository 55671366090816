import { Injectable, inject } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Price, StripePayments, getStripePayments } from '@invertase/firestore-stripe-payments';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { filter, map, switchMap } from 'rxjs/operators';
import { FirestoreItem } from '../models/firestore-item.model';
import { User } from '../models/user.model';
import { FirestoreService } from './firestore.service';
import { UsersService } from './users.service';

interface TaxRate extends FirestoreItem {
  id: string;
}

interface Product extends FirestoreItem {
  name: string;
  metadata: ProductData;
  active: boolean;
  currentPrice: Price & { guid: string };
}

export interface ProductData {
  guid: string;
  name?: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private firebaseApp = inject(FirebaseApp);
  private firestoreService = inject(FirestoreService);
  private functions = inject(Functions);
  private usersService = inject(UsersService);

  private payments: StripePayments;

  initialize() {
    this.payments = getStripePayments(this.firebaseApp, {
      productsCollection: 'products',
      customersCollection: 'users',
    });
  }

  async getPrices(guid: string) {
    const collection = this.payments.productsCollection;
    const queryConstraints = [where('active', '==', true)];
    return lastValueFrom(this.firestoreService.getListOnce<Price & { guid: string }>(`${collection}/${guid}/prices`, undefined, queryConstraints).pipe(
      filter((prices) => prices?.length > 0),
      map((prices) => prices[0]),
      map((price) => Object.assign({}, price, { price: price.unit_amount ? price.unit_amount / 100 : 0 })),
    ));
  }

  async getProducts() {
    if (this.payments) {
      const collection = this.payments.productsCollection;
      const queryConstraints = [where('active', '==', true)];
      const item = lastValueFrom(this.firestoreService.getListOnce<Product>(collection, undefined, queryConstraints).pipe(
        map((items) => items.map(async (it) => {
          it.currentPrice = await this.getPrices(it.guid);
          return it;
        })),
      ));
      return item;
    }
    throw Error('Not initialized');
  }

  async getTaxRate(): Promise<TaxRate> {
    if (this.payments) {
      const collection = this.payments.productsCollection;
      const queryConstraints = [where('active', '==', true)];
      const collectionKey = `${collection}/tax_rates/tax_rates`;
      return lastValueFrom(this.firestoreService.getListOnce<TaxRate>(collectionKey, undefined, queryConstraints).pipe(
        map((val) => val?.length > 0 ? val[0] : null),
      ));
    }
    throw Error('Not initialized');
  }

  getSubscriptions() {
    return this.usersService.getCurrentUser().pipe(
      filter((user) => user != null),
      switchMap((user) => this.usersService.getUserSubscriptions(user.guid)),
    );
  }

  async createSubscription(price: Price & { guid: string }, taxRate: string, worker: User, ticket: { parent: string; roomTicket: string }) {
    const user = this.usersService.currentUserS();
    if (user?.guid) {
      const host = Capacitor.isNativePlatform() ? 'https://staging.urakka.app/static-pages/close.html' : window.location.origin;
      const successUrl = Capacitor.isNativePlatform() ? host : `${host}/stripe/success?parent=${ticket?.parent}&ticket=${ticket?.roomTicket}&user=${worker.guid}`;
      const cancelUrl = Capacitor.isNativePlatform() ? host : `${host}/stripe/cancel?parent=${ticket?.parent}&ticket=${ticket?.roomTicket}`;
      const sub = {
        price: price.guid,
        success_url: successUrl,
        cancel_url: cancelUrl,
        tax_rates: [taxRate],
        metadata: {
          guid: worker.guid,
          name: worker.displayName,
          email: worker.email,
        },
      };
      const doc = await this.usersService.saveCheckoutSession(sub, user.guid);
      return this.usersService.getCheckoutSession(user.guid, doc.id);
    }
    throw Error('User null');
  }

  async createPortalLink(contractor: string) {
    const functionRef = httpsCallable<unknown, { url: string }>(this.functions, 'ext-firestore-stripe-payments-createPortalLink');

    const { data } = await functionRef({
      returnUrl: Capacitor.isNativePlatform() ? 'http://localhost' : `${window.location.origin}/contractors/${contractor}`,
    });
    if (Capacitor.isNativePlatform()) {
      return Browser.open({ url: data.url });
    } else {
      return window.location.assign(data.url);
    }
  }
}
