import { appModules } from '../services/licence.service';
import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const products: MenuSubItem[] = [
  {
    icon: 'cube',
    route: '/materials/order',
    name: 'nav.materials.order',
    module: appModules.products,
  },
  {
    icon: 'cube',
    route: '/materials/process',
    name: 'nav.materials.process',
    module: appModules.products,
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'diamond',
    name: 'nav.kanban',
    route: '/week-user',
    module: appModules.essentials,
  },
  {
    icon: 'cube',
    name: 'nav.materials.title',
    children: products,
  },
  {
    icon: 'newspaper',
    name: 'nav.news',
    route: '/worker/messages',
    module: appModules.essentials,
  },
  {
    icon: 'calendar',
    name: 'nav.userreportworker',
    route: '/report/user',
    module: appModules.reporting,
  },
  {
    icon: 'calendar-number-outline',
    name: 'nav.workingCalendar',
    route: '/user-week-calendar',
    module: appModules.users,
  },
  {
    icon: 'cash',
    name: 'nav.userpaycheckworker',
    route: '/worker/paycheck',
    module: appModules.users,
  },
];

export const workerMenu = {
  items,
};
