<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle
    class="flex-justify-between">
    {{ 'report.new.title' | translate }}
    <ion-note>
      {{ step() }} / {{ finalStep }}
    </ion-note>
  </div>
  @switch (step()) {
    @case (1) {
      <p>{{ 'report.new.info1' | translate }}</p>
      <h4>{{ 'report.new.select' | translate }}</h4>
      @for (template of templates; track template.name) {
        <ion-item button="true"
          lines="none"
          [disabled]="template.disabled"
          (click)="onTemplate(template.name)">
          {{ 'report.new.list.' + template.name | translate }}
        </ion-item>
      }
    } @case (2) {
      <p>{{ 'report.new.info2' | translate }}</p>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
      <div class="ion-margin-top flex-justify-between">
        <ion-button color="light"
          shape="round"
          (click)="onStep(-1)">
          {{ 'general.prev' | translate }}
        </ion-button>
        <ion-button color="light"
          shape="round"
          (click)="onStep(1)">
          {{ 'general.next' | translate }}
        </ion-button>
      </div>
    } @case (3) {
      <p>{{ 'report.new.info3' | translate }}</p>
      @switch (selectedTemplate()) {
        @case ('faultList') {
          <ng-container [ngTemplateOutlet]="notesTemplate"></ng-container>
          <div class="ion-margin-top">
            <ion-button color="light"
              shape="round"
              (click)="onNewNote()">
              {{ 'item.add' | translate }}
            </ion-button>
          </div>
        } @case ('tasks') {
          <app-select-tickets [tickets]="tickets$ | async"
            [selectedArea]="selectedArea()"
            [selectedProject]="selectedProject()"
            [selectedJob]="selectedJob()"
            (selectedTickets)="onSelectTicket($event)"></app-select-tickets>
        }
      }
      <div class="ion-margin-top flex-justify-between">
        <ion-button color="light"
          shape="round"
          (click)="onStep(-1)">
          {{ 'general.prev' | translate }}
        </ion-button>
        <ion-button color="light"
          shape="round"
          (click)="onStep(1)">
          {{ 'general.next' | translate }}
        </ion-button>
      </div>
    } @case (4) {
      <p>{{ 'report.new.info4' | translate }}</p>
      <ng-container [ngTemplateOutlet]="settingsTemplate"
        [ngTemplateOutletContext]="{ summary: true }"></ng-container>
      @switch (selectedTemplate()) {
        @case ('faultList') {
          <ng-container [ngTemplateOutlet]="notesTemplate"></ng-container>
        } @case ('tasks') {
          <h4>{{ 'kanban.title' | translate }}</h4>
          <div class="items-container summary">
            @for (ticket of selectedTickets(); track ticket) {
              <ion-item lines="none">
                <ion-thumbnail slot="start">
                  @if (ticket.preview$ | async; as preview) {
                    <img [src]="preview" />
                  } @else {
                    <img src="./assets/images/default-loading-image.png" />
                  }
                </ion-thumbnail>
                <div>
                  {{ ticket.name | translateTicket | async }}
                </div>
              </ion-item>
            }
          </div>
          <div id="print-taskreport"
            class="print">
            <div class="flex">
              <img class="logo"
                src="./assets/images/logo.png" />
              <div>
                <ion-note>{{ 'report.new.printTitle' | translate }}</ion-note>
                <h4>{{ 'report.new.list.' + selectedTemplate() | translate }}</h4>
              </div>
              <div class="grid">
                <ion-note>{{ 'report.new.created' | translate }}</ion-note>
                {{ creationDate | date:'d.M.yyyy' }}
                <ion-note>{{ 'report.new.creator' | translate }}</ion-note>
                {{ username() }}
              </div>
            </div>
            @for (ticket of selectedTickets(); track ticket; let i = $index) {
              @if (i > 0 && i % itemsPerPage === 0) {
                <div class="empty-container"></div>
              }
              <table>
                <tr class="header">
                  <td>ID</td>
                  <td>KUVAUS</td>
                  <td>VASTUUYRITYS</td>
                  <td>AJANKOHTA</td>
                </tr>
                <tr>
                  <td class="id">{{ ticket.guid }}</td>
                  <td class="room">{{ ticket.room?.id }}: {{ ticket.name }}</td>
                  <td>{{ selectedContractor().name }}</td>
                  <td class="date">{{ ticket.date | date:'d.M.yyyy' }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    @if (ticket.preview$ | async; as preview) {
                      <img [src]="preview" />
                    } @else {
                      <img src="./assets/images/default-loading-image.png" />
                    }
                  </td>
                  <td colspan="2">
                    @if (ticket.location) {
                      <img [src]="ticket.location">
                    } @else if (ticket.room?.blueprint) {
                      <img [src]="ticket.room?.blueprint">
                    }
                  </td>
                </tr>
              </table>
              @if (i > 0 && (i - (itemsPerPage - 1)) % itemsPerPage === 0) {
                <div class="empty-container"></div>
              }
            }
          </div>
        }
      }
      <div class="ion-margin-top flex-justify-between">
        <ion-button color="light"
          shape="round"
          (click)="onStep(-1)">
          {{ 'general.prev' | translate }}
        </ion-button>
        <ion-button color="light"
          shape="round"
          (click)="onDone()">
          {{ 'general.done' | translate }}
        </ion-button>
      </div>
    }
  }
</app-main-dialog>
<ng-template #settingsTemplate
  let-summary="summary">
  <h4>{{ 'site.management' | translate }}</h4>
  @if ({
  areas: areas$ | async,
  contractors: contractors$ | async,
  projects: projects$ | async,
  jobs: jobs$ | async
  }; as vm) {
    @if (!summary || selectedContractor()) {
      <ion-item button="true"
        lines="none"
        [disabled]="!['manager'].includes(role())"
        (click)="selectItem(vm.contractors, selectedContractor)">
        <div class="flex-justify-between full-width">
          {{ 'logistics.form.contractor' | translate }}
          <div>
            @if (selectedContractor()) {
              {{ selectedContractor().name }}
            } @else {
              {{ 'general.notSelected' | translate }}
            }
          </div>
        </div>
      </ion-item>
    }
    @if (!summary || selectedArea()) {
      <ion-item button="true"
        lines="none"
        (click)="selectItem(vm.areas, selectedArea)">
        <div class="flex-justify-between full-width">
          {{ 'calendar.site.area' | translate }}
          <div>
            @if (selectedArea()) {
              {{ selectedArea().name }}
            } @else {
              {{ 'general.notSelected' | translate }}
            }
          </div>
        </div>
      </ion-item>
    }
    @if (!summary || selectedProject()) {
      <ion-item button="true"
        lines="none"
        [disabled]="selectedContractor() == null"
        (click)="selectItem(vm.projects, selectedProject)">
        <div class="flex-justify-between full-width">
          {{ 'report.new.project' | translate }}
          <div>
            @if (selectedProject()) {
              {{ selectedProject().name }}
            } @else {
              {{ 'general.notSelected' | translate }}
            }
          </div>
        </div>
      </ion-item>
    }
    @if (!summary || selectedJob()) {
      <ion-item button="true"
        lines="none"
        [disabled]="selectedProject() == null"
        (click)="selectItem(vm.jobs, selectedJob)">
        <div class="flex-justify-between full-width">
          {{ 'site.job' | translate }}
          <div>
            @if (selectedJob()) {
              {{ selectedJob().name }}
            } @else {
              {{ 'general.notSelected' | translate }}
            }
          </div>
        </div>
      </ion-item>
    }
  }
</ng-template>
<ng-template #notesTemplate>
  <h4>{{ 'report.new.images' | translate }} ({{ notes().length }})</h4>
  @for (note of notes(); track note; let i = $index) {
    <ion-item lines="none">
      <ion-thumbnail slot="start">
        @if (note.images.length > 0) {
          <img [src]="note.images[0]" />
        } @else {
          <img src="./assets/images/default-loading-image.png" />
        }
      </ion-thumbnail>
      <div>
        <ion-note>
          {{ note.area }}
        </ion-note>
        <span class="name">
          {{ note.name }}
        </span>
        <div class="ellipsis">
          {{ note.text }}
        </div>
      </div>
    </ion-item>
    <ion-button class="remove"
      color="danger"
      fill="clear"
      shape="round"
      (click)="removeNote(i, note.name)">
      <ion-icon slot="icon-only"
        name="close-circle-outline"></ion-icon>
    </ion-button>
  } @empty {
    <app-no-results></app-no-results>
  }
</ng-template>