import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { News } from 'src/app/models/news.model';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-unread-news-dialog',
  templateUrl: './unread.dialog.html',
  styleUrls: ['./unread.dialog.scss'],
  providers: [DatePipe],
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    TimestampPipe,
  ],
})
export class UnreadNewsDialogComponent {

  news: News;

  lang$ = this.translate.onLangChange.asObservable().pipe(
    map((event) => event.lang),
    startWith(this.usersService.currentUserS()?.lang ?? this.translate.currentLang),
    shareReplay(1),
  );

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.news = navParams.get('news');
  }

  check() {
    const user = this.usersService.currentUserS();
    if (user) {
      const readNews = user.readNews ?? [];
      const item = {
        guid: this.news.guid,
        checked: new Date(),
      };
      if (!readNews.some((it) => it.guid === item.guid)) {
        readNews.push(item);
      }
      this.usersService.updateReadNews(user.guid, readNews).then(async () => this.modalCtrl.dismiss());
    }
  }
}
