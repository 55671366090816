import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IService } from '../models/service.interface';
import { SiteProjectTemplate } from '../models/site-project-template.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'site-project-templates';

@Injectable({
  providedIn: 'root',
})
export class SiteProjectTemplatesService implements IService<SiteProjectTemplate> {
  private firestore = inject(FirestoreService);

  async save(item: SiteProjectTemplate) {
    return this.firestore.save<SiteProjectTemplate>(itemCollection, item);
  }

  async update(item: SiteProjectTemplate) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<SiteProjectTemplate> {
    return this.firestore.get<SiteProjectTemplate>(itemCollection, guid);
  }

  getList(): Observable<SiteProjectTemplate[]> {
    const queryConstraints = [];

    return this.firestore
      .getList<SiteProjectTemplate>(itemCollection, undefined, queryConstraints);
  }
}
