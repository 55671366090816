import { Room } from '../models/room.model';
import { RoomTicket, TicketStatus } from '../models/ticket.model';

const fullPercentage = 100;

export function getHours(tickets: RoomTicket[]): { done: number; all: number } {
  if (tickets) {
    const all = tickets.reduce((prev, curr) => prev + +curr.hours, 0);
    const dones = tickets.filter((t) => t.status === TicketStatus.done || t.status === TicketStatus.checked);
    const done = dones.reduce((prev, curr) => prev + +curr.hours, 0);
    return { done, all };
  } else {
    return { done: 0, all: 0 };
  }
}

export function getPercentage(tickets: RoomTicket[]): number {
  if (tickets) {
    const { done, all } = getHours(tickets);
    return (done / all) * fullPercentage;
  } else {
    return 0;
  }
}

/**
 * @deprecated
 */
export function onFloor(floor: string, rooms: Room[], index: number) {
  rooms.map((room, i) => {
    if (i >= index) {
      if (room.floor === 'P' && floor !== 'P') {
        room.floor = floor;
      } else if (room.floor !== 'P' && floor === 'P') {
        room.floor = floor;
      } else if (room.floor == null || (room.floor !== 'P' && floor !== 'P' && +room.floor < +floor)) {
        room.floor = floor;
      }
    }
  });
}

export const defaultRoom = 'Ei aluevalintaa';
