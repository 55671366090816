import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload, LoadingService, UploadService } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { AppCanvasComponent } from 'src/app/components/blueprint/canvas.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NoResultsModule } from 'src/app/components/no-results/no-results.module';
import { SiteBlueprint } from 'src/app/models/site.model';

@Component({
  standalone: true,
  selector: 'app-select-location-dialog',
  templateUrl: './select-location.dialog.html',
  styleUrls: ['./select-location.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    AppCanvasComponent,
    MainDialogComponent,
    NoResultsModule,
  ],
})
export class SelectLocationDialogComponent {
  private loadingService = inject(LoadingService);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);
  private translate = inject(TranslateService);
  private uploadService = inject(UploadService);

  blueprints: SiteBlueprint[] = this.navParams.get('site').blueprints;

  imageElement = signal<HTMLImageElement>(null);
  selectedBlueprint = signal<SiteBlueprint>(null);

  private async saveBlueprint(blob: Blob, name: string) {
    this.loadingService.showLoading();
    const file = new File([blob], name,
      {
        lastModified: new Date().getTime(),
        type: blob.type,
      });
    const guid = name + '-' + new Date().toISOString();
    const fileupload = new FileUpload(guid, file);
    return this.uploadService.uploadImage(fileupload).then((downloadUrl) => {
      this.loadingService.hideLoading();
      this._modal.dismiss(downloadUrl);
    }).catch((e) => {
      this.loadingService.hideLoading();
      console.error(e);
      Dialog.alert({ message: this.translate.instant('general.tryAgain') });
    });
  }

  setBlueprintImage(img: Event) {
    const imgElement = img.target as HTMLImageElement;
    imgElement.crossOrigin = 'anonymous';
    setTimeout(() => this.imageElement.set(imgElement), 300);
  }

  onBlueprint(event: IonSelectCustomEvent<SelectChangeEventDetail<SiteBlueprint>>) {
    const blueprint = event.detail.value;
    this.selectedBlueprint.set(blueprint);
    const imageElement = new Image();
    imageElement.id = 'blueprint';
    imageElement.src = blueprint.url;
    imageElement.crossOrigin = 'anonymous';
    imageElement.className = 'canvas-img';
  }

  onSave(data: Blob) {
    this.saveBlueprint(data, this.selectedBlueprint().name);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
