<ion-button [expand]="expand()"
  [disabled]="disabled()"
  shape="round"
  [size]="size()"
  [color]="color()"
  (click)="selectWorker()">
  @if (selectedUsers()?.length === 1) {
    <div class="text-ellipsis">
      {{ selectedUsers()[0] }}
    </div>
  } @else if (selectedUsers()?.length > 0) {
    {{ selectedUsers().length }} {{ 'kanban.workers' | translate }}
  } @else {
    {{ 'kanban.chooseWorker' | translate }}
  }
</ion-button>