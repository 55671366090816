import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { IService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteArea } from '../models/site-area.model';
import { getLeaf } from '../utility/kanban';
import { FirestoreService } from './firestore.service';

const converter = {
  toFirestore: (item: SiteArea) => {
    const leafs = getLeaf(item);
    leafs?.map((it) => {
      it.rooms = it.rooms?.map((room) => {
        delete room.ticket$;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (room as any).path;
        return room;
      });
    });

    return item;
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options) as SiteArea;

    return data;
  },
};

const itemCollection = 'site-areas';

@Injectable({
  providedIn: 'root',
})
export class SiteAreasService implements IService<SiteArea> {
  constructor(private firestore: FirestoreService) { }

  async save(item: SiteArea) {
    return this.firestore.save<SiteArea>(itemCollection, item, converter);
  }

  async update(item: SiteArea) {
    return this.firestore.update(itemCollection, item, converter);
  }

  async delete(item: SiteArea) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string): Observable<SiteArea> {
    return this.firestore.get<SiteArea>(itemCollection, guid);
  }

  getList(options?: { site?: string }): Observable<SiteArea[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }

    return this.firestore
      .getList<SiteArea>(itemCollection, undefined, queryConstraints)
      .pipe(map((results) => results ?? []));
  }
}
