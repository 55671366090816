import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable, filter, switchMap } from 'rxjs';
import { ListItem } from '../models/list-item.model';
import { IService } from '../models/service.interface';
import { ContractorsService } from './contractors.service';
import { FirestoreService } from './firestore.service';

const itemCollection = 'material-categories';

@Injectable({
  providedIn: 'root',
})
export class MaterialCategoryService implements IService<ListItem> {
  constructor(
    private contractorsService: ContractorsService,
    private firestore: FirestoreService,
  ) { }

  async save(item: ListItem) {
    item.contractor = this.contractorsService.contractorS()?.guid ?? null;
    return this.firestore.save(itemCollection, item);
  }

  async update(item: ListItem) {
    return this.firestore.update(itemCollection, item);
  }

  get(_guid: string): Observable<ListItem> {
    throw Error('Not implemented get material category');
  }

  getList(): Observable<ListItem[]> {
    return this.contractorsService.getCurrentContractor().pipe(
      filter((contractor) => contractor != null),
      switchMap((contractor) => this.firestore.getList<ListItem>(itemCollection, { value: 'name', sort: 'asc' }, [where('contractor', '==', contractor.guid)])),
    );
  }
}
