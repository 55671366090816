export const objectsEqual = (o1, o2) =>
  o1 != null && o2 != null && Object.keys(o1).length === Object.keys(o2).length
  && Object.keys(o1).every(p => o1[p] === o2[p]);

export function getColor(value: number): 'red' | 'green' | 'yellow' | 'gray' {
  return value === 0 ? 'gray' : value < 6 ? 'red' : value > 7 ? 'green' : 'yellow';
}

export function getUniqueGuid(index?: number) {
  return (Date.now() + Math.ceil(Math.random() * 400) + (index ?? 0)).toString(36);
}
