import { Component, signal } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular';
import { InputInputEventDetail, IonInputCustomEvent } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@scandium-oy/ngx-scandium';
import { format, parse } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SelectSiteDialog } from 'src/app/components/select-site/select-site.dialog';
import { LocationService } from 'src/app/location/location.service';
import { ILocation } from 'src/app/models/location.model';
import { Options } from 'src/app/models/options.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { Timesheet, TimesheetPaymentType } from 'src/app/models/timesheet.model';
import { getPaymentTypes } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UsersService } from 'src/app/services/users.service';
import { allowedDistance, storageKeys } from 'src/app/utility/constants';
import { Roles } from 'src/app/utility/role';
import { millisToMinutesAndSeconds } from 'src/app/utility/time';

const FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

@Component({
  selector: 'app-timesheet-dialog',
  templateUrl: './timesheet.dialog.html',
  styleUrls: ['./timesheet.dialog.scss'],
})
export class TimesheetDialogComponent {

  private userGuid$ = new BehaviorSubject<string>(null);
  private paymentTypes = getPaymentTypes(this.translate);
  selectedProject = signal<SubItem>(null);

  sites$: Observable<Site[]> = this.userGuid$.asObservable().pipe(
    filter((userGuid) => userGuid != null),
    switchMap((userGuid) => this.usersService.get(userGuid)),
    map((user) => user.worksites),
    switchMap((siteGuids) => this.sitesService.getByGuids(siteGuids)),
    shareReplay(1),
  );

  site: Site;

  formGroup: UntypedFormGroup;
  timesheet: Timesheet;

  isAdmin$ = this.usersService.role$.pipe(
    map((role) => role === Roles.admin),
    shareReplay(1),
  );

  isSiteLead = signal(false);

  allowedDistance = allowedDistance;

  showPaymentTypeUnit = [this.paymentTypes[0].key, this.paymentTypes[1].key];
  milageAllowance = signal(0);
  milageAllowanceSummary = signal<string>('0.00');

  projects$ = this.userGuid$.asObservable().pipe(
    filter((user) => user != null),
    switchMap((_) => this.siteProjectsService.getList({ site: this.timesheet.site, contractor: this.timesheet.contractor })),
    tap((projects) => {
      const currentProject = this.timesheet?.project ?? null;
      const project = projects?.find((it) => it.guid === currentProject) ?? null;
      this.selectedProject.set({ guid: project?.guid, name: project?.name ?? '' });
    }),
    shareReplay(1),
  );

  constructor(
    private locationService: LocationService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private siteProjectsService: SiteProjectsService,
    private storageService: StorageService,
    private translate: TranslateService,
    private timesheetsService: TimesheetsService,
    private usersService: UsersService,
    navParams: NavParams,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.timesheet = Object.assign({}, navParams.get('timesheet'));
    this.userGuid$.next(this.timesheet.user);
    const sites: Site[] = navParams.get('sites');

    this.site = sites.find((s) => s.guid === this.timesheet.site);
    this.isSiteLead.set(this.usersService.isSitelead(this.site));

    let timesheetDate: Date;
    if (this.timesheet.dateIn) {
      this.timesheet.userDates = {
        in: this.timesheet.dateIn,
        out: this.timesheet.dateOut ?? null,
      };
      const tsDate = new Date(this.timesheet.dateIn);
      tsDate.setHours(0, 0, 0, 0);
      timesheetDate = tsDate;
    } else {
      timesheetDate = parse(this.timesheet.date, 'd.M.yyyy', new Date());
    }
    const formattedTimesheetDate = format(timesheetDate, FORMAT);

    const dateIn = this.timesheet.dateIn ? format(this.timesheet.dateIn, FORMAT) : formattedTimesheetDate;
    const dateOut = this.timesheet.dateOut ? format(this.timesheet.dateOut, FORMAT) : formattedTimesheetDate;
    this.formGroup = this.formBuilder.group({
      dateIn: [dateIn],
      dateOut: [dateOut],
      paymentTypes: this.formBuilder.array([]),
    });

    this.init();
  }

  private async init() {
    const options = await this.storageService.get<Options>(storageKeys.options);
    this.milageAllowance.set(options?.milageAllowance ?? 0);

    if (this.timesheet.paymentTypes?.length > 0) {
      this.timesheet.paymentTypes.map((pt) => {
        const newItem = this.formBuilder.group({
          paymentType: [pt.paymentType, Validators.required],
          paymentTypeUnit: [pt.paymentTypeUnit],
          paymentTypeText: [pt.paymentTypeText],
        });
        this.getFields().push(newItem);
        this.setMilageAllowance(+pt.paymentTypeUnit, pt.paymentType);
      });
    }
  }

  private setMilageAllowance(value: number, type: string) {
    if (type === 'kmPay') {
      const ret = (value * (this.milageAllowance() ?? 0)).toFixed(2);
      this.milageAllowanceSummary.set(ret);
    }
  }

  selectProject(items: SiteProject[]) {
    const clearButton = this.selectedProject() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedProject.set(null);
          } else {
            this.selectedProject.set(data.data);
          }
        }
      });
    });
  }


  onMilageAllowance(event: IonInputCustomEvent<InputInputEventDetail>, type: string) {
    const value = +event.detail.value;
    this.setMilageAllowance(value, type);
  }

  getFields() {
    return this.formGroup.get('paymentTypes') as FormArray;
  }

  onSelectPaymentType() {
    const existing = this.formGroup.get('paymentTypes').value as TimesheetPaymentType[];
    const items = this.paymentTypes.filter((it) => !existing.some((itt) => itt.paymentType === it.key));
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {

          } else {
            const newItem = this.formBuilder.group({
              paymentType: [data.data.key, Validators.required],
              paymentTypeUnit: [''],
              paymentTypeText: [''],
            });
            this.getFields().push(newItem);
          }
        }
      });
    });
  }

  removePaymentType(index: number) {
    this.getFields().removeAt(index);
  }

  onSite(sites: Site[]) {
    this.modalCtrl.create({ component: SelectSiteDialog, componentProps: { sites } }).then((m) => {
      m.present();
      m.onDidDismiss<Site>().then((data) => {
        if (data.data) {
          this.site = data.data;
          this.timesheet.site = data.data.guid;
        }
      });
    });
  }

  summary(outP: string, inP: string): string {
    const dateOut = new Date(outP);
    const dateIn = new Date(inP);
    return millisToMinutesAndSeconds(dateOut.getTime() - dateIn.getTime());
  }

  async onAccept() {
    const translateValues = await this.translate
      .get(['timesheet.done.title', 'timesheet.done.text'])
      .toPromise();
    const confirmRet = await Dialog.confirm({
      title: translateValues['timesheet.done.title'],
      message: translateValues['timesheet.done.text'],
    });
    if (confirmRet.value) {
      this.timesheet.checked = true;
      this.save();
    }
  }

  async setFullDay() {
    const translateValues = await this.translate
      .get(['timesheet.fullDay.title', 'timesheet.fullDay.text'])
      .toPromise();
    const confirmRet = await Dialog.confirm({
      title: translateValues['timesheet.fullDay.title'],
      message: translateValues['timesheet.fullDay.text'],
    });
    if (confirmRet.value) {
      const dateIn = new Date(this.timesheet.dateIn);
      dateIn.setHours(7, 0, 0, 0);
      this.formGroup.get('dateIn').patchValue(format(dateIn, FORMAT));
      const dateOut = new Date(this.timesheet.dateIn);
      dateOut.setHours(15, 30, 0, 0);
      this.formGroup.get('dateOut').patchValue(format(dateOut, FORMAT));
    }
  }

  async clearOut() {
    const translateValues = await this.translate
      .get(['timesheet.clear.title', 'timesheet.clear.text'])
      .toPromise();
    const confirmRet = await Dialog.confirm({
      title: translateValues['timesheet.clear.title'],
      message: translateValues['timesheet.clear.text'],
    });
    if (confirmRet.value) {
      this.formGroup.get('dateOut').patchValue(null);
    }
  }

  getDistance(location: ILocation) {
    if (this.site?.location) {
      return this.locationService.calculateDistance(location, this.site.location);
    }
  }

  openMap(location: ILocation) {
    const url = `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
    Browser.open({ url });
  }

  save() {
    const { dateIn, dateOut, paymentTypes } = this.formGroup.value;
    if (this.selectedProject()) {
      this.timesheet.project = this.selectedProject().guid;
    }
    if (paymentTypes) {
      this.timesheet.paymentTypes = paymentTypes;
    }
    this.timesheet.dateIn = dateIn ? new Date(dateIn) : null;
    this.timesheet.dateOut = dateOut ? new Date(dateOut) : null;
    this.timesheetsService.update(this.timesheet).then(() => this.dismiss(true));
  }

  async delete() {
    const translateValues = await this.translate
      .get(['timesheet.delete.title', 'timesheet.delete.text'])
      .toPromise();
    const confirmRet = await Dialog.confirm({
      title: translateValues['timesheet.delete.title'],
      message: translateValues['timesheet.delete.text'],
    });
    if (confirmRet.value) {
      // Delete
      this.timesheetsService.remove(this.timesheet).then(() => this.dismiss(true));
    }
  }

  dismiss(item?: boolean) {
    this._modal.dismiss(item);
  }
}
