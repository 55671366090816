<div class="nav">
  @for (item of items; track item.key) {
  <div class="desktop">
    <div (click)="onNav(item.key)"
      [class.selected]="selectedTab() === item.key">{{ item.name }}</div>
  </div>
  }
  <ion-item color="secondary"
    lines="none">
    <ion-select aria-label=""
      label-placement="stacked"
      [value]="selectedTab()"
      interface="action-sheet"
      (ionChange)="onNav($event)">
      @for (item of items; track item.key) {
      <ion-select-option [value]="item.key">
        {{ item.name }}
      </ion-select-option>
      }
    </ion-select>
  </ion-item>
</div>