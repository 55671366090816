<app-main-dialog [canBack]="false"
  (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'location.title' | translate }}
  </div>
  <div class="img">
    <ion-icon name="location-outline"></ion-icon>
  </div>
  <div class="info">
    <p>{{ 'location.info' | translate }}</p>
    @if (loading()) {
      <app-loading></app-loading>
    } @else {
      <ion-button color="light"
        shape="round"
        class="ion-margin-top"
        (click)="askPermission()">{{ 'kanban.continue' | translate }}</ion-button>
    }
  </div>
</app-main-dialog>