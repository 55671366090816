import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';

export interface InviteContractor {
  name: string;
  admin: string;
  adminEmail: string;
  workers: { displayName: string; email: string }[];
}

@Component({
  standalone: true,
  selector: 'app-subcontractor-dialog',
  templateUrl: './subcontractor.dialog.html',
  styleUrls: ['./subcontractor.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class SubcontractorDialogComponent {

  step = signal(1);
  formGroup: FormGroup;
  showAdmin = signal(true);

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      admin: [''],
      adminEmail: ['', [Validators.email]],
      workers: this.formBuilder.array([]),
    });
  }

  getFields() {
    return this.formGroup.get('workers') as FormArray;
  }

  addItem() {
    const item = this.formBuilder.group({
      displayName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.getFields().push(item);
  }

  deleteItem(index: number) {
    this.getFields().removeAt(index);
  }

  save() {
    const item = this.formGroup.value;
    this.dismiss(item);
  }

  dismiss(item?: InviteContractor) {
    this._modal.dismiss(item);
  }
}
