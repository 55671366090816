<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'site.job' | translate }}
  </div>
  @for (project of projects(); track project.guid) {
    <h3>{{ project.name }}</h3>
    @for (period of project.periods; track period) {
      <ion-item button="true"
        lines="none"
        (click)="select(period, project)">
        {{ period.name }}
        <span slot="end">
          {{ period.start | date:'dd.MM.' }}
          -
          {{ period.end | date:'dd.MM.' }}
        </span>
      </ion-item>
    } @empty {
      <app-no-results></app-no-results>
    }
  }
  <div class="ion-margin-top flex">
    @if (clearButton) {
      <ion-button color="light"
        shape="round"
        (click)="clear()">
        <ion-icon slot="start"
          name="close-outline"></ion-icon>
        {{ 'general.clear' | translate }}
      </ion-button>
    }
  </div>
</app-main-dialog>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <ion-button color="light"
        class="add-button"
        shape="round"
        (click)="onNew()">
        <ion-icon slot="icon-only"
          name="add-circle"
          aria-hidden="true"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>