import { Injectable } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Client } from '../models/client.model';
import { IService } from '../models/service.interface';
import { firestoreInLimit } from '../utility/constants';
import { FirestoreService } from './firestore.service';

const itemCollection = 'clients';

const converter = {
  toFirestore: (item: Client) => {
    delete item.workSites;
    delete item.file;
    return item;
  },
  fromFirestore: (snapshot, options) => snapshot.data(options) as Client,
};

@Injectable({
  providedIn: 'root',
})
export class ClientsService implements IService<Client> {
  clients$ = new BehaviorSubject<Client[]>(null);

  constructor(
    private firestore: FirestoreService,
  ) { }

  async save(client: Client) {
    return this.firestore.saveAs(itemCollection, client.guid, client, converter);
  }

  async update(item: Client) {
    return this.firestore.update(itemCollection, item, converter);
  }

  async delete(item: Client): Promise<void> {
    return this.firestore.softDelete(itemCollection, item);
  }

  get(guid: string): Observable<Client> {
    return this.clients$.asObservable().pipe(
      filter((clients) => clients?.length > 0),
      map((clients) => clients?.find((it) => it.guid === guid)),
    );
  }

  getSync(guid: string) {
    return this.clients$.getValue()?.find((it) => it.guid === guid);
  }

  getList(): Observable<Client[]> {
    return this.clients$.asObservable().pipe(
      map((clients) => clients ?? []),
    );
  }

  getListFromDb(guids?: string[]): Observable<Client[]> {
    if (guids != null) {
      let ids = [...guids];
      if (ids?.length === 0) {
        return of([]);
      }
      const batches: Observable<Client[]>[] = [];

      while (ids.length) {
        const batch = ids.splice(0, firestoreInLimit);
        batches.push(this.firestore
          .getList<Client>(itemCollection, undefined, [where(documentId(), 'in', [...batch])],
          ));
      }

      return combineLatest(batches).pipe(
        map((results) => results.flat()),
        map((results) => results.filter((client) => client.deleted == null)),
      );
    } else {
      return this.firestore.getList<Client>(itemCollection).pipe(
        map((results) => results.filter((client) => client.deleted == null)),
      );
    }
  }

  setClients(clients: Client[]) {
    this.clients$.next(clients);
  }
}
