import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { News } from '../models/news.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'news';

@Injectable({
  providedIn: 'root',
})
export class NewsService implements IService<News> {

  constructor(private firestore: FirestoreService) { }

  async save(item: News) {
    return this.firestore.save(itemCollection, item);
  }

  async update(item: News) {
    return this.firestore.update(itemCollection, item);
  }

  async delete(item: News) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string): Observable<News> {
    return this.firestore.get<News>(itemCollection, guid);
  }

  getList(options?: { role?: string; contractor?: string }): Observable<News[]> {
    const queryConstraints = [];
    if (options?.role) {
      queryConstraints.push(where('role', '==', options.role));
    }
    if (options?.contractor || options?.contractor === null) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }

    return this.firestore
      .getList<News>(itemCollection, undefined, queryConstraints)
      .pipe(map((results) => results.map((it) => setDates(it, 'created')) ?? []));
  }
}
