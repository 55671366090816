import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SitesService } from '../services/sites.service';
import { UsersService } from '../services/users.service';

export const isSiteleadGuard = (): CanActivateFn => {
  return () => {
    const sitesService = inject(SitesService);
    const usersService = inject(UsersService);
    return combineLatest([toObservable(usersService.isSupervisor), sitesService.isSiteLead$]).pipe(
      filter(([isSupervisor, isSitelead]) => isSupervisor != null && isSitelead != null),
      map(([isSupervisor, isSitelead]) => isSupervisor || isSitelead),
    );
  };
};
