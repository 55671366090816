import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {

  private update$ = new ReplaySubject<void>(1);

  update() {
    this.update$.next();
  }

  getUpdate() {
    return this.update$.asObservable();
  }
}
