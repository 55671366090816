import { Pipe, PipeTransform } from '@angular/core';
import { millisToMinutesAndSeconds } from 'src/app/utility/time';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    return millisToMinutesAndSeconds(value);
  }
}
