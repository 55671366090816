import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { StorageReference } from '@angular/fire/storage';
import { Dialog } from '@capacitor/dialog';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { FileUpload } from 'src/app/models/file.model';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  standalone: true,
  selector: 'app-take-video',
  templateUrl: './take-video.component.html',
  styleUrls: ['./take-video.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class TakeVideoComponent {

  color = input('medium');
  disabled = input(false);
  name = input('');
  title = input('item.selectVideo');
  fill = input('outline');
  expand = input('block');
  shape = input('');
  multiline = input(false);
  hideText = input(false);
  returnRef = input(false);

  imageUrl = output<string>();
  reference = output<StorageReference>();

  constructor(
    private loadingService: LoadingService,
    private translate: TranslateService,
    private uploadService: UploadService,
  ) { }

  selectFiles(event: Event) {
    this.loadingService.showLoading();
    const inputElem = event.target as HTMLInputElement;
    const file = inputElem.files[0];
    const guid = this.name() + '-' + new Date().toISOString();
    const fileupload = new FileUpload(file);
    fileupload.guid = guid;
    if (this.returnRef()) {
      this.uploadService.uploadFile(fileupload).then((ref) => {
        this.reference.emit(ref);
      }).catch((e) => {
        this.loadingService.hideLoading();
        console.error(e);
        Dialog.alert({ message: this.translate.instant('general.tryAgain') });
      });
    } else {
      this.uploadService.uploadImage(fileupload).then((downloadUrl) => {
        this.loadingService.hideLoading();
        this.imageUrl.emit(downloadUrl);
      }).catch((e) => {
        this.loadingService.hideLoading();
        console.error(e);
        Dialog.alert({ message: this.translate.instant('general.tryAgain') });
      });
    }
  }
}
