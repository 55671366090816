<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'kanban.ticketCreated' | translate }}
  </div>
  <div class="flex">
    <ion-button color="light"
      shape="round"
      (click)="toTicket()">
      {{ 'kanban.toTicket' | translate }}
    </ion-button>
    <ion-button color="light"
      shape="round"
      (click)="toTicketList()">
      {{ 'kanban.toTicketList' | translate }}
    </ion-button>
    <ion-button color="light"
      shape="round"
      (click)="newTicket()">
      {{ 'kanban.newTicket' | translate }}
    </ion-button>
  </div>
</app-main-dialog>