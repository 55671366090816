import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectSiteButtonComponent } from 'src/app/components/select-site/select-site.component';
import { SelectUserButtonComponent } from 'src/app/components/select-user/select-user.component';
import { AppCommonModule } from '../../common.module';
import { NewTimesheetDialogComponent } from './new-timesheet.dialog';

@NgModule({
  declarations: [NewTimesheetDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonicModule,
    AppCommonModule,
    TranslateModule,
    SelectDayComponent,
    SelectUserButtonComponent,
    SelectSiteButtonComponent,
    MainDialogComponent,
  ],
})
export class NewTimesheetModule { }
