import { Component, Input, OnDestroy, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Site } from 'src/app/models/site.model';
import { LicenseService } from 'src/app/services/licence.service';
import { PlusService } from 'src/app/services/plus.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { getTabHidden } from 'src/app/utility/tab';

@Component({
  selector: 'app-adhoc',
  styleUrls: ['./adhoc.component.scss'],
  templateUrl: './adhoc.component.html',
})
export class AdhocComponent implements OnDestroy {

  private destroy = new Subject<void>();
  private url = toSignal(this.router.events.pipe(
    takeUntil(this.destroy),
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((e) => e.url)));

  @Input()
  set loading(value: boolean) {
    this.plusService.isLoading.set(value);
  }

  hidden = computed(() => {
    const role = this.usersService.role();
    if (role) {
      return getTabHidden(this.url(), role);
    } else {
      return true;
    }
  });

  sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites != null),
    shareReplay(1),
  );

  isLoading = computed(() => this.plusService.isLoading());

  constructor(
    private route: ActivatedRoute,
    private licenseService: LicenseService,
    private plusService: PlusService,
    private router: Router,
    private sitesService: SitesService,
    private siteProjectsService: SiteProjectsService,
    private usersService: UsersService,
  ) { }


  async onClick(sites: Site[], e: Event) {
    const user = this.usersService.currentUserS();
    const role = this.usersService.role();
    if (![Roles.manager, Roles.superAdmin].includes(role)) {
      const modules = this.licenseService.getModulesS();
      if (Object.keys(modules).length === 0) {
        return this.plusService.openLicense();
      }
    }

    let url = this.router.url;

    if (url === '/partner/notes') {
      this.plusService.onNewPartnerNote();
      return;
    }

    if (url !== '/worker/messages' && role === Roles.partner) {
      if (user.premiumPartner) {
        return this.plusService.inviteUser();
      } else {
        return;
      }
    }

    if (url === '/work-machines') {
      return this.plusService.onWorkMachine();
    }
    if (url === '/materials/products') {
      return this.plusService.newProduct();
    }
    if (url.startsWith('/materials/')) {
      url = '/materials/process';
    }
    if (url.startsWith('/news?')) {
      url = '/news';
    }
    if (url.startsWith('/report/site')) {
      const siteGuid = url.split('/').pop();
      const site = sites.find((s) => s.guid === siteGuid);
      return this.plusService.onNewTimesheet(site);
    }
    if (url.startsWith('/clients/')) {
      const clientGuid = url.split('/').pop();
      return this.plusService.onNewSite(decodeURIComponent(clientGuid), sites);
    }

    if (url === '/worker/messages') {
      this.plusService.onNewMessage();
    } else if (url === '/worker/sites') {
      this.plusService.onNewSite(null, sites);
    } else if (url === '/instructions') {
      this.plusService.onInstruction(e);
    } else if (url === '/materials/process') {
      this.plusService.onOrder();
    } else if (url === '/clients') {
      this.plusService.onNewClient();
    } else if (url === '/news') {
      const queryParams = this.route.snapshot.queryParamMap;
      const segment = queryParams.get('segment');
      this.plusService.onNews(segment);
    } else if (url === '/users') {
      this.plusService.inviteUser();
    } else if (url === '/worker/timesheets') {
      this.plusService.onNewTimesheet(null, user);
    } else if (url.startsWith('/sites/site/')) {
      if (url.includes('/project/')) {
        const splitted = url.split('/');
        const projectGuid = splitted.pop().split('?')[0];
        // Pop project from url
        splitted.pop();
        const siteGuid = splitted.pop();
        const site = sites.find((s) => s.guid === siteGuid);
        this.siteProjectsService.get(projectGuid).pipe(
          take(1),
        ).subscribe((project) => {
          this.plusService.onAdHoc([site], project);
        });
      } else {
        const siteGuid = url.split('/').pop().split('?')[0];
        const site = sites.find((s) => s.guid === siteGuid);
        const queryParams = this.route.snapshot.queryParamMap;
        const segment = queryParams.get('tabs');
        const subsegment = queryParams.get('subtabs');
        if (segment === 'reports') {
          this.plusService.onNewReport(site);
        } else if (segment === 'reviews') {
          this.plusService.onNewReview(site);
        } else if (segment === 'settings' && subsegment === 'areas') {
          this.plusService.onAddAreas(site);
        } else {
          this.plusService.onAdHoc([site]);
        }
      }
    } else {
      this.plusService.onAdHoc(sites);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
