import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { StorageService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListItem } from '../models/list-item.model';
import { IService } from '../models/service.interface';
import { storageKeys } from '../utility/constants';
import { FirestoreService } from './firestore.service';

const itemCollection = 'materials';

@Injectable({
  providedIn: 'root',
})
export class MaterialsService implements IService<ListItem> {
  constructor(
    private firestore: FirestoreService,
    private storageService: StorageService,
  ) { }

  async save(item: ListItem) {
    return this.firestore.save<ListItem>(itemCollection, item);
  }

  async update(item: ListItem) {
    return this.firestore.update(itemCollection, item);
  }

  get(name: string): Observable<ListItem> {
    return this.storageService.getValue<ListItem[]>(storageKeys.materials).pipe(
      map((results) => results?.find((it) => it.name === name) ?? null),
    );
  }

  async delete(item: ListItem) {
    return this.firestore.delete(itemCollection, item);
  }

  getList(): Observable<ListItem[]> {
    return this.storageService.getValue<ListItem[]>(storageKeys.materials);
  }

  getListFromDb(options?: { contractor: string }): Observable<ListItem[]> {
    const queryConstraints = [];
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }
    return this.firestore.getList<ListItem>(itemCollection, { value: 'name', sort: 'asc' }, queryConstraints);
  }
}
