import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { IonicModule } from '@ionic/angular';
import { LoadingService, UploadService } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { FileUpload } from 'src/app/models/file.model';
import { NoResultsModule } from '../no-results/no-results.module';

@Component({
  standalone: true,
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NoResultsModule,
  ],
})
export class AttachmentsComponent {

  @Input()
  set attachments(items: string[]) {
    const attachments = items.map((url) => ({ url, name: this.getName(url), timestamp: this.getTimestamp(url) }));
    this.attachmentsS.set(attachments);
  }

  @Input()
  isReadOnly = false;

  @Input()
  autoSave = false;

  @Output()
  save = new EventEmitter<string[]>();

  attachmentsS = signal<{ name: string; url: string; timestamp: Date }[]>([]);

  constructor(
    private loadingService: LoadingService,
    private uploadService: UploadService,
  ) { }

  private getName(url: string) {
    let name = url.slice(url.lastIndexOf('Z%2F') + 4);
    name = name.slice(0, name.lastIndexOf('?'));
    return decodeURI(name);
  }

  private getTimestamp(url: string) {
    let name = url.slice(0, url.lastIndexOf('Z%2F'));
    name = name.slice(name.lastIndexOf('T') - 10);
    return new Date(decodeURIComponent(name));
  }

  private addItem(url: string) {
    const item = { url, name: this.getName(url), timestamp: this.getTimestamp(url) };
    this.attachmentsS.update((val) => [...val, item]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async selectFiles(event: any) {
    const files = event.target.files as FileList;
    this.loadingService.showLoading();
    for (let file of Array.from(files)) {
      const fileupload = new FileUpload(file);
      const filename = file.name.split('.')[0];
      fileupload.guid = filename + '-' + new Date().toISOString();
      const downloadUrl = await this.uploadService.uploadImage(fileupload);
      this.addItem(downloadUrl);
    }
    this.loadingService.hideLoading();
    if (this.autoSave) {
      this.onSave();
    }
  }

  remove(event: MouseEvent, item: string) {
    event.stopPropagation();
    this.attachmentsS.update((val) => val.filter((it) => it.url !== item));
  }

  openUrl(url: string) {
    Browser.open({ url });
  }

  onSave() {
    const items = this.attachmentsS().map((it) => it.url);
    this.save.emit(items);
  }
}
