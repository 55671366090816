<form method="post"
  enctype="multipart/form-data"
  class="input-form">
  <input #videoInput
    type="file"
    name="file"
    accept="video/*"
    capture="environment"
    (change)="selectFiles($event)" />
  <ion-button [color]="color()"
    [disabled]="disabled()"
    [fill]="fill()"
    [expand]="expand()"
    [shape]="shape()"
    (click)="videoInput.click()">
    @if (multiline()) {
      <div class="multiline">
        <ion-icon name="videocam"></ion-icon>
        @if (!hideText()) {
          <span>
            {{ title() | translate }}
          </span>
        }
      </div>
    } @else {
      <ion-icon slot="start"
        name="videocam"></ion-icon>
      @if (!hideText()) {
        <span>
          {{ title() | translate }}
        </span>
      }
    }
  </ion-button>
</form>