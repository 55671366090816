import { Injectable } from '@angular/core';
import { BackgroundGeolocationPlugin, CallbackError, Location as CapLocation } from '@capacitor-community/background-geolocation';
import { App } from '@capacitor/app';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';
import { Geolocation as CapGeolocation } from '@capacitor/geolocation';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocationLog, LocationType } from '../models/location-log.model';
import { ILocation, IPositionCallback } from '../models/location.model';
import { NotificationService } from '../notification/notification.service';
import { LicenseService, appModules } from '../services/licence.service';
import { LocationLogService } from '../services/location-log.service';
import { allowedDistance } from '../utility/constants';
import { calcDistance } from '../utility/location';
import { LocationDialogComponent } from './dialog/location.dialog';

const backgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>('BackgroundGeolocation');

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  private dialogOpen: boolean;

  constructor(
    private licenseService: LicenseService,
    private locationLogService: LocationLogService,
    private modalCtrl: ModalController,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) { }

  private watchCallback(position: ILocation, baseLocation: ILocation, site: string, error: CallbackError, type: LocationType = 'other'): void {
    if (error) {
      if (error.code === 'NOT_AUTHORIZED') {
        Dialog.confirm({
          message: this.translate.instant('location.ask'),
        }).then((dialog) => {
          if (dialog.value) {
            backgroundGeolocation.openSettings();
          }
        });
      }
      return console.error(error);
    }
    console.info('Checking distance...');
    const distance = calcDistance(position, baseLocation);
    if (distance > allowedDistance) {
      const item: LocationLog = {
        location: position,
        site,
        type,
      };
      this.locationLogService.save(item);
      console.info('Distance too long', distance);
      const title = this.translate.instant('location.awayFromTitle');
      const message = this.translate.instant('location.awayFrom');
      App.getState().then((state) => {
        if (state.isActive) {
          this.notificationService.scheduleNotification(title, message, 'distance');
        } else {
          this.notificationService.scheduleNotification(title, message, 'distance');
        }
      });
    }
  }

  private async isPrompt(): Promise<boolean> {
    try {
      const permission = await CapGeolocation.checkPermissions();
      return permission.location === 'prompt' || permission.location === 'prompt-with-rationale';
    } catch {
      return false;
    }
  }

  async isGranted() {
    const permission = await CapGeolocation.checkPermissions();
    return permission.location === 'granted';
  }

  async checkAndAskPermission(): Promise<void> {
    const permissionPrompt = await this.isPrompt();
    if (permissionPrompt && !this.dialogOpen) {
      const m = await this.modalCtrl.create({ component: LocationDialogComponent });
      m.present().then(() => this.dialogOpen = true);
      await m.onDidDismiss();
      this.dialogOpen = false;
    }
  }

  ask(callback: IPositionCallback) {
    if (Capacitor.isNativePlatform()) {
      CapGeolocation.requestPermissions({ permissions: ['location'] }).then(() => callback(null));
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        callback({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      }, () => callback(null));
    }
  }

  async getLocation(site: string, callback: IPositionCallback, type: LocationType) {
    const modules = this.licenseService.getModulesS();
    if (!modules[appModules.location]) {
      callback(null);
      return;
    }
    if (Capacitor.isNativePlatform()) {
      const permission = await this.isGranted();
      if (permission) {
        const position = await CapGeolocation.getCurrentPosition({ enableHighAccuracy: true });
        const deviceInfo = await Device.getInfo();
        const item: LocationLog = {
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          site,
          type,
        };
        this.locationLogService.save(item);
        callback({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          device: deviceInfo?.model ?? null,
        });
      } else {
        callback(null);
      }
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const item: LocationLog = {
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          site,
          type,
        };
        this.locationLogService.save(item);
        callback({ latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy });
      }, () => callback(null));
    }
  }

  checkLocation(baseLocation: ILocation, site: string, type: LocationType = 'other') {
    if (Capacitor.isNativePlatform()) {
      let lastLocation: CapLocation;
      let error: CallbackError;
      backgroundGeolocation.addWatcher(
        {
          backgroundMessage: this.translate.instant('location.trackingOn'),
          requestPermissions: false,
          stale: true,
          distanceFilter: 1000,
        },
        (location, e) => {
          lastLocation = location;
          error = e;
        },
      ).then((id) => {
        setTimeout(() => {
          this.watchCallback(lastLocation, baseLocation, site, error, type);
          backgroundGeolocation.removeWatcher({ id });
        }, 5000);
      });
    }
  }

  calculateDistance(start: ILocation, end: ILocation) {
    const modules = this.licenseService.getModulesS();
    if (modules[appModules.location]) {
      return calcDistance(start, end);
    }
    return 0;
  }
}
