@if (mobileView()) {
  <ion-segment [value]="segmentValueS()"
    mode="ios"
    scrollable="true"
    (ionChange)="onSegmentChanged($event)">
    <ng-content select="[mobileContent]"></ng-content>
    @for (item of segmentButtonsS(); track $index) {
      @if (item.show) {
        @if (item.action) {
          <ion-button [ngClass]="item.cssClass ?? []"
            (click)="item.action($event)">
            {{ item.name | translate }}
          </ion-button>
        } @else {
          <ion-segment-button [value]="item.value"
            [ngClass]="item.cssClass ?? []"
            layout="icon-start">
            @if (item.icon) {
              <ion-icon [name]="item.icon"></ion-icon>
            }
            {{ item.name | translate }}
          </ion-segment-button>
        }
      }
    }
  </ion-segment>
} @else {
  <drag-scroll [scrollbar-hidden]="true"
    [drag-scroll-y-disabled]="true"
    [class.sub]="sub">
    <ng-content></ng-content>
    @for (item of segmentButtonsS(); track $index) {
      @if (item.show) {
        @if (item.action) {
          <button drag-scroll-item
            class="action"
            [ngClass]="item.cssClass ?? []"
            (click)="item.action($event)">
            {{ item.name | translate }}
          </button>
        } @else {
          <button drag-scroll-item
            [class.selected]="item.value === segmentValueS()"
            [ngClass]="item.cssClass ?? []"
            (click)="onButtonClick(item.value)">
            @if (item.icon) {
              <div class="flex">
                <ion-icon slot="start"
                  [name]="item.icon"></ion-icon>
                {{ item.name | translate }}
              </div>
            } @else {
              {{ item.name | translate }}
            }
          </button>
        }
      }
    }
  </drag-scroll>
}