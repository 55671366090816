<ion-content class="ion-padding">
  @if (lang$ | async; as currentLang) {
  <div class="main">
    <div>
      <ion-note>{{ news.created | timestamp:'dd.MM.yyyy' }}</ion-note>
    </div>
    <h1>{{ news.title[currentLang] }}</h1>
    <p>{{ news.message[currentLang] }}</p>
    <div>
      <ion-button color="light"
        shape="round"
        (click)="check()">
        <ion-icon slot="start"
          name="checkmark-outline"></ion-icon>
        {{ 'news.check' | translate }}
      </ion-button>
    </div>
  </div>
  }
</ion-content>