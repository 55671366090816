<div>
  <div class="ion-margin days">
    <a class="link"
      (click)="changeMonth(-1)">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </a>
    <div class="ion-text-center">
      {{ 'report.month.' + currentMonth() | translate }} {{ currentYear() }}
    </div>
    <a class="link"
      (click)="changeMonth(1)">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </a>
  </div>
</div>