<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (title()) {
      {{ title() }}
    } @else {
      {{ 'additionalInfo.addJob' | translate }}
      @if (projectS()) {
        - {{ projectS().name }}
      }
    }
  </div>
  @if (hasSelectContractor()) {
    <div class="ion-margin-bottom">
      <ion-button expand="block"
        shape="round"
        color="light"
        (click)="selectContractor()">
        @if (selectedContractor()) {
          <span>
            {{ selectedContractor().name }}
          </span>
        } @else {
          {{ 'subcontractor.select' | translate }}
        }
      </ion-button>
    </div>
  }
  <form [formGroup]="formGroup">
    <ion-label>{{ 'item.name' | translate }}</ion-label>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <ion-item color="primary"
      lines="none">
      <ion-label>{{ 'sites.new.projectStart' | translate }}</ion-label>
      <app-select-day color="light"
        [id]="'selectStartDay'"
        [format]="'d.M.yyyy'"
        [expand]="''"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [value]="formGroup.get('start').value"
        (changed)="onDateChange($event, 'start')"></app-select-day>
    </ion-item>
    <ion-item color="primary"
      lines="none">
      <ion-label>{{ 'sites.new.projectEnd' | translate }}</ion-label>
      <app-select-day color="light"
        [id]="'selectEndDay'"
        [format]="'d.M.yyyy'"
        [expand]="''"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [value]="formGroup.get('end').value"
        (changed)="onDateChange($event, 'end')"></app-select-day>
    </ion-item>
    <ion-label>{{ 'room.setWorkTypes' | translate }}</ion-label>
    <div class="ion-margin-bottom">
      <ion-button shape="round"
        color="secondary"
        [disabled]="projectS()?.areas?.length > 0"
        (click)="selectRoom()">
        @if (formGroup.get('areas').value; as areas) {
          <span>
            @if (areas.length > 0) {
              @for (area of areas; track area) {
                <div>
                  {{ area.name }}
                </div>
              }
            } @else {
              {{ 'general.select' | translate }}
            }
          </span>
        }
      </ion-button>
    </div>
    <div class="flex flex-justify-between">
      <ion-label>{{ 'sites.tickets' | translate }}</ion-label>
      <ion-button shape="round"
        color="light"
        [disabled]="selectedContractor() == null"
        (click)="addNewTicket()">
        {{ 'item.add' | translate }}
      </ion-button>
    </div>
    @if (formGroup.get('tickets').value; as selectedTickets) {
      @if (selectedTickets.length > 0) {
        @for (ticket of selectedTickets; track $index) {
          <ion-item color="secondary"
            button="true"
            lines="none"
            (click)="openTicket(ticket)">
            {{ ticket.name }}
          </ion-item>
        }
      }
    }
    <div class="ion-margin-top ion-margin-bottom">
      <ion-button color="light"
        shape="round"
        [disabled]="!formGroup.valid"
        (click)="save()">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>