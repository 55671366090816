export function capitalizeFirstLetter(string: string): string {
  if (string == null) {
    return '';
  }
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function cleanString(string: string): string {
  if (string == null) {
    return '';
  }
  return string.replace(/\//g, '-');
}

export function commaDot(string: string): string {
  if (string == null) {
    return '';
  }
  return string.replace(/,/g, '.');
}
