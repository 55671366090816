import { Injectable, inject } from '@angular/core';
import { where } from '@firebase/firestore';
import { IService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { SiteReview } from '../models/site-review.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'site-reviews';

@Injectable({
  providedIn: 'root',
})
export class SiteReviewsService implements IService<SiteReview> {
  private firestore = inject(FirestoreService);

  async save(item: SiteReview) {
    return this.firestore.save<SiteReview>(itemCollection, item);
  }

  async update(item: SiteReview) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<SiteReview> {
    return this.firestore.get<SiteReview>(itemCollection, guid);
  }

  getList(options?: { site: string; contractor?: string }): Observable<SiteReview[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor.guid', '==', options.contractor));
    }

    return this.firestore
      .getList<SiteReview>(itemCollection, undefined, queryConstraints);
  }
}
