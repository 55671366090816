import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerNote } from '../models/partner-note.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'partner-notes';

@Injectable({
  providedIn: 'root',
})
export class PartnerNotesService implements IService<PartnerNote> {

  constructor(private firestore: FirestoreService) { }

  async save(note: PartnerNote) {
    return this.firestore.save(itemCollection, note);
  }

  async update(item: PartnerNote) {
    return this.firestore.update(itemCollection, item);
  }

  async delete(item: PartnerNote) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string): Observable<PartnerNote> {
    return this.firestore.get<PartnerNote>(itemCollection, guid);
  }

  getList(options?: { user?: string }): Observable<PartnerNote[]> {
    const queryConstraints = [];
    if (options?.user) {
      queryConstraints.push(where('inviter', '==', options.user));
    }

    return this.firestore
      .getList<PartnerNote>(itemCollection, undefined, queryConstraints)
      .pipe(map((results) => results.map((it) => setDates(it, 'date')) ?? []));
  }
}
