<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>

  </div>
  @if (step() === 1) {
    <div class="icon">
      <ion-icon name="person-add-outline"></ion-icon>
    </div>
    <h1>{{ 'subcontractor.title' | translate }}</h1>
    <p>{{ 'subcontractor.info' | translate }}</p>
    <ion-button color="light"
      shape="round"
      (click)="step.set(2)">
      {{ 'subcontractor.start' | translate }}
    </ion-button>
  }
  @if (step() === 2) {
    <h1>{{ 'subcontractor.inviteTitle' | translate }}</h1>
    <p>{{ 'subcontractor.inviteInfo' | translate }}</p>
    <form [formGroup]="formGroup"
      (ngSubmit)="save()">
      <ion-label>{{ 'signup.businessName' | translate }}</ion-label>
      <ion-item color="secondary">
        <ion-input autocapitalize="on"
          formControlName="name"></ion-input>
      </ion-item>
      @if (showAdmin()) {
        <ion-label>{{ 'signup.adminName' | translate }}</ion-label>
        <ion-item color="secondary">
          <ion-input autocapitalize="on"
            formControlName="admin"></ion-input>
        </ion-item>
        <ion-label>{{ 'signup.adminEmail' | translate }}</ion-label>
        <ion-item color="secondary">
          <ion-input formControlName="adminEmail"
            type="email"></ion-input>
        </ion-item>
      }
      <form formArrayName="workers">
        @for (item of getFields().controls; track item; let i = $index) {
          <div [formGroupName]="i">
            <ion-label>{{ 'tickets.freemium.workerName' | translate }}</ion-label>
            <ion-item color="secondary">
              <ion-input autocapitalize="on"
                formControlName="displayName"></ion-input>
            </ion-item>
            <ion-label>{{ 'tickets.freemium.workerEmail' | translate }}</ion-label>
            <ion-item color="secondary">
              <ion-input formControlName="email"
                type="email"></ion-input>
            </ion-item>
            <ion-button color="danger"
              (click)="deleteItem(i)">
              {{ 'subcontractor.deleteWorker' | translate }}
            </ion-button>
          </div>
        }
      </form>
      <div class="ion-margin-top">
        <ion-button color="secondary"
          shape="round"
          (click)="addItem()">
          <ion-icon slot="start"
            name="add-outline"></ion-icon>
          {{ 'schedule.add.worker' | translate }}
        </ion-button>
      </div>
      <div class="ion-margin-top">
        <ion-button color="light"
          shape="round"
          type="submit"
          [disabled]="!formGroup.valid">{{ 'subcontractor.sendInvite' | translate }}</ion-button>
      </div>
    </form>
  }
</app-main-dialog>