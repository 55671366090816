import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NoResultsModule } from 'src/app/components/no-results/no-results.module';
import { DurationPipeModule } from 'src/app/pipes/duration/duration.module';
import { AppCommonModule } from '../../common.module';
import { DayModule } from '../../day/day.module';
import { NewTimesheetModule } from '../../timesheets/new-timesheet/new-timesheet.dialog.module';
import { TimesheetModule } from '../timesheet/timesheet.dialog.module';
import { TimesheetsComponent } from './timesheets.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AppCommonModule,
    DayModule,
    TimesheetModule,
    NewTimesheetModule,
    DurationPipeModule,
    NoResultsModule,
  ],
  declarations: [TimesheetsComponent],
  exports: [TimesheetsComponent],
})
export class TimesheetsComponentModule { }
