import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input, output, signal } from '@angular/core';
import { IonModal, IonicModule, Platform } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { SpeechToTextService } from 'src/app/services/speech-to-text.service';

@Component({
  standalone: true,
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class SpeechToTextComponent implements OnInit {
  private platform = inject(Platform);
  private speechToTextService = inject(SpeechToTextService);

  title = input<string>('');
  text = output<string>();

  id = signal('speechButton_' + new Date().getTime());

  hasSpeechToText = signal(false);

  ngOnInit(): void {
    this.speechToTextService.isAvailable().then((val) => this.hasSpeechToText.set(val));
  }

  onMic(m: IonModal) {
    this.speechToTextService.record().then((val) => {
      if (val?.matches.length > 0) {
        this.text.emit(val.matches[val.matches.length - 1]);
        if (this.platform.is('android')) {
          this.stop(m);
        }
      }
    });
  }

  stop(m: IonModal) {
    m.dismiss();
    this.speechToTextService.stopRecording();
  }
}
