import { CommonModule } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { SelectDialogComponent } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { Site } from 'src/app/models/site.model';
import { PlusService } from 'src/app/services/plus.service';

@Component({
  standalone: true,
  selector: 'app-select-site-button',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: `
    <ion-button [expand]="expand()" shape="round" [color]="color()" (click)="selectSite()" [disabled]="disabled()">
      @if (icon()) {
        <ion-icon slot="start" [name]="icon()"></ion-icon>
      }
      @if (text()) {
        {{ text() | translate }}
      } @else {
      @if (selectedSite()) {
      <span>
        {{ selectedSite().name }}
      </span>
      } @else {
      {{ title() | translate }}
      }
    }
    </ion-button>
  `,
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class SelectSiteButtonComponent {
  private modalCtrl = inject(ModalController);
  private plusService = inject(PlusService);

  selectedSite = input<Site>();
  items = input<Site[]>();
  multiple = input(false);
  color = input('light');
  expand = input('');
  icon = input('');
  title = input('report.selectSite');
  text = input<string>();
  hasNew = input(false);
  disabled = input(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected = output<any>();

  private onNewSite() {
    this.plusService.onNewSite(null, this.items()).then((site) => this.selected.emit(site));
  }

  selectSite() {
    if (this.hasNew() && this.items().length === 0) {
      return this.onNewSite();
    }
    const clearButton = this.selectedSite() != null;
    this.modalCtrl.create({
      component: SelectDialogComponent<Site>,
      componentProps: { items: this.items(), clearButton, multiple: this.multiple() },
    })
      .then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.selected.emit(null);
            } else {
              this.selected.emit(data.data);
            }
          }
        });
      });
  }
}
