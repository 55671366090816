<app-main-dialog (dismiss)="dismiss(true)">
  <div desktopTitle>

  </div>
  <div class="flex">
    <div class="content">
      <p>{{ 'tickets.addPhotosInfo' | translate }}</p>
      <app-camera-button [name]="'adhoc'"
        [disabled]="disableAddingMedia()"
        color="primary"
        fill="clear"
        [multiline]="true"
        [cb]="cb"
        (imageUrl)="onImageUrl($event)"
        (queueItem)="onQueueItem($event)"></app-camera-button>
      <app-take-video [name]="'adhoc'"
        [disabled]="disableAddingMedia()"
        color="primary"
        fill="clear"
        [multiline]="true"
        (imageUrl)="videoUrl($event)"></app-take-video>
      @if (images().length > 0) {
        <div>
          <app-images [showCamera]="null"
            [onlyThumbs]="true"
            [images]="images()"></app-images>
        </div>
      }
      @if (videos().length > 0) {
        <div>
          <app-images [showCamera]="null"
            [videos]="videos()"></app-images>
        </div>
      }
      @if (items().length > 0) {
        <div class="previews">
          @for (item of items(); track item) {
            <div>
              <img [src]="item.src" />
            </div>
          }
        </div>
      }
    </div>
  </div>
</app-main-dialog>
<ion-footer class="ion-no-border">
  <ion-row>
    <ion-col size="12"
      class="ion-text-center">
      <ion-button [disabled]="isRequired() && (images().length === 0 && videos().length === 0 && items().length === 0)"
        fill="clear"
        color="light"
        (click)="dismiss()">
        <ion-icon slot="icon-only"
          name="checkmark-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>