<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'materials.newCategory' | translate }}
  </div>
  <div class="ion-padding">
    <form class="ion-padding-bottom"
      [formGroup]="formGroup"
      (ngSubmit)="save()">
      <ion-label>{{ 'item.name' | translate }}</ion-label>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="name"></ion-input>
      </ion-item>
      <div class="ion-margin-top">
        <ion-button color="light"
          shape="round"
          expand="full"
          type="submit"
          [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
      </div>
    </form>
  </div>
</app-main-dialog>