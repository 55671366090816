@if (user$ | async; as user) {
<ion-button shape="round"
  [routerLink]="['/profile']">
  @if (user.image) {
  <ion-avatar slot="icon-only">
    <img [src]="user.image" />
  </ion-avatar>
  } @else {
  <ion-icon slot="icon-only"
    name="happy-outline"></ion-icon>
  }
</ion-button>
}