import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { fromEvent, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { HeaderComponent } from '../header/header.component';

@Component({
  standalone: true,
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    HeaderComponent,
    TranslateModule,
    NgScrollbarModule,
  ],
})
export class MainPageComponent {

  private firestoreService = inject(FirestoreService);
  private scrollService = inject(ScrollService);

  @ViewChild(NgScrollbar)
  scrollbar: NgScrollbar;

  offline$ = merge(
    of(null),
    fromEvent(window, 'online'),
    fromEvent(window, 'offline'),
  ).pipe(
    map(() => !navigator.onLine),
    distinctUntilChanged(),
    tap(async (offline) => this.firestoreService.toggleNetwork(offline)),
    shareReplay(1),
  );

  scrollTo$ = this.scrollService.getScrollElement().pipe(
    filter((value) => value != null),
    map(async (value) => this.scrollbar?.scrollToElement(value.element, value.options)),
  );
}
