import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonSearchbar, IonicModule, ModalController, NavParams } from '@ionic/angular';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { Room, RoomSiteArea } from 'src/app/models/room.model';
import { SiteArea } from 'src/app/models/site-area.model';
import { SiteAreasService } from 'src/app/services/site-areas.service';
import { SitesService } from 'src/app/services/sites.service';
import { getAreaName, getLeaf, setAreaParents } from 'src/app/utility/kanban';
import { defaultRoom } from 'src/app/utility/room';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-select-area-dialog',
  templateUrl: './select-area.dialog.html',
  styleUrls: ['./select-area.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class SelectAreaDialogComponent {

  private defaultRoom: SiteArea;
  private siteGuid$ = new BehaviorSubject<string>(null);
  private preSelected$ = new BehaviorSubject<SiteArea[]>([]);
  private filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  selected = signal<{ id?: string; siteArea?: RoomSiteArea }[]>([]);

  isMultiple = signal(false);
  isMultipleRooms = signal(false);
  clearButtonS = signal(false);

  site$ = this.siteGuid$.pipe(
    filter((siteGuid) => siteGuid != null),
    switchMap((siteGuid) => this.sitesService.get(siteGuid)),
    shareReplay(1),
  );

  private areas$ = this.site$.pipe(
    switchMap((site) => this.siteAreasService.getList({ site: site.guid })),
    tap((areas) => {
      this.defaultRoom = areas?.find((it) => it.name === defaultRoom);
    }),
    map((areas) => areas?.filter((a) => a.name !== defaultRoom)?.sort(fieldSorter(['name'])) ?? []),
    tap((areas) => areas.map((a) => setAreaParents(a))),
    switchMap((areas) => this.preSelected$.pipe(
      map((preSelected) => areas.map((area) => {
        area.selected = preSelected.some((it) => it.guid === area.guid);
        this.setSelected(area, this.selected());
        return area;
      })),
    )),
    shareReplay(1),
  );

  filteredAreas$ = combineLatest([this.areas$, this.filter$.asObservable()]).pipe(
    map(([areas, filterValue]) => areas.map((area) => {
      const clone = Object.assign({}, area);
      clone.children = this.filterAreas(clone.children, filterValue);
      if (clone.name.toLowerCase().includes(filterValue.toLowerCase()) || clone.children.length > 0) {
        return clone;
      }
    }).filter((it) => it != null)),
    shareReplay(1),
  );

  constructor(
    private _modal: ModalController,
    private sitesService: SitesService,
    private siteAreasService: SiteAreasService,
    navParams: NavParams,
  ) {
    const siteGuid = navParams.get('site');
    this.siteGuid$.next(siteGuid);
    this.isMultiple.set(navParams.get('multiple') ?? false);
    this.isMultipleRooms.set(navParams.get('multipleRooms') ?? false);
    this.clearButtonS.set(navParams.get('clearButton') ?? false);
    const preSelected = navParams.get('areas');
    if (preSelected) {
      const value = preSelected.map((it) => (it.siteArea ? it : { siteArea: { root: it.guid, id: it.id, parentAreaIds: it.parentAreaIds, name: it.name } }));
      this.selected.set(value);
      this.preSelected$.next(preSelected);
    }
  }

  private filterAreas(areas: SiteArea[], filterValue: string) {
    const ret: SiteArea[] = [];
    areas.map((area) => {
      const clone = Object.assign({}, area);
      if (clone.children.length > 0) {
        clone.children = this.filterAreas(clone.children, filterValue);
      }
      if (clone.name.toLowerCase().includes(filterValue.toLowerCase()) || clone.children.length > 0) {
        ret.push(clone);
      }
    });
    return ret;
  }

  private setSelected(area: SiteArea, selected: { siteArea?: RoomSiteArea }[], root?: string) {
    selected.map((it) => {
      const isRoot = it.siteArea.root === area.guid || it.siteArea.root === root;
      if (isRoot) {
        const isId = area.id === it.siteArea.id;
        if (isId) {
          area.selected = true;
        } else if (area.children.length > 0) {
          area.children.map((c) => this.setSelected(c, selected, root ?? area.guid));
        }
      }
    }).filter((it) => it);
  }

  clear() {
    this._modal.dismiss({ clear: true });
  }

  onFilter(input: IonSearchbar) {
    const filterValue = input.value;
    this.filter$.next(filterValue);
  }

  selectFiltered(areas: SiteArea[]) {
    const leafs = areas.map((area) => {
      const items = getLeaf(area);
      return items.map((it) => ({ root: area, area: it }));
    }).flat();
    leafs.map((it) => {
      this.onSelect(it.area, it.root);
    });
  }

  selectNone() {
    const room = this.defaultRoom?.rooms[0] ?? null;
    this._modal.dismiss(room);
  }

  onSelect(area: SiteArea, root: SiteArea, room?: Room) {
    if (room) {
      room.siteArea = {
        root: root.guid,
        id: area.id,
        name: getAreaName(area),
      };
      room.selected = !room.selected;
      if (this.isMultipleRooms()) {
        this.selected.update((value) => {
          if (room.selected) {
            return [...value, room];
          } else {
            const index = value.findIndex((it) => room.id !== it.id);
            value.splice(index, 1);
            return value;
          }
        });
      } else {
        this._modal.dismiss(room);
      }
    } else {
      const siteArea = {
        root: root.guid,
        id: area.id,
        name: getAreaName(area),
      };
      if (this.isMultiple()) {
        area.selected = !area.selected;
        this.selected.update((value) => {
          if (area.selected) {
            value.push({ id: null, siteArea });
          } else {
            const index = value.findIndex((it) => it.siteArea.id !== siteArea.id && it.siteArea.root !== siteArea.root && it.siteArea.name !== siteArea.name);
            value.splice(index, 1);
          }
          return value;
        });
      } else {
        this._modal.dismiss({ siteArea });
      }
    }
  }

  selectAllRooms(area: SiteArea, root: SiteArea) {
    if (area.rooms?.length > 0) {
      area.rooms.map((room) => {
        this.onSelect(area, root, room);
      });
    } else {
      area.children.map((child) => {
        this.onSelect(child, root);
      });
    }
  }

  onSelectMultiple() {
    this._modal.dismiss(this.selected());
  }

  dismiss() {
    this._modal.dismiss();
  }
}
