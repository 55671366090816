import { Injectable } from '@angular/core';
import { arrayUnion, documentId, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Checklist } from '../models/checklist.model';
import { IService } from '../models/service.interface';
import { FirestoreService } from './firestore.service';

const itemCollection = 'cleaning-list';
export const translations = 'translations';

@Injectable({
  providedIn: 'root',
})
export class ChecklistService implements IService<Checklist> {
  constructor(private firestore: FirestoreService) { }

  async save(item: Checklist) {
    if (item.guid) {
      return this.firestore.saveAs(itemCollection, item.guid, item);
    }
    return this.firestore.save(itemCollection, item);
  }

  async update(item: Checklist): Promise<void> {
    return this.firestore.update(itemCollection, item);
  }

  async addTranslationField(name: string) {
    return this.firestore.updateOnly(itemCollection, translations, { fields: arrayUnion(name) });
  }

  get(guid: string): Observable<Checklist> {
    return this.firestore.get<Checklist>(itemCollection, guid);
  }

  getList(options?: { contractor?: string; adhocContractor?: string; site?: string }): Observable<Checklist[]> {
    const queryConstraints = [];
    if (options?.contractor) {
      queryConstraints.push(where(documentId(), '==', options.contractor));
    }
    if (options?.adhocContractor) {
      queryConstraints.push(where('contractor', '==', options.adhocContractor));
    }
    if (options?.site) {
      queryConstraints.push(where('site.guid', '==', options.site));
    }

    return this.firestore.getList<Checklist>(itemCollection, undefined, queryConstraints);
  }
}
