/* eslint-disable @typescript-eslint/require-await */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IService } from '../models/service.interface';
import { Version } from '../models/version.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'version';

@Injectable({
  providedIn: 'root',
})
export class VersionService implements IService<Version> {
  constructor(private firestore: FirestoreService) { }

  async save(_item: Version): Promise<void> {
    throw Error('Not implemented save version');
  }

  async update(_item: Version): Promise<void> {
    throw Error('Not implemented update version');
  }

  get(guid: string): Observable<Version> {
    return this.firestore.get<Version>(itemCollection, guid);
  }

  getList(): Observable<Version[]> {
    throw Error('Not implemented getList version');
  }

}
