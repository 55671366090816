import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule, ModalController, PopoverController } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { NewCategoryDialogComponent } from '../category/new-category.dialog';
import { NewItemDialogComponent } from '../item/new-item.dialog';

@Component({
  standalone: true,
  selector: 'app-instruction-popover',
  templateUrl: './instruction.popover.html',
  styleUrls: ['./instruction.popover.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class InstructionPopover {
  private modalCtrl = inject(ModalController);
  private _popover = inject(PopoverController);

  newCategory() {
    this.modalCtrl.create({ component: NewCategoryDialogComponent }).then((m) => {
      m.present();
      this.dismiss();
    });
  }

  newItem() {
    this.modalCtrl.create({ component: NewItemDialogComponent }).then((m) => {
      m.present();
      this.dismiss();
    });
  }

  dismiss() {
    this._popover.dismiss();
  }
}
