import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const extras: MenuSubItem[] = [
  {
    icon: 'cog',
    route: '/news',
    name: 'nav.news',
  },
  {
    icon: 'cog',
    route: '/merge-users',
    name: 'nav.mergeUsers',
  },
  {
    icon: 'cog',
    route: '/templates',
    name: 'nav.templates',
  },
  {
    icon: 'documents',
    route: '/littera/manage',
    name: 'nav.litteras',
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'people',
    name: 'nav.users',
    route: '/users',
  },
  {
    icon: 'car',
    name: 'nav.clients',
    route: '/clients',
  },
  {
    icon: 'hammer',
    name: 'nav.contractors',
    route: '/contractors',
  },
  {
    icon: 'business',
    name: 'nav.sites',
    route: '/worker/sites',
  },
  {
    icon: 'cash',
    name: 'nav.invoicing',
    route: '/invoicing',
  },
  {
    icon: 'cog',
    name: 'nav.extras',
    children: extras,
  },
];

export const superAdminMenu = {
  items,
};
