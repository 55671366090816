import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.dialog.html',
  styleUrls: ['./text-dialog.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class TextDialogComponent {

  title: string;
  text: string;
  button: string;
  original: {
    title: string;
    text: string;
  };

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.title = navParams.get('title');
    this.text = navParams.get('text');
    this.button = navParams.get('button');
    const originalTitle = navParams.get('originalTitle');
    const originalText = navParams.get('originalText');
    if (originalTitle && originalText) {
      this.original = { title: originalTitle, text: originalText };
    }
  }

  dismiss(button?: boolean) {
    this._modal.dismiss(button);
  }
}
