import { MenuItem } from './menu.item.model';

export const items: MenuItem[] = [
  {
    icon: 'business',
    name: 'nav.sites',
    route: '/worker/sites',
  },
  /*{
    icon: 'calendar',
    name: 'nav.calendar',
    route: '/calendar',
  },
  {
    icon: 'people-circle',
    name: 'nav.schedule',
    route: '/schedule',
  },
  {
    icon: 'diamond',
    name: 'nav.kanban',
    route: '/kanban-dashboard',
  },
  {
    icon: 'speedometer',
    name: 'nav.dashboard',
    route: '/dashboard',
  },
  {
    icon: 'document',
    name: 'nav.sitereportmanager',
    route: '/report/site',
  },
  {
    icon: 'newspaper',
    name: 'nav.news',
    route: '/worker/messages',
  },*/
];

export const managerMenu = {
  items,
};
