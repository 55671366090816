@if ({
isAdmin: isAdmin$ | async
}; as vm) {
  <app-main-dialog (dismiss)="dismiss()">
    <div desktopTitle>
      @if (timesheet?.absence) {
        {{ 'timesheet.absence' | translate }}
      } @else {
        {{ 'timesheet.title' | translate }}
      }
    </div>
    <ion-title>
      {{ site?.name }}
    </ion-title>
    @if (vm.isAdmin) {
      <div class="ion-margin-top">
        @if (sites$ | async; as sites) {
          <ion-button color="secondary"
            shape="round"
            (click)="onSite(sites)">{{ 'timesheet.changeSite' | translate }}
          </ion-button>
        }
      </div>
      @if (projects$ | async; as projects) {
        <div>
          <ion-button size="small"
            shape="round"
            color="light"
            (click)="selectProject(projects)">
            @if (selectedProject()?.name) {
              <span>
                <span>
                  {{ selectedProject().name }}
                </span>
              </span>
            } @else {
              {{ 'sites.new.project' | translate }}
            }
          </ion-button>
        </div>
      }
    }
    <form class="ion-margin-top"
      [formGroup]="formGroup"
      (ngSubmit)="save()">
      @if (timesheet?.absence == null) {
        <ion-label>{{ 'timesheet.start' | translate }}</ion-label>
        <div>
          <ion-button id="start"
            color="light"
            shape="round">
            <ion-text>{{ formGroup.get('dateIn').value | date:'HH:mm' }}</ion-text>
            <ion-popover [trigger]="'start'"
              show-backdrop="false"
              side="top">
              <ng-template>
                <ion-datetime presentation="time"
                  locale="fi-FI"
                  [showDefaultButtons]="true"
                  [doneText]="'general.done' | translate"
                  [cancelText]="'general.cancel' | translate"
                  formControlName="dateIn"></ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-button>
        </div>
        <ion-label>{{ 'timesheet.end' | translate }}</ion-label>
        <div>
          <ion-button id="end"
            color="light"
            shape="round">
            <ion-text>{{ formGroup.get('dateOut').value | date:'HH:mm' }}</ion-text>
            <ion-popover [trigger]="'end'"
              show-backdrop="false"
              side="top">
              <ng-template>
                <ion-datetime presentation="time"
                  locale="fi-FI"
                  [showDefaultButtons]="true"
                  [doneText]="'general.done' | translate"
                  [cancelText]="'general.cancel' | translate"
                  formControlName="dateOut"></ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-button>
        </div>
        @if (vm.isAdmin) {
          <div class="ion-margin-top">
            <ion-button color="secondary"
              (click)="setFullDay()"
              shape="round">
              {{ 'timesheet.setFullDay' | translate }}
            </ion-button>
            <ion-button color="secondary"
              (click)="clearOut()"
              shape="round">
              {{ 'timesheet.clearOut' | translate }}
            </ion-button>
          </div>
        }
        @if (timesheet.overtime) {
          <ion-label position="stacked">{{ 'timesheet.overtimeForm' | translate }}</ion-label>
          <ion-item>
            <ion-textarea autocapitalize="on"
              disabled
              [value]="timesheet.overtime"></ion-textarea>
          </ion-item>
        }
        @if (formGroup.value.dateIn && formGroup.value.dateOut) {
          <ion-label>{{ 'timesheet.summary' | translate }}</ion-label>
          <ion-item class="summary">
            {{ summary(formGroup.value.dateOut, formGroup.value.dateIn) }}
          </ion-item>
        }
        @if (vm.isAdmin && timesheet.flagged) {
          @if (timesheet.locationIn == null && timesheet.locationOut == null) {
            <ion-item lines="none">
              <ion-icon slot="start"
                color="warning"
                name="warning-outline"></ion-icon>
              <span>
                {{ 'timesheet.warning.bothMissing' | translate }}
              </span>
            </ion-item>
          }
          @if (timesheet.locationIn == null && timesheet.locationOut != null) {
            <ion-item lines="none">
              <ion-icon slot="start"
                color="warning"
                name="warning-outline"></ion-icon>
              <span>
                {{ 'timesheet.warning.inMissing' | translate }}
              </span>
            </ion-item>
          }
          @if (timesheet.locationIn != null && timesheet.locationOut == null) {
            <ion-item lines="none">
              <ion-icon slot="start"
                color="warning"
                name="warning-outline"></ion-icon>
              <span>
                {{ 'timesheet.warning.outMissing' | translate }}
              </span>
            </ion-item>
          }
          @if (timesheet.locationIn != null && timesheet.locationOut != null) {
            @if (getDistance(timesheet.locationIn); as distance) {
              <ion-item lines="none">
                <ion-icon slot="start"
                  color="warning"
                  name="warning-outline"></ion-icon>
                <ng-container>
                  @if (distance > allowedDistance) {
                    <div>
                      {{ 'timesheet.warning.inTooFar' | translate }}, {{ distance.toFixed(2) }}km
                      <span slot="end"
                        class="link"
                        (click)="openMap(timesheet.locationIn)">
                        <ion-icon name="location-outline">
                        </ion-icon>
                        @if (timesheet.locationIn.accuracy) {
                          <ion-note>
                            ({{ timesheet.locationIn.accuracy.toFixed(1) }}m)</ion-note>
                        }
                      </span>
                    </div>
                  }
                </ng-container>
              </ion-item>
            }
            @if (getDistance(timesheet.locationOut); as distance) {
              <ion-item lines="none">
                <ion-icon slot="start"
                  color="warning"
                  name="warning-outline"></ion-icon>
                <ng-container>
                  @if (distance > allowedDistance) {
                    <div>
                      {{ 'timesheet.warning.outTooFar' | translate }}, {{ distance.toFixed(2) }}km
                      <span slot="end"
                        class="link"
                        (click)="openMap(timesheet.locationOut)">
                        <ion-icon name="location-outline">
                        </ion-icon>
                        @if (timesheet.locationOut.accuracy) {
                          <ion-note>
                            ({{ timesheet.locationOut.accuracy.toFixed(1) }}m)
                          </ion-note>
                        }
                      </span>
                    </div>
                  }
                </ng-container>
              </ion-item>
            }
            }
            }
            } @else {
              {{ 'timesheet.absences.' + timesheet.absence | translate }}
            }
            @if (timesheet.absence == null) {
              <div>
                <ion-button color="secondary"
                  shape="round"
                  (click)="onSelectPaymentType()">
                  <span>
                    {{ 'timesheet.paymentType' | translate }}
                  </span>
                </ion-button>
              </div>
            }
            <form formArrayName="paymentTypes">
              @for (item of getFields().controls; track item; let i = $index) {
                <div>
                  @if (showPaymentTypeUnit.includes(item.get('paymentType').value)) {
                    <ion-label>{{ 'timesheet.paymentTypes.' + item.get('paymentType').value | translate }}</ion-label>
                    <div class="payment-type ion-margin-bottom"
                      [formGroup]="$any(item)">
                      <ion-input autocapitalize="on"
                        formControlName="paymentTypeText"
                        [placeholder]="'timesheet.paymentTypeText' | translate"></ion-input>
                      <ion-input autocapitalize="on"
                        formControlName="paymentTypeUnit"
                        [placeholder]="'timesheet.paymentTypeUnit' | translate"
                        type="number"
                        (ionInput)="onMilageAllowance($event, item.get('paymentType').value)"></ion-input>
                      <ion-button color="danger"
                        fill="clear"
                        (click)="removePaymentType(i)">
                        <ion-icon slot="icon-only"
                          name="remove-circle-outline"></ion-icon>
                      </ion-button>
                    </div>
                    @if (item.get('paymentType').value === 'kmPay') {
                      <div class="flex-summary ion-margin-bottom">
                        <span>
                          {{ 'timesheet.paymentUnitValue' | translate }}
                          {{ milageAllowance() }}€
                        </span>
                        <span>
                          {{ 'timesheet.paymentSummary' | translate }}
                          {{ milageAllowanceSummary() }}€
                        </span>
                      </div>
                    }
                  } @else {
                    <div class="ion-margin-top flex">
                      {{ 'timesheet.paymentTypes.' + item.get('paymentType').value | translate }}
                      <ion-button color="danger"
                        fill="clear"
                        (click)="removePaymentType(i)">
                        <ion-icon slot="icon-only"
                          name="remove-circle-outline"></ion-icon>
                      </ion-button>
                    </div>
                  }
                </div>
              }
            </form>
    </form>
    <div class="ion-margin-top">
      @if (vm.isAdmin || isSiteLead() || !timesheet.checked) {
        <ion-button shape="round"
          color="danger"
          (click)="delete()">{{ 'general.delete' | translate }}</ion-button>
      }
      <ion-button color="light"
        shape="round"
        (click)="save()"
        [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
      @if (vm.isAdmin || isSiteLead()) {
        <ion-button shape="round"
          color="success"
          (click)="onAccept()"
          [disabled]="!formGroup.valid">
          @if (timesheet.checked) {
            <span>
              {{ 'kanban.status.checked' | translate }}
            </span>
          } @else {
            {{ 'timesheet.acceptAndSave' | translate }}
          }
        </ion-button>
      }
    </div>
  </app-main-dialog>
  }