<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'sites.new.project' | translate }}
  </div>
  <div class="flex-end">
    <ion-button shape="round"
      color="light"
      (click)="selectTemplate()">
      <ion-icon slot="start"
        name="document-outline"></ion-icon>
      @if (selectedTemplate()) {
        {{ selectedTemplate().name }}
      } @else {
        {{ 'projects.selectTemplate' | translate }}
      }
    </ion-button>
  </div>
  <form [formGroup]="formGroup">
    <ion-label>{{ 'item.name' | translate }}</ion-label>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    @if (defaultProjectName !== formGroup.get('name').value) {
      <ion-item color="primary"
        lines="none">
        <ion-label>{{ 'sites.new.projectStart' | translate }}</ion-label>
        <app-select-day slot="end"
          color="light"
          [id]="'selectStartDay'"
          [expand]="''"
          [format]="'d.M.yyyy'"
          [value]="formGroup.get('start').value"
          (changed)="onDateChange($event, 'start')"></app-select-day>
      </ion-item>
      <ion-item color="primary"
        lines="none">
        <ion-label>{{ 'sites.new.projectEnd' | translate }}</ion-label>
        <app-select-day slot="end"
          color="light"
          [id]="'selectEndDay'"
          [expand]="''"
          [format]="'d.M.yyyy'"
          [value]="formGroup.get('end').value"
          (changed)="onDateChange($event, 'end')"></app-select-day>
      </ion-item>
      @if (itemS() == null) {
        <div class="ion-margin-bottom">
          <ion-button expand="block"
            shape="round"
            color="light"
            (click)="onChain()">
            <ion-icon slot="start"
              name="link-outline"></ion-icon>
            @if (doneBefore() != null || doneAfter() != null) {
              {{ doneBefore() ? doneBefore().name : '' }}
              {{ doneAfter() ? doneAfter().name : '' }}
            } @else {
              {{ 'projects.chain' | translate }}
            }
          </ion-button>
        </div>
      }
    }
    <div class="ion-margin-top ion-margin-bottom">
      <ion-button color="light"
        shape="round"
        [disabled]="!formGroup.valid"
        (click)="save()">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>