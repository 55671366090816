import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IonPopover, IonicModule, ModalController, NavParams } from '@ionic/angular';
import { ImagesModule } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { RoomTicket, TicketNote, TicketStatus } from 'src/app/models/ticket.model';
import { TranslateFieldPipe } from 'src/app/pipes/field-translate/field-translate.pipe';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { timeArray } from 'src/app/utility/time';

@Component({
  standalone: true,
  selector: 'app-accept-ticket-dialog',
  templateUrl: './accept-ticket.dialog.html',
  styleUrls: ['./accept-ticket.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
    TranslateFieldPipe,
    ImagesModule,
  ],
})
export class AcceptTicketDialogComponent {

  formGroup: FormGroup;
  role: Roles;
  ticket: RoomTicket;

  durations = timeArray;

  notes: TicketNote[];

  changed$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.role = this.usersService.currentUserS().role;
    this.ticket = navParams.get('ticket');
    this.notes = this.ticket.notes?.filter((it) => it.isAdHoc) ?? [];

    this.formGroup = this.formBuilder.group({
      hours: [this.ticket.hours ?? null],
      comment: [''],
    });

    this.changed$ = this.formGroup.valueChanges.pipe(
      map(() => this.formGroup.dirty),
      shareReplay(1),
    );
  }

  private setFormValues() {
    const { comment, hours } = this.formGroup.value;
    this.ticket.hours = hours ?? null;
    if (this.ticket.notes == null) {
      this.ticket.notes = [];
    }
    const note = {
      timestamp: new Date(),
      username: this.usersService.currentUserS()?.displayName,
      userGuid: this.usersService.currentUserS()?.guid,
      text: comment,
      isAdHoc: true,
      hours,
    };
    this.ticket.notes.push(note);
  }

  onDuration(value: number, popover: IonPopover) {
    this.formGroup.get('hours').setValue(value);
    popover.dismiss();
  }

  onAccept() {
    this.setFormValues();
    if (this.ticket.additionalWorkInfo == null) {
      this.ticket.additionalWorkInfo = {};
    }
    if (this.role === Roles.manager) {
      this.ticket.additionalWorkInfo.client = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.clientTimestamp = new Date();
    } else {
      this.ticket.additionalWorkInfo.admin = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.adminTimestamp = new Date();
    }
    this.dismiss(this.ticket);
  }

  onChange() {
    this.setFormValues();
    if (this.role === Roles.manager) {
      this.ticket.additionalWorkInfo.adminTimestamp = null;
      this.ticket.additionalWorkInfo.client = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.clientTimestamp = new Date();
    } else {
      this.ticket.additionalWorkInfo.clientTimestamp = null;
      this.ticket.additionalWorkInfo.admin = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.adminTimestamp = new Date();
    }
    this.dismiss(this.ticket);
  }

  onDecline() {
    this.ticket.status = TicketStatus.deleted;
    if (this.role === Roles.manager) {
      this.ticket.additionalWorkInfo.client = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.clientTimestamp = new Date();
    } else {
      this.ticket.additionalWorkInfo.admin = this.usersService.currentUserS().displayName;
      this.ticket.additionalWorkInfo.adminTimestamp = new Date();
    }
    this.dismiss(this.ticket);
  }

  dismiss(ticket?: RoomTicket) {
    this._modal.dismiss(ticket);
  }
}
