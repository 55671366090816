import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HideHeaderModule } from './directives/hide-header/hide-header.module';

@NgModule({
  imports: [ReactiveFormsModule, TranslateModule, HideHeaderModule],
  exports: [ReactiveFormsModule, TranslateModule, HideHeaderModule],
})
export class AppCommonModule { }
