import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable, map } from 'rxjs';
import { IService } from '../models/service.interface';
import { WorkMachine } from '../models/work-machine.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'work-machines';

@Injectable({
  providedIn: 'root',
})
export class WorkMachinesService implements IService<WorkMachine> {

  constructor(private firestore: FirestoreService) { }

  async save(item: WorkMachine) {
    return this.firestore.save<WorkMachine>(itemCollection, item);
  }

  async update(item: WorkMachine): Promise<void> {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<WorkMachine> {
    return this.firestore.get<WorkMachine>(itemCollection, guid);
  }

  getList(options?: { contractor: string }): Observable<WorkMachine[]> {
    const queryConstraints = [];
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }

    return this.firestore.getList<WorkMachine>(itemCollection, undefined, queryConstraints).pipe(
      map((items) => items?.filter((it) => !it.deleted)),
    );
  }
}
