import { Injectable, inject } from '@angular/core';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class SpeechToTextService {
  private usersService = inject(UsersService);

  private getLanguage(lang: string) {
    switch (lang) {
      case 'en':
        return 'en-US';
      case 'sv':
        return 'sv-SE';
      case 'uk':
        return 'uk-UA';
      case 'ar':
        return 'ar-SA';
      case 'de':
        return 'de-DE';
      case 'ru':
        return 'ru-RU';
      case 'fr':
        return 'fr-FR';
      case 'ro':
        return 'ro-RO';
      default:
        return 'fi-FI';
    }
  }

  async isAvailable() {
    try {
      return await SpeechRecognition.available().then((it) => it.available);
    } catch {
      return Promise.resolve(false);
    }
  }

  async record(): Promise<{
    matches?: string[];
  }> {
    const hasPermission = await SpeechRecognition.checkPermissions();
    if (hasPermission.speechRecognition === 'granted') {
      const user = this.usersService.currentUserS();
      const language = this.getLanguage(user.lang ?? 'fi');
      return SpeechRecognition.start({
        popup: true,
        language,
      });
    } else {
      const askedState = await SpeechRecognition.requestPermissions();
      if (askedState.speechRecognition === 'granted') {
        return this.record();
      }
    }
  }

  async stopRecording() {
    return SpeechRecognition.stop().catch((e) => console.error(e));
  }
}
