import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { LoadingModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NotificationService } from '../notification.service';

@Component({
  standalone: true,
  selector: 'app-notification-dialog',
  templateUrl: './notification.dialog.html',
  styleUrls: ['./notification.dialog.scss'],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonModule,
    LoadingModule,
    MainDialogComponent,
  ],
})
export class NotificationDialogComponent {

  loading: boolean;

  constructor(
    private notificationService: NotificationService,
    private _modal: ModalController,
  ) { }

  askPermission(): void {
    const callback = () => {
      this.loading = false;
      this.dismiss();
    };

    this.loading = true;
    this.notificationService.ask(callback);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
