import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Instruction } from 'src/app/models/instruction.model';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-instruction-dialog',
  templateUrl: './instruction.dialog.html',
  styleUrls: ['./instruction.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class InstructionDialogComponent {

  lang$ = this.translate.onLangChange.asObservable().pipe(
    map((event) => event.lang),
    startWith(this.usersService.currentUserS()?.lang ?? this.translate.currentLang),
    shareReplay(1),
  );

  item: Instruction;

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.item = navParams.get('item');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
