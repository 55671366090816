import { TranslateService } from '@ngx-translate/core';
import { addMinutes, format, parse } from 'date-fns';
import { Observable } from 'rxjs';
import { Schedule } from '../models/schedule.model';
import { Site } from '../models/site.model';
import { Timesheet } from '../models/timesheet.model';
import { User } from '../models/user.model';
import { TimesheetsService } from '../services/timesheets.service';
import { lunchBreak, overwork100BoundaryMs, overworkBoundaryMs, removeLunchDate, reportMillisToMinutesAndSeconds, sixHours, twoHoursMs } from '../utility/time';
import { removeLunchBreak } from '../utility/timesheet';

export interface ISiteTimesheet {
  weekday: number;
  date: string;
  timesheets: Timesheet[];
  schedule$?: Observable<Schedule>;
  users?: string[];
}

export const isSite = (object: object): object is Site => {
  return 'guid' in object && 'client' in object;
};

export const editTimesheet = async (
  timesheetsService: TimesheetsService,
  selected: User | Site,
  sheet: ISiteTimesheet,
  input: { value?: string | number },
  source: User | Site,
  accept = false,
  invoiced = false,
  wontInvoice = false,
  project?: string,
) => {
  let timesheets: Timesheet[];
  if (isSite(source)) {
    timesheets = sheet?.timesheets.filter((ts) => ts.site === source?.guid) ?? [];
  } else {
    timesheets = sheet?.timesheets.filter((ts) => ts.user === source?.guid) ?? [];
  }
  const startOfWorkday = 7;

  const [hours, mins] = (input.value as string).split(',');

  if (hours === '0' && mins == null) {
    for (let ts of timesheets) {
      timesheetsService.remove(ts);
    }
    return;
  }

  let date = new Date();
  let minutes = mins ?? '0';

  if (+minutes < 10) {
    minutes = `${minutes}0`;
  }

  date.setHours(+hours, +minutes);
  // Add lunch hour if six or more working hours
  if (+hours >= 6) {
    date = addMinutes(date, 30);
  }

  const timesheet = timesheets[0];
  if (timesheet?.dateIn) {
    timesheet.userDates = {
      in: timesheet.dateIn,
      out: timesheet.dateOut ?? null,
    };
    const startDate = new Date(timesheet.dateIn);
    startDate.setHours(startOfWorkday, 0, 0, 0);
    timesheet.dateIn = startDate;

    const endDate = new Date(timesheet.dateIn);
    endDate.setHours(startDate.getHours() + date.getHours(), date.getMinutes(), 0, 0);
    timesheet.dateOut = endDate;
    if (accept) {
      timesheet.checked = true;
    }
    if (invoiced) {
      timesheet.checked = true;
      timesheet.wontInvoice = false;
      timesheet.invoiced = true;
    }
    if (wontInvoice) {
      timesheet.checked = true;
      timesheet.invoiced = false;
      timesheet.wontInvoice = true;
    }
    if (timesheet.locationIn == null && timesheets.length > 1) {
      timesheets.map((ts) => {
        if (timesheet.locationIn == null && ts.locationIn != null) {
          timesheet.locationIn = ts.locationIn;
        }
        if (timesheet.locationOut == null && ts.locationOut != null) {
          timesheet.locationOut = ts.locationOut;
        }
      });
    }
    if (project) {
      timesheet.project = project;
    }

    return timesheetsService.update(timesheet).then(async () => {
      if (timesheets.length > 1) {
        const extras = timesheets.filter((ts, index) => index > 0 && ts.site === timesheet.site);
        // Delete extras
        for (let ts of extras) {
          await timesheetsService.remove(ts);
        }
      }
    });
  } else {
    const newDate = parse(sheet.date, 'd.M.yyyy', new Date());
    const startDate = new Date(newDate);
    startDate.setHours(startOfWorkday, 0, 0, 0);
    const endDate = new Date(newDate);
    endDate.setHours(startDate.getHours() + date.getHours(), date.getMinutes(), 0, 0);
    let newTimesheet: Timesheet;
    if (isSite(selected)) {
      newTimesheet = {
        date: sheet.date,
        dateIn: startDate,
        dateOut: endDate,
        site: selected.guid,
        checked: accept,
        user: source.guid,
        contractor: (source as User).contractors[0],
        project: project ?? '',
        username: (source as User).displayName,
      };
    } else {
      newTimesheet = {
        date: sheet.date,
        dateIn: startDate,
        dateOut: endDate,
        site: source.guid,
        checked: accept,
        user: selected.guid,
        contractor: selected.contractors[0],
        project: project ?? '',
        username: selected.displayName,
      };
    }
    return timesheetsService.save(newTimesheet, true);
  }
};

export const getOver50 = (timesheets: Timesheet[]): number => {
  let over50 = 0;
  timesheets.map((ts) => {
    if (ts?.dateIn && ts?.dateOut) {
      const date = new Date(ts.dateIn);
      if (date.getDay() !== 0) {
        let durationMs = ts.dateOut.getTime() - ts.dateIn.getTime();
        if (date.getDay() === 6) {
          // Saturday full day
          durationMs = removeLunchBreak(date, durationMs);
          over50 += durationMs;
        } else if (durationMs > overworkBoundaryMs) {
          let over = durationMs - overworkBoundaryMs;
          if (over >= twoHoursMs) {
            over50 += twoHoursMs;
          } else {
            over = removeLunchBreak(date, over);
            over50 += over;
          }
        }
      }
    }
  });

  return over50;
};

export const getOver100 = (timesheets: Timesheet[]): number => {
  let over100 = 0;
  timesheets.map((ts) => {
    if (ts?.dateIn && ts?.dateOut) {
      const date = new Date(ts.dateIn);
      if (date.getDay() !== 6) {
        let duration = ts.dateOut.getTime() - ts.dateIn.getTime();
        if (date.getDay() === 0) {
          // Sunday full day
          duration = removeLunchBreak(date, duration);
          over100 += duration;
        } else if (duration > overwork100BoundaryMs) {
          let over = duration - overwork100BoundaryMs;
          over = removeLunchBreak(date, over);
          over100 += over;
        }
      }
    }
  });

  return over100;
};

export function durationMsToString(durationMs: number) {
  return reportMillisToMinutesAndSeconds(durationMs).toFixed(2).replace('.', ',');
}

export const getWorkingHours = (selectedGuid: string, timesheets: Timesheet[], key: string): string => {
  const selectedTimesheets = selectedGuid ? timesheets.filter((t) => t[key] === selectedGuid) : timesheets;
  let hasSheets = false;
  let allDuration = 0;
  let date: Date;
  let signed = '';
  selectedTimesheets.map((ts) => {
    if (ts?.dateIn && ts?.dateOut && ts?.absence == null) {
      date = ts.dateIn;
      const duration = ts.dateOut.getTime() - ts.dateIn.getTime();
      allDuration += duration;
      hasSheets = true;
    } else if (ts?.dateIn) {
      const dateIn = new Date(ts.dateIn);
      signed += format(dateIn, 'HH:mm') + '-';
    }
  });
  if (date > removeLunchDate) {
    if (allDuration >= sixHours) {
      allDuration -= lunchBreak;
    }
  }
  return allDuration > 0 || hasSheets ? durationMsToString(allDuration) : signed;
};

export function durationToString(duration: number) {
  return duration.toFixed(2).replace('.', ',');
}

export function getKmPayAllowance(selectedGuid: string, timesheets: Timesheet[], key: string) {
  const selectedTimesheets = selectedGuid ? timesheets.filter((t) => t[key] === selectedGuid) : timesheets;
  const qties = selectedTimesheets.map((ts) => {
    const ptypes = ts.paymentTypes?.filter((it) => it.paymentType === 'kmPay') ?? [];
    const qty = ptypes?.reduce((prev, curr) => prev + +curr.paymentTypeUnit, 0) ?? 0;
    return qty;
  });
  return qties.reduce((prev, curr) => prev + curr, 0);
}

export function getFullDayAllowances(selectedGuid: string, timesheets: Timesheet[], key: string) {
  const selectedTimesheets = selectedGuid ? timesheets.filter((t) => t[key] === selectedGuid) : timesheets;
  const qties = selectedTimesheets.map((ts) => {
    const ptypes = ts.paymentTypes?.filter((it) => it.paymentType === 'fullDay') ?? [];
    const qty = ptypes?.reduce((prev, curr) => prev + +curr.paymentTypeUnit, 0) ?? 0;
    return qty;
  });
  return qties.reduce((prev, curr) => prev + curr, 0);
}

export function getAllowanceDescriptions(selectedGuid: string, timesheets: Timesheet[], key: string) {
  const selectedTimesheets = timesheets.filter((t) => t[key] === selectedGuid);
  return selectedTimesheets.map((ts) => ts.paymentTypes?.map((it) => it.paymentTypeText) ?? []).flat();
}

export const isChecked = (sheet: ISiteTimesheet, selected: User | Site) => {
  let timesheets: Timesheet[];
  if (isSite(selected)) {
    timesheets = sheet.timesheets.filter((ts) => ts.site === selected?.guid);
  } else {
    timesheets = sheet.timesheets.filter((ts) => ts.user === selected?.guid);
  }
  return timesheets.length > 0 && timesheets.every((ts) => ts.checked);
};

export const isInvoiced = (sheet: ISiteTimesheet, selected: User | Site) => {
  let timesheets: Timesheet[];
  if (isSite(selected)) {
    timesheets = sheet.timesheets.filter((ts) => ts.site === selected?.guid);
  } else {
    timesheets = sheet.timesheets.filter((ts) => ts.user === selected?.guid);
  }
  return timesheets.length > 0 && timesheets.every((ts) => ts.invoiced);
};

export const isWontInvoice = (sheet: ISiteTimesheet, selected: User | Site) => {
  let timesheets: Timesheet[];
  if (isSite(selected)) {
    timesheets = sheet.timesheets.filter((ts) => ts.site === selected?.guid);
  } else {
    timesheets = sheet.timesheets.filter((ts) => ts.user === selected?.guid);
  }
  return timesheets.length > 0 && timesheets.every((ts) => ts.wontInvoice);
};

export const absenceSite = 'absence';

export function getAbsences(translate: TranslateService) {
  return [
    { key: 'kidSick', name: translate.instant('timesheet.absences.kidSick') },
    { key: 'holiday', name: translate.instant('timesheet.absences.holiday') },
    { key: 'noPayLeave', name: translate.instant('timesheet.absences.noPayLeave') },
    { key: 'sickLeave', name: translate.instant('timesheet.absences.sickLeave') },
  ];
}

export function getPaymentTypes(translate: TranslateService) {
  return [
    {
      key: 'kmPay', name: translate.instant('timesheet.paymentTypes.kmPay'),
    },
    {
      key: 'fullDay', name: translate.instant('timesheet.paymentTypes.fullDay'),
    },
    {
      key: 'partDay', name: translate.instant('timesheet.paymentTypes.partDay'),
    },
  ];
}
