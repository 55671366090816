@if ({
contractor: contractor$ | async,
news: news$ | async,
}; as vm) {
  @if (user$ | async; as user) {
    <ion-toolbar>
      <div slot="start"
        class="flex">
        <ion-buttons class="desktop">
          <ion-button (click)="onMenu()">
            <ion-icon slot="icon-only"
              name="apps"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title mode="md">
          <ng-content></ng-content>
        </ion-title>
      </div>
      <div class="desktop flex-center center">
        <ion-button (click)="toHome()">
          <ion-icon slot="icon-only"
            name="home"
            aria-hidden="true"></ion-icon>
        </ion-button>
      </div>
      <div class="flex"
        slot="end">
        <div class="desktop">
          <ion-button (click)="onInstructions()">
            <ion-icon slot="icon-only"
              name="help-circle"
              aria-hidden="true"></ion-icon>
          </ion-button>
        </div>
        @if (['admin', 'worker'].includes(user.role)) {
          <ion-button (click)="onSearch()">
            <ion-icon slot="icon-only"
              name="search-outline"></ion-icon>
          </ion-button>
        }
        <ion-button (click)="onMessages()">
          <ion-icon slot="icon-only"
            name="chatbubbles"></ion-icon>
        </ion-button>
        @if (messages$ | async; as messages) {
          <ion-badge class="count">{{ messages }}</ion-badge>
        }
        <app-login-icon></app-login-icon>
      </div>
      @if (loadingSites()) {
        <ion-progress-bar class="desktop"
          type="indeterminate"
          color="light"></ion-progress-bar>
      }
    </ion-toolbar>
  }
}