import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Options } from '../models/options.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'options';
const itemKey = 'options';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {

  constructor(private firestore: FirestoreService) { }

  async update(value: object) {
    return this.firestore.updateOnly(itemCollection, itemKey, value);
  }

  get(): Observable<Options> {
    return this.firestore.get<Options>(itemCollection, itemKey).pipe(
      map((item) => item ?? {
        milageAllowance: 0,
        prices: null,
      }),
    );
  }
}
