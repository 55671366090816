import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { AuthService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUpload } from '../models/file.model';
import { SiteImage } from '../models/site-image.model';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const imageCollection = 'site-images';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private authService: AuthService,
    private firestore: FirestoreService,
  ) { }

  async uploadImage(file: FileUpload, tmp = false) {
    const authUser = this.authService.getCurrentUser();
    const path = tmp ? `tmp/${file.guid}` : `${authUser.uid}/${file.guid}`;
    return this.firestore.pushFileToStorage(file, path);
  }

  async uploadFile(file: FileUpload, tmp = false) {
    const authUser = this.authService.getCurrentUser();
    const path = tmp ? `tmp/${file.guid}` : `${authUser.uid}/${file.guid}`;
    return this.firestore.pushFileToStorageRef(file, path);
  }

  async saveImage(image: SiteImage) {
    return this.firestore.save(imageCollection, image);
  }

  getImages(guid: string): Observable<SiteImage[]> {
    return this.firestore
      .getList<SiteImage>(imageCollection, undefined, [where('site', '==', guid)]).pipe(
        map((results) => results.map((result) => setDates(result, 'date'))),
      );
  }
}
