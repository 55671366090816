import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { PartnerNote } from 'src/app/models/partner-note.model';

@Component({
  standalone: true,
  selector: 'app-partner-note-dialog',
  templateUrl: './partner-note.dialog.html',
  styleUrls: ['./partner-note.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    FormsModule,
    MainDialogComponent,
  ],
})
export class PartnerNoteDialogComponent {

  formGroup: FormGroup;

  contactTypes = ['phone', 'email', 'meeting'];

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    const item: PartnerNote = navParams.get('item');

    this.formGroup = this.formBuilder.group({
      guid: [item?.guid ?? null],
      inviter: [item?.inviter ?? null],
      target: [item?.target ?? '', Validators.required],
      contact: [item?.contact ?? '', Validators.required],
      contactType: [item?.contactType ?? '', Validators.required],
      duration: [item?.duration ?? '', Validators.required],
      interested: [item?.interested ?? '', Validators.required],
      future: [item?.future ?? '', Validators.required],
      date: [item?.date ?? new Date()],
    });
  }

  save() {
    const item = this.formGroup.value;
    this.dismiss(item);
  }

  dismiss(item?: PartnerNote) {
    this._modal.dismiss(item);
  }
}
