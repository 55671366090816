import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

const url = `${environment.backendUrl}/api/workers`;

@Injectable({
  providedIn: 'root',
})
export class WorkersService {

  constructor(
    private http: HttpClient,
  ) { }

  getWorkers(site: string): Observable<User[]> {
    if (environment.backendUrl) {
      return this.http.get<User[]>(`${url}/${site}`);
    } else {
      return of([]);
    }
  }
}
