import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import { debounceTime, from, map, of, switchMap } from 'rxjs';

export async function getThumb(img: string) {
  try {
    const url = new URL(img);
    const lastIndex = url.pathname.lastIndexOf('.');
    const extension = url.pathname.substring(lastIndex + 1);
    const startIndex = url.pathname.lastIndexOf('/o/') + 2;
    const start = url.pathname.substring(startIndex, lastIndex);
    const thumbRaw = decodeURIComponent(`${start}_200x200.${extension}`);
    const storage = getStorage();
    const imgRef = ref(storage, thumbRaw);
    const thumb = await getDownloadURL(imgRef) ?? img;
    return { thumb, img };
  } catch {
    return { thumb: img, img };
  }
}

export function getThumbObs(img: string) {
  try {
    const url = new URL(img);
    const lastIndex = url.pathname.lastIndexOf('.');
    const extension = url.pathname.substring(lastIndex + 1);
    const startIndex = url.pathname.lastIndexOf('/o/') + 2;
    const start = url.pathname.substring(startIndex, lastIndex);
    const thumbRaw = decodeURIComponent(`${start}_200x200.${extension}`);
    const storage = getStorage();
    const imgRef = ref(storage, thumbRaw);
    return from(of(imgRef)).pipe(
      debounceTime(800),
      switchMap((imgReff) => from(getDownloadURL(imgReff))),
      map((downloadUrl) => ({ thumb: downloadUrl ?? img, img })),
    );
  } catch {
    return of({ thumb: img, img });
  }
}

export async function getVideoThumb(img: string) {
  try {
    const url = new URL(img);
    const lastIndex = url.pathname.lastIndexOf('.');
    const startIndex = url.pathname.lastIndexOf('/o/') + 2;
    const start = url.pathname.substring(startIndex, lastIndex);
    const thumbRaw = decodeURIComponent(`${start}_thumbnail.png`);
    const storage = getStorage();
    const imgRef = ref(storage, thumbRaw);
    const thumb = await getDownloadURL(imgRef) ?? img;
    return { thumb, img };
  } catch {
    return { thumb: img, img };
  }
}
