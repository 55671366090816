import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, Output, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { IonicModule, Platform, SegmentCustomEvent } from '@ionic/angular';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { AppCommonModule } from 'src/app/common.module';
import { mobileWidth } from 'src/app/utility/constants';

export interface SegmentButton {
  value?: string;
  name: string;
  show: boolean;
  action?: (item?: unknown) => void;
  cssClass?: string | string[];
  icon?: string;
}

@Component({
  standalone: true,
  selector: 'app-segment-list',
  templateUrl: './segment-list.component.html',
  styleUrls: ['./segment-list.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    DragScrollComponent,
    DragScrollItemDirective,
  ],
})
export class SegmentListComponent {

  private platform = inject(Platform);

  segmentValueS = signal<string>(null);
  segmentButtonsS = signal<SegmentButton[]>([]);
  mobileView = signal(true);

  @Input()
  set segmentValue(value: string) {
    this.segmentValueS.set(value);
  }

  @Input()
  set segmentButtons(value: SegmentButton[]) {
    this.segmentButtonsS.set(value);
  }

  @Input()
  sub = false;

  @Output()
  segmentChanged = toObservable(this.segmentValueS);

  constructor() {
    const isDesktop = this.platform.is('desktop');
    this.mobileView.set(!isDesktop);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView.set(window.innerWidth < mobileWidth);
  }

  onSegmentChanged(event: SegmentCustomEvent) {
    if (event.detail.value) {
      this.segmentValueS.set(event.detail.value.valueOf() as string);
    }
  }

  onButtonClick(value: string) {
    this.segmentValueS.set(value);
  }
}
