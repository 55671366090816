<app-main-page>
  <div desktopTitle>
    {{ 'nav.notes' | translate }}
  </div>
  <ion-segment segmentContainer
    mode="ios"
    scrollable="true">
    <ion-button class="desktop"
      (click)="onNewNote()">
      {{ 'users.newNote' | translate }}
    </ion-button>
  </ion-segment>
  @if (items$ | async; as items) {
  @for (item of items; track item) {
  <ion-item class="client"
    button="true"
    (click)="onItem(item)">
    {{ item.target }}
    <ion-note slot="end">
      {{ item.date | date:'dd.MM.yyyy' }}
    </ion-note>
  </ion-item>
  } @empty {
  <div class="ion-margin-top">
    <app-no-results></app-no-results>
    <app-chevron [text]="'item.addNote' | translate"></app-chevron>
  </div>
  }
  } @else {
  <ion-item lines="none">
    <ion-label>
      <app-loading></app-loading>
    </ion-label>
  </ion-item>
  }
</app-main-page>