@if ({
  role: role$ | async,
  rooms: rooms$ | async,
  tasks: tasks$ | async,
  contractors: subcontractors$ | async,
  canSelectSubcontractor: canSelectSubcontractor$ | async,
  modules: modules$ | async,
  limit: limit$ | async,
  isFree: isFree$ | async
  }; as vm) {
  @if (!aiTask() && vm.limit?.isFree) {
    <div class="flex flex-justify-between free-container">
      <span>{{ 'tickets.tryFree' | translate }}</span>
      {{ vm.limit.count }} / {{ vm.limit.maxPrompts }}
    </div>
  }
  <div class="ion-margin-bottom">
    <app-select-site-button [expand]="'block'"
      [disabled]="job() != null"
      [selectedSite]="selectedSite()"
      [items]="sites()"
      [hasNew]="true"
      (selected)="onSite($event)"></app-select-site-button>
  </div>
  <form [formGroup]="formGroup"
    (ngSubmit)="save(vm.role)">
    @if (['manager', 'super-admin'].includes(vm.role) || isSitelead()) {
      @if (vm.contractors) {
        <div class="ion-margin-bottom">
          <ion-button [disabled]="job() != null"
            expand="block"
            shape="round"
            color="light"
            (click)="selectContractor(vm.contractors)">
            @if (formGroup.get('subcontractor').value; as subcontractor) {
              <span>
                {{ subcontractor.name }}
              </span>
            } @else {
              {{ 'subcontractor.select' | translate }}
            }
          </ion-button>
        </div>
      }
    }
    <ion-button expand="block"
      shape="round"
      color="secondary"
      [disabled]="vm.tasks == null"
      (click)="onTask(vm.tasks)">
      @if (formGroup.get('name').value; as name) {
        {{ 'item.name' | translate }}:
        {{ name | translateTicket | async }}
      } @else {
        {{ 'calendar.site.name' | translate }}
      }
    </ion-button>
    <app-carousel-images [items]="images()"
      (klick)="onImage($event)"></app-carousel-images>
    <div class="flex">
      <app-camera-button [name]="'adhoc'"
        [disabled]="images().length >= maxImages"
        color="light"
        fill="clear"
        (imageUrl)="onUrl($event, 'image')"></app-camera-button>
      <app-take-video [name]="'adhoc'"
        [disabled]="images().length >= maxImages"
        color="light"
        fill="clear"
        (imageUrl)="onUrl($event, 'video')"></app-take-video>
    </div>
    @if (['manager'].includes(vm.role) && job() == null) {
      <ion-item color="primary"
        lines="none">
        <ion-toggle color="light"
          formControlName="securityObservation">
          {{ 'tickets.securityObservation' | translate }}
        </ion-toggle>
      </ion-item>
    }
    <ion-item class="description"
      lines="none">
      <ion-textarea autocapitalize="on"
        class="description"
        formControlName="description"
        rows="5"
        [placeholder]="'calendar.site.description' | translate">
      </ion-textarea>
      <app-speech-to-text (text)="onMic($event)"></app-speech-to-text>
    </ion-item>
    @if (vm.isFree && !['worker', 'manager'].includes(vm.role) && selectedSite()) {
      <app-free-select-worker expand="block"
        [disabled]="!(vm.role === 'admin' || isSitelead())"
        [site]="selectedSite().guid"
        [size]="'default'"
        [selectedUsers]="selectedWorkers()"
        [ticket]="{ parent: null, roomTicket: formGroup.get('guid').value }"
        (selected)="onWorker($event)"
        (opened)="setStorage()"></app-free-select-worker>
    }
    <ion-accordion-group>
      <ion-accordion>
        <ion-item slot="header"
          lines="none"
          color="primary">
          {{ 'item.showMore' | translate }}
        </ion-item>
        <div slot="content">
          <ion-button expand="block"
            shape="round"
            color="secondary"
            [disabled]="selectedSite() == null || job() != null"
            (click)="selectRoom(selectedSite())">
            @if (selectedRooms().length > 1) {
              {{ selectedRooms().length }} {{ 'room.selected' | translate }}
            } @else if (formGroup.get('room').value) {
              @if (formGroup.get('room').value; as room) {
                @if (room.id !== defaultRoom) {
                  @if (room.siteArea) {
                    <span>
                      {{ room.siteArea.name }} /&nbsp;
                    </span>
                  }
                  {{ room.id }}
                } @else {
                  {{ 'calendar.site.area' | translate }}
                }
              }
            } @else {
              {{ 'calendar.site.area' | translate }}
            }
          </ion-button>
          @if (hasBlueprints() && selectedRooms().length < 2) {
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="selectLocation(selectedSite())">
              @if (formGroup.get('location').value; as room) {
                <ion-icon slot="start"
                  name="checkmark-outline"></ion-icon> {{ 'calendar.site.location' | translate }}
              } @else {
                {{ 'calendar.site.location' | translate }}
              }
            </ion-button>
          }
          <ion-button expand="block"
            shape="round"
            color="secondary"
            [disabled]="selectedSite() == null || job() != null"
            (click)="selectProject(selectedSite())">
            @if (job()) {
              {{ job().name }}
            } @else if (selectedJob()) {
              {{ selectedJob().name }} ({{ selectedJob().parent.name }})
          } @else {
            {{ 'site.job' | translate }}
          }
          </ion-button>
          @if (!vm.isFree && canSelectWorker() && users$ | async; as users) {
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="selectWorker(users)">
              @if (formGroup.get('users').value.length > 0) {
                <div>
                  @for (user of formGroup.get('users').value; track user) {
                    <div>
                      {{ user.name }}
                    </div>
                  }
                </div>
              } @else {
                {{ 'calendar.site.user' | translate }}
              }
            </ion-button>
          }
          @if (hasLitteras()) {
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="selectLittera()">
              @if (formGroup.get('littera').value) {
                {{ formGroup.get('littera').value.code }} - {{ formGroup.get('littera').value.name }}
              } @else {
                {{ 'item.littera' | translate }}
              }
            </ion-button>
          }
          @if (vm.canSelectSubcontractor) {
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="selectSubContractor(vm.contractors)">
              @if (formGroup.get('invoiceContractor').value; as subcontractor) {
                <span>
                  {{ subcontractor.name }}
                </span>
              } @else {
                {{ 'subcontractor.selectSub' | translate }}
              }
            </ion-button>
          }
          <h4>{{ 'calendar.site.options' | translate }}</h4>
          <div class="flex flex-wrap">
            <app-select-day color="secondary"
              [id]="'adhocSelectDay'"
              [minDate]="minDate()"
              [highlightedDates]="highlightedDates()"
              [name]="'kanban.dateDeadline'"
              size="medium"
              [value]="formGroup.get('date').value"
              (changed)="onDateChange($event)"></app-select-day>
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="onSchedule()">
              @if (formGroup.get('schedule').value; as schedule) {
                <span>
                  {{ schedule.start | date:'d.M. HH:mm' }} - {{ schedule.end | date:'d.M. HH:mm' }}
                </span>
              } @else {
                {{ 'calendar.site.schedule' | translate }}
              }
            </ion-button>
            <ion-button id="adHocDuration"
              color="secondary"
              shape="round">
              @if (formGroup.get('duration').value; as duration) {
                <span>
                  {{ duration }}h
                </span>
              } @else {
                {{ 'calendar.site.duration' | translate }}
              }
            </ion-button>
            <ion-modal #durationModal
              class="picker-modal"
              trigger="adHocDuration"
              [keepContentsMounted]="true">
              <ng-template>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button (click)="durationModal.dismiss()">
                      {{ 'general.cancel' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons>
                    <ion-button color="danger"
                      (click)="onDuration(0); durationModal.dismiss()">
                      {{ 'general.reset' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons slot="end">
                    <ion-button (click)="onDuration(durationPicker.value); durationModal.dismiss()">
                      {{ 'general.select' | translate }}
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <ion-picker>
                  <ion-picker-column #durationPicker
                    [value]="formGroup.get('duration').value">
                    @for (duration of pickerColumns; track duration.value) {
                      <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                    }
                  </ion-picker-column>
                </ion-picker>
              </ng-template>
            </ion-modal>
            @if (workMachines$ | async; as workMachines) {
              @if (workMachines.length > 0) {
                <ion-button expand="block"
                  shape="round"
                  color="secondary"
                  (click)="onWorkMachine(vm.role, vm.modules, workMachines)">
                  @if (formGroup.get('workMachine').value) {
                    <span>
                      <div>
                        {{ formGroup.get('workMachine').value.name }}
                      </div>
                    </span>
                  } @else {
                    {{ 'nav.workMachines' | translate }}
                  }
                </ion-button>
              }
            }
            @if (instructions$ | async; as instructions) {
              <ion-button expand="block"
                shape="round"
                color="secondary"
                (click)="selectInstructions(instructions)">
                @if (formGroup.get('instruction').value; as instruction) {
                  <span>
                    {{ instruction.name }}
                  </span>
                } @else {
                  {{ 'help.item' | translate }}
                }
              </ion-button>
            }
            @if (vm.role !== 'worker') {
              <ion-button expand="block"
                shape="round"
                color="secondary"
                (click)="onAttachments()">
                {{ 'site.attachments' | translate }}
              </ion-button>
            }
            @if (['manager'].includes(vm.role) && job() == null) {
              <ion-item color="primary"
                lines="none">
                <ion-toggle color="light"
                  formControlName="planningObservation"
                  (ionChange)="onPlanningObservation($event)">
                  {{ 'tickets.planningObservation' | translate }}
                </ion-toggle>
              </ion-item>
            }
          </div>
          <h4>{{ 'calendar.site.repeat' | translate }}</h4>
          <div>
            <ion-button expand="block"
              shape="round"
              color="secondary"
              (click)="selectRepeatOption()">
              @if (selectedRepeatOption(); as repeatOption) {
                <span>
                  {{ repeatOption.name }}
                </span>
              } @else {
                {{ 'general.select' | translate }}
              }
            </ion-button>
            @if (selectedRepeatOption() != null) {
              @if (selectedRepeatOption().guid === 'daily') {
                <ion-item color="primary"
                  lines="none">
                  <ion-label>{{ 'calendar.site.clock' | translate }}</ion-label>
                  <ion-datetime-button datetime="clock">
                    <div slot="time-target">
                      @if (selectedRepeatOption().time) {
                        {{ selectedRepeatOption().time | date:'HH:mm' }}
                      } @else {
                        --:--
                      }
                    </div>
                  </ion-datetime-button>
                  <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                      <ion-datetime #clock
                        id="clock"
                        presentation="time"
                        locale="fi-FI"
                        [value]="selectedRepeatOption().time"
                        (ionChange)="onRepeatTimeChange($event)">
                        <ion-buttons slot="buttons">
                          <ion-button color="danger"
                            (click)="clock.reset()">{{ 'general.clear' | translate }}</ion-button>
                          <ion-button color="primary"
                            (click)="clock.cancel(true)">{{ 'general.cancel' | translate }}</ion-button>
                          <ion-button color="primary"
                            (click)="clock.confirm(true)">{{ 'general.done' | translate }}</ion-button>
                        </ion-buttons>
                      </ion-datetime>
                    </ng-template>
                  </ion-modal>
                </ion-item>
              }
              <app-select-day color="secondary"
                [id]="'adhocRepeatStart'"
                [minDate]="minDate()"
                [name]="'calendar.site.start'"
                size="medium"
                [value]="selectedRepeatOption()?.start"
                (changed)="onRepeatStartChange($event)"></app-select-day>
              <app-select-day color="secondary"
                [id]="'adhocRepeatEnd'"
                [minDate]="selectedRepeatOption()?.start"
                [name]="'calendar.site.end'"
                size="medium"
                [value]="selectedRepeatOption()?.end"
                (changed)="onRepeatEndChange($event)"></app-select-day>
            }
          </div>
          <div class="flex ion-margin-top05 gap025">
            @if (false) { <!-- && !initialAdditionalWork() && !noAdditionalCheck()) { -->
              <ion-checkbox color="light"
                formControlName="additionalWork"
                labelPlacement="end">
                {{ 'calendar.site.additionalWork' | translate }}
              </ion-checkbox>
            }
            @if (formGroup.get('date').value && formGroup.get('duration').value && vm.modules && vm.modules[appModules.users]) {
              <ion-checkbox color="light"
                formControlName="allocate"
                labelPlacement="end">
                {{ 'calendar.site.allocate' | translate }}
              </ion-checkbox>
            }
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
    @if (freeLimit()) {

    } @else {
      <div class="ion-margin-top">
        <ion-button color="light"
          shape="round"
          type="submit"
          [disabled]="!formGroup.valid || loading()">{{ 'general.save' | translate }}</ion-button>
      </div>
    }
  </form>
  }