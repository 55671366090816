import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';

@Component({
  standalone: true,
  selector: 'app-desktop-card',
  templateUrl: './desktop-card.component.html',
  styleUrls: ['./desktop-card.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class DesktopCardComponent {

}
