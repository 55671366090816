import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject, input, signal } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { filter, map } from 'rxjs';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  standalone: true,
  selector: 'app-main-dialog',
  templateUrl: './main-dialog.component.html',
  styleUrls: ['./main-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    NgScrollbarModule,
  ],
})
export class MainDialogComponent {
  private platform = inject(Platform);
  private scrollService = inject(ScrollService);

  onlyMobile = input(false);
  canBack = input(true);

  @Output()
  dismiss = new EventEmitter<void>();

  @ViewChild(NgScrollbar)
  scrollbar: NgScrollbar;

  isNative = signal(this.platform.is('capacitor'));

  scrollToTop$ = this.scrollService.scrollToTop$.asObservable().pipe(
    filter((value) => value != null),
    map(async (top) => this.scrollbar.scrollTo({ top })),
  );

  onDismiss() {
    this.dismiss.emit();
  }
}
