<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'general.find' | translate }}
  </div>
  <div class="ion-margin-bottom">
    <ion-searchbar animated="true"
      mode="ios"
      show-clear-button="focus"
      [placeholder]="'general.search' | translate"
      (ionInput)="onChange($event)"></ion-searchbar>
  </div>
  @if ({
  clients: filteredClients$ | async,
  sites: filteredSites$ | async,
  users: filteredUsers$ | async
  }; as vm) {
  @if (vm.sites?.length > 0) {
  <h3>{{ 'nav.sites' | translate }}</h3>
  @for (item of vm.sites; track item.guid) {
  <ion-item lines="none"
    button="true"
    (click)="onSite(item)">
    <ion-avatar slot="start">
      @if (item.client$ | async; as client) {
      <app-logo [logo]="client.logo"></app-logo>
      } @else {
      <img src="./assets/images/logo.png" />
      }
    </ion-avatar>
    {{ item.name }}
  </ion-item>
  }
  }
  @if (vm.clients?.length > 0) {
  <h3>{{ 'nav.clients' | translate }}</h3>
  @for (item of vm.clients; track item.guid) {
  <ion-item lines="none"
    button="true"
    (click)="onClient(item)">
    <ion-avatar slot="start">
      @if (item.logo) {
      <app-logo [logo]="item.logo"></app-logo>
      } @else {
      <img src="./assets/images/logo.png" />
      }
    </ion-avatar>
    {{ item.name }}
  </ion-item>
  }
  }
  @if (vm.users?.length > 0) {
  <h3>{{ 'nav.users' | translate }}</h3>
  @for (item of vm.users; track item.guid) {
  <ion-item lines="none"
    button="true"
    (click)="onUser(item)">
    <ion-avatar slot="start">
      @if (item.image) {
      <img [src]="item.image" />
      } @else {
      <ion-icon name="person-circle-outline"></ion-icon>
      }
    </ion-avatar>
    {{ item.displayName }}
  </ion-item>
  }
  }
  @if (vm.users?.length === 0 && vm.sites?.length === 0 && vm.clients?.length === 0) {
  <app-no-results></app-no-results>
  }
  }
</app-main-dialog>