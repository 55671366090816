<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (client?.guid) {
      <span>
        {{ 'general.edit' | translate }}
      </span>
    } @else {
      {{ 'clients.new' | translate }}
    }
  </div>
  <form class="ion-padding-bottom"
    [formGroup]="formGroup"
    (ngSubmit)="save()">
    <h4>{{ 'sites.new.image' | translate }}</h4>
    @if (formGroup.value.logo) {
      <ion-item lines="none">
        <img [src]="formGroup.value.logo" />
        <ion-icon slot="end"
          color="danger"
          name="remove-circle-outline"
          (click)="removeLogo()"></ion-icon>
      </ion-item>
    } @else {
      <div class="ion-text-center">
        <app-camera-button class="only-camera"
          [name]="formGroup.value.name"
          color="light"
          fill="clear"
          [multiline]="true"
          shape="round"
          (imageUrl)="onImageUrl($event)"></app-camera-button>
      </div>
    }
    <h4>{{ 'clients.name' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        type="submit"
        [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>