import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ContentComponent } from './content.component';

@NgModule({
  declarations: [ContentComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
  ],
  exports: [ContentComponent],
})
export class ContentModule { }
