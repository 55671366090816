import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LoginIconComponent } from './login-icon.component';

@NgModule({
  declarations: [LoginIconComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [LoginIconComponent],
})
export class LoginIconModule { }
