import { CommonModule } from '@angular/common';
import { Component, WritableSignal, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Timestamp } from '@angular/fire/firestore';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModule, LoadingService, fieldSorter } from '@scandium-oy/ngx-scandium';
import { startOfDay } from 'date-fns';
import { of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NoResultsModule } from 'src/app/components/no-results/no-results.module';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectTicketsComponent, SelectedTicket } from 'src/app/components/select-tickets/select-tickets.component';
import { Contractor } from 'src/app/models/contractor.model';
import { FileUpload } from 'src/app/models/file.model';
import { SiteArea } from 'src/app/models/site-area.model';
import { ProjectPeriod, SiteProject } from 'src/app/models/site-project.model';
import { SiteReport } from 'src/app/models/site-report.model';
import { Site } from 'src/app/models/site.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { AreasService } from 'src/app/services/areas.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { PrintService } from 'src/app/services/print.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SiteReportsService } from 'src/app/services/site-report.service';
import { UploadService } from 'src/app/services/upload.service';
import { UsersService } from 'src/app/services/users.service';
import { getRoomName } from 'src/app/services/worker-tickets.service';
import { defaultRoom } from 'src/app/utility/room';
import { NewReportNoteDialogComponent, SiteReportNote } from './new-note.dialog';
import { css } from './report-style';

@Component({
  standalone: true,
  selector: 'app-new-site-report-dialog',
  templateUrl: './new-site-report.dialog.html',
  styleUrls: ['./new-site-report.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
    NoResultsModule,
    TranslateTicketPipe,
    SelectDayComponent,
    LoadingModule,
    SelectTicketsComponent,
  ],
})
export class NewSiteReportDialogComponent {
  private areasService = inject(AreasService);
  private contractorsService = inject(ContractorsService);
  private loadingService = inject(LoadingService);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);
  private printService = inject(PrintService);
  private scrollService = inject(ScrollService);
  private siteProjectsService = inject(SiteProjectsService);
  private siteReportsService = inject(SiteReportsService);
  private translate = inject(TranslateService);
  private uploadService = inject(UploadService);
  private usersService = inject(UsersService);

  areas$ = this.areasService.getAreas(of(this.navParams.get('site'))).pipe(
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  contractors$ = this.contractorsService.getList({ site: this.navParams.get('site').guid }).pipe(
    tap((contractors) => {
      const user = this.usersService.currentUserS();
      if (user.contractors?.length > 0) {
        const contractor = contractors.find((c) => c.guid === user.contractors[0]);
        this.selectedContractor.set(contractor);
      }
    }),
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  selectedArea = signal<SiteArea>(null);
  selectedContractor = signal<Contractor>(null);
  selectedProject = signal<SiteProject>(null);
  selectedJob = signal<ProjectPeriod>(null);
  selectedTemplate = signal<string>(null);
  notes = signal<SiteReportNote[]>([]);
  selectedTickets = signal<RoomTicket[]>([]);

  step = signal(1);
  loading = signal(false);

  projects$ = toObservable(this.selectedContractor).pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.siteProjectsService.getList({ site: this.navParams.get('site').guid, contractor: contractor.guid })),
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  jobs$ = toObservable(this.selectedProject).pipe(
    filter((project) => project != null),
    map((project) => project.periods),
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  tickets$ = toObservable(this.selectedContractor).pipe(
    filter((contractor) => contractor != null),
    tap(() => this.loading.set(true)),
    switchMap((contractor) => this.areasService.getAreaTickets(of([this.navParams.get('site')]), contractor.guid)),
    map((tickets) => tickets.map((t) => t.tickets
      .map((rt) => {
        rt.parent = t;
        rt.room = t.room;
        rt.date = startOfDay(rt.history[rt.history.length - 1].timestamp);
        const roomName = getRoomName(rt);
        return Object.assign({}, rt, { selected: false, roomName });
      }) ?? []).flat()),
    tap(() => this.loading.set(false)),
    shareReplay(1),
  );

  readonly itemsPerPage = 3;
  readonly defaultRoom = defaultRoom;
  readonly finalStep = 4;
  readonly templates = [
    {
      name: 'tasks',
      disabled: false,
    },
    {
      name: 'faultList',
      disabled: true,
    },
    {
      name: 'note',
      disabled: true,
    },
    {
      name: 'tr',
      disabled: true,
    },
  ];

  role = computed(() => this.usersService.currentUserS().role);
  username = computed(() => this.usersService.currentUserS().displayName);
  readonly creationDate = new Date();

  onStep(value: number) {
    this.step.update((val) => val + value);
    this.scrollService.scrollToTop();
  }

  onTemplate(template: string) {
    this.selectedTemplate.set(template);
    this.step.update((val) => ++val);
  }

  selectItem(items: unknown[], item: WritableSignal<unknown>) {
    const clearButton = item() != null;

    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            item.set(null);
          } else {
            item.set(data.data);
          }
        }
      });
    });
  }

  removeNote(index: number, name: string) {
    Dialog.confirm({
      message: this.translate.instant('general.delete') + ' ' + name,
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        this.notes.update((val) => {
          val.splice(index, 1);
          return val;
        });
      }
    });
  }

  onNewNote() {
    const site = this.navParams.get('site').guid;
    this.modalCtrl.create({ component: NewReportNoteDialogComponent, componentProps: { site } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.notes.update((val) => [...val, data.data]);
        }
      });
    });
  }

  onSelectTicket(roomTickets: SelectedTicket[]) {
    this.selectedTickets.set(roomTickets);
  }

  onDone() {
    const contractor = this.selectedContractor();
    const site: Site = this.navParams.get('site');
    this.loadingService.showLoading(2000);
    setTimeout(() => {
      const element = document.getElementById('print-taskreport');
      const name = `tehtäväraportti_${site.name}_${contractor.name}_${this.creationDate.toISOString()}`;
      this.printService.printDoc(name, element, css, { orientation: 'portrait', scale: 2 }).then((blob) => {
        this.loadingService.hideLoading();
        if (blob) {
          const file = new File([blob], `${name}.pdf`);
          const fileupload = new FileUpload(file);
          fileupload.guid = site.guid;
          this.uploadService.uploadImage(fileupload).then((url) => {
            const siteReport: SiteReport = {
              name: this.selectedTemplate(),
              site: site.guid,
              contractor: { guid: contractor.guid, name: contractor.name },
              url,
              created: Timestamp.now(),
              creator: { guid: this.usersService.currentUserS().guid, name: this.usersService.currentUserS().displayName },
              tickets: this.selectedTickets().map((it) => ({ guid: it.guid, parentGuid: it.parent.guid, name: it.name })),
            };
            this.siteReportsService.save(siteReport).then(() => this.dismiss());
          });
        }
      }, (e) => {
        console.error(e);
        this.loadingService.hideLoading();
      });
    }, 50);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
