@if (hasSpeechToText()) {
  <ion-button [id]="id()"
    class="mic"
    fill="clear"
    (click)="onMic(modal)">
    @if (title()) {
      <div>
        <ion-icon name="mic-outline"></ion-icon><br />
        {{ title() | translate }}
      </div>
    } @else {
      <ion-icon slot="icon-only"
        name="mic-outline"></ion-icon>
    }
  </ion-button>
  <ion-modal #modal
    [trigger]="id()"
    class="transparent-modal"
    [keepContentsMounted]="true">
    <ng-template>
      <ion-content class="container">
        <div class="center">
          {{ 'speech.recording' | translate }}
          <ion-button size="large"
            shape="round"
            color="danger"
            (click)="stop(modal)">
            {{ 'speech.stop' | translate }}
          </ion-button>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>
}