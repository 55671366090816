<app-main-page>
  <div desktopTitle>
    {{ 'nav.invites' | translate }}
  </div>
  <ion-segment segmentContainer
    [value]="segmentValue"
    mode="ios"
    scrollable="true"
    (ionChange)="segmentChanged($event)">
    <ion-segment-button value="accepted">
      {{ 'invites.accepted' | translate }}
    </ion-segment-button>
    <ion-segment-button value="waiting">
      {{ 'invites.waiting' | translate }}
    </ion-segment-button>
  </ion-segment>
  @if (segmentValue === 'accepted') {
  @for (invite of acceptedInvites$ | async; track invite) {
  <ion-item>
    <span slot="start">
      {{ invite.created | date:'dd.MM.yyyy' }}
    </span>
    <div>
      <div>
        {{ invite.invitee }}
      </div>
      @if (invite.contractor$ | async; as contractor) {
      <div>
        {{ contractor.name }}
      </div>
      }
    </div>
  </ion-item>
  }
  }
  @if (segmentValue === 'waiting') {
  @for (invite of waitingInvites$ | async; track invite) {
  <ion-item>
    <span slot="start">
      {{ invite.created | date:'dd.MM.yyyy' }}
    </span>
    <div>
      {{ invite.invitee }}
      <div>
        <ion-note>
          {{ 'invites.expires' | translate }}
          {{ invite.expires.toDate() | date:'dd.MM.yyyy' }}
        </ion-note>
      </div>
    </div>
  </ion-item>
  }
  }
</app-main-page>