import { Component, EventEmitter, Input, Output, signal } from '@angular/core';

export interface ITabItem {
  key: string;
  name: string;
}

@Component({
  selector: 'app-tabs',
  styleUrls: ['./tabs.component.scss'],
  templateUrl: './tabs.component.html',
})
export class TabsComponent {

  selectedTab = signal<string>(null);

  @Input() items: ITabItem[];

  @Input()
  set initial(value: string) {
    this.selectedTab.set(value);
  }

  @Output() selected = new EventEmitter<string>();

  onNav(key: string | CustomEvent) {
    if (typeof key === 'string') {
      this.selected.emit(key);
    } else {
      const eventKey = key.detail.value;
      this.selected.emit(eventKey);
    }
  }
}
