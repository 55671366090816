<ion-datetime-button [datetime]="'datetime-' + id"></ion-datetime-button>

<ion-modal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime [id]="'datetime-' + id"
      presentation="date"
      [locale]="locale$ | async"
      [firstDayOfWeek]="1"
      [showDefaultButtons]="true"
      [doneText]="'general.done' | translate"
      [cancelText]="'general.cancel' | translate"
      [value]="currentDayISO"
      (ionChange)="onDayChange($event)"></ion-datetime>
  </ng-template>
</ion-modal>