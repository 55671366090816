import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from '../models/note.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'notes';

@Injectable({
  providedIn: 'root',
})
export class NotesService implements IService<Note> {

  constructor(private firestore: FirestoreService) { }

  async save(note: Note) {
    return this.firestore.save(itemCollection, note);
  }

  async update(item: Note) {
    return this.firestore.update(itemCollection, item);
  }

  async delete(item: Note) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string): Observable<Note> {
    return this.firestore.get<Note>(itemCollection, guid);
  }

  getList(options?: { site?: string; contractor?: string }): Observable<Note[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('site', '==', options.site));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }

    return this.firestore
      .getList<Note>(itemCollection, undefined, queryConstraints)
      .pipe(map((results) => results.map((it) => setDates(it, 'date')) ?? []));
  }
}
