
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { DesktopCardComponent } from '../components/desktop-card/desktop-card.component';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';
import { SegmentListComponent } from '../components/segment-list/segment-list.component';
import { NewNoteDialogComponent } from './dialog/new-note.dialog';
import { NotesComponent } from './notes.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AppCommonModule,
    DesktopCardComponent,
    MainDialogComponent,
    SegmentListComponent,
  ],
  declarations: [
    NotesComponent,
    NewNoteDialogComponent,
  ],
  providers: [
    DatePipe,
  ],
  exports: [
    NotesComponent,
  ],
})
export class NotesModule { }
