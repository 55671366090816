import { Roles } from './role';

export const getTabHidden = (url: string, role: Roles) => {
  url = url?.split('?')[0] ?? '';
  if (url.startsWith('/sites/site/')) {
    url = `/sites/site`;
  } else if (url.startsWith('/materials/order/')) {
    url = `/materials/order`;
  } else if (url.startsWith('/instructions/')) {
    url = `/instructions`;
  } else if (url.startsWith('/contractors/')) {
    url = '/contractors/';
  }

  switch (url) {
    case '/instructions':
      return ![Roles.admin, Roles.superAdmin].includes(role);
    case '/home':
      return [Roles.superAdmin].includes(role);
    case '/worker/sites':
      return [Roles.worker, Roles.storage].includes(role);
    case '/worker/messages/new':
    case '/profile':
    case '/worker/paycheck':
    case '/worker/paycheck/period':
    case '/worker/calendar':
    case '/calendar':
    case '/schedule':
    case '/contractors/':
    case '/efficiency':
      return true;
    default:
      return false;
  }
};
