import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[swipe]',
})
export class SwipeDirective {

  private swipeCoord = [0, 0];
  private swipeTime = new Date().getTime();

  @Output()
  next = new EventEmitter<void>();

  @Output()
  previous = new EventEmitter<void>();

  private onSwipe(e: TouchEvent, when: string) {
    this.swipe(e, when);
  }

  private swipe(e: TouchEvent, when: string): void {
    if (e.changedTouches == null) {
      return;
    }

    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start' && coord[0] < 20) {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipeDir = direction[0] < 0 ? 'next' : 'previous';
        if (swipeDir === 'next') {
          this.next.emit();
        } else {
          this.previous.emit();
        }
      }
    }
  }

  @HostListener('touchstart', ['$event'])
  onSwipeStart(event: TouchEvent) {
    this.onSwipe(event, 'start');
  }

  @HostListener('touchend', ['$event'])
  onSwipeEnd(event: TouchEvent) {
    this.onSwipe(event, 'end');
  }
}
