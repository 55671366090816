export const css = `
  #print-taskreport {
    color: #000;
    font-size: 11px;
    padding: 1em;
    width: 100%;
  }
  h4 {
    font-size: 12px;
    text-transform: uppercase;
  }
  ion-note {
    font-size: 11px;
  }
  .flex {
    display: flex;
    gap: 2em;
    padding-bottom: 2em;
    width: 100%;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  img.logo { height: 48px; }
  table {
    border: 1px solid #000;
    margin-bottom: 1em;
    width: 100%;
  }
  .empty-container {
    height: 37px;
    width: 100%;
  }
  tr.header {
    background-color: #000;
    color: #fff;
  }
  td {
    padding: 1em;
    border: 1px solid #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
      object-fit: cover;
      height: 135px;
      width: 100%;
    }
  }
  td.id {
    width: 50px;
  }
  td.date {
    width: 80px;
  }
  td.room {
    width: 400px;
  }
  .no-print { display: none; }
  .print { opacity: 1 !important; height: initial !important; }
`;
