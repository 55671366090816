import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-thank-you-dialog',
  templateUrl: './thank-you.dialog.html',
  styleUrls: ['./thank-you.dialog.scss'],
})
export class ThankYouDialogComponent {

  header = 'messages.title';
  message = 'messages.text';
  button: boolean;

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.header = navParams.get('header') ?? 'messages.title';
    this.message = navParams.get('message') ?? 'messages.text';
    this.button = navParams.get('button') ?? false;
  }

  dismiss() {
    this._modal.dismiss();
  }
}
