import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CameraButtonModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { CarouselImagesComponent } from 'src/app/components/carousel/images/carousel-images.component';
import { ImagesModule } from 'src/app/components/images/images.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectAreaDialogComponent } from 'src/app/components/select-area/select-area.dialog';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectSiteButtonComponent } from 'src/app/components/select-site/select-site.component';
import { SpeechToTextComponent } from 'src/app/components/speech-to-text/speech-to-text.component';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { WeekDayModule } from 'src/app/pipes/weekday.module';
import { SiteCalendarDialog } from './site-calendar.dialog';

@NgModule({
  imports: [
    AppCommonModule,
    IonicModule,
    CommonModule,
    FormsModule,
    WeekDayModule,
    CameraButtonModule,
    ImagesModule,
    TakeVideoComponent,
    TranslateTicketPipe,
    SelectAreaDialogComponent,
    SelectDayComponent,
    CarouselImagesComponent,
    MainDialogComponent,
    SelectSiteButtonComponent,
    SpeechToTextComponent,
  ],
  declarations: [SiteCalendarDialog],
  exports: [SiteCalendarDialog],
})
export class SiteCalendarDialogModule { }
