import { parse } from 'date-fns';
import { Schedule } from '../models/schedule.model';
import { convertTimestamp } from './time';

function isNumber(value) {
  return typeof value === 'number';
}

export const fieldSorter = (fields: string[]) => (a, b) =>
  fields
    .map((o) => {
      let dir = 1;
      if (o.startsWith('-')) {
        dir = -1;
        o = o.substring(1);
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
    })
    .reduce((p, n) => (p ? p : n), 0);

export function sortByDate(a: Schedule, b: Schedule): number {
  const dateA = parse(a.date, 'd.M.yyyy', new Date());
  const dateB = parse(b.date, 'd.M.yyyy', new Date());
  return dateA.getTime() < dateB.getTime() ? -1 : 1;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortAnyByDate(a: any, b: any, key: string): number {
  if (a[key] == null && b[key] == null) {
    return 0;
  }
  if (a[key] == null && b[key] != null) {
    return -1;
  }
  if (a[key] != null && b[key] == null) {
    return 1;
  }
  return a[key].getTime() < b[key].getTime() ? -1 : 1;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByTimestamp = (a: any, b: any, key: string): number => {
  const dateA = convertTimestamp(a[key]);
  const dateB = convertTimestamp(b[key]);
  return dateA.getTime() < dateB.getTime() ? -1 : 1;
};

export function sortIgnoreCase(a: unknown, b: unknown, field: string): number {
  return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
}

export function sortByNumber(a: unknown, b: unknown, field: string): number {
  const first = +a[field];
  if (isNumber(first)) {
    return +a[field] < +b[field] ? -1 : 1;
  } else {
    return a[field] < b[field] ? -1 : 1;
  }
}
