import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { IService } from '@scandium-oy/ngx-scandium';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstructionCategory } from '../models/instruction-category.model';
import { Instruction } from '../models/instruction.model';
import { Roles } from '../utility/role';
import { ContractorsService } from './contractors.service';
import { FirestoreService } from './firestore.service';
import { UsersService } from './users.service';

const itemCollection = 'instructions';
const categoryCollection = 'instruction-categories';

@Injectable({
  providedIn: 'root',
})
export class InstructionsService implements IService<Instruction> {
  constructor(
    private contractorsService: ContractorsService,
    private firestore: FirestoreService,
    private usersService: UsersService,
  ) { }

  async save(item: Instruction) {
    if (this.contractorsService.contractorS() != null) {
      item.contractor = this.contractorsService.contractorS().guid;
    }
    return this.firestore.save(itemCollection, item);
  }

  async saveCategory(item: InstructionCategory) {
    if (this.contractorsService.contractorS() != null) {
      item.contractor = this.contractorsService.contractorS().guid;
    }
    return this.firestore.save(categoryCollection, item);
  }

  async update(item: Instruction) {
    return this.firestore.update(itemCollection, item);
  }

  async updateCategory(item: InstructionCategory) {
    return this.firestore.update(categoryCollection, item);
  }

  async delete(item: Instruction) {
    return this.firestore.softDelete(itemCollection, item);
  }

  async deleteCategory(item: InstructionCategory) {
    return this.firestore.softDelete(categoryCollection, item);
  }

  get(guid: string): Observable<Instruction> {
    return this.firestore.get<Instruction>(itemCollection, guid);
  }

  getList(options?: { category?: string; contractor?: string }): Observable<Instruction[]> {
    const queryConstraints = [];
    if (options?.category) {
      queryConstraints.push(where('category.guid', '==', options.category));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }
    return this.firestore
      .getList<Instruction>(itemCollection, undefined, queryConstraints).pipe(
        map((items) => items?.filter((it) => it.deleted == null) ?? []),
        map((items) => {
          if (this.contractorsService.contractorS() != null) {
            return items.filter((it) => it.contractor === this.contractorsService.contractorS().guid || it.contractor == null);
          } else {
            return items;
          }
        }),
      );
  }

  getCategories(options?: { parent: string }): Observable<InstructionCategory[]> {
    const queryConstraints = [];
    if (options?.parent) {
      queryConstraints.push(where('parent.guid', '==', options.parent));
    } else if (options?.parent === null) {
      queryConstraints.push(where('parent', '==', null));
    }

    return this.firestore
      .getList<InstructionCategory>(categoryCollection, undefined, queryConstraints).pipe(
        map((items) => items?.filter((it) => it.deleted == null) ?? []),
        map((items) => {
          if (this.contractorsService.contractorS() != null) {
            return items.filter((it) => it.contractor === this.contractorsService.contractorS().guid || it.contractor == null);
          } else {
            if (this.usersService.currentUserS()?.role === Roles.superAdmin) {
              return items;
            } else {
              return items.filter((it) => it.contractor == null);
            }
          }
        }),
      );
  }

}
