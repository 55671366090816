<app-main-page>
  <div desktopTitle>
    {{ 'nav.workMachines' | translate }}
  </div>
  <app-segment-list segmentContainer
    [segmentButtons]="segmentButtons"></app-segment-list>
  @if ({
  machines: machines$ | async
  }; as vm) {
  @for (item of vm.machines; track item) {
  <ion-item lines="none">
    {{ item.name }}
    <ion-buttons slot="end">
      <ion-button fill="clear"
        color="primary"
        (click)="edit(item)">
        <ion-icon slot="icon-only"
          name="create-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear"
        color="danger"
        (click)="delete(item)">
        <ion-icon slot="icon-only"
          name="remove-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
  } @empty {
  <div>
    <app-no-results></app-no-results>
    <app-chevron [text]="'item.addNewMachine' | translate"></app-chevron>
  </div>
  }
  }
</app-main-page>