import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { InstructionCategory } from 'src/app/models/instruction-category.model';

@Component({
  standalone: true,
  selector: 'app-select-category-dialog',
  templateUrl: './select-category.dialog.html',
  styleUrls: ['./select-category.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class SelectCategoryDialogComponent implements OnInit {

  parentItems = signal<InstructionCategory[]>([]);

  clearButton: boolean;
  multiple: boolean;

  constructor(
    private _modal: ModalController,
    private navParams: NavParams,
  ) {
    this.clearButton = this.navParams.get('clearButton');
    this.multiple = this.navParams.get('multiple');
  }

  private addChildren(item: InstructionCategory, categories: InstructionCategory[]) {
    if (item?.name) {
      item.children = categories
        .filter((it) => it.parent?.guid === item.guid)
        .map((it) => {
          const child = Object.assign(it, { children: [] });
          return this.addChildren(child, categories);
        }) ?? [];
    }
    return item;
  }

  ngOnInit(): void {
    const categories = this.navParams.get('items');
    const parents = categories.filter((it) => it.parent == null);
    const items = parents.map((it) => {
      const parent = Object.assign(it, { children: [] });
      return this.addChildren(parent, categories);
    });
    this.parentItems.set(items);
  }

  clear() {
    this.dismiss({ clear: true } as InstructionCategory);
  }

  select(item: InstructionCategory) {
    if (this.multiple) {
      item.selected = !item.selected;
    } else {
      this.dismiss(item);
    }
  }

  save() {
    this._modal.dismiss(this.parentItems().filter((it) => it.selected));
  }

  dismiss(item?: InstructionCategory) {
    this._modal.dismiss(item);
  }
}
