import { Injectable } from '@angular/core';
import { License } from '../models/license.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'licenses';

@Injectable({
  providedIn: 'root',
})
export class LicensesService {

  constructor(
    private firestore: FirestoreService,
  ) { }

  get(contractor: string, year: number, month: number) {
    return this.firestore.get<License>(itemCollection, `${contractor}-${year}-${month}`);
  }
}
