import { Room } from '../models/room.model';
import { SiteArea } from '../models/site-area.model';
import { User } from '../models/user.model';
import { defaultRoom } from './room';
import { cleanString } from './string';

export const defaultProjectName = 'Tuntityöt';

export function setSiteArea(room: Room, area: SiteArea, it: SiteArea) {
  room.siteArea = {
    root: area.guid,
    id: it.id,
    name: area.name !== it.name ? `${area.name} / ${it.name}` : `${area.name}`,
  };
  return room;
}

export function getLeaf(item: SiteArea): SiteArea[] {
  if (item.children.length === 0) {
    return [item];
  } else {
    return item.children.map((c) => getLeaf(c)).flat();
  }
}

export function findParent(parent: SiteArea, itemId: string): SiteArea {
  // If root level
  if (itemId === '0') {
    return parent;
  }
  let ret: SiteArea;
  const found = parent.children?.find((c) => c.id === itemId);
  if (found) {
    return parent;
  } else {
    parent.children?.forEach((c) => {
      const foundInChild = findParent(c, itemId);
      if (foundInChild) {
        ret = foundInChild;
      }
    });
  }
  return ret;
}

export function setAreaParents(area: SiteArea) {
  area.children.map((c) => {
    c.parent = { name: area.name, parent: area.parent };
    setAreaParents(c);
  });
}

export function getAreaName(area: SiteArea) {
  if (area.name === defaultRoom) {
    return '';
  }

  const ret: string[] = [area.name];
  let parent = area.parent;
  while (parent != null) {
    ret.push(parent.name);
    parent = parent.parent;
  }

  return ret.reverse().join(' / ');
}

export function getTicketGuid(siteGuid: string, roomId: string): string {
  return `${siteGuid}-${cleanString(roomId)}`;
}

export function getAreaTicketGuid(siteGuid: string, areaGuid: string, childAreaGuid: string, roomId: string, contractor: string): string {
  return `${siteGuid}-${contractor}-${areaGuid}-${childAreaGuid}-${cleanString(roomId)}`;
}

export function getManagerAreaTicketGuids(user: User, siteGuid: string, areaGuid: string, childAreaGuid: string, roomId: string) {
  return user.contractors.map((c) => getAreaTicketGuid(siteGuid, areaGuid, childAreaGuid, roomId, c));
}

export function parseAreaTicketGuid(guid: string) {
  const splitted = guid.split('-');
  if (splitted.length < 5) {
    return null;
  }

  return {
    siteGuid: splitted[0],
    contractorGuid: splitted[1],
    areaGuid: splitted[2],
    childAreaGuid: splitted[3],
    roomId: splitted[4],
  };
}

export function getAreaTicketGuids(areas: SiteArea[], contractor: string): string[] {
  return areas.map((area) => {
    const leafs = getLeaf(area);
    return leafs.map((it) => it.rooms.map((room) => getAreaTicketGuid(area.site, area.guid, it.id, room.id, contractor))).flat();
  }).flat();
}

export function getAreaTicketItems(areas: SiteArea[], contractor: string): { guid: string; room: Room; area: SiteArea }[] {
  return areas.map((area) => {
    const leafs = getLeaf(area);
    return leafs.map((it) => it.rooms.map((room) => {
      room = setSiteArea(room, area, it);
      return { guid: getAreaTicketGuid(area.site, area.guid, it.id, room.id, contractor), room, area };
    })).flat();
  }).flat();
}
