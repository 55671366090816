import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { filter, map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Contractor } from 'src/app/models/contractor.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles, roles } from 'src/app/utility/role';
import { capitalizeFirstLetter } from 'src/app/utility/string';

@Component({
  standalone: true,
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user.dialog.html',
  styleUrls: ['./invite-user.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class InviteUserDialogComponent {

  private sites$ = this.sitesService.getAdminList();

  formGroup: FormGroup;

  canBeDummy = signal(false);
  roleS = signal<Roles>(null);
  roles$ = this.usersService.getCurrentUser().pipe(
    map((user) => {
      if (user.role === Roles.superAdmin) {
        return [...roles, Roles.partner];
      } else if (user.role === Roles.partner) {
        this.formGroup.get('role').setValue(Roles.partner);
        return [Roles.partner];
      } else {
        return [...roles, Roles.supervisor];
      }
    }),
    shareReplay(1),
  );

  allWorksites$ = this.sites$.pipe(
    filter((sites) => sites.length > 0),
    map((sites) => sites.map((site) => {
      site.clientName = capitalizeFirstLetter(site.client);
      return { name: site.name, clientName: site.clientName, guid: site.guid };
    })),
    map((sites) => sites.sort(fieldSorter(['clientName', 'name']))),
    shareReplay(1),
  );

  contractors$ = this.contractorsService.getList();

  userRoleS = computed(() => this.usersService.currentUserS()?.role);

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.roleS.set(navParams.get('role') ?? null);
    const worksite = navParams.get('site');
    this.canBeDummy.set(navParams.get('dummy') ?? false);

    this.formGroup = this.formBuilder.group({
      displayName: ['', Validators.required],
      role: [this.roleS(), Validators.required],
      email: ['', [Validators.required, Validators.email]],
      worksites: [worksite ? [{ guid: worksite }] : []],
      phone: [''],
      address: this.formBuilder.group({
        street: [''],
        postalCode: [''],
        city: [''],
      }),
      contractors: [[]],
      rights: this.formBuilder.group({
        officer: [false],
        calendar: [false],
      }),
    });
  }

  saveContact() {
    const user = this.formGroup.value;
    if (![Roles.partner].includes(user.role)) {
      user.contractors = [this.contractorsService.contractorS()?.guid];
      user.worksites = user.worksites?.map((it) => it.guid) ?? [];
    }
    user.disabled = true;
    this._modal.dismiss(user);
  }

  selectContractor(items: Contractor[]) {
    const clearButton = this.formGroup.get('contractor').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('contractor').setValue(null);
          } else {
            this.formGroup.get('contractor').setValue([]);
          }
        }
      });
    });
  }

  onSave() {
    const user = this.formGroup.value;
    if (![Roles.partner].includes(user.role)) {
      if (user.role === Roles.supervisor) {
        user.supervisor = true;
        user.role = Roles.admin;
      }
      if (this.userRoleS() !== Roles.superAdmin) {
        user.contractors = [Roles.manager].includes(this.userRoleS())
          ? this.usersService.currentUserS().contractors : [this.contractorsService.contractorS()?.guid];
      }
      user.worksites = user.worksites?.map((it) => it.guid) ?? [];
    }
    this._modal.dismiss(user);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
