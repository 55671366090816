import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const path = `./assets/i18n/${environment.i18n}`;

export class CoreTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<object> {
    return this.http.get('.' + path + lang + '.json').pipe(
      map((langFile) => langFile),
      catchError((err) => {
        console.warn(err);
        return of(null);
      }),
    );
  }
}

export const createTranslateLoader =
  (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, path, '.json');

export const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useClass: CoreTranslateLoader,
    deps: [HttpClient],
  },
};
