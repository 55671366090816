@if (mobileView()) {
<div class="row">
  @for (item of items; track item) {
  @if (item.type === 'image') {
  <img drag-scroll-item
    [src]="item.url"
    (click)="onItem(item)" />
  } @else if (item.type === 'video') {
  <video drag-scroll-item
    controls
    playsinline
    preload="metadata">
    <source [src]="item.url + '#t=0.5'"
      type="video/mp4">
  </video>
  }
  }
</div>
} @else {
<drag-scroll [scrollbar-hidden]="true"
  [drag-scroll-y-disabled]="true">
  @for (item of items; track item) {
  @if (item.type === 'image') {
  <img drag-scroll-item
    [src]="item.url"
    (click)="onItem(item)" />
  } @else if (item.type === 'video') {
  <video drag-scroll-item
    controls
    playsinline
    preload="metadata">
    <source [src]="item.url + '#t=0.5'"
      type="video/mp4">
  </video>
  }
  }
</drag-scroll>
}