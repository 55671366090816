import { Component } from '@angular/core';
import { filter, shareReplay } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-login-icon',
  templateUrl: './login-icon.component.html',
  styleUrls: ['./login-icon.component.scss'],
})
export class LoginIconComponent {

  user$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    shareReplay(1),
  );

  constructor(
    private usersService: UsersService,
  ) { }
}
