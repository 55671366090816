import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { addHours } from 'date-fns';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { getDateWithTimezone, validateDates } from 'src/app/utility/time';

const initialStart = new Date();
initialStart.setMinutes(0, 0, 0);
const initialEnd = addHours(initialStart, 1);

@Component({
  standalone: true,
  selector: 'app-schedule-ticket-dialog',
  templateUrl: './schedule-ticket.dialog.html',
  styleUrls: ['./schedule-ticket.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class ScheduleTicketDialogComponent {

  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);

  formGroup = this.formBuilder.group({
    start: [getDateWithTimezone(initialStart), [Validators.required, validateDates]],
    end: [getDateWithTimezone(initialEnd), [Validators.required, validateDates]],
  });

  onTimeChange(isStart: boolean) {
    const controlStart = this.formGroup.get('start');
    const controlEnd = this.formGroup.get('end');
    const start = controlStart.value;
    const end = controlEnd.value;
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (startDate >= endDate) {
      if (isStart) {
        const newEndDate = addHours(startDate, 1);
        controlEnd.setValue(getDateWithTimezone(newEndDate));
        controlStart.updateValueAndValidity();
      } else {
        const newStartDate = addHours(endDate, -1);
        controlStart.setValue(getDateWithTimezone(newStartDate));
        controlStart.updateValueAndValidity();
      }
    }
  }

  save() {
    const { start, end } = this.formGroup.value;
    const startDate = new Date(start);
    const endDate = new Date(end);
    this._modal.dismiss({ start: startDate, end: endDate });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
