import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NewCategoryDialogComponent } from './new-category.dialog';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
  ],
  declarations: [
    NewCategoryDialogComponent,
  ],
})
export class NewCategoryInstructionModule { }
