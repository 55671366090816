<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ title | translate }}
  </div>
  <div [innerHTML]="text | translate"
    class="ion-padding"></div>
  @if (original) {
  <div class="ion-margin-top">
    {{ 'logs.original' | translate }}
    <div>
      {{ original.title }}
    </div>
    <div [innerHTML]="original.text | translate">
    </div>
  </div>
  }
  @if (button) {
  <div>
    <ion-button color="light"
      shape="round"
      (click)="dismiss(true)">
      <ion-icon slot="start"
        name="checkmark-outline"></ion-icon>
      {{ button | translate }}
    </ion-button>
  </div>
  }
</app-main-dialog>