@if ({
role: role$ | async,
isFree: isFreeLicence$ | async,
modules: modules$ | async,
siteGuid: siteGuid$ | async
}; as vm) {
  <app-segment-list [segmentValue]="segmentValue()"
    [segmentButtons]="segmentButtons"
    (segmentChanged)="segmentChanged($event)"></app-segment-list>
  @switch (segmentValue()) {
    @case ('main') {
      <div class="flex-wrap">
        <div>
          <div class="title">
            <h3>
              {{ ticket.name | translateTicket | async }}
              @if (room().id !== defaultRoom) {
                ({{ room().id }})
          }
            </h3>
            @if (ticket.guid) {
              <ion-note>
                {{ ticket.guid }}
              </ion-note>
            }
            @if (ticket.littera) {
              <ion-note>
                {{ 'item.littera' | translate }}: {{ ticket.littera.code }} {{ ticket.littera.name }}
              </ion-note>
            }
          </div>
          @if (ticket.subcontractor) {
            <div>
              <ion-note>
                {{ ticket.subcontractor.name }}
              </ion-note>
            </div>
          }
        </div>
        <div>
          @if (ticket.instruction) {
            <app-ticket-help-button [ticket]="ticket"></app-ticket-help-button>
          }
        </div>
      </div>
      <ng-template #loadingUsers>
        <ion-button shape="round"
          color="light">
          <app-loading></app-loading>
        </ion-button>
      </ng-template>
      <div class="history">
        @for (item of history(); track item) {
          <div class="ticket-block">
            <div class="ticket-container-item">
              <div class="progress-border-container">
                <div class="progress-border">
                  <ion-icon color="secondary"
                    name="ellipse"></ion-icon>
                  <div class="border"></div>
                </div>
              </div>
              <div class="speech-container">
                <ion-item class="ticket"
                  lines="none">
                  <div class="ion-padding-bottom-05 full-width">
                    <div class="flex">
                      @if (item.images?.length > 0 || item.videos?.length > 0) {
                        <app-images [showCamera]="null"
                          [images]="item.images"
                          [videos]="item.videos"></app-images>
                      }
                    </div>
                    <div class="text-container">
                      <div class="badge-container">
                        @if (item.label) {
                          <ion-badge [color]="item.labelColor">
                            {{ 'kanban.label.' + item.label | translate }}
                          </ion-badge>
                        }
                      </div>
                      <div class="ion-margin-top">
                        <ion-note>
                          {{ item.timestamp | date:'dd.MM.yyyy klo HH:mm' }}
                        </ion-note>
                      </div>
                      <h4>{{ item.text }}</h4>
                      <div class="ticket-name">
                        @if (item.blockedBy) {
                          <div>
                            {{ 'tickets.createdNewTicket' | translate }} {{ item.blockedBy.name }}.
                          </div>
                        }
                        @if (item.status === 'created') {
                          <p>
                            @if (ticket.descriptionText) {
                              {{ ticket.descriptionText | translateField }}
                            } @else if (ticket.description) {
                              {{ ticket.description }}
                            } @else {
                              <span class="empty-description">
                                {{ 'item.noDescription' | translate }}
                              </span>
                            }
                          </p>
                          @if (ticket.hours) {
                            <ion-chip color="danger">
                              {{ 'tickets.reservedTime' | translate }}: {{ ticket.hours }}h
                            </ion-chip>
                          }
                          @if (ticket.deadline) {
                            <ion-chip color="danger">
                              {{ 'kanban.dateDeadline' | translate }}: {{ ticket.deadline | date:'d.M.yyyy' }}
                            </ion-chip>
                          }
                          @if (ticket.schedule) {
                            <ion-chip color="danger">
                              {{ 'kanban.deadline' | translate }}:
                              {{ ticket.schedule.start | date:'d.M. HH:mm' }} -
                              {{ ticket.schedule.end | date:'d.M. HH:mm' }}
                            </ion-chip>
                          }
                        }
                        @if (item.commentText) {
                          <ion-note>{{ item.commentText | translateField }}</ion-note>
                        } @else if (item.comment) {
                          <ion-note>{{ item.comment }}</ion-note>
                        }
                      </div>
                      <div class="creator">
                        <ion-avatar>
                          @if (item.userImage; as image) {
                            <img [src]="image" />
                          } @else {
                            <ion-icon name="person-circle"></ion-icon>
                          }
                        </ion-avatar>
                        @if (item.username) {
                          <div>
                            {{ item.username }}
                          </div>
                        } @else {
                          @if (ticket.additionalWork && ticket.additionalWorkInfo?.client) {
                            <span>
                              {{ ticket.additionalWorkInfo.client }}
                            </span>
                          }
                        }
                      </div>
                    </div>
                  </div>
                </ion-item>
              </div>
            </div>
          </div>
          @if (item.status === 'created') {
            <div class="ticket-block">
              <div class="ticket-container-item">
                <div class="progress-border-container">
                  <div class="progress-border">
                    <ion-icon color="secondary"
                      name="ellipse"></ion-icon>
                    <div class="border"></div>
                  </div>
                </div>
                <div class="speech-container">
                  <ion-item class="ticket"
                    lines="none">
                    <div class="ion-padding-bottom-05 full-width">
                      <div class="text-container">
                        <div class="badge-container">
                          @if (item.label) {
                            <ion-badge [color]="item.labelColor">
                              {{ 'kanban.label.' + item.label | translate }}
                            </ion-badge>
                          }
                        </div>
                        @if (ticket.location) {
                          <img class="blueprint link"
                            [src]="ticket.location"
                            (click)="showLocation(ticket.location)" />
                        } @else if (room()?.blueprint) {
                          <img class="blueprint link"
                            [src]="room().blueprint"
                            (click)="showLocation(room())" />
                        } @else {
                          <h4>{{ 'site.missingBlueprint' | translate }}</h4>
                          <div class="ticket-name">
                            {{ 'site.missingBlueprintText' | translate }}
                          </div>
                        }
                      </div>
                    </div>
                  </ion-item>
                </div>
              </div>
            </div>
          }
        }
        <div class="ticket-block">
          <div class="ticket-container-item">
            <div class="progress-border-container">
              <div class="progress-border">
                <ion-icon color="secondary"
                  name="ellipse"></ion-icon>
                <div class="border"></div>
              </div>
            </div>
            <div class="speech-container">
              <ion-item class="ticket"
                lines="none">
                <div class="ion-padding-bottom-05 full-width text-container">
                  <div class="badge-container">
                    <ion-badge [color]="'light'">
                      {{ 'kanban.label.note' | translate }}
                    </ion-badge>
                  </div>
                  <div class="ion-margin-top">
                    <ion-note>
                      {{ today | date:'dd.MM.yyyy' }}
                    </ion-note>
                  </div>
                  <h4>{{ 'tickets.writeComment' | translate }}</h4>
                  <div></div>
                  <div class="ticket-name">
                    <ion-item lines="none">
                      <ion-input autocapitalize="on"
                        #note
                        [placeholder]="'additionalInfo.comment' | translate"></ion-input>
                    </ion-item>
                    <ion-button color="primary"
                      [disabled]="note.value?.toString().length === 0"
                      (click)="onNote(note)">
                      {{ 'tickets.saveComment' | translate }}
                    </ion-button>
                  </div>
                  <div class="creator">
                    <ion-avatar>
                      @if (user()?.image; as image) {
                        <img [src]="image" />
                      } @else {
                        <ion-icon name="person-circle"></ion-icon>
                      }
                    </ion-avatar>
                    @if (user()?.displayName; as name) {
                      <div>
                        {{ name }}
                      </div>
                    }
                  </div>
                </div>
              </ion-item>
            </div>
          </div>
        </div>
      </div>
      @if (ticket.additionalWork && !(ticket.additionalWorkInfo.adminTimestamp && ticket.additionalWorkInfo.clientTimestamp)) {
        <p>
          {{ 'kanban.additionalWorkInfoText' | translate }}
        </p>
      }
      @if (vm.role === 'admin' && ticket.additionalWorkInfo
      && !ticket.additionalWorkInfo.adminTimestamp && ticket.additionalWorkInfo.clientTimestamp) {
        <div class="check-buttons">
          <ion-button color="light"
            shape="round"
            (click)="onAdminAccept(ticket)">
            <ion-icon slot="start"
              name="checkmark-outline"></ion-icon>
            {{ 'general.accept' | translate }}
          </ion-button>
          <ion-button color="secondary"
            shape="round"
            (click)="onAdminToWork(ticket)">
            <ion-icon slot="start"
              name="close-outline"></ion-icon>
            {{ 'kanban.toWork' | translate }}
          </ion-button>
        </div>
      }
      @if (canJoin() && ticket.requests?.length > 0) {
        <div class="ion-margin-top">
          <div class="ion-margin-bottom">{{ 'kanban.acceptWorkers' | translate }}</div>
          @for (request of ticket.requests; track request) {
            <ion-button shape="round"
              color="light"
              (click)="acceptRequest(request)">
              {{ request.name }}
            </ion-button>
          }
        </div>
      }
    } @case ('attachments') {
      <h3>{{ 'site.attachments' | translate }}</h3>
      <app-attachments [attachments]="ticket.attachments"
        [isReadOnly]="['worker', 'storage'].includes(vm.role)"
        [autoSave]="true"
        (save)="saveAttachment($event)"></app-attachments>
    } @case ('productInfo') {
      {{ 'home.coming' | translate }}
    } @case ('settings') {
      <div class="ion-margin-bottom">
        @if (!vm.isFree) {
          <h4>{{ 'kanban.dateDeadline' | translate }}</h4>
          <app-select-day [disabled]="!canJoin()"
            [color]="'light'"
            [format]="'d.M.'"
            [value]="ticket.deadline"
            (changed)="onDeadlineChange($event)"></app-select-day>
          @if (ticket.schedule) {
            <h4>{{ 'kanban.deadline' | translate }}</h4>
            <ion-button shape="round"
              color="light"
              expand="block">
              {{ ticket.schedule.start | date:'d.M. HH:mm' }}<br />-<br />{{ ticket.schedule.end | date:'d.M. HH:mm' }}
            </ion-button>
          }
          @if ({
            users: users$ | async
          }; as usersVm) {
            <h4>{{ 'calendar.site.user' | translate }}</h4>
            <ion-button [disabled]="!(vm.role === 'admin' || isSiteLead())"
              shape="round"
              color="light"
              expand="block"
              (click)="selectWorker(usersVm.users)">
              @if (ticket.users?.length === 1) {
                <div>
                  {{ ticket.usernames[0] }}
                </div>
              } @else if (ticket.users?.length > 0) {
                {{ ticket.users.length }} {{ 'kanban.workers' | translate }}
              } @else {
                {{ 'kanban.chooseWorker' | translate }}
              }
            </ion-button>
          }
          @if (room().id !== defaultRoom) {
            <h4>{{ 'calendar.site.area' | translate }}</h4>
            <ion-button color="light"
              shape="round"
              expand="block"
              (click)="selectRoom()">
              <div>
                @if (room().id) {
                  {{ room().id }}
                } @else {
                  {{ room().siteArea?.name }}
                }
              </div>
            </ion-button>
          }
          @if (vm.modules && vm.modules[appModules.workMachines]) {
            <h4>{{ 'nav.workMachines' | translate }}</h4>
            <ion-button shape="round"
              color="light"
              expand="block"
              (click)="onWorkMachine(vm.modules, [])">
              @if (ticket.workMachine) {
                <div>
                  {{ ticket.workMachine.name }}
                </div>
              } @else {
                {{ 'nav.workMachines' | translate }}
              }
            </ion-button>
          }
          @if (siteClassification$ | async; as classification) {
            <h4>{{ 'item.littera' | translate }}</h4>
            <ion-button shape="round"
              color="light"
              expand="block"
              (click)="selectLittera(classification)">
              @if (ticket.littera) {
                {{ ticket.littera.code }} - {{ ticket.littera.name }}
              } @else {
                {{ 'item.littera' | translate }}
              }
            </ion-button>
          }
        } @else {
          <app-free-select-worker [disabled]="!(vm.role === 'admin' || isSiteLead())"
            [color]="'light'"
            [site]="vm.siteGuid"
            [selectedUsers]="ticket.usernames"
            [ticket]="{ parent: parentGuid(), roomTicket: ticket.guid }"
            (selected)="onWorker($event)"></app-free-select-worker>
        }
      </div>
      @if (['admin', 'manager'].includes(vm.role) || !vm.isFree) {
        <h4>{{ 'nav.materials.manage' | translate }}</h4>
        @if (ticket?.plainName != null) {
          <ion-button shape="round"
            color="secondary"
            expand="block"
            (click)="onCopy(ticket)">
            {{ 'general.copy' | translate }}
          </ion-button>
        }
        <ion-button color="light"
          expand="block"
          (click)="onEditName(ticket)">
          {{ 'site.ai.newName' | translate }}
        </ion-button>
        <ion-button color="light"
          expand="block"
          (click)="onEditDescription(ticket)">
          {{ 'calendar.site.edit.description' | translate }}
        </ion-button>
      }
      <h4>{{ 'calendar.site.duration' | translate }}</h4>
      <ion-button id="duration"
        color="secondary"
        expand="block"
        shape="round">
        @if (ticket.hours; as duration) {
          <span>
            {{ duration }}h
          </span>
        } @else {
          0h
        }
      </ion-button>
      <ion-modal #durationModal
        class="picker-modal"
        trigger="duration"
        [keepContentsMounted]="true">
        <ng-template>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button (click)="durationModal.dismiss()">
                {{ 'general.cancel' | translate }}
              </ion-button>
            </ion-buttons>
            <ion-buttons>
              <ion-button color="danger"
                (click)="onDuration(0); durationModal.dismiss()">
                {{ 'general.reset' | translate }}
              </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <ion-button (click)="onDuration(durationPicker.value); durationModal.dismiss()">
                {{ 'general.select' | translate }}
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-picker>
            <ion-picker-column #durationPicker
              [value]="ticket.hours">
              @for (duration of pickerColumns; track duration.value) {
                <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
              }
            </ion-picker-column>
          </ion-picker>
        </ng-template>
      </ion-modal>
      @if (canDelete()) {
        <div>
          <ion-button shape="round"
            color="danger"
            (click)="onDelete()">
            {{ 'general.delete' | translate }}
          </ion-button>
        </div>
      }
    } @case ('images') {
      @for (item of images(); track $index) {
        <div class="ion-margin-bottom">
          <app-image-item [item]="item"
            (klick)="onImage(item.image)"></app-image-item>
        </div>
      }
      @for (media of videos(); track $index) {
        @if (media.type === 'image') {
          <img class="link media"
            [src]="media.url"
            (click)="onImage(media.url)" />
        } @else if (media.type === 'video') {
          <video class="link media"
            preload="metadata">
            <source [src]="media.url + '#t=0.5'"
              type="video/mp4">
          </video>
        }
      }
      <div class="flex-center">
        <app-camera-button class="only-camera"
          [name]="ticket.name"
          color="light"
          fill="clear"
          [multiline]="true"
          (imageUrl)="onImageUrl($event)"></app-camera-button>
        <app-take-video class="only-camera"
          [name]="ticket.name"
          color="light"
          fill="clear"
          [multiline]="true"
          (imageUrl)="videoUrl($event)"></app-take-video>
      </div>
    }
    }
    @if (segmentValue() === 'main') {
      <div class="footer">
        <div>
          <div class="to-progress">
            @if (ticket.planningObservation) {
              @if (ticket.status === statuses.created) {
                <ion-button shape="round"
                  (click)="toHandled()">
                  {{ 'kanban.toDone' | translate }}
                </ion-button>
              }
            } @else {
              @if (canEdit() && canJoin() && [statuses.created, statuses.paused].includes(ticket.status)
                && !['manager'].includes(vm.role)) {
                <ion-button shape="round"
                  [disabled]="ticket.additionalWork
                  && !(ticket.additionalWorkInfo.adminTimestamp && ticket.additionalWorkInfo.clientTimestamp)"
                  (click)="toProgress()">
                  {{ 'kanban.toProgress' | translate }}
                </ion-button>
              }
              @if ((canEdit() && canJoin() && ticket.status === statuses.inProgress)
            || (['manager'].includes(vm.role) && ![statuses.done, statuses.checked].includes(ticket.status))) {
                <ion-button shape="round"
                  (click)="toDone()">
                  {{ 'kanban.toDone' | translate }}
                </ion-button>
              }
              @if ((canJoin() && ticket.status === statuses.done) || (vm.role === 'admin' && ticket.status === statuses.checked)) {
                <ion-button shape="round"
                  color="secondary"
                  (click)="toProgress(true)">
                  {{ 'kanban.backProgress' | translate }}
                </ion-button>
              }
              @if ((vm.role === 'manager' || vm.role === 'admin' || isSiteLead()) && ticket.status === statuses.done) {
                <ion-button shape="round"
                  (click)="checked()">
                  {{ 'kanban.check' | translate }}
                </ion-button>
              }
              @if (canEdit() && canJoin() && ticket.status === statuses.inProgress) {
                <ion-button shape="round"
                  color="secondary"
                  (click)="toBacklog()">
                  {{ 'kanban.toBacklog' | translate }}
                </ion-button>
              }
              @if (canEdit() && ticket.status === statuses.inProgress && canJoin()) {
                <app-block-button [room]="room()"
                  [ticket]="ticket"
                  [siteGuid]="vm.siteGuid"
                  (save)="toBlocked(vm.siteGuid, $event)">
                </app-block-button>
              }
              @if (canEdit() && ticket.status === statuses.blocked && canJoin()) {
                <ion-button shape="round"
                  (click)="toProgress(false, true)">
                  {{ 'kanban.continue' | translate }}
                </ion-button>
              }
              @if (!canJoin() && ticket.status !== statuses.checked) {
                @if (asked()) {
                  <ion-button shape="round"
                    color="secondary"
                    disabled="true">
                    {{ 'kanban.asked' | translate }}
                  </ion-button>
                } @else {
                  <ion-button shape="round"
                    color="secondary"
                    (click)="askToJoin()">
                    {{ 'kanban.askJoin' | translate }}
                  </ion-button>
                }
              }
              @if (vm.role === 'super-admin' && ticket.status === statuses.paused) {
                <ion-button shape="round"
                  color="secondary"
                  (click)="removePaused()">
                  {{ 'kanban.removePaused' | translate }}
                </ion-button>
              }
            }
          </div>
        </div>
      </div>
    }
    }