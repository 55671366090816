import { appModules } from '../services/licence.service';
import { Roles } from '../utility/role';
import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const users: MenuSubItem[] = [
  {
    icon: 'people',
    route: '/schedule',
    name: 'nav.schedule',
    module: appModules.users,
  },
  {
    icon: 'people',
    route: '/users',
    name: 'nav.userManagement',
    roles: [Roles.admin],
  },
  {
    icon: 'people',
    route: '/paycheck/worklist',
    name: 'nav.worklist',
    module: appModules.users,
  },
  {
    icon: 'people',
    route: '/paycheck',
    name: 'nav.paycheckAdmin',
    roles: [Roles.admin],
    module: appModules.users,
  },
  {
    icon: 'people',
    route: '/paycheck/notes',
    name: 'nav.paycheckNotes',
    roles: [Roles.admin],
    module: appModules.users,
  },
  {
    icon: 'people',
    route: '/logs',
    name: 'nav.notices',
    queryParams: { tabs: 'users' },
    module: appModules.users,
  },
  {
    icon: 'calendar-number-outline',
    name: 'nav.workingCalendar',
    route: '/user-week-calendar',
    module: appModules.users,
  },
];

export const products: MenuSubItem[] = [
  {
    icon: 'cube',
    route: '/materials/order',
    name: 'nav.materials.order',
    module: appModules.products,
  },
  {
    icon: 'cube',
    route: '/materials/process',
    name: 'nav.materials.title',
    module: appModules.products,
  },
  {
    icon: 'cube',
    route: '/materials/products',
    name: 'nav.materials.manage',
    roles: [Roles.admin],
    module: appModules.products,
  },
];

export const workMachines: MenuSubItem[] = [
  {
    icon: 'bus',
    route: '/schedule/machines',
    name: 'nav.schedule',
    module: appModules.workMachines,
  },
  {
    icon: 'bus',
    route: '/work-machines',
    name: 'nav.workMachinesManage',
    roles: [Roles.admin],
    module: appModules.workMachines,
  },
];

export const reports: MenuSubItem[] = [
  {
    icon: 'analytics',
    route: '/efficiency',
    name: 'nav.efficiency',
    module: appModules.reporting,
  },
  {
    icon: 'analytics',
    route: '/report/site',
    name: 'nav.sitereportadmin',
    module: appModules.reporting,
  },
  {
    icon: 'analytics',
    route: '/logs',
    name: 'nav.userNotes',
    queryParams: { tabs: 'sites' },
    module: appModules.reporting,
  },
  {
    icon: 'analytics',
    route: '/report/locations',
    name: 'nav.locations',
    module: appModules.reporting,
  },
  {
    icon: 'analytics',
    route: '/profit',
    name: 'nav.profit',
    roles: [Roles.admin],
    module: appModules.reporting,
  },
];

export const extras: MenuSubItem[] = [
  {
    icon: 'cog',
    route: '/templates',
    name: 'nav.templates',
    module: appModules.essentials,
  },
  {
    icon: 'cog',
    route: '/options',
    name: 'nav.options',
    roles: [Roles.admin],
    module: appModules.users,
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'albums',
    route: '/clients',
    name: 'nav.clients',
  },
  {
    icon: 'business',
    name: 'nav.sites',
    route: '/worker/sites',
  },
  {
    icon: 'document',
    name: 'sites.tickets',
    route: '/worker/tickets',
  },
  {
    icon: 'people',
    name: 'nav.personel',
    children: users,
  },
  {
    icon: 'list',
    route: '/kanban-dashboard',
    name: 'nav.kanban',
    module: appModules.essentials,
  },
  {
    icon: 'calendar',
    route: '/calendar',
    name: 'nav.projectCalendar',
    module: appModules.essentials,
  },
  {
    icon: 'pie-chart',
    route: '/dashboard',
    name: 'nav.dashboard',
    module: appModules.essentials,
  },
  {
    icon: 'newspaper',
    route: '/news',
    name: 'nav.news',
    module: appModules.essentials,
  },
  {
    icon: 'time',
    route: '/report/user',
    name: 'nav.userreportadmin',
    module: appModules.reporting,
  },
  {
    icon: 'cube',
    name: 'nav.materials.products',
    children: products,
    module: appModules.products,
  },
  {
    icon: 'bus',
    name: 'nav.workMachines',
    children: workMachines,
    module: appModules.workMachines,
  },
  {
    icon: 'analytics',
    name: 'nav.reports',
    children: reports,
    module: appModules.reporting,
  },
  {
    icon: 'cog',
    name: 'nav.extras',
    children: extras,
  },
];

export const adminMenu = {
  items,
};
