import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, shareReplay, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { AreasService } from 'src/app/services/areas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlusService } from 'src/app/services/plus.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-stripe-success-page',
  templateUrl: './success.page.html',
  styleUrls: ['./success.page.scss'],
  imports: [
    AppCommonModule,
    IonicModule,
    CommonModule,
    MainPageComponent,
  ],
})
export class StripeSuccessPage {
  private areasService = inject(AreasService);
  private plusService = inject(PlusService);
  private route = inject(ActivatedRoute);
  private navigationService = inject(NavigationService);
  private sitesService = inject(SitesService);
  private usersService = inject(UsersService);

  params$ = combineLatest([this.route.queryParams, this.sitesService.getActiveList()]).pipe(
    filter(([_, sites]) => sites?.length > 0),
    debounceTime(1000),
    tap(([params, sites]) => {
      const user = params['user'];
      this.usersService.enable(user);
      if (params['parent'] === 'undefined') {
      } else if (params['parent'] !== 'null') {
        this.areasService.openRoomTicket(params['parent'], params['ticket'], true);
      } else {
        this.plusService.onAdHoc(sites, null, null, true);
      }
    }),
    tap(async () => this.navigationService.navigateToHome()),
    shareReplay(1),
  );
}
