<app-main-dialog [onlyMobile]="true"
  (dismiss)="dismiss(true)">
  <div desktopTitle></div>
  <div class="flex flex-center header">
    <div class="logo">
      <img src="./assets/images/logo.png" />
    </div>
    <h4>{{ 'nav.app' | translate }}</h4>
    @if (version(); as version) {
      <ion-note>Version {{ version }}</ion-note>
    }
  </div>
  <div class="container">
    <div class="inner-container">
      <div class="nav-container">
        <div class="desktop title">
          <h4>{{ 'nav.selectAction' | translate }}</h4>
          <ion-button color="light"
            shape="round"
            (click)="dismiss(true)">
            {{ 'general.close' | translate }}
          </ion-button>
        </div>
        <div class="nav">
          @if (selectedItem() == null) {
            @switch (role()) {
              @case ('admin') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.admin.items }">
                </ng-container>
              } @case ('supervisor') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.supervisor.items }">
                </ng-container>
              } @case ('worker') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.worker.items }">
                </ng-container>
              } @case ('super-admin') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.superAdmin.items }">
                </ng-container>
              } @case ('manager') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.manager.items }">
                </ng-container>
              } @case ('storage') {
                <ng-container [ngTemplateOutlet]="itemsTemplate"
                  [ngTemplateOutletContext]="{ items: appMenu.storage.items }">
                </ng-container>
              }
            }
          } @else {
            <ng-container [ngTemplateOutlet]="itemsTemplate"
              [ngTemplateOutletContext]="{ items: selectedItem().children }">
            </ng-container>
          }
        </div>
      </div>
      <div class="desktop flex">
        <h4>{{ 'nav.extraInfo' | translate }}</h4>
      </div>
      <div class="flex list">
        @if (['admin'].includes(role())) {
          @if (contractor()) {
            <ion-item button
              lines="none"
              detail="false"
              (click)="navigate(['/contractors', contractor().guid])">
              <ion-icon slot="start"
                name="key"></ion-icon>
              <div class="ellipsis">{{ contractor().name }}</div>
            </ion-item>
          }
        }
        <ion-item button
          lines="none"
          detail="false"
          (click)="navigate(['/info'])">
          <ion-icon slot="start"
            name="information-circle"></ion-icon>
          {{ 'nav.info' | translate }}
        </ion-item>
        <ion-item button
          lines="none"
          detail="false"
          (click)="navigate(['/instructions'])">
          <ion-icon slot="start"
            name="help-circle"></ion-icon>
          {{ 'help.title' | translate }}
        </ion-item>
      </div>
    </div>
  </div>
</app-main-dialog>
<ng-template #itemsTemplate
  let-items="items">
  @for (item of items; track item.name) {
    <div class="nav-item link"
      [class.disabled]="item.module && !modules[item.module]"
      (click)="onItem(item)">
      <ion-icon [name]="item.icon"></ion-icon>
      <div>
        {{ item.name | translate }}
      </div>
    </div>
  }
</ng-template>