import { Injectable } from '@angular/core';
import { SmoothScrollElement, SmoothScrollToElementOptions } from 'ngx-scrollbar/smooth-scroll';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {

  private scroll$ = new ReplaySubject<{ element: SmoothScrollElement; options?: SmoothScrollToElementOptions }>(1);
  scrollToTop$ = new ReplaySubject<number>(1);

  scrollTo(elementId: SmoothScrollElement, options?: SmoothScrollToElementOptions) {
    this.scroll$.next({ element: elementId, options });
  }

  scrollToTop() {
    this.scrollToTop$.next(0);
  }

  getScrollElement() {
    return this.scroll$.asObservable();
  }
}
