import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { shareReplay } from 'rxjs';
import { FreeLicenseDialogComponent } from 'src/app/contractors/free-license/free-license.dialog';
import { ListItem } from 'src/app/models/list-item.model';
import { ContractorsService } from 'src/app/services/contractors.service';
import { LicenseService } from 'src/app/services/licence.service';
import { MaterialCategoryService } from 'src/app/services/material-category.service';
import { NewCategoryDialogComponent } from '../new-category/new-category.dialog';

@Component({
  selector: 'app-new-material-dialog',
  templateUrl: './new-material.dialog.html',
  styleUrls: ['./new-material.dialog.scss'],
})
export class NewMaterialDialog implements OnInit {

  existing: ListItem;
  categories$ = this.materialCategoryService.getList().pipe(
    shareReplay(1),
  );

  formGroup: FormGroup;

  imgName = new Date().getTime().toString();

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private licenseService: LicenseService,
    private materialCategoryService: MaterialCategoryService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    navParams: NavParams,
  ) {
    this.existing = navParams.get('material');
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      guid: [this.existing?.guid ?? ''],
      name: [this.existing?.name ?? '', Validators.required],
      img: [this.existing?.img ?? null],
      category: [this.existing?.category ?? '', Validators.required],
      price: [this.existing?.price ?? null, Validators.required],
      retailPrice: [this.existing?.retailPrice ?? null],
      amount: [this.existing?.amount ?? null],
      contractor: [this.existing?.contractor ?? this.contractorsService.contractorS()?.guid],
    });
  }

  removeImage() {
    this.formGroup.get('img').setValue(null);
  }

  onImageUrl(imageUrl: string) {
    this.formGroup.get('img').setValue(imageUrl);
  }

  onCategory() {
    const { category } = this.formGroup.value;
    if (category === null) {
      this.modalCtrl.create({ component: NewCategoryDialogComponent }).then((m) => {
        m.present();

        m.onDidDismiss<ListItem>().then((data) => {
          if (data.data) {
            this.materialCategoryService.save(data.data).then(() => this.formGroup.get('category').setValue(data.data.name));
          } else {
            this.formGroup.get('category').setValue('');
          }
        });
      });
    }
  }

  async save(isFree: boolean) {
    if (isFree) {
      return this.modalCtrl.create({ component: FreeLicenseDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
    }
    const item = this.formGroup.value;
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
