import { Injectable } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { LocalNotifications } from '@capacitor/local-notifications';
import { ModalController } from '@ionic/angular';
import { NotificationDialogComponent } from './dialog/notification.dialog';

interface INotificationCallback {
  (): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  private dialogOpen: boolean = false;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  private async isPrompt(): Promise<boolean> {
    try {
      const permission = await LocalNotifications.checkPermissions();
      return permission.display === 'prompt' || permission.display === 'prompt-with-rationale';
    } catch {
      return false;
    }
  }

  private async isGranted() {
    const permission = await LocalNotifications.checkPermissions();
    return permission.display === 'granted';
  }

  public async checkAndAskPermission(): Promise<void> {
    const permissionPrompt = await this.isPrompt();
    if (permissionPrompt && !this.dialogOpen) {
      const m = await this.modalCtrl.create({ component: NotificationDialogComponent });
      m.present().then(() => this.dialogOpen = true);
      await m.onDidDismiss();
      this.dialogOpen = false;
    }
  }

  public ask(callback: INotificationCallback) {
    LocalNotifications.requestPermissions().then(() => callback());
  }

  public async scheduleNotification(title: string, body: string, group?: string, at?: Date) {
    const permission = await this.isGranted();
    if (permission) {
      const date = new Date();
      const id = +`${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getMilliseconds()}`;
      await LocalNotifications.schedule({
        notifications: [{
          id,
          title,
          body,
          group,
          schedule: {
            at,
          },
          autoCancel: true,
        }],
      });
      LocalNotifications.addListener('localNotificationReceived', (notification) => {
        if (!this.dialogOpen) {
          this.dialogOpen = true;
          Dialog.alert({
            title: notification.title,
            message: notification.body,
          }).finally(() => this.dialogOpen = false);
        }
      });
      return id;
    }
  }

  public async cancel(id: number) {
    return LocalNotifications.cancel({
      notifications: [{ id }],
    });
  }

  public async removeListeners() {
    return LocalNotifications.removeAllListeners();
  }
}
