import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { RoomComponent } from './room.component';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, AppCommonModule],
  declarations: [RoomComponent],
  exports: [RoomComponent],
})
export class RoomModule { }
