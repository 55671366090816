import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CameraButtonModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { ImagesModule } from 'src/app/components/images/images.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { NewItemDialogComponent } from './new-item.dialog';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    ImagesModule,
    CameraButtonModule,
    TakeVideoComponent,
    MainDialogComponent,
  ],
  declarations: [
    NewItemDialogComponent,
  ],
})
export class NewItemInstructionModule { }
