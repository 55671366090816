<app-main-page>
  <div desktopTitle>
    {{ 'nav.invoicing' | translate }}
  </div>
  <div>
    <app-month-switch class="no-print"
      [current]="currentDate()"
      (monthChange)="changeMonth($event)"></app-month-switch>
  </div>
  @if (paid$ | async; as usage) {
  <div>
    <h3>
      {{ 'contractor.invoice.paid' | translate }}
    </h3>
  </div>
  @for (item of usage; track item.guid) {
  <div class="grid">
    <span>{{ item.name }}</span>
    <span>
      <ion-button fill="clear"
        color="light"
        shape="round"
        (click)="openUsers(item.users)">
        {{ item.count }} {{ 'sites.workers.qty' | translate }}
        <ion-icon slot="end"
          name="people-circle-outline"></ion-icon>
      </ion-button>
    </span>
    <span>{{ item.price }} €</span>
    <span>{{ item.total }} €</span>
  </div>
  } @empty {
  <div>
    <app-no-results></app-no-results>
  </div>
  }
  }
  @if (free$ | async; as usage) {
  <div>
    <h3>
      {{ 'contractor.invoice.free' | translate }}
    </h3>
  </div>
  @for (item of usage; track item.guid) {
  <div class="grid">
    <span>{{ item.name }}</span>
    <span>
      <ion-button fill="clear"
        color="light"
        shape="round"
        (click)="openUsers(item.users)">
        {{ item.count }} {{ 'sites.workers.qty' | translate }}
        <ion-icon slot="end"
          name="people-circle-outline"></ion-icon>
      </ion-button>
    </span>
    <span>N/A</span>
    <span>N/A</span>
  </div>
  } @empty {
  <div>
    <app-no-results></app-no-results>
  </div>
  }
  }
</app-main-page>