import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { LicensesComponent } from 'src/app/components/licenses/licenses.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Feedback } from 'src/app/models/feedback.model';
import { FeedbacksService } from 'src/app/services/feedback.service';
import { LicenseService, appModules, modulePrices } from 'src/app/services/licence.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-free-license-dialog',
  templateUrl: './free-license.dialog.html',
  styleUrls: ['./free-license.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    LicensesComponent,
    MainDialogComponent,
  ],
})
export class FreeLicenseDialogComponent {

  formGroup: FormGroup;

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  availableModules = Object.keys(appModules).map((it, index) => ({ name: it, price: modulePrices[index] }));

  constructor(
    private feedbacksService: FeedbacksService,
    private licenseService: LicenseService,
    private _modal: ModalController,
    private usersService: UsersService,
    formBuilder: FormBuilder,
  ) {
    const user = this.usersService.currentUserS();
    this.formGroup = formBuilder.group({
      name: [user.displayName, Validators.required],
      phone: [user.phone ?? ''],
      email: [user.email ?? '', Validators.email],
    });
  }

  sendInfo() {
    const user = this.usersService.currentUserS();
    const { name, email, phone } = this.formGroup.value;
    const item: Feedback = {
      title: 'Lisenssipaketti',
      feedback: `Email: ${email as string} phone: ${phone as string}`,
      user: user.guid,
      username: name,
      date: new Date(),
      checked: false,
      contractor: user.contractors[0],
      help: true,
    };
    this.feedbacksService.save(item).then(() => this.dismiss());
  }

  dismiss() {
    this._modal.dismiss();
  }
}
