@if (!hidden()) {
@if (isLoading()) {
<ion-tab-button class="loading-container">
  <app-loading></app-loading>
</ion-tab-button>
} @else {
@if (sites$ | async; as sites) {
<ion-tab-button (click)="onClick(sites, $event)">
  <ion-icon name="add-circle"
    aria-hidden="true"></ion-icon>
</ion-tab-button>
}
}
}