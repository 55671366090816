@if ({
  startDate: selectedStartDate$ | async,
  endDate: selectedEndDate$ | async
}; as vm) {
  <div class="filters">
    <ion-searchbar #filter
      mode="ios"
      [placeholder]="'general.filterBy' | translate"
      (ionInput)="onFilter(filter)"></ion-searchbar>
    <div class="flex">
      <app-select-day [id]="'allStartDateButton'"
        [color]="undefined"
        [expand]="'block'"
        [format]="'d.M.'"
        [size]="'small'"
        [name]="'kanban.selectDay' | translate"
        [value]="vm.startDate"
        (changed)="onDateChange($event, true)"></app-select-day>
      -
      <app-select-day [id]="'allEndDateButton'"
        [color]="undefined"
        [expand]="'block'"
        [format]="'d.M.'"
        [size]="'small'"
        [name]="'kanban.selectDay' | translate"
        [value]="vm.endDate"
        (changed)="onDateChange($event, false)"></app-select-day>
    </div>
  </div>
}
@for (ticket of tickets$ | async; track ticket) {
  <ion-item button="true"
    lines="none"
    (click)="onSelectTicket(ticket)">
    <ion-thumbnail slot="start">
      @if (ticket.preview$ | async; as preview) {
        <img [src]="preview" />
      } @else {
        <img src="./assets/images/default-loading-image.png" />
      }
    </ion-thumbnail>
    <div>
      {{ ticket.name | translateTicket | async }}
      <div>
        <ion-note>
          {{ ticket.roomName }}
        </ion-note>
      </div>
      <div>
        {{ ticket.date | date:'d.M.yyyy' }}
      </div>
    </div>
    @if (ticket.selected) {
      <ion-icon slot="end"
        name="checkbox-outline"></ion-icon>
    } @else {
      <ion-icon slot="end"
        name="square-outline"></ion-icon>
    }
  </ion-item>
}