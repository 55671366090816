import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { map, shareReplay } from 'rxjs/operators';
import { InstructionCategory } from 'src/app/models/instruction-category.model';
import { InstructionsService } from 'src/app/services/instructions.service';
import { SelectCategoryDialogComponent } from './select-category/select-category.dialog';

@Component({
  selector: 'app-new-category-dialog',
  templateUrl: './new-category.dialog.html',
  styleUrls: ['./new-category.dialog.scss'],
})
export class NewCategoryDialogComponent {

  categories$ = this.instructions.getCategories().pipe(
    map((items) => items?.sort(fieldSorter(['name'])) ?? []),
    shareReplay(1),
  );

  formGroup: FormGroup;

  constructor(
    private instructions: InstructionsService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    formBuilder: FormBuilder,
    navParams: NavParams,
  ) {
    const item = navParams.get('item');

    this.formGroup = formBuilder.group({
      guid: [item?.guid ?? null],
      name: [item?.name ?? '', Validators.required],
      parent: [item?.parent ?? null],
    });
  }

  selectCategory(items: InstructionCategory[]) {
    const clearButton = this.formGroup.get('parent').value != null;
    this.modalCtrl.create({ component: SelectCategoryDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss<InstructionCategory>().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('parent').setValue(null);
          } else {
            const selected = { name: data.data.name, guid: data.data.guid };
            this.formGroup.get('parent').setValue(selected);
          }
        }
      });
    });
  }

  onSave() {
    const value = this.formGroup.value;
    if (value.guid) {
      this.instructions.updateCategory(value).then(() => this.dismiss());
    } else {
      this.instructions.saveCategory(value).then(() => this.dismiss());
    }
  }

  dismiss() {
    this._modal.dismiss();
  }
}
