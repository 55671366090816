@if (lang$ | async; as currentLang) {
<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ item.name[currentLang] }}
  </div>
  <div>
    <h3>{{ 'help.model' | translate }}</h3>
    <video controls>
      <source [src]="item.model"
        type="video/mp4">
    </video>
    <h3>{{ 'help.execute' | translate }}</h3>
    <p>{{ item.execute[currentLang] }}</p>
    <h3>{{ 'help.checklist' | translate }}</h3>
    <ul>
      @for (i of item.checklist; track i) {
      <li>
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        {{ i[currentLang] }}
      </li>
      }
    </ul>
    <h3>{{ 'help.safety' | translate }}</h3>
    <p>{{ item.safety[currentLang] }}</p>
    <h3>{{ 'help.quality' | translate }}</h3>
    <p>{{ 'help.qualityText' | translate }}</p>
    <div class="images">
      @for (img of item.quality; track img) {
      <img class="link"
        [src]="img" />
      }
    </div>
  </div>
</app-main-dialog>
}