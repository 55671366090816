<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'room.editArea' | translate }}
  </div>
  <form [formGroup]="formGroup">
    <h4>{{ 'room.name' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <h4>{{ 'room.size' | translate }}</h4>
    <ion-item lines="none">
      <ion-input type="number"
        formControlName="size">
        <span slot="end">m<sup>2</sup></span>
      </ion-input>
    </ion-item>
    <h4>{{ 'room.volume' | translate }}</h4>
    <ion-item lines="none">
      <ion-input type="number"
        formControlName="volume">
        <span slot="end">m<sup>3</sup></span>
      </ion-input>
    </ion-item>
    <h4>{{ 'room.height' | translate }}</h4>
    <ion-item lines="none">
      <ion-input type="number"
        formControlName="height">
        <span slot="end">m</span>
      </ion-input>
    </ion-item>
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        type="submit"
        [disabled]="formGroup?.invalid"
        (click)="save()">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>