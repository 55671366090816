import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feedback } from '../models/feedback.model';
import { IService } from '../models/service.interface';
import { setDates } from '../utility/time';
import { FirestoreService } from './firestore.service';

const itemCollection = 'feedbacks';

@Injectable({
  providedIn: 'root',
})
export class FeedbacksService implements IService<Feedback> {
  constructor(
    private firestore: FirestoreService,
  ) { }

  async save(item: Feedback) {
    return this.firestore.save(itemCollection, item);
  }

  async update(item: Feedback) {
    return this.firestore.update(itemCollection, item);
  }

  get(_guid: string): Observable<Feedback> {
    throw Error('Not implemented get feedback');
  }

  getList(options?: { user?: string; contractor?: string; help?: boolean }): Observable<Feedback[]> {
    const queryConstraints = [];
    if (options?.user) {
      queryConstraints.push(where('user', '==', options.user));
    }
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }

    return this.firestore.getList<Feedback>(itemCollection, undefined, queryConstraints).pipe(
      map((results) => results.map((res) => setDates<Feedback>(res, 'date'))),
      map((results) => results.filter((it) => (!options?.help && !it.help) || (options?.help && it.help))),
    );
  }
}
