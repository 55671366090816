import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SiteProject } from 'src/app/models/site-project.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';

@Component({
  standalone: true,
  selector: 'app-chain-projects-dialog',
  templateUrl: './chain-projects.dialog.html',
  styleUrls: ['./chain-projects.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class ChainProjectsDialogComponent {
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);

  doneBefore = signal<SiteProject>(null);
  doneAfter = signal<SiteProject>(null);

  selectProject(isBefore: boolean) {
    const items = this.navParams.get('projects');
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (isBefore) {
            this.doneBefore.set(data.data);
          } else {
            this.doneAfter.set(data.data);
          }
        }
      });
    });
  }

  save() {
    this._modal.dismiss({ before: this.doneBefore(), after: this.doneAfter() });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
