import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SiteReview } from 'src/app/models/site-review.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { AreasService } from 'src/app/services/areas.service';
import { PlusService } from 'src/app/services/plus.service';
import { SiteReviewsService } from 'src/app/services/site-reviews.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-site-review-dialog',
  templateUrl: './review.dialog.html',
  styleUrls: ['./review.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class SiteReviewDialogComponent {
  private areasService = inject(AreasService);
  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);
  private plusService = inject(PlusService);
  private siteReviewsService = inject(SiteReviewsService);
  private usersService = inject(UsersService);

  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    created: [new Date()],
    creator: [{ guid: this.usersService.currentUserS().guid, name: this.usersService.currentUserS().displayName }],
    site: [this.navParams.get('site').guid],
    tickets: [Array.from<SubItem>([])],
  });

  addNewTicket() {
    this.plusService.onAdHoc([this.navParams.get('site')], null, null, false, true).then((tickets) => {
      if (tickets?.length > 0) {
        const allTickets = [...this.formGroup.get('tickets').value, ...tickets];
        this.formGroup.get('tickets').setValue(allTickets);
      }
    });
  }

  openTicket(item: SubItem) {
    this.areasService.openRoomTicket(item.parent, item.guid);
  }

  save() {
    const item = this.formGroup.value as SiteReview;
    this.siteReviewsService.save(item).then(() => this.dismiss());
  }

  dismiss() {
    this._modal.dismiss();
  }
}
