import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PartnerInvite } from '../models/partner-invite.model';
import { IService } from '../models/service.interface';
import { FirestoreService } from './firestore.service';

const itemCollection = 'partner-invites';

@Injectable({
  providedIn: 'root',
})
export class PartnerInviteService implements IService<PartnerInvite> {

  constructor(private firestore: FirestoreService) { }

  async save(item: PartnerInvite) {
    return this.firestore.save(itemCollection, item);
  }

  async update(item: PartnerInvite) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<PartnerInvite> {
    return this.firestore.get<PartnerInvite>(itemCollection, guid);
  }

  getList(options?: { inviter: string }): Observable<PartnerInvite[]> {
    const queryConstraints = [];
    if (options?.inviter) {
      queryConstraints.push(where('inviter', '==', options.inviter));
    }

    return this.firestore
      .getList<PartnerInvite>(itemCollection, undefined, queryConstraints);
  }
}
