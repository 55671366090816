import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { Classification, Classifications } from '../models/classifications.model';
import { sortByNumber } from '../utility/sort';
import { FirestoreService } from './firestore.service';

const itemCollection = 'classifications';

@Injectable({
  providedIn: 'root',
})
export class ClassificationsService {
  private firestore = inject(FirestoreService);

  private getLeafs(root: Classification): Classification[] {
    if (root.children?.length > 0) {
      return root.children.map((c) => this.getLeafs(c)).flat();
    } else {
      return [root];
    }
  }

  async update(item: Classifications) {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string) {
    if (guid == null) {
      return of<Classifications>(null);
    }
    return this.firestore.get<Classifications>(itemCollection, guid);
  }

  getList() {
    return this.firestore.getList<Classifications>(itemCollection, undefined);
  }

  getChildren(root: Classifications) {
    return root.codes.map((code) => this.getLeafs(code)).flat();
  }

  setFullCodes(code: Classification, parentCode: string) {
    const fullCode = parentCode ? `${parentCode}${code.code}` : code.code;
    code.fullCode = fullCode;
    code.children?.map((child) => this.setFullCodes(child, fullCode));
    return code;
  }

  sortChildren(code: Classification) {
    if (code.children) {
      code.children = code.children.sort((a, b) => sortByNumber(a, b, 'code'));
      code.children.map((child) => this.sortChildren(child));
    }
    return code;
  }
}
