import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { IService } from '@scandium-oy/ngx-scandium';
import { addDays, endOfDay, endOfToday, intervalToDuration, isAfter } from 'date-fns';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Contractor } from '../models/contractor.model';
import { maxAIPrompts, maxTasks } from '../utility/constants';
import { convertTimestamp, getDuration, isTimestamp } from '../utility/time';
import { FirestoreService } from './firestore.service';

const url = `${environment.backendUrl}/api/contractors`;
const itemCollection = 'contractors';

const converter = {
  toFirestore: (item: Contractor) => {
    return item;
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options) as Contractor;
    data.created = convertTimestamp(data.created);

    return data;
  },
};

@Injectable({
  providedIn: 'root',
})
export class ContractorsService implements IService<Contractor> {
  private contractor$ = new BehaviorSubject<Contractor>(null);

  contractorS = signal<Contractor>(null);

  aiLimit$ = this.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    map((contractor) => ({
      count: contractor.ai?.prompts ?? 0,
      isAvailable: contractor.license !== 'free' || !(contractor.ai?.prompts >= maxAIPrompts),
      isFree: contractor.license === 'free',
      maxPrompts: maxAIPrompts,
    })),
    shareReplay(1),
  );

  taskLimit$ = this.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    map((contractor) => ({
      count: contractor.ai?.tasks ?? 0,
      isAvailable: contractor.license !== 'free' || !(contractor.ai?.tasks >= maxTasks),
      isFree: contractor.license === 'free',
      maxPrompts: maxTasks,
    })),
    shareReplay(1),
  );

  constructor(
    private firestore: FirestoreService,
    private http: HttpClient,
  ) { }

  async save(item: Contractor) {
    item.archivedSites = [];
    item.archiveClients = [];
    return this.firestore.save(itemCollection, item);
  }

  async update(item: Contractor) {
    return this.firestore.update(itemCollection, item);
  }

  async updateAiConfig(contractorGuid: string, ai: { languages: string[]; tasks: number; prompts: number }) {
    return this.firestore.updateOnly(itemCollection, contractorGuid, { ai });
  }

  get(guid: string): Observable<Contractor> {
    return this.firestore.get<Contractor>(itemCollection, guid, converter);
  }

  getList(options?: { site?: string; guids?: string[]; client?: string; hasNoSite?: string }): Observable<Contractor[]> {
    const queryConstraints = [];
    if (options?.site) {
      queryConstraints.push(where('sites', 'array-contains', options.site));
    }
    if (options?.guids) {
      if (options.guids.length === 0) {
        return of([]);
      }
      queryConstraints.push(where(documentId(), 'in', options.guids));
    }
    if (options?.client) {
      queryConstraints.push(where('clients', 'array-contains', options.client));
    }

    return this.firestore.getList<Contractor>(itemCollection, undefined, queryConstraints, converter).pipe(
      map((items) => items?.filter((it) => options?.hasNoSite ? !it.sites.includes(options.hasNoSite) : true)),
    );
  }

  hasContractor(businessId: string) {
    if (environment.backendUrl) {
      return this.http.get<{ found: boolean }>(`${url}/${businessId}`).pipe(
        map((res) => res.found),
      );
    } else {
      return null;
    }
  }

  updateSite(guid: string, site: string) {
    if (environment.backendUrl) {
      return this.http.put(`${url}/${guid}`, { site }).pipe(
        map((res) => res),
      );
    } else {
      return null;
    }
  }

  setContractor(contractor: Contractor) {
    console.info(getDuration(), 'Setting contractor', contractor?.guid);
    this.contractor$.next(contractor);
    this.contractorS.set(contractor);
  }

  getCurrentContractor() {
    return this.contractor$.asObservable();
  }

  isFreeLicenceCount() {
    return this.getCurrentContractor().pipe(
      filter((contractor) => contractor != null),
      map((contractor) => {
        if (contractor.license !== 'free') {
          if (contractor.licenseStart && isTimestamp(contractor.licenseStart)) {
            const started = contractor.licenseStart.toDate();
            const trialEnd = endOfDay(addDays(started, 30));
            const day = intervalToDuration({ start: endOfToday(), end: trialEnd });
            return { value: isAfter(endOfToday(), trialEnd), day: day.days };
          }
        }
        return { value: contractor.license === 'free', day: -1 };
      }),
    );
  }

}
