<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'calendar.site.location' | translate }}
  </div>
  <h4>{{ 'site.blueprint' | translate }}</h4>
  <ion-select interface="popover"
    label-placement="stacked"
    [value]="selectedBlueprint()"
    (ionChange)="onBlueprint($event)">
    @for (print of blueprints; track print) {
    <ion-select-option [value]="print">
      {{ print.floor }}
    </ion-select-option>
    } @empty {
    <app-no-results></app-no-results>
    }
  </ion-select>
  @if (imageElement()) {
  <app-canvas [imgElement]="imageElement()"
    [setLocation]="true"
    (save)="onSave($event)"></app-canvas>
  }
  @else if (selectedBlueprint()) {
  <img id="blueprint"
    [src]="selectedBlueprint().url"
    (load)="setBlueprintImage($event)" />
  }
</app-main-dialog>