@if ({
role: role$ | async,
isFree: isFreeLicence$ | async
}; as vm) {
  <app-main-dialog (dismiss)="dismiss()">
    <div desktopTitle>
      {{ 'calendar.site.name' | translate }}
    </div>
    <ion-buttons buttonsEnd
      slot="end">
      @if (showShare()) {
        <ion-button color="light"
          class="share"
          fill="clear"
          (click)="onShare()">
          <ion-icon slot="icon-only"
            name="share-outline"></ion-icon>
        </ion-button>
      }
    </ion-buttons>
    <app-ticket [room]="roomS()"
      [siteGuid]="siteGuidS()"
      [isSiteLead]="isSiteLeadS()"
      [roomTicket]="roomTicket()"
      [canEdit]="canEditS()"
      [parentGuid]="parentGuid()"
      (save)="onTicketSave($event)"
      (delete)="onDelete($event)"
      (roomChanged)="onRoomChange($event)"
      (segment)="segmentValue.set($event)"
      (dismiss)="dismiss()"></app-ticket>
  </app-main-dialog>
  @if (segmentValue() === 'images') {
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-buttons>
          <ion-button color="light"
            class="add-button"
            shape="round"
            (click)="onPhoto()">
            <ion-icon slot="icon-only"
              name="add-circle"
              aria-hidden="true"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  }
}