import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraButtonModule, GeocodeService, PostalCodeModule, PostalCodePipe } from '@scandium-oy/ngx-scandium';
import { Observable, of } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { SelectClientDialogComponent } from 'src/app/clients/select-client/select-client.dialog';
import { AppCommonModule } from 'src/app/common.module';
import { LoadingButtonComponent } from 'src/app/components/loading-button/loading-button.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectUserButtonComponent, SelectedUser } from 'src/app/components/select-user/select-user.component';
import { Classifications } from 'src/app/constants/classifications';
import { Client } from 'src/app/models/client.model';
import { ILocation } from 'src/app/models/location.model';
import { Site } from 'src/app/models/site.model';
import { ClassificationsService } from 'src/app/services/classifications.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { buildingTypes, siteImageOptions, siteTypes } from 'src/app/utility/site';
import { fieldSorter } from 'src/app/utility/sort';
import { capitalizeFirstLetter } from 'src/app/utility/string';

@Component({
  standalone: true,
  selector: 'app-new-site-dialog',
  templateUrl: './new-site.dialog.html',
  styleUrls: ['./new-site.dialog.scss'],
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
    CameraButtonModule,
    LoadingButtonComponent,
    SelectUserButtonComponent,
    PostalCodeModule,
  ],
})
export class NewSiteDialogComponent {
  preClient = signal(false);
  clientName$: Observable<string>;
  selectedClient = signal<Client>(null);

  item = signal<Site>(null);
  formGroup: FormGroup;

  clients$ = this.clientsService.getList().pipe(
    map((clients) => clients.map((client) => {
      client.name = client.name ?? client.guid;
      client.name = capitalizeFirstLetter(client.name);
      return client;
    })),
    map((clients) => clients.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  readonly classifications = [Classifications.talo80, Classifications.talo90, Classifications.talo2000];

  role = computed(() => this.usersService.currentUserS().role);
  isLimited = signal(false);
  supervisor = signal<SelectedUser>(null);
  sitelead = signal<SelectedUser>(null);
  siteImageOptions = signal<string>('after');
  loadingLocation = signal(false);
  saving = signal(false);

  readonly siteImageOptionsItems = siteImageOptions;
  readonly buildingTypes = buildingTypes;
  readonly siteTypes = siteTypes;

  users$ = this.usersService.getList().pipe(
    map((users) => users?.filter((u) => [Roles.admin, Roles.supervisor].includes(u.role))),
    shareReplay(1),
  );

  siteleadUsers$ = this.usersService.getList().pipe(
    map((users) => users?.filter((u) => [Roles.worker].includes(u.role))),
    shareReplay(1),
  );

  classifications$ = this.classificationsService.getList().pipe(
    shareReplay(1),
  );

  constructor(
    private classificationsService: ClassificationsService,
    private clientsService: ClientsService,
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private geocodeService: GeocodeService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private postalCode: PostalCodePipe,
    private translate: TranslateService,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    const currentUser = this.usersService.currentUserS();
    const clientGuid = navParams.get('clientGuid') ?? null;
    const site: Site = Object.assign({}, navParams.get('site'));
    this.item.set(site);
    if (clientGuid != null) {
      this.preClient.set(true);
      this.clientName$ = this.clientsService.get(clientGuid).pipe(
        map((client) => client?.name),
        shareReplay(1),
      );
    } else if (site?.client) {
      this.clientName$ = this.clientsService.get(site.client).pipe(
        map((client) => client?.name),
        shareReplay(1),
      );
    }
    const contractor = this.getContractor(site);
    if ([Roles.admin].includes(currentUser.role) && site?.guid != null) {
      if (site.contractor == null) {
        this.isLimited.set(true);
      }
      const supervisor = site.supervisor?.find((it) => it.contractor === currentUser.contractors[0]);
      if (supervisor) {
        const user = this.usersService.getSync(supervisor.guid);
        this.supervisor.set({ guid: user.guid, name: user.displayName, displayName: user.displayName, role: user.role });
      }
      const sitelead = site.sitelead?.find((it) => it.contractor === currentUser.contractors[0]);
      if (sitelead) {
        const user = this.usersService.getSync(sitelead.guid);
        this.sitelead.set({ guid: user.guid, name: user.displayName, displayName: user.displayName, role: user.role });
      }
    }

    const userContractor = this.contractorsService.contractorS();
    if (userContractor) {
      if (userContractor.siteOptions == null) {
        userContractor.siteOptions = [];
      }
      const existingOptions = userContractor.siteOptions.find((it) => it.site === site?.guid);
      this.siteImageOptions.set(existingOptions?.images ?? 'after');
    }

    this.formGroup = this.formBuilder.group({
      client: [site?.client ?? clientGuid],
      streetAddress: [site?.streetAddress, Validators.required],
      project: [site?.project, Validators.required],
      postalCode: [site?.postalCode ? this.postalCode.transform(+site.postalCode) : undefined, Validators.required],
      city: [site?.city, Validators.required],
      image: [site?.image],
      contacts: [site?.contacts ?? []],
      classification: [site?.classification],
      creator: [site?.creator ?? currentUser.guid],
      created: [site?.created ?? new Date()],
      contractor: [contractor],
      logisticsCalendar: [site?.logisticsCalendar ?? false],
      worknumber: [site?.worknumber ?? ''],
      buildingType: [site?.buildingType ?? null],
      siteType: [site?.siteType ?? null],
    });
  }

  private getContractor(site: Site) {
    const currentUser = this.usersService.currentUserS();
    if (site?.guid) {
      return site.contractor ?? null;
    } else {
      return currentUser.role === Roles.superAdmin ? null : currentUser.contractors[0];
    }
  }

  private getLocation(always = false) {
    const { streetAddress, city, postalCode } = this.formGroup.value;
    const site = this.item();
    if (!always && site && site.streetAddress === streetAddress && this.postalCode.transform(+site?.postalCode) === postalCode && site.location != null) {
      return of(site.location);
    }
    const finalPostalCode = this.postalCode.transform(postalCode);
    return this.geocodeService.getGeocodeStructured(streetAddress.replace(/\s/g, '+'), finalPostalCode, city);
  }

  private setContractorUsers(item: Site) {
    const contractor = this.usersService.currentUserS().contractors[0];
    if (item.supervisor == null) {
      item.supervisor = [];
    }
    if (item.sitelead == null) {
      item.sitelead = [];
    }
    if (item.siteleadGuids == null) {
      item.siteleadGuids = [];
    }
    if (this.supervisor()) {
      const existingSupervisor = item.supervisor.find((it) => it.contractor === contractor);
      if (existingSupervisor) {
        existingSupervisor.guid = this.supervisor()?.guid ?? null;
      } else {
        item.supervisor.push({ guid: this.supervisor().guid, contractor });
      }
    }
    if (this.sitelead()) {
      const existingSitelead = item.sitelead.find((it) => it.contractor === contractor);
      if (existingSitelead) {
        existingSitelead.guid = this.sitelead().guid ?? null;
      } else {
        item.sitelead.push({ guid: this.sitelead().guid, contractor });
      }
      item.siteleadGuids.push(this.sitelead().guid);
    }
    return item;
  }

  onSelectClient() {
    this.modalCtrl
      .create({ component: SelectClientDialogComponent, componentProps: { isNewSite: true } })
      .then((m) => {
        m.present();
        m.onDidDismiss<Client>().then((data) => {
          if (data.data) {
            this.selectedClient.set(data.data);
            this.formGroup.get('client').setValue(this.selectedClient().guid);
          }
        });
      });
  }

  onImageUrl(imageUrl: string) {
    this.item().image = imageUrl;
    this.formGroup.get('image').setValue(imageUrl);
  }

  removeImage() {
    delete this.item().image;
  }

  updateLocation() {
    this.loadingLocation.set(true);
    this.getLocation(true).pipe(
      take(1),
    ).subscribe((location) => {
      if (location == null) {
        Dialog.alert({ message: this.translate.instant('site.noLocationFound') });
      } else {
        this.item.update((val) => {
          val.location = location;
          return val;
        });
      }
      this.loadingLocation.set(false);
    });
  }

  openMap(location: ILocation) {
    const url = `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
    Browser.open({ url });
  }

  onSupervisor(selectedUser: SelectedUser) {
    this.supervisor.set(selectedUser);
  }

  onSitelead(selectedUser: SelectedUser) {
    this.sitelead.set(selectedUser);
  }

  onSiteImageOption(event: CustomEvent) {
    this.siteImageOptions.set(event.detail.value);
  }

  save() {
    this.saving.set(true);
    this.getLocation().pipe(
      take(1),
    ).subscribe((location) => {
      if (location == null) {
        Dialog.alert({ message: this.translate.instant('site.noLocationFound') });
      } else {
        let item: Site = Object.assign(
          {},
          this.item() ? this.item() : {},
          this.formGroup.value,
          { siteImageOptions: this.siteImageOptions() },
        );
        if ([Roles.admin].includes(this.role())) {
          item = this.setContractorUsers(item);
        }
        item.location = location;
        this.dismiss(item);
      }
      this.saving.set(false);
    });
  }

  dismiss(item?: Site) {
    this._modal.dismiss(item);
  }
}
