import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UsersService } from '../services/users.service';
import { Roles } from '../utility/role';

export const isRoleGuard = (roles: Roles[]): CanActivateFn => {
  return () => {
    const usersService = inject(UsersService);

    return toObservable(usersService.role).pipe(
      filter((role) => role !== null),
      map((role) => roles.includes(role)),
    );
  };
};
