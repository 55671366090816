import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Message } from 'src/app/models/message.model';
import { NewMessageComponent } from '../components/new-message.component';

@Component({
  standalone: true,
  selector: 'app-new-message-dialog',
  templateUrl: './new-message.dialog.html',
  styleUrls: ['./new-message.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NewMessageComponent,
    MainDialogComponent,
  ],
})
export class NewMessageDialogComponent {

  messageItem = signal<Message>(null);

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.messageItem.set(navParams.get('message'));
  }

  dismiss() {
    this._modal.dismiss();
  }
}
