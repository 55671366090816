<app-main-page>
  <div desktopTitle>
    {{ 'nav.logs' | translate }}
  </div>
  @if ({
    nav: nav$ | async
  }; as vm) {
    @defer(on timer(1000)) {
      <ion-searchbar mode="ios"
        [placeholder]="'general.search' | translate"
        type="text"
        debounce="500"
        (ionInput)="onChange($event)"></ion-searchbar>
      @switch (vm.nav) {
        @case ('sites') {
          <div class="ion-margin-top">
            @for (note of siteNotes$ | async; track note) {
              <ion-item button="true"
                lines="none"
                (click)="onLog(note.text, note.site)">
                <div class="text-container">
                  <ion-note>
                    {{ note.date | date:'dd.MM.yyyy HH:mm' }}
                  </ion-note>
                  <div class="name">
                    {{ note.username }}
                  </div>
                  <div>
                    <ion-icon name="business-outline"></ion-icon>
                    {{ note.site }} @if (note.room) {
                      <span>({{ note.room }})</span>
                    }
                  </div>
                </div>
              </ion-item>
            } @empty {
              {{ 'logs.noItems' | translate }}
            }
          </div>
        } @case ('users') {
          <div class="ion-margin-top">
            @for (note of userNotes$ | async; track note) {
              <ion-item lines="none"
                button="true"
                (click)="onLog(note.text, note.title)">
                <div class="text-container">
                  <ion-note>
                    {{ note.date | date:'dd.MM.yyyy HH:mm' }}
                  </ion-note>
                  <div class="name">
                    {{ note.username }} @if (note.site) {
                      <span>({{ note.site }})</span>
                    }
                  </div>
                  <div>
                    @if (note.warning) {
                      <ion-icon color="warning"
                        name="warning-outline"></ion-icon>
                    } @else {
                      <ion-icon name="newspaper-outline"></ion-icon>
                    }
                    {{ note.title }}
                  </div>
                </div>
              </ion-item>
            } @empty {
              {{ 'logs.noItems' | translate }}
            }
          </div>
          } @case ('feedbacks') {
            <div class="feedback-container">
              <app-tabs [items]="feedbackTabItems"
                [initial]="feedbackNav$ | async"
                (selected)="onFeedbackTabSelected($event)"></app-tabs>
              @for (feedback of feedbacks$ | async; track feedback) {
                <ion-item lines="none"
                  (click)="onLog(feedback.feedback, feedback.title)">
                  <div class="text-container">
                    <ion-note>
                      {{ feedback.date | date:'dd.MM.yyyy HH:mm' }}
                    </ion-note>
                    <div class="name">
                      {{ feedback.username }}
                    </div>
                    <div class="ellipsis">{{ feedback.feedback }}</div>
                  </div>
                  <span slot="end">
                    @if (feedback.checked) {
                      <ion-button color="success"
                        shape="round"
                        size="small">
                        {{ 'kanban.status.checked' | translate }}
                      </ion-button>
                    } @else {
                      <ion-button shape="round"
                        size="small"
                        (click)="onCheck(feedback)">
                        {{ 'kanban.check' | translate }}
                      </ion-button>
                    }
                  </span>
                </ion-item>
              } @empty {
                {{ 'feedback.empty' | translate }}
              }
            </div>
          }
          }
          } @placeholder {
            <app-loading></app-loading>
          }
          }
</app-main-page>