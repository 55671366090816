<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'item.littera' | translate }}
  </div>
  <div class="filters">
    <ion-searchbar #filter
      mode="ios"
      [placeholder]="'general.filterBy' | translate"
      (ionInput)="onFilter(filter)"></ion-searchbar>
  </div>
  @for (item of litteras$ | async; track item) {
    <ion-item button="true"
      lines="none"
      (click)="select(item)">
      <span slot="start">
        {{ item.fullCode }}
      </span>
      {{ item.name }}
    </ion-item>
  } @empty {
    <app-no-results></app-no-results>
  }
  @if (clearButton) {
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="clear()">
        <ion-icon slot="start"
          name="close-outline"></ion-icon>
        {{ 'general.clear' | translate }}
      </ion-button>
    </div>
  }
</app-main-dialog>