import { Timesheet, TimesheetPaymentType } from '../models/timesheet.model';
import { lunchBreak, removeLunchDate, reportMillisToMinutesAndSeconds, sixHours } from './time';

export function removeLunchBreak(date: Date, value: number): number {
  if (date > removeLunchDate) {
    if (value >= sixHours) {
      value -= lunchBreak;
    }
  }
  return value;
}

export function getDurations(timesheets: Timesheet[]): number {
  let summaryMillis = 0;
  timesheets?.map((timesheet) => {
    if (timesheet.dateIn && timesheet.dateOut && timesheet.absence == null) {
      let millis = timesheet.dateOut.getTime() - timesheet.dateIn.getTime();
      // Remove lunch break
      millis = removeLunchBreak(timesheet.dateIn, millis);
      summaryMillis += millis;
    }
  });
  const duration = reportMillisToMinutesAndSeconds(summaryMillis);
  return duration;
}

export const getDurationsAsString = (timesheets: Timesheet[]): string => {
  if (!timesheets?.length) {
    return '';
  }
  let summary = 0;
  timesheets?.map((timesheet) => {
    if (timesheet.dateIn && timesheet.dateOut && timesheet.absence == null) {
      let millis = timesheet.dateOut.getTime() - timesheet.dateIn.getTime();
      // Remove lunch break
      millis = removeLunchBreak(timesheet.dateIn, millis);
      summary += millis;
    }
  });
  const duration = reportMillisToMinutesAndSeconds(summary).toFixed(2).replace('.', ',');
  return duration;
};

export function getPaymentTypes(timesheets: Timesheet[]): TimesheetPaymentType[] {
  return timesheets.map((ts) => ts.paymentTypes).flat();
}

export const lateToSite = 'Myöhässä työmaalla';
export const leaveEarly = 'Liian aikaisin poistunut työmaalta';
export const distanceTooLongTitle = 'Sijaintipoikkeama';
