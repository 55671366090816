import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppCommonModule } from 'src/app/common.module';
import { NewAdhocDialogComponent } from 'src/app/components/ad-hoc/new-dialog/new-adhoc.dialog';
import { Room } from 'src/app/models/room.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  standalone: true,
  selector: 'app-block-button',
  template: `<ion-button
               shape="round"
               color="danger"
               (click)="toBlocked()">
                 {{ 'kanban.toBlocked' | translate }}
            </ion-button>`,
  imports: [
    AppCommonModule,
    IonicModule,
  ],
})
export class BlockButtonComponent {

  @Input() room: Room;

  @Input() siteGuid: string;

  @Input() ticket: RoomTicket;

  @Output() save: EventEmitter<RoomTicket> = new EventEmitter();

  constructor(
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private translate: TranslateService,
  ) { }

  private openAdHoc() {
    const site = this.sitesService.getSync(this.siteGuid);
    // Open dialog
    this.modalCtrl.create({
      component: NewAdhocDialogComponent,
      componentProps: { site, additionalWork: true, room: this.room },
    },
    ).then((m) => {
      m.present();

      m.onDidDismiss<RoomTicket>().then((data) => {
        if (data.data) {
          this.save.emit(data.data);
        }
      });
    });
  }

  toBlocked() {
    Dialog.confirm({
      title: this.translate.instant('kanban.block.title'),
      message: this.translate.instant('kanban.block.message'),
      okButtonTitle: this.translate.instant('kanban.block.new'),
      cancelButtonTitle: this.translate.instant('kanban.block.temp'),
    }).then((result) => {
      if (result.value) {
        this.openAdHoc();
      } else {
        this.save.emit();
      }
    });
  }
}
