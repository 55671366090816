<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'site.review' | translate }}
  </div>
  <form [formGroup]="formGroup">
    <h4>{{ 'clients.name' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <div class="ion-margin-top flex flex-justify-between">
      <h4>{{ 'sites.tickets' | translate }}</h4>
      <ion-button shape="round"
        color="light"
        (click)="addNewTicket()">
        {{ 'item.add' | translate }}
      </ion-button>
    </div>
    @if (formGroup.get('tickets').value; as selectedTickets) {
      @if (selectedTickets.length > 0) {
        @for (ticket of selectedTickets; track ticket.guid) {
          <ion-item color="secondary"
            button="true"
            lines="none"
            (click)="openTicket(ticket)">
            {{ ticket.name }}
          </ion-item>
        }
      }
    }
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="save()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  </form>
</app-main-dialog>