import { Component, inject, input, output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { FreeSelectWorkerDialogComponent } from './dialog/select-worker.dialog';

@Component({
  standalone: true,
  selector: 'app-free-select-worker',
  templateUrl: './select-worker.component.html',
  styleUrls: ['./select-worker.component.scss'],
  imports: [
    AppCommonModule,
    IonicModule,
  ],
})
export class FreeSelectWorkerComponent {
  private modalCtrl = inject(ModalController);

  color = input<string>('secondary');
  expand = input<string>('');
  disabled = input<boolean>(false);
  site = input.required<string>();
  size = input('small');
  selectedUsers = input<string[]>([]);
  ticket = input.required<{ parent: string; roomTicket: string }>();

  opened = output();
  selected = output<{ guid: string; name: string }>();

  selectWorker() {
    this.opened.emit();
    this.modalCtrl.create({ component: FreeSelectWorkerDialogComponent, componentProps: { site: this.site(), ticket: this.ticket() } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.selected.emit(data.data);
        }
      });
    });
  }
}
