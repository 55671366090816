import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonSearchbar, IonicModule } from '@ionic/angular';
import { startOfDay } from 'date-fns/startOfDay';
import { BehaviorSubject, combineLatest, map, shareReplay } from 'rxjs';
import { AppCommonModule } from 'src/app/common.module';
import { SiteArea } from 'src/app/models/site-area.model';
import { ProjectPeriod, SiteProject } from 'src/app/models/site-project.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { SelectDayComponent } from '../select-day/select-day.component';

export interface SelectedTicket extends RoomTicket {
  selected: boolean;
  roomName: string;
}

@Component({
  standalone: true,
  selector: 'app-select-tickets',
  templateUrl: './select-tickets.component.html',
  styleUrls: ['./select-tickets.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    TranslateTicketPipe,
    SelectDayComponent,
  ],
})
export class SelectTicketsComponent {

  private _selectedTickets: SelectedTicket[] = [];
  private _tickets$ = new BehaviorSubject<SelectedTicket[]>([]);
  private filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input()
  selectedArea: SiteArea;

  @Input()
  selectedProject: SiteProject;

  @Input()
  selectedJob: ProjectPeriod;

  selectedStartDate$ = new BehaviorSubject<Date>(null);
  selectedEndDate$ = new BehaviorSubject<Date>(null);
  tickets$ = combineLatest([this._tickets$, this.selectedStartDate$.asObservable(), this.selectedEndDate$.asObservable(), this.filter$.asObservable()]).pipe(
    map(([tickets, startDate, endDate, text]) => tickets
      .filter((it) => this.selectedArea == null || this.selectedArea.guid === it.area)
      .filter((it) => this.selectedProject == null || this.selectedProject.periods.some((itt) => itt.tickets.some((ittt) => ittt === it.guid)))
      .filter((it) => this.selectedJob == null || this.selectedJob.tickets.some((itt) => itt === it.guid))
      .filter((it) => startDate == null || it.date >= startDate)
      .filter((it) => endDate == null || it.date <= endDate)
      .filter((it) => it.name?.toLowerCase().includes(text.toLowerCase()) || it.guid?.toLowerCase().includes(text.toLowerCase())),
    ),
    shareReplay(1),
  );

  @Input()
  set tickets(value: SelectedTicket[]) {
    this._tickets$.next(value);
  }

  @Output()
  selectedTickets = new EventEmitter<SelectedTicket[]>();

  onFilter(input: IonSearchbar) {
    const value = input.value;
    this.filter$.next(value);
  }

  onDateChange(value: string, start: boolean) {
    if (value) {
      const date = startOfDay(new Date(value));
      if (start) {
        this.selectedStartDate$.next(date);
      } else {
        this.selectedEndDate$.next(date);
      }
    } else {
      if (start) {
        this.selectedStartDate$.next(null);
      } else {
        this.selectedEndDate$.next(null);
      }
    }
  }

  onSelectTicket(roomTicket: SelectedTicket) {
    roomTicket.selected = !roomTicket.selected;
    if (roomTicket.selected) {
      this._selectedTickets.push(roomTicket);
    } else {
      this._selectedTickets = this._selectedTickets.filter((it) => it.guid !== roomTicket.guid);
    }
    this.selectedTickets.emit(this._selectedTickets);
  }
}
