import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { addMonths } from 'date-fns';
import { AppCommonModule } from 'src/app/common.module';

@Component({
  standalone: true,
  selector: 'app-month-switch',
  styleUrls: ['./month-switch.component.scss'],
  templateUrl: './month-switch.component.html',
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class MonthSwitchComponent {

  private _current: Date;

  @Input()
  set current(value: Date) {
    if (value != null) {
      this._current = value;
      this.setMonths(value);
    }
  }

  @Output()
  monthChange = new EventEmitter<Date>();

  prevMonth = signal<number>(null);
  nextMonth = signal<number>(null);
  currentMonth = signal<number>(null);
  currentYear = signal<number>(null);

  private setMonths(date: Date) {
    const prev = new Date(date);
    this.prevMonth.set(addMonths(prev, -1).getMonth());
    const next = new Date(date);
    this.nextMonth.set(addMonths(next, 1).getMonth());
    this.currentMonth.set(date.getMonth());
    this.currentYear.set(date.getFullYear());
  }

  changeMonth(newMonths: number) {
    const newMonth = addMonths(this._current, newMonths);
    this.monthChange.emit(newMonth);
    this.setMonths(newMonth);
  }
}
