<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'general.select' | translate }}
  </div>
  <div class="search">
    <ion-searchbar #filter
      mode="ios"
      [placeholder]="'general.filter' | translate"
      (ionInput)="onFilter(filter)"></ion-searchbar>
  </div>
  @for (item of items$ | async; track item) {
  <ion-item button="true"
    lines="none"
    (click)="select(item)">
    @if (item.selected) {
    <ion-icon slot="start"
      name="checkmark-outline"></ion-icon>
    }
    {{ item.name }}
  </ion-item>
  }
  <div class="ion-margin-top flex">
    @if (multiple) {
    <ion-button color="light"
      shape="round"
      (click)="save()">
      {{ 'general.select' | translate }}
    </ion-button>
    }
    @if (clearButton) {
    <ion-button color="light"
      shape="round"
      (click)="clear()">
      <ion-icon slot="start"
        name="close-outline"></ion-icon>
      {{ 'general.clear' | translate }}
    </ion-button>
    }
  </div>
</app-main-dialog>