<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (!item()?.guid) {
      {{ 'sites.newTitle' | translate }}
    } @else {
      {{ item().project }}
    }
  </div>
  @if ({
  clientName: clientName$ | async
  }; as vm) {
    <form class="ion-padding-bottom"
      [formGroup]="formGroup"
      (ngSubmit)="save()">
      @if (!isLimited()) {
        @if (role() === 'super-admin' || (!item()?.guid && !preClient())) {
          <ion-button color="light"
            shape="round"
            (click)="onSelectClient()">
            @if (selectedClient()) {
              {{ selectedClient().name }}
            } @else if (vm.clientName) {
              {{ vm.clientName }}
            } @else {
              {{ 'clients.newTitle' | translate }}
            }
          </ion-button>
        } @else if (role() !== 'manager') {
          <h4>{{ 'sites.new.client' | translate }} *</h4>
          <div>
            <h4>{{ vm.clientName }}</h4>
          </div>
        }
        @if (item()?.image) {
          <div class="img-container">
            <img class="image"
              [src]="item().image" />
            <ion-button color="danger"
              (click)="removeImage()">
              <ion-icon slot="icon-only"
                name="remove-circle-outline"></ion-icon>
            </ion-button>
          </div>
        } @else {
          <div>
            <app-camera-button class="only-camera"
              [name]="item().guid"
              color="light"
              fill="clear"
              [multiline]="true"
              (imageUrl)="onImageUrl($event)"></app-camera-button>
          </div>
        }
        <h4>{{ 'item.name' | translate }} *</h4>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            formControlName="project"></ion-input>
        </ion-item>
        <h4>{{ 'site.worknumber' | translate }}</h4>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            formControlName="worknumber"></ion-input>
        </ion-item>
        <div class="divider"></div>
        <div class="flex address-title">
          <h4>{{ 'sites.new.streetAddress' | translate }} *</h4>
          <div class="flex-align-center">
            <ion-button color="light"
              class="action-button"
              shape="round"
              size="small"
              (click)="updateLocation()">
              <app-loading-button [loading]="loadingLocation()">
                {{ 'sites.new.updateLocation' | translate }}
              </app-loading-button>
            </ion-button>
            @if (item().location != null) {
              <ion-button color="light"
                shape="round"
                size="small"
                (click)="openMap(item().location)">{{ 'sites.new.showLocation' | translate }}</ion-button>
            }
          </div>
        </div>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            formControlName="streetAddress"></ion-input>
        </ion-item>
        <h4>{{ 'sites.new.postalCode' | translate }} *</h4>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            formControlName="postalCode"
            type="number"></ion-input>
        </ion-item>
        <h4>{{ 'sites.new.city' | translate }} *</h4>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            formControlName="city"></ion-input>
        </ion-item>
        <div class="divider"></div>
        <div class="ion-margin-top">
          <ion-radio-group formControlName="buildingType">
            @for (item of buildingTypes; track item) {
              <ion-radio aria-label=""
                color="primary"
                [value]="item">
                {{ 'site.buildingTypes.' + item | translate }}</ion-radio>
            }
          </ion-radio-group>
        </div>
        <div class="ion-margin-top">
          <ion-radio-group formControlName="siteType">
            @for (item of siteTypes; track item) {
              <ion-radio aria-label=""
                color="primary"
                [value]="item">
                {{ 'site.siteTypes.' + item | translate }}</ion-radio>
            }
          </ion-radio-group>
        </div>
        <ion-item color="primary"
          class="toggle-container"
          lines="none">
          <ion-toggle color="light"
            formControlName="logisticsCalendar">
            {{ 'nav.logisticsCalendar' | translate }}
          </ion-toggle>
        </ion-item>
        <div class="ion-margin-top">
          <h4>{{ 'home.selectLittera' | translate }}</h4>
          <ion-radio-group formControlName="classification">
            @for (item of classifications$ | async; track item) {
              <ion-radio aria-label=""
                color="primary"
                [value]="item.guid">
                {{ 'home.classifications.' + item.guid | translate }}</ion-radio>
            }
          </ion-radio-group>
        </div>
      }
      <div class="divider"></div>
      @if (['admin'].includes(role())) {
        <div class="flex">
          <div>
            <h4>{{ 'site.supervisor' | translate }}</h4>
            <div>
              <app-select-user-button [items]="users$ | async"
                [selectedUser]="supervisor()"
                (selected)="onSupervisor($event)"></app-select-user-button>
            </div>
          </div>
          <div>
            <h4>{{ 'site.sitelead' | translate }}</h4>
            <div>
              <app-select-user-button [items]="siteleadUsers$ | async"
                [selectedUser]="sitelead()"
                (selected)="onSitelead($event)"></app-select-user-button>
            </div>
          </div>
        </div>
        <div>
          <h4>{{ 'contractor.siteOptions.imagesTitle' | translate }}</h4>
          <ion-item lines="none">
            <ion-select aria-label=""
              label-placement="stacked"
              interface="popover"
              [value]="siteImageOptions()"
              (ionChange)="onSiteImageOption($event)">
              @for (opt of siteImageOptionsItems; track opt) {
                <ion-select-option [value]="opt">
                  {{ 'contractor.siteOptions.images.' + opt | translate }}
                </ion-select-option>
              }
            </ion-select>
          </ion-item>
        </div>
        <div class="divider"></div>
      }
      <div class="ion-margin-top">
        <ion-button color="secondary"
          shape="round"
          type="submit"
          [disabled]="!formGroup.valid">
          <app-loading-button [loading]="saving()">
            {{ 'general.save' | translate }}
          </app-loading-button>
        </ion-button>
      </div>
    </form>
  }
</app-main-dialog>