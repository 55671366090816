@if (!hideChoice) {
  <ion-radio-group (ionChange)="onRoomType($event)">
    <ion-label color="light">{{ 'room.type' | translate }}</ion-label>
    <ion-item>
      <ion-label>{{ 'room.room' | translate }}</ion-label>
      <ion-radio slot="start"
        value="room"></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label>{{ 'room.common' | translate }}</ion-label>
      <ion-radio slot="start"
        value="common"></ion-radio>
    </ion-item>
  </ion-radio-group>
}
@if (item?.type === 'room') {
  @if (roomFormGroup) {
    <form [formGroup]="roomFormGroup">
      <ion-label position="stacked">{{ 'room.block' | translate }}</ion-label>
      <ion-item>
        <ion-input autocapitalize="on"
          formControlName="block"></ion-input>
      </ion-item>
      <ion-label position="stacked">{{ 'room.numbers' | translate }}</ion-label>
      <ion-item>
        <ion-input autocapitalize="on"
          formControlName="numbers"></ion-input>
      </ion-item>
      <ion-button color="light"
        shape="round"
        [disabled]="!roomFormGroup.valid"
        (click)="save()">{{ 'room.saveRoom' | translate }}</ion-button>
    </form>
  }
}
@if (item?.type === 'common') {
  @if (commonFormGroup) {
    <form [formGroup]="commonFormGroup">
      @if (multiple()) {
        <div>
          <ion-label>{{ 'room.numbers' | translate }}</ion-label>
          <ion-item>
            <ion-input autocapitalize="on"
              formControlName="numbers"></ion-input>
          </ion-item>
        </div>
      } @else {
        <ion-label>{{ 'room.name' | translate }}</ion-label>
        <ion-item>
          <ion-input autocapitalize="on"
            formControlName="name"></ion-input>
        </ion-item>
      }
      <ion-item color="secondary"
        lines="none">
        <ion-toggle aria-label=""
          (ionChange)="toggleMultiple()">
          {{ 'room.range' | translate }}
        </ion-toggle>
      </ion-item>
      <ion-button color="light"
        shape="round"
        (click)="onCancel()">{{ 'general.cancel' | translate }}</ion-button>
      <ion-button color="light"
        shape="round"
        [disabled]="(multiple() && !commonFormGroup.get('numbers').value) || (!multiple() && !commonFormGroup.get('name').value)"
        (click)="save()">{{ 'room.saveCommon' | translate }}</ion-button>
    </form>
  }
}