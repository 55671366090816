@if ({
expired: licenseExpired$ | async
}; as vm) {
  <ion-app swipe
    (previous)="onSwipeBack()">
    <div id="main-content"
      class="ion-page">
      <ion-tabs>
        @if (isLogged() && role() != null && !['registered', ''].includes(role()) && !vm.expired) {
          <ion-tab-bar slot="bottom"
            color="primary">
            <ion-tab-button tab="home"
              (click)="onHome()">
              <ion-icon name="home"
                aria-hidden="true"></ion-icon>
            </ion-tab-button>
            <app-adhoc [loading]="loadingSites()"></app-adhoc>
            <ion-tab-button (click)="onMenu()">
              <ion-icon class="help"
                name="apps"
                aria-hidden="true"></ion-icon>
            </ion-tab-button>
          </ion-tab-bar>
        }
      </ion-tabs>
    </div>
  </ion-app>
}