import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { KanbanItemViewDialogComponent } from 'src/app/kanban/view/item/item-view.dialog';
import { Room } from 'src/app/models/room.model';
import { RoomTicket, Ticket } from 'src/app/models/ticket.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-ticket-created-dialog',
  templateUrl: './ticket-created.dialog.html',
  styleUrls: ['./ticket-created.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class TicketCreatedDialogComponent {

  ticket: RoomTicket;
  parent: Ticket;
  siteGuid: string;
  room: Room;

  constructor(
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    navParams: NavParams,
  ) {
    this.ticket = navParams.get('ticket');
    this.parent = navParams.get('parent');
    this.siteGuid = navParams.get('siteGuid');
    this.room = navParams.get('room');
  }

  toTicket() {
    this.modalCtrl.create({
      component: KanbanItemViewDialogComponent,
      componentProps: {
        ticket: this.ticket,
        room: this.room,
        parent: this.parent,
        siteGuid: this.siteGuid,
        canEdit: true,
      },
    }).then((m) => {
      m.present();
      this._modal.dismiss();
    });
  }

  toTicketList() {
    this.navigationService.navigate(['/worker', 'tickets'], { queryParams: { site: this.siteGuid, tab: 'open' } }).then(async () => this._modal.dismiss());
  }

  newTicket() {
    this._modal.dismiss({ newTicket: true });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
