import { Component, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@scandium-oy/ngx-scandium';
import { addHours, parse, parseISO, startOfDay } from 'date-fns';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { SelectedUser } from 'src/app/components/select-user/select-user.component';
import { Options } from 'src/app/models/options.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { Timesheet, TimesheetPaymentType } from 'src/app/models/timesheet.model';
import { User } from 'src/app/models/user.model';
import { absenceSite, getAbsences, getPaymentTypes } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { storageKeys } from 'src/app/utility/constants';
import { Roles } from 'src/app/utility/role';
import { fieldSorter } from 'src/app/utility/sort';
import { capitalizeFirstLetter } from 'src/app/utility/string';
import { getDateWithTimezone } from 'src/app/utility/time';

const workdayStart = 7;
const workdayEnd = 15;
const workdayEndMinutes = 30;

@Component({
  selector: 'app-new-timesheet-dialog',
  templateUrl: './new-timesheet.dialog.html',
  styleUrls: ['./new-timesheet.dialog.scss'],
})
export class NewTimesheetDialogComponent implements OnInit {

  private absences = getAbsences(this.translate);

  private paymentTypes = getPaymentTypes(this.translate);

  site$ = new BehaviorSubject<Site>(null);
  private user$: ReplaySubject<User> = new ReplaySubject(1);

  private timesheet = {
    date: undefined,
  };

  selectedUser$ = this.user$.pipe(
    map((user) => ({ guid: user.guid, name: user.displayName, displayName: user.displayName }) as SelectedUser),
    shareReplay(1),
  );

  formGroup: FormGroup;

  showPaymentTypeUnit = [this.paymentTypes[0].key, this.paymentTypes[1].key];

  projects$ = combineLatest([this.site$, this.contractorsService.getCurrentContractor()]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    switchMap(([site, contractor]) => this.siteProjectsService.getList({ site: site.guid, contractor: contractor.guid })),
    shareReplay(1),
  );

  sites$: Observable<Site[]> = combineLatest([this.user$, this.usersService.role$]).pipe(
    filter(([user]) => user != null),
    switchMap(([user, role]) => {
      if (role === Roles.admin) {
        return this.sitesService.getActiveList();
      } else if (user.worksites?.length > 0) {
        return this.sitesService.getByGuids(user.worksites);
      } else {
        return of([]);
      }
    }),
    shareReplay(1),
  );

  users$ = combineLatest([this.site$, this.contractorsService.getCurrentContractor()]).pipe(
    switchMap(([site, contractor]) => this.usersService.getListOnce({ site: site?.guid ?? undefined, role: Roles.worker, contractor: contractor.guid })),
    map((users) => users.map((user) => {
      user.displayName = capitalizeFirstLetter(user.displayName);
      return user;
    }).sort(fieldSorter(['displayName']))),
    shareReplay(1),
  );

  preUser: boolean;
  preSite: Site;

  milageAllowance: number;
  milageAllowanceSummary = signal<string>('0.00');

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private sitesService: SitesService,
    private siteProjectsService: SiteProjectsService,
    private storageService: StorageService,
    private translate: TranslateService,
    private usersService: UsersService,
  ) { }

  private setDate(date: Date) {
    const formatted = date.toLocaleDateString('fi');
    this.timesheet.date = formatted;
  }

  ngOnInit(): void {
    const user: User = this.navParams.get('user');
    if (user != null) {
      this.preUser = true;
      this.user$.next(user);
    }

    this.preSite = this.navParams.get('site');
    this.site$.next(this.preSite ?? null);

    const date = this.navParams.get('date') ?? new Date();
    const startDate = addHours(startOfDay(date), workdayStart);
    const endDate = addHours(startOfDay(date), workdayEnd);
    endDate.setMinutes(workdayEndMinutes);
    this.setDate(date);
    this.formGroup = this.formBuilder.group({
      site: [this.preSite?.guid ?? null, Validators.required],
      siteName: [this.preSite?.name ?? null],
      user: [user?.guid ?? '', Validators.required],
      username: [user?.displayName ?? ''],
      date: [date.toISOString(), Validators.required],
      dateIn: [getDateWithTimezone(startDate), Validators.required],
      dateOut: [getDateWithTimezone(endDate), Validators.required],
      checked: [false],
      contractor: [this.contractorsService.contractorS()?.guid],
      project: [null, Validators.required],
      projectName: [null],
      absence: [null],
      paymentTypes: this.formBuilder.array([]),
    });

    this.storageService.get<Options>(storageKeys.options).then((options) => this.milageAllowance = options.milageAllowance);
  }

  getFields() {
    return this.formGroup.get('paymentTypes') as FormArray;
  }

  onMilageAllowance(event: CustomEvent, type: string) {
    if (type === 'kmPay') {
      const value = +event.detail.value;
      const ret = (value * (this.milageAllowance ?? 0)).toFixed(2);
      this.milageAllowanceSummary.set(ret);
    }
  }

  onSelectPaymentType() {
    const existing = this.formGroup.get('paymentTypes').value as TimesheetPaymentType[];
    const items = this.paymentTypes.filter((it) => !existing.some((itt) => itt.paymentType === it.key));
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {

          } else {
            const newItem = this.formBuilder.group({
              paymentType: [data.data.key, Validators.required],
              paymentTypeUnit: [''],
              paymentTypeText: [''],
            });
            this.getFields().push(newItem);
          }
        }
      });
    });
  }

  removePaymentType(index: number) {
    this.getFields().removeAt(index);
  }

  onAbsence() {
    const items = this.absences;
    const clearButton = this.formGroup.get('absence').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('absence').setValue(null);
            this.formGroup.get('site').setValue(null);
            this.formGroup.get('project').setValue(null);
          } else {
            this.formGroup.get('absence').setValue(data.data.key);
            this.formGroup.get('site').setValue(absenceSite);
            this.formGroup.get('project').setValue(absenceSite);
          }
        }
      });
    });
  }

  onSelectSite(item: Site) {
    this.formGroup.get('site').setValue(item?.guid ?? null);
    this.formGroup.get('siteName').setValue(item?.name ?? null);
    this.site$.next(item);
  }

  onDateChange(value: string): void {
    const date = parseISO(value);
    this.setDate(date);
    this.formGroup.get('date').setValue(value);
  }

  onUser(selectedUser: SelectedUser): void {
    this.formGroup.get('user').setValue(selectedUser.guid);
    this.formGroup.get('username').setValue(selectedUser.displayName ?? selectedUser.name);
    if (this.preSite == null) {
      this.user$.next(selectedUser);
      this.formGroup.get('site').setValue('');
    }
  }

  onProject(items: SiteProject[]) {
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.formGroup.get('project').setValue(data.data.guid);
          this.formGroup.get('projectName').setValue(data.data.name);
        }
      });
    });
  }

  onSave() {
    const timesheet = this.formGroup.value;
    timesheet.date = this.timesheet.date;

    const date = parse(timesheet.date, 'dd.MM.yyyy', new Date());
    const dateIn = new Date(timesheet.dateIn);
    dateIn.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    timesheet.dateIn = dateIn;

    const dateOut = new Date(timesheet.dateOut);
    dateOut.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    timesheet.dateOut = dateOut;

    this.dismiss(timesheet);
  }

  dismiss(timesheet?: Timesheet) {
    this._modal.dismiss(timesheet);
  }
}
