import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { ListItem } from 'src/app/models/list-item.model';

@Component({
  standalone: true,
  selector: 'app-new-category-dialog',
  templateUrl: './new-category.dialog.html',
  styleUrls: ['./new-category.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class NewCategoryDialogComponent {
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _modal: ModalController,
  ) {

    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  removeLogo() {
    this.formGroup.get('logo').setValue(null);
  }

  onImageUrl(imageUrl: string) {
    this.formGroup.get('logo').setValue(imageUrl);
  }

  save() {
    const { name } = this.formGroup.value;
    const item: ListItem = {
      name,
    };
    this.dismiss(item);
  }

  dismiss(item?: ListItem) {
    this._modal.dismiss(item);
  }
}
