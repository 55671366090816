import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WeekdayPipe } from './weekday.pipe';

@NgModule({
  declarations: [WeekdayPipe],
  imports: [
    CommonModule,
  ],
  exports: [WeekdayPipe],
})
export class WeekDayModule { }
