<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'timesheet.title' | translate }}
  </div>
  <form [formGroup]="formGroup"
    (ngSubmit)="onSave()">
    @if (!preUser) {
      <app-select-user-button [items]="users$ | async"
        [selectedUser]="selectedUser$ | async"
        (selected)="onUser($event)"></app-select-user-button>
    }
    @if (preSite == null) {
      @if (formGroup.get('absence').value == null) {
        @if (sites$ | async; as sites) {
          <div>
            <app-select-site-button [items]="sites"
              [selectedSite]="site$ | async"
              (selected)="onSelectSite($event)"></app-select-site-button>
          </div>
        }
      }
    } @else {
      <div>
        <ion-button color="light">{{ preSite.name }}</ion-button>
      </div>
    }
    @if (projects$ | async; as projects) {
      <div>
        <ion-button color="light"
          (click)="onProject(projects)">
          @if (formGroup.get('projectName').value; as project) {
            {{ project }}
          } @else {
            {{ 'timesheet.project' | translate }}
          }
        </ion-button>
      </div>
    }
    @if (formGroup.get('absence').value == null) {
      <h4>{{ 'timesheet.date' | translate }}</h4>
      <div>
        <app-select-day color="light"
          [id]="'selectStartDay'"
          [format]="'d.M.'"
          [expand]="''"
          [value]="formGroup.get('date').value"
          (changed)="onDateChange($event)"></app-select-day>
      </div>
      <ion-item id="start"
        button="true">
        <ion-label>{{ 'timesheet.start' | translate }}</ion-label>
        <ion-text>{{ formGroup.get('dateIn').value | date:'HH:mm' }}</ion-text>
        <ion-popover [trigger]="'start'"
          show-backdrop="false"
          side="top">
          <ng-template>
            <ion-datetime presentation="time"
              locale="fi-FI"
              [showDefaultButtons]="true"
              [doneText]="'general.done' | translate"
              [cancelText]="'general.cancel' | translate"
              formControlName="dateIn"></ion-datetime>
          </ng-template>
        </ion-popover>
      </ion-item>
      <ion-item id="end"
        button="true">
        <ion-label>{{ 'timesheet.end' | translate }}</ion-label>
        <ion-text>{{ formGroup.get('dateOut').value | date:'HH:mm' }}</ion-text>
        <ion-popover [trigger]="'end'"
          show-backdrop="false"
          side="top">
          <ng-template>
            <ion-datetime presentation="time"
              locale="fi-FI"
              [showDefaultButtons]="true"
              [doneText]="'general.done' | translate"
              [cancelText]="'general.cancel' | translate"
              formControlName="dateOut"></ion-datetime>
          </ng-template>
        </ion-popover>
      </ion-item>
      <div>
        <ion-button color="secondary"
          shape="round"
          (click)="onSelectPaymentType()">
          <span>
            {{ 'timesheet.paymentType' | translate }}
          </span>
        </ion-button>
      </div>
      <form formArrayName="paymentTypes">
        @for (item of getFields().controls; track item; let i = $index) {
          <div>
            @if (showPaymentTypeUnit.includes(item.get('paymentType').value)) {
              <h4>{{ 'timesheet.paymentTypes.' + item.get('paymentType').value | translate }}</h4>
              <div class="payment-type ion-margin-bottom"
                [formGroup]="$any(item)">
                <ion-input autocapitalize="on"
                  formControlName="paymentTypeText"
                  [placeholder]="'timesheet.paymentTypeText' | translate"></ion-input>
                <ion-input autocapitalize="on"
                  formControlName="paymentTypeUnit"
                  [placeholder]="'timesheet.paymentTypeUnit' | translate"
                  type="number"
                  (ionInput)="onMilageAllowance($event, item.get('paymentType').value)"></ion-input>
                <ion-button color="danger"
                  fill="clear"
                  (click)="removePaymentType(i)">
                  <ion-icon slot="icon-only"
                    name="remove-circle-outline"></ion-icon>
                </ion-button>
              </div>
              @if (item.get('paymentType').value === 'kmPay') {
                <div class="flex-summary ion-margin-bottom">
                  <span>
                    {{ 'timesheet.paymentUnitValue' | translate }}
                    {{ milageAllowance }}€
                  </span>
                  <span>
                    {{ 'timesheet.paymentSummary' | translate }}
                    {{ milageAllowanceSummary() }}€
                  </span>
                </div>
              }
            } @else {
              <div class="ion-margin-top flex">
                {{ 'timesheet.paymentTypes.' + item.get('paymentType').value | translate }}
                <ion-button color="danger"
                  fill="clear"
                  (click)="removePaymentType(i)">
                  <ion-icon slot="icon-only"
                    name="remove-circle-outline"></ion-icon>
                </ion-button>
              </div>
            }
          </div>
        }
      </form>
    }
    <div class="ion-margin-top flex">
      <ion-button color="light"
        shape="round"
        (click)="onSave()"
        [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
      <ion-button color="secondary"
        shape="round"
        (click)="onAbsence()">
        @if (formGroup.get('absence').value) {
          <span>
            {{ 'timesheet.absences.' + formGroup.get('absence').value | translate }}
          </span>
        } @else {
          {{ 'timesheet.absence' | translate }}
        }
      </ion-button>
    </div>
  </form>
</app-main-dialog>