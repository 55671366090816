import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';
import { AppCanvasComponent } from './canvas.component';

@Component({
  standalone: true,
  selector: 'app-blueprint-dialog',
  templateUrl: './blueprint.dialog.html',
  styleUrls: ['./blueprint.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    AppCanvasComponent,
    MainDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BlueprintDialogComponent {

  blueprintS = signal<string>(null);
  titleS = signal<string>(null);

  height: number = null;
  width: number = null;
  scaleX: number = null;
  scaleY: number = null;

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.blueprintS.set(navParams.get('blueprint'));
    this.titleS.set(navParams.get('title'));
  }

  dismiss() {
    this._modal.dismiss();
  }
}
