import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LoadingModule } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { NewCategoryInstructionModule } from 'src/app/instructions/new/category/new-category.module';
import { NewItemInstructionModule } from 'src/app/instructions/new/item/new-item.module';
import { SiteCalendarDialogModule } from 'src/app/working-calendar/site/dialog/site-calendar-dialog.module';
import { SelectSiteDialog } from '../select-site/select-site.dialog';
import { AdhocComponent } from './adhoc.component';

@NgModule({
  declarations: [AdhocComponent],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    SiteCalendarDialogModule,
    SelectSiteDialog,
    NewCategoryInstructionModule,
    NewItemInstructionModule,
    LoadingModule,
  ],
  exports: [AdhocComponent],
})
export class AdhocModule { }
