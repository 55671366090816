<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ header | translate }}
  </div>
  <div class="content">
    <p>{{ message | translate }}</p>
    @if (button) {
    <div class="ion-text-center full-width">
      <ion-button color="light"
        shape="round"
        (click)="dismiss()">{{ 'general.ok' | translate }}</ion-button>
    </div>
    }
  </div>
</app-main-dialog>