<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'kanban.acceptWork' | translate }}
  </div>
  <div class="ticket-block">
    <div class="ticket-container">
      <div>
        <ion-avatar>
          @if (ticket.userImage) {
            <img [src]="ticket.userImage" />
          } @else {
            <ion-icon name="person-circle-outline"></ion-icon>
          }
        </ion-avatar>
      </div>
      <div class="ticket-title">
        <div>
          @if (ticket.username) {
            <span>
              {{ ticket.username }}
            </span>
          } @else {
            @if (ticket.additionalWork && ticket.additionalWorkInfo?.client) {
              <span>
                {{ ticket.additionalWorkInfo.client }}
              </span>
            }
          }
          <div>
            {{ 'kanban.proposesWork' | translate }}
          </div>
        </div>
        <div class="ticket-info">
          @if (ticket.phase) {
            <ion-icon name="list-outline"></ion-icon>
            {{ ticket.phase }}
          }
          <ion-icon name="time-outline"></ion-icon>
          {{ ticket.hours }}
        </div>
      </div>
      <div class="progress-container">
        @if (ticket.start) {
          <div>
            {{ ticket.start.timestamp | date:'H:mm' }}
          </div>
        }
      </div>
    </div>
    <div class="ticket-container">
      <div></div>
      <div class="speech-container">
        <div class="pointer"></div>
        <ion-item class="ticket"
          lines="none">
          <ion-label class="ion-text-wrap">
            {{ ticket.room.id }}:
            {{ ticket.name }}<br />
            {{ ticket.descriptionText | translateField }}
          </ion-label>
        </ion-item>
        @if (ticket.images) {
          <div class="images">
            <app-images [images]="ticket.images"></app-images>
          </div>
        }
        @if (ticket.progress?.length > 0) {
        }
      </div>
      <div class="progress-border-container">
        <div class="progress-border">
          <div class="border"></div>
        </div>
      </div>
    </div>
    @if (ticket.additionalWork && !ticket.username && ticket.additionalWorkInfo?.clientTimestamp) {
      <div class="ticket-container">
        <div></div>
        <div class="ion-margin-start">
          <ion-item class="ticket"
            lines="none">
            <div class="ion-padding-top ion-padding-bottom">
              {{ ticket.additionalWorkInfo.client }}
              <div>
                {{ ticket.descriptionText | translateField }}
              </div>
            </div>
          </ion-item>
        </div>
        <div>
          <div class="progress-container">
            {{ ticket.additionalWorkInfo.clientTimestamp | date:'H:mm' }}
          </div>
          <div class="progress-border">
            <div class="border"></div>
          </div>
        </div>
      </div>
    }
    @for (note of notes; track note) {
      <div class="ticket-container">
        <div></div>
        <div class="ion-margin-start">
          <ion-item class="ticket"
            lines="none">
            <div class="ion-padding-top ion-padding-bottom">
              {{ note.username }}
              <div>
                {{ note.text }}
              </div>
              @if (note.hours) {
                <div>
                  {{ 'kanban.proposedHours' | translate }}: {{ note.hours }}h
                </div>
              }
            </div>
          </ion-item>
        </div>
        <div>
          <div class="progress-container">
            {{ note.timestamp | date:'H:mm' }}
          </div>
          <div class="progress-border">
            <div class="border"></div>
          </div>
        </div>
      </div>
    }
    <div class="ticket-container">
      <div></div>
      <div class="ion-margin-start">
        <ion-item class="ticket"
          lines="none">
          <div class="ion-padding-bottom">
            <h4>{{ 'kanban.acceptanceTermsTitle' | translate }}</h4>
            <form [formGroup]="formGroup">
              <ion-item color="primary"
                lines="none">
                <ion-textarea autocapitalize="on"
                  formControlName="comment"
                  rows="5"
                  [placeholder]="'additionalInfo.comment' | translate">
                </ion-textarea>
              </ion-item>
              <ion-button id="duration"
                color="primary"
                shape="round">
                @if (formGroup.get('hours').value; as hours) {
                  <span>
                    {{ hours }}h
                  </span>
                } @else {
                  {{ 'calendar.site.duration' | translate }}
                }
              </ion-button>
              <ion-popover #durationPopover
                trigger="duration"
                triggerAction="click">
                <ng-template>
                  <ion-list>
                    @for (item of durations; track item) {
                      <ion-item (click)="onDuration(item.value, durationPopover)">
                        {{ item.text }}
                      </ion-item>
                    }
                  </ion-list>
                </ng-template>
              </ion-popover>
            </form>
            @if ({
            changed: changed$ | async
            }; as vm) {
              <div>
                @if (role !== 'manager' || !vm.changed) {
                  <ion-button color="light"
                    shape="round"
                    (click)="onAccept()">
                    <ion-icon slot="start"
                      name="checkmark-outline"></ion-icon>
                    {{ 'general.accept' | translate }}
                  </ion-button>
                }
                @if (role === 'manager' && vm.changed) {
                  <ion-button color="light"
                    shape="round"
                    (click)="onChange()">
                    {{ 'general.proposeChange' | translate }}
                  </ion-button>
                }
                <ion-button color="tertiary"
                  shape="round"
                  (click)="onDecline()">
                  <ion-icon slot="start"
                    name="close-outline"></ion-icon>
                  {{ 'general.decline' | translate }}
                </ion-button>
              </div>
            }
          </div>
        </ion-item>
      </div>
      <div>
        <div class="progress-border">
          <div class="border"></div>
        </div>
      </div>
    </div>
  </div>
</app-main-dialog>